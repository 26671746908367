/* eslint-disable*/
import React, { useState, useEffect, useRef, useContext } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  CommandContainerWidget,
  ButtonWidget,
  GridWidget,
  LabelWidget,
  setValue,
  getValue,
  setData,
  getData,
  hide,
  show,
  hideColumn,
  goTo
} from "../../shared/WindowImports";

import "./ViewWRApplications.scss";
import { WarehouseReceiptService } from "../Service/WarehouseReceiptService";
import CommonContext from "../../Store/CommonContext";
import StripPic from "../../../../assets/img/PinStrip.png";
// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
// END_USER_CODE-USER_IMPORTS
function WarehouseReceipts_ViewWRApplications(props) {
  const commonContext1 = useContext(CommonContext);
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "ViewWRApplications",
    windowName: "ViewWRApplications",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "WarehouseReceipts.ViewWRApplications",
    // START_USER_CODE-USER_ViewWRApplications_PROPERTIES
    horizontalForm : true,
    headerData: {
      scrName: "Warehouse Receipt Contract Applications",
      scrCode: "PN1090D",
    },
    // END_USER_CODE-USER_ViewWRApplications_PROPERTIES
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    col1SGA: {
      name: "col1SGA",
      type: "GridColumnWidget",
      parent: "gridSeedGrowerApplications",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_col1SGA_PROPERTIES

      // END_USER_CODE-USER_col1SGA_PROPERTIES
    },
    colAppliedLbsAdj: {
      name: "colAppliedLbsAdj",
      type: "GridColumnWidget",
      parent: "gridContractApplications",
      Label: "Applied Pounds Adjustments",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAppliedLbsAdj_PROPERTIES

      // END_USER_CODE-USER_colAppliedLbsAdj_PROPERTIES
    },
    colAppliedLbsSGA: {
      name: "colAppliedLbsSGA",
      type: "GridColumnWidget",
      parent: "gridSeedGrowerApplications",
      Label: "Applied Pounds",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAppliedLbsSGA_PROPERTIES

      // END_USER_CODE-USER_colAppliedLbsSGA_PROPERTIES
    },
    colBasis: {
      name: "colBasis",
      type: "GridColumnWidget",
      parent: "gridContractApplications",
      Label: "Basis",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colBasis_PROPERTIES

      // END_USER_CODE-USER_colBasis_PROPERTIES
    },
    colBasisSGA: {
      name: "colBasisSGA",
      type: "GridColumnWidget",
      parent: "gridSeedGrowerApplications",
      Label: "Basis",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colBasisSGA_PROPERTIES

      // END_USER_CODE-USER_colBasisSGA_PROPERTIES
    },
    colBuyPt: {
      name: "colBuyPt",
      type: "GridColumnWidget",
      parent: "gridContractApplications",
      Label: "Buy Point",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colBuyPt_PROPERTIES

      // END_USER_CODE-USER_colBuyPt_PROPERTIES
    },
    colBuyPtSGA: {
      name: "colBuyPtSGA",
      type: "GridColumnWidget",
      parent: "gridSeedGrowerApplications",
      Label: "Buy Point",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colBuyPtSGA_PROPERTIES

      // END_USER_CODE-USER_colBuyPtSGA_PROPERTIES
    },
    colContractDate: {
      name: "colContractDate",
      type: "GridColumnWidget",
      parent: "gridContractApplications",
      Label: "Contract Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colContractDate_PROPERTIES

      // END_USER_CODE-USER_colContractDate_PROPERTIES
    },
    colContractDateSGA: {
      name: "colContractDateSGA",
      type: "GridColumnWidget",
      parent: "gridSeedGrowerApplications",
      Label: "Contract Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colContractDateSGA_PROPERTIES

      // END_USER_CODE-USER_colContractDateSGA_PROPERTIES
    },
    colContractLbsSGA: {
      name: "colContractLbsSGA",
      type: "GridColumnWidget",
      parent: "gridSeedGrowerApplications",
      Label: "Contract Pounds",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colContractLbsSGA_PROPERTIES

      // END_USER_CODE-USER_colContractLbsSGA_PROPERTIES
    },
    colContractNumber: {
      name: "colContractNumber",
      type: "GridColumnWidget",
      parent: "gridContractApplications",
      Label: "Contract Number",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colContractNumber_PROPERTIES

      // END_USER_CODE-USER_colContractNumber_PROPERTIES
    },
    colContractNumberSGA: {
      name: "colContractNumberSGA",
      type: "GridColumnWidget",
      parent: "gridSeedGrowerApplications",
      Label: "Contract Number",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colContractNumberSGA_PROPERTIES

      // END_USER_CODE-USER_colContractNumberSGA_PROPERTIES
    },
    colContType: {
      name: "colContType",
      type: "GridColumnWidget",
      parent: "gridContractApplications",
      Label: "Cont Type",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colContType_PROPERTIES

      // END_USER_CODE-USER_colContType_PROPERTIES
    },
    colContTypeSGA: {
      name: "colContTypeSGA",
      type: "GridColumnWidget",
      parent: "gridSeedGrowerApplications",
      Label: "Cont Type",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colContTypeSGA_PROPERTIES

      // END_USER_CODE-USER_colContTypeSGA_PROPERTIES
    },
    colDelvEndDt: {
      name: "colDelvEndDt",
      type: "GridColumnWidget",
      parent: "gridContractApplications",
      Label: "Delivery End Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colDelvEndDt_PROPERTIES

      // END_USER_CODE-USER_colDelvEndDt_PROPERTIES
    },
    colDelvStartDt: {
      name: "colDelvStartDt",
      type: "GridColumnWidget",
      parent: "gridContractApplications",
      Label: "Delivery Start Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colDelvStartDt_PROPERTIES

      // END_USER_CODE-USER_colDelvStartDt_PROPERTIES
    },
    colGrouping: {
      name: "colGrouping",
      type: "GridColumnWidget",
      parent: "gridContractApplications",
      Label: "Grouping",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colGrouping_PROPERTIES

      // END_USER_CODE-USER_colGrouping_PROPERTIES
    },
    colOpenLbsSGA: {
      name: "colOpenLbsSGA",
      type: "GridColumnWidget",
      parent: "gridSeedGrowerApplications",
      Label: "Open Pounds",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOpenLbsSGA_PROPERTIES

      // END_USER_CODE-USER_colOpenLbsSGA_PROPERTIES
    },
    colPeanutVariety: {
      name: "colPeanutVariety",
      type: "GridColumnWidget",
      parent: "gridContractApplications",
      Label: "Peanut Variety",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPeanutVariety_PROPERTIES

      // END_USER_CODE-USER_colPeanutVariety_PROPERTIES
    },
    colPeanutVarietySGA: {
      name: "colPeanutVarietySGA",
      type: "GridColumnWidget",
      parent: "gridSeedGrowerApplications",
      Label: "Peanut Variety",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPeanutVarietySGA_PROPERTIES

      // END_USER_CODE-USER_colPeanutVarietySGA_PROPERTIES
    },
    colPoundsToApplySGA: {
      name: "colPoundsToApplySGA",
      type: "GridColumnWidget",
      parent: "gridSeedGrowerApplications",
      Label: "Pounds To Apply",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPoundsToApplySGA_PROPERTIES

      // END_USER_CODE-USER_colPoundsToApplySGA_PROPERTIES
    },
    colPrice: {
      name: "colPrice",
      type: "GridColumnWidget",
      parent: "gridContractApplications",
      Label: "Price",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPrice_PROPERTIES

      // END_USER_CODE-USER_colPrice_PROPERTIES
    },
    colPriceSGA: {
      name: "colPriceSGA",
      type: "GridColumnWidget",
      parent: "gridSeedGrowerApplications",
      Label: "Price",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPriceSGA_PROPERTIES

      // END_USER_CODE-USER_colPriceSGA_PROPERTIES
    },
    colSeed: {
      name: "colSeed",
      type: "GridColumnWidget",
      parent: "gridContractApplications",
      Label: "Seed",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSeed_PROPERTIES

      // END_USER_CODE-USER_colSeed_PROPERTIES
    },
    colSeedSGA: {
      name: "colSeedSGA",
      type: "GridColumnWidget",
      parent: "gridSeedGrowerApplications",
      Label: "Seed",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSeedSGA_PROPERTIES

      // END_USER_CODE-USER_colSeedSGA_PROPERTIES
    },
    colTentLbsAdj: {
      name: "colTentLbsAdj",
      type: "GridColumnWidget",
      parent: "gridContractApplications",
      Label: "Tentative Pound Adjustments",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colTentLbsAdj_PROPERTIES

      // END_USER_CODE-USER_colTentLbsAdj_PROPERTIES
    },
    btnCol1: {
      name: "btnCol1",
      type: "ButtonWidget",
      colName: "col1",
      parent: "gridContractApplications",
      CharWidth: "4",
      Height: "",
      Width: "",
      HasLabel: false,
      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCol2_PROPERTIES

      // END_USER_CODE-USER_btnCol2_PROPERTIES
    },
    col1: {
      name: "col1",
      type: "GridColumnWidget",
      parent: "gridContractApplications",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_col2_PROPERTIES

      // END_USER_CODE-USER_col2_PROPERTIES
    },
    btnCol2: {
      name: "btnCol2",
      type: "ButtonWidget",
      colName: "col2",
      parent: "gridSeedGrowerApplications",
      CharWidth: "4",
      Height: "",
      Width: "",
      HasLabel: false,
      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCol2_PROPERTIES

      // END_USER_CODE-USER_btnCol2_PROPERTIES
    },
    col2: {
      name: "col2",
      type: "GridColumnWidget",
      parent: "gridSeedGrowerApplications",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_col2_PROPERTIES

      // END_USER_CODE-USER_col2_PROPERTIES
    },
    gridContractApplications: {
      name: "gridContractApplications",
      type: "GridWidget",
      parent: "grpbxContractApplication",
      gridCellsOrder:
        "btnCol1,txtcolGrouping,txtcolBuyPt,txtcolContractNumber,txtcolContractDate,txtcolPeanutVariety,txtcolSeed,txtcolBasis,txtcolContType,txtcolDelvStartDt,txtcolDelvEndDt,txtcolAppliedLbsAdj,txtcolTentLbsAdj,txtcolPrice",
      Pagination: false,
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridContractApplications_PROPERTIES

      // END_USER_CODE-USER_gridContractApplications_PROPERTIES
    },
    gridSeedGrowerApplications: {
      name: "gridSeedGrowerApplications",
      type: "GridWidget",
      parent: "grpbxSeedGroverApplications",
      gridCellsOrder:
        "btnCol2,txtcol1SGA,txtcolBuyPtSGA,txtcolContractNumberSGA,txtcolContractDateSGA,txtcolPeanutVarietySGA,txtcolSeedSGA,txtcolBasisSGA,txtcolContTypeSGA,txtcolPoundsToApplySGA,txtcolContractLbsSGA,txtcolAppliedLbsSGA,txtcolOpenLbsSGA,txtcolPriceSGA",
      Pagination: false,
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridSeedGrowerApplications_PROPERTIES

      // END_USER_CODE-USER_gridSeedGrowerApplications_PROPERTIES
    },
    grpbxContractApplication: {
      name: "grpbxContractApplication",
      type: "GroupBoxWidget",
      parent: "grpbxViewWRApplications",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxContractApplication_PROPERTIES

      // END_USER_CODE-USER_grpbxContractApplication_PROPERTIES
    },
    grpbxDetails: {
      name: "grpbxDetails",
      type: "GroupBoxWidget",
      parent: "grpbxViewWRApplications",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxDetails_PROPERTIES

      // END_USER_CODE-USER_grpbxDetails_PROPERTIES
    },
    grpbxSeedGroverApplications: {
      name: "grpbxSeedGroverApplications",
      type: "GroupBoxWidget",
      parent: "grpbxViewWRApplications",
      ColSpan: "2",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxSeedGroverApplications_PROPERTIES

      // END_USER_CODE-USER_grpbxSeedGroverApplications_PROPERTIES
    },
    grpbxSpotApplication: {
      name: "grpbxSpotApplication",
      type: "GroupBoxWidget",
      parent: "grpbxViewWRApplications",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForMobile: "2",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxSpotApplication_PROPERTIES

      // END_USER_CODE-USER_grpbxSpotApplication_PROPERTIES
    },
    lblContractApplications: {
      name: "lblContractApplications",
      type: "LabelWidget",
      parent: "grpbxContractApplication",
      Label: "Contract Applications",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblContractApplications_PROPERTIES

      // END_USER_CODE-USER_lblContractApplications_PROPERTIES
    },
    lblSeedGrowerApplications: {
      name: "lblSeedGrowerApplications",
      type: "LabelWidget",
      parent: "grpbxSeedGroverApplications",
      Label: "Seed Grower Applications",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblSeedGrowerApplications_PROPERTIES

      // END_USER_CODE-USER_lblSeedGrowerApplications_PROPERTIES
    },
    lblSpotApplication: {
      name: "lblSpotApplication",
      type: "LabelWidget",
      parent: "grpbxSpotApplication",
      Label: "Spot Application",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblSpotApplication_PROPERTIES

      // END_USER_CODE-USER_lblSpotApplication_PROPERTIES
    },
    txtcol1SGA: {
      name: "txtcol1SGA",
      type: "TextBoxWidget",
      colName: "col1SGA",
      parent: "gridSeedGrowerApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcol1SGA_PROPERTIES

      // END_USER_CODE-USER_txtcol1SGA_PROPERTIES
    },
    txtcolAppliedLbsAdj: {
      name: "txtcolAppliedLbsAdj",
      type: "TextBoxWidget",
      colName: "colAppliedLbsAdj",
      parent: "gridContractApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolAppliedLbsAdj_PROPERTIES

      // END_USER_CODE-USER_txtcolAppliedLbsAdj_PROPERTIES
    },
    txtcolAppliedLbsSGA: {
      name: "txtcolAppliedLbsSGA",
      type: "TextBoxWidget",
      colName: "colAppliedLbsSGA",
      parent: "gridSeedGrowerApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolAppliedLbsSGA_PROPERTIES

      // END_USER_CODE-USER_txtcolAppliedLbsSGA_PROPERTIES
    },
    txtcolBasis: {
      name: "txtcolBasis",
      type: "TextBoxWidget",
      colName: "colBasis",
      parent: "gridContractApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolBasis_PROPERTIES

      // END_USER_CODE-USER_txtcolBasis_PROPERTIES
    },
    txtcolBasisSGA: {
      name: "txtcolBasisSGA",
      type: "TextBoxWidget",
      colName: "colBasisSGA",
      parent: "gridSeedGrowerApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolBasisSGA_PROPERTIES

      // END_USER_CODE-USER_txtcolBasisSGA_PROPERTIES
    },
    txtcolBuyPt: {
      name: "txtcolBuyPt",
      type: "TextBoxWidget",
      colName: "colBuyPt",
      parent: "gridContractApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolBuyPt_PROPERTIES

      // END_USER_CODE-USER_txtcolBuyPt_PROPERTIES
    },
    txtcolBuyPtSGA: {
      name: "txtcolBuyPtSGA",
      type: "TextBoxWidget",
      colName: "colBuyPtSGA",
      parent: "gridSeedGrowerApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolBuyPtSGA_PROPERTIES

      // END_USER_CODE-USER_txtcolBuyPtSGA_PROPERTIES
    },
    txtcolContractDate: {
      name: "txtcolContractDate",
      type: "TextBoxWidget",
      colName: "colContractDate",
      parent: "gridContractApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolContractDate_PROPERTIES

      // END_USER_CODE-USER_txtcolContractDate_PROPERTIES
    },
    txtcolContractDateSGA: {
      name: "txtcolContractDateSGA",
      type: "TextBoxWidget",
      colName: "colContractDateSGA",
      parent: "gridSeedGrowerApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolContractDateSGA_PROPERTIES

      // END_USER_CODE-USER_txtcolContractDateSGA_PROPERTIES
    },
    txtcolContractLbsSGA: {
      name: "txtcolContractLbsSGA",
      type: "TextBoxWidget",
      colName: "colContractLbsSGA",
      parent: "gridSeedGrowerApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolContractLbsSGA_PROPERTIES

      // END_USER_CODE-USER_txtcolContractLbsSGA_PROPERTIES
    },
    txtcolContractNumber: {
      name: "txtcolContractNumber",
      type: "TextBoxWidget",
      colName: "colContractNumber",
      parent: "gridContractApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolContractNumber_PROPERTIES

      // END_USER_CODE-USER_txtcolContractNumber_PROPERTIES
    },
    txtcolContractNumberSGA: {
      name: "txtcolContractNumberSGA",
      type: "TextBoxWidget",
      colName: "colContractNumberSGA",
      parent: "gridSeedGrowerApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolContractNumberSGA_PROPERTIES

      // END_USER_CODE-USER_txtcolContractNumberSGA_PROPERTIES
    },
    txtcolContType: {
      name: "txtcolContType",
      type: "TextBoxWidget",
      colName: "colContType",
      parent: "gridContractApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolContType_PROPERTIES

      // END_USER_CODE-USER_txtcolContType_PROPERTIES
    },
    txtcolContTypeSGA: {
      name: "txtcolContTypeSGA",
      type: "TextBoxWidget",
      colName: "colContTypeSGA",
      parent: "gridSeedGrowerApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolContTypeSGA_PROPERTIES

      // END_USER_CODE-USER_txtcolContTypeSGA_PROPERTIES
    },
    txtcolDelvEndDt: {
      name: "txtcolDelvEndDt",
      type: "TextBoxWidget",
      colName: "colDelvEndDt",
      parent: "gridContractApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolDelvEndDt_PROPERTIES

      // END_USER_CODE-USER_txtcolDelvEndDt_PROPERTIES
    },
    txtcolDelvStartDt: {
      name: "txtcolDelvStartDt",
      type: "TextBoxWidget",
      colName: "colDelvStartDt",
      parent: "gridContractApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolDelvStartDt_PROPERTIES

      // END_USER_CODE-USER_txtcolDelvStartDt_PROPERTIES
    },
    txtcolGrouping: {
      name: "txtcolGrouping",
      type: "TextBoxWidget",
      colName: "colGrouping",
      parent: "gridContractApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolGrouping_PROPERTIES

      // END_USER_CODE-USER_txtcolGrouping_PROPERTIES
    },
    txtcolOpenLbsSGA: {
      name: "txtcolOpenLbsSGA",
      type: "TextBoxWidget",
      colName: "colOpenLbsSGA",
      parent: "gridSeedGrowerApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolOpenLbsSGA_PROPERTIES

      // END_USER_CODE-USER_txtcolOpenLbsSGA_PROPERTIES
    },
    txtcolPeanutVariety: {
      name: "txtcolPeanutVariety",
      type: "TextBoxWidget",
      colName: "colPeanutVariety",
      parent: "gridContractApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPeanutVariety_PROPERTIES

      // END_USER_CODE-USER_txtcolPeanutVariety_PROPERTIES
    },
    txtcolPeanutVarietySGA: {
      name: "txtcolPeanutVarietySGA",
      type: "TextBoxWidget",
      colName: "colPeanutVarietySGA",
      parent: "gridSeedGrowerApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPeanutVarietySGA_PROPERTIES

      // END_USER_CODE-USER_txtcolPeanutVarietySGA_PROPERTIES
    },
    txtcolPoundsToApplySGA: {
      name: "txtcolPoundsToApplySGA",
      type: "TextBoxWidget",
      colName: "colPoundsToApplySGA",
      parent: "gridSeedGrowerApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPoundsToApplySGA_PROPERTIES

      // END_USER_CODE-USER_txtcolPoundsToApplySGA_PROPERTIES
    },
    txtcolPrice: {
      name: "txtcolPrice",
      type: "TextBoxWidget",
      colName: "colPrice",
      parent: "gridContractApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPrice_PROPERTIES

      // END_USER_CODE-USER_txtcolPrice_PROPERTIES
    },
    txtcolPriceSGA: {
      name: "txtcolPriceSGA",
      type: "TextBoxWidget",
      colName: "colPriceSGA",
      parent: "gridSeedGrowerApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPriceSGA_PROPERTIES

      // END_USER_CODE-USER_txtcolPriceSGA_PROPERTIES
    },
    txtcolSeed: {
      name: "txtcolSeed",
      type: "TextBoxWidget",
      colName: "colSeed",
      parent: "gridContractApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolSeed_PROPERTIES

      // END_USER_CODE-USER_txtcolSeed_PROPERTIES
    },
    txtcolSeedSGA: {
      name: "txtcolSeedSGA",
      type: "TextBoxWidget",
      colName: "colSeedSGA",
      parent: "gridSeedGrowerApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolSeedSGA_PROPERTIES

      // END_USER_CODE-USER_txtcolSeedSGA_PROPERTIES
    },
    txtcolTentLbsAdj: {
      name: "txtcolTentLbsAdj",
      type: "TextBoxWidget",
      colName: "colTentLbsAdj",
      parent: "gridContractApplications",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolTentLbsAdj_PROPERTIES

      // END_USER_CODE-USER_txtcolTentLbsAdj_PROPERTIES
    },
    txtLbsToApply: {
      name: "txtLbsToApply",
      type: "TextBoxWidget",
      parent: "grpbxSpotApplication",
      Label: "Lbs to Apply:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLbsToApply_PROPERTIES

      // END_USER_CODE-USER_txtLbsToApply_PROPERTIES
    },
    txtObligationLocation: {
      name: "txtObligationLocation",
      type: "TextBoxWidget",
      parent: "grpbxDetails",
      Label: "Obligation Location",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtObligationLocation_PROPERTIES

      // END_USER_CODE-USER_txtObligationLocation_PROPERTIES
    },
    txtSpotPrice: {
      name: "txtSpotPrice",
      type: "TextBoxWidget",
      parent: "grpbxSpotApplication",
      Label: "Spot Price:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSpotPrice_PROPERTIES

      // END_USER_CODE-USER_txtSpotPrice_PROPERTIES
    },
    txtUnloadLocation: {
      name: "txtUnloadLocation",
      type: "TextBoxWidget",
      parent: "grpbxDetails",
      Label: "Unload Location",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtUnloadLocation_PROPERTIES

      // END_USER_CODE-USER_txtUnloadLocation_PROPERTIES
    },
    txtWarehouseReceiptNum: {
      name: "txtWarehouseReceiptNum",
      type: "TextBoxWidget",
      parent: "grpbxDetails",
      Label: "Warehouse Receipt #",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWarehouseReceiptNum_PROPERTIES

      // END_USER_CODE-USER_txtWarehouseReceiptNum_PROPERTIES
    },
    grpbxViewWRApplications: {
      name: "grpbxViewWRApplications",
      type: "GroupBoxWidget",
      parent: "ViewWRApplications",
      Height: "",
      Width: "",
      clonedExtId: "86902",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxViewWRApplications_PROPERTIES

      // END_USER_CODE-USER_grpbxViewWRApplications_PROPERTIES
    },
    cmmndCntnrActions: {
      name: "cmmndCntnrActions",
      type: "CommandContainerWidget",
      parent: "ViewWRApplications",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrActions_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    _kaledo.thisObj = thisObj
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    LoadApplicationData();
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS

  const LoadApplicationData = () => {
    let parentData = getData(thisObj, 'frmViewWRApplicationsData')
    let WRnum = parentData.lblWRNum
    let buy_pt_id = parentData.lblBuyPtId
    let UnloadLocation = parentData.lblUnloadBuyPtName  
    let ObligationLocation = parentData.lblBuyPtName

    setValue(thisObj, 'txtWarehouseReceiptNum', WRnum)
    setValue(thisObj, 'txtUnloadLocation', UnloadLocation)
    setValue(thisObj, 'txtObligationLocation', ObligationLocation)
    
    if (WRnum != null && buy_pt_id != undefined) {
      WarehouseReceiptService.RetrieveWareHouseTentPurchContractApplicationDetails(buy_pt_id, WRnum)
        .then(response => {
            if (response.length != 0) {
              let WRContAppList = response[0].whouse_tent_purch_contract_application_list
              let WRSeedAppList = response[0].whouse_tent_purchase_seed_grower_application_list
              let WRHeaders = response[0].whouse_recipt_headers
              let ContApplist = []
              let ContAppObj = {}
              for (var j = 0; j < WRContAppList.length; j++) {
                ContAppObj.txtcolGrouping = WRContAppList[j].grouping_num
                ContAppObj.txtcolBuyPt = WRContAppList[j].buy_pt_id
                ContAppObj.txtcolContractNumber = WRContAppList[j].contract_num
                ContAppObj.txtcolContractDate = moment(WRContAppList[j].cont_date).format('MM/DD/YYYY')
                let symbolind = WRContAppList[j].symbol_ind
                let symbolmark = ''
                if(symbolind == "TRADEMARK")
                {
                  symbolmark = " " + Chr(153)
                } else if(symbolind == "REGISTERED TRADEMARK")
                {
                  symbolmark = " " + Chr(174)
                } else if(symbolind == "COPYRIGHT")
                {
                  symbolmark = " " + Chr(169)
                }else
                {
                  symbolmark = ""
                }
                ContAppObj.txtcolPeanutVariety = WRContAppList[j].pnut_variety_name + symbolmark
                ContAppObj.txtcolSeed = WRContAppList[j].seed_grower
                ContAppObj.txtcolBasis = WRContAppList[j].firm_basis
                ContAppObj.txtcolContType = WRContAppList[j].cont_type
                ContAppObj.txtcolDelvStartDt = moment(WRContAppList[j].delv_beg_date).format('MM/DD/YYYY')
                ContAppObj.txtcolDelvEndDt = moment(WRContAppList[j].delv_end_date).format('MM/DD/YYYY')
                ContAppObj.txtcolAppliedLbsAdj = WRContAppList[j].appl_lbs_adj
                ContAppObj.txtcolTentLbsAdj = WRContAppList[j].tent_lbs_adj
                ContAppObj.txtcolPrice = WRContAppList[j].price_per_ton
                ContApplist.push(ContAppObj)
                ContAppObj = {}
              }
              setValue(thisObj, 'gridContractApplications', ContApplist)

              let SeedApplist = []
              let SeedAppObj = {}
              for (var j = 0; j < WRSeedAppList.length; j++) {
                
                SeedAppObj.txtcol1SGA = ""
                SeedAppObj.txtcolBuyPtSGA = WRSeedAppList[j].buy_pt_id
                SeedAppObj.txtcolContractNumberSGA = WRSeedAppList[j].contract_num
                SeedAppObj.txtcolContractDateSGA = moment(WRSeedAppList[j].cont_date).format('MM/DD/YYYY')
                let symbolind = WRSeedAppList[j].symbol_ind
                let symbolmark = ''
                if(symbolind == "TRADEMARK")
                {
                  symbolmark = " " + Chr(153)
                } else if(symbolind == "REGISTERED TRADEMARK")
                {
                  symbolmark = " " + Chr(174)
                } else if(symbolind == "COPYRIGHT")
                {
                  symbolmark = " " + Chr(169)
                }else
                {
                  symbolmark = ""
                }
                SeedAppObj.txtcolPeanutVarietySGA = WRSeedAppList[j].pnut_variety_name + symbolmark
                SeedAppObj.txtcolSeedSGA = WRSeedAppList[j].seed_grower
                SeedAppObj.txtcolBasisSGA = ""
                SeedAppObj.txtcolContTypeSGA = WRSeedAppList[j].cont_type
                SeedAppObj.txtcolPoundsToApplySGA = WRSeedAppList[j].appl_lbs_adj
                SeedAppObj.txtcolContractLbsSGA = WRSeedAppList[j].cont_lbs
                SeedAppObj.txtcolAppliedLbsSGA = WRSeedAppList[j].appl_lbs
                SeedAppObj.txtcolOpenLbsSGA = Number(WRSeedAppList[j].cont_lbs) - Number(WRSeedAppList[j].appl_lbs)
                SeedAppObj.txtcolPriceSGA = WRSeedAppList[j].prem_per_ton
                SeedApplist.push(SeedAppObj)
                SeedAppObj = {}
              }
              setValue(thisObj, 'gridSeedGrowerApplications', SeedApplist)
              hideColumn(thisObj, "gridSeedGrowerApplications", "txtcol1SGA")
              hideColumn(thisObj, "gridSeedGrowerApplications", "txtcolSeedSGA")
              hideColumn(thisObj, "gridSeedGrowerApplications", "txtcolBasisSGA")
              hideColumn(thisObj, "gridSeedGrowerApplications", "txtcolContTypeSGA")
              if (WRHeaders.length > 0){
                for (var j = 0; j < WRHeaders.length; j++) {
                  setValue(thisObj, 'txtLbsToApply', WRHeaders[j].spot_pounds)
                  setValue(thisObj, 'txtSpotPrice', WRHeaders[j].spot_price)
                }
              } else {
                setValue(thisObj, 'txtLbsToApply', '0')
                setValue(thisObj, 'txtSpotPrice', '0.00')
              }
            }
          });
    }
    return true;
  }

  const onbtnCancelClick = () => {
    try {
      setData(thisObj, 'frmViewWarehouseReceiptFormReLoad', { flag : true } )
      document.getElementById("WarehouseReceipts_ViewWRApplicationsPopUp").childNodes[0].click();
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnCancel event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCancelClick = onbtnCancelClick;

  const onbtnCol1Click = async (event) => {
    try {
     let highlight = document.getElementsByClassName("activeRow")
     if (highlight.length > 0) {
       highlight[0].classList.remove("activeRow")
     }
     let rowSelected = event.target.parentElement.parentElement.parentElement
     rowSelected.classList.add("activeRow")
     let rowID = rowSelected.id.substring(4, 5);
     let NewGridValue = getValue(thisObj, 'gridContractApplications')
     if (NewGridValue[rowID].txtcolContractNumber != null && NewGridValue[rowID].txtcolContractNumber != undefined && NewGridValue[rowID].txtcolContractNumber != "") {
       let contractnum = NewGridValue[rowID].txtcolContractNumber;
       if(contractnum.length >0){
         commonContext1.setContractProfile_ContractNumber(contractnum);
         commonContext1.setContractProfile_FormState("edit");
         goTo(thisObj, "ContractManagement#ContractProfile#DEFAULT#true#Click");
       } else {
         showMessage(thisObj, "Contract does not exist")
       }
     } else {
       showMessage(thisObj, "Contract number cannot be null !!")
     }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Error while selecting contract");
      }
    }
  };
  thisObj.onbtnCol1Click = onbtnCol1Click;

  const onbtnCol2Click = async (event) => {
    try {
      let highlight = document.getElementsByClassName("activeRow")
      if (highlight.length > 0) {
        highlight[0].classList.remove("activeRow")
      }
      let rowSelected = event.target.parentElement.parentElement.parentElement
      rowSelected.classList.add("activeRow")
      let rowID = rowSelected.id.substring(4, 5);
      let NewGridValue = getValue(thisObj, 'gridSeedGrowerApplications')
      if (NewGridValue[rowID].txtcolContractNumberSGA != null && NewGridValue[rowID].txtcolContractNumberSGA != undefined && NewGridValue[rowID].txtcolContractNumberSGA != "") {
        let contractnum = NewGridValue[rowID].txtcolContractNumberSGA;
        if(contractnum.length >0){
          commonContext1.setContractProfile_ContractNumber(contractnum);
          commonContext1.setContractProfile_FormState("edit");
          goTo(thisObj, "ContractManagement#ContractProfile#DEFAULT#true#Click");
        } else {
          showMessage(thisObj, "Contract does not exist")
        }
      } else {
        showMessage(thisObj, "Contract number cannot be null !!")
      }

    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj, "Error while selecting contract");
      }
    }
  };
  thisObj.onbtnCol2Click = onbtnCol2Click;

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_ViewWRApplications*/}

              {/* END_USER_CODE-USER_BEFORE_ViewWRApplications*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxViewWRApplications*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxViewWRApplications*/}

              <GroupBoxWidget
                conf={state.grpbxViewWRApplications}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_grpbxDetails*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxDetails*/}

                <GroupBoxWidget conf={state.grpbxDetails} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_txtWarehouseReceiptNum*/}

                  {/* END_USER_CODE-USER_BEFORE_txtWarehouseReceiptNum*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtWarehouseReceiptNum}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtWarehouseReceiptNum*/}

                  {/* END_USER_CODE-USER_AFTER_txtWarehouseReceiptNum*/}
                  {/* START_USER_CODE-USER_BEFORE_txtUnloadLocation*/}

                  {/* END_USER_CODE-USER_BEFORE_txtUnloadLocation*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtUnloadLocation}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtUnloadLocation*/}

                  {/* END_USER_CODE-USER_AFTER_txtUnloadLocation*/}
                  {/* START_USER_CODE-USER_BEFORE_txtObligationLocation*/}

                  {/* END_USER_CODE-USER_BEFORE_txtObligationLocation*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtObligationLocation}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtObligationLocation*/}

                  {/* END_USER_CODE-USER_AFTER_txtObligationLocation*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxDetails*/}

                {/* END_USER_CODE-USER_AFTER_grpbxDetails*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxContractApplication*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxContractApplication*/}

                <GroupBoxWidget
                  conf={state.grpbxContractApplication}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblContractApplications*/}

                  {/* END_USER_CODE-USER_BEFORE_lblContractApplications*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblContractApplications}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblContractApplications*/}

                  {/* END_USER_CODE-USER_AFTER_lblContractApplications*/}
                  {/* START_USER_CODE-USER_BEFORE_gridContractApplications*/}

                  {/* END_USER_CODE-USER_BEFORE_gridContractApplications*/}

                  <GridWidget
                    conf={state.gridContractApplications}
                    screenConf={state}
                    linkClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    errors={errors}
                    touched={touched}
                    rows={values.gridContractApplications}
                    onEvent={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></GridWidget>
                  {/* START_USER_CODE-USER_AFTER_gridContractApplications*/}

                  {/* END_USER_CODE-USER_AFTER_gridContractApplications*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxContractApplication*/}

                {/* END_USER_CODE-USER_AFTER_grpbxContractApplication*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxSpotApplication*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxSpotApplication*/}

                <GroupBoxWidget
                  conf={state.grpbxSpotApplication}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblSpotApplication*/}

                  {/* END_USER_CODE-USER_BEFORE_lblSpotApplication*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblSpotApplication}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblSpotApplication*/}

                  {/* END_USER_CODE-USER_AFTER_lblSpotApplication*/}
                  {/* START_USER_CODE-USER_BEFORE_txtLbsToApply*/}

                  {/* END_USER_CODE-USER_BEFORE_txtLbsToApply*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtLbsToApply}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtLbsToApply*/}

                  {/* END_USER_CODE-USER_AFTER_txtLbsToApply*/}
                  {/* START_USER_CODE-USER_BEFORE_txtSpotPrice*/}

                  {/* END_USER_CODE-USER_BEFORE_txtSpotPrice*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtSpotPrice}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtSpotPrice*/}

                  {/* END_USER_CODE-USER_AFTER_txtSpotPrice*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxSpotApplication*/}

                {/* END_USER_CODE-USER_AFTER_grpbxSpotApplication*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxSeedGroverApplications*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxSeedGroverApplications*/}

                <GroupBoxWidget
                  conf={state.grpbxSeedGroverApplications}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblSeedGrowerApplications*/}

                  {/* END_USER_CODE-USER_BEFORE_lblSeedGrowerApplications*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblSeedGrowerApplications}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblSeedGrowerApplications*/}

                  {/* END_USER_CODE-USER_AFTER_lblSeedGrowerApplications*/}
                  {/* START_USER_CODE-USER_BEFORE_gridSeedGrowerApplications*/}

                  {/* END_USER_CODE-USER_BEFORE_gridSeedGrowerApplications*/}

                  <GridWidget
                    conf={state.gridSeedGrowerApplications}
                    screenConf={state}
                    linkClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    errors={errors}
                    touched={touched}
                    rows={values.gridSeedGrowerApplications}
                    onEvent={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></GridWidget>
                  {/* START_USER_CODE-USER_AFTER_gridSeedGrowerApplications*/}

                  {/* END_USER_CODE-USER_AFTER_gridSeedGrowerApplications*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxSeedGroverApplications*/}

                {/* END_USER_CODE-USER_AFTER_grpbxSeedGroverApplications*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxViewWRApplications*/}

              {/* END_USER_CODE-USER_AFTER_grpbxViewWRApplications*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrActions}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* START_USER_CODE-USER_AFTER_ViewWRApplications*/}

              {/* END_USER_CODE-USER_AFTER_ViewWRApplications*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(WarehouseReceipts_ViewWRApplications);
