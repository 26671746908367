/* eslint-disable*/
import React from "react";
import AccountPayment_SpecialPayablesScan from "./SpecialPayablesScan";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("SpecialPayablesScan Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<AccountPayment_SpecialPayablesScan />);
    });
  });
  afterEach(cleanup);
  test("is SpecialPayablesScan Loads Successfully", () => {
    expect(screen.getByText("SpecialPayablesScan")).toBeInTheDocument;
  });
  test("Custom Test Cases for SpecialPayablesScan", () => {
    // START_USER_CODE-USER_SpecialPayablesScan_Custom_Test_Case
    // END_USER_CODE-USER_SpecialPayablesScan_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<AccountPayment_SpecialPayablesScan />);
    });
  });
  afterEach(cleanup);
  test("btn1(Button Widget) Test Cases", async () => {
    const btn1 = screen.getByTestId("btn1");
    expect(btn1).toBeInTheDocument;
    expect(btn1.textContent).toEqual(
      t("AccountPayment:SpecialPayablesScan_btn1")
    );
  });
  test("Custom Test Cases for btn1", () => {
    // START_USER_CODE-USER_btn1_TEST
    // END_USER_CODE-USER_btn1_TEST
  });
  test("btn2(Button Widget) Test Cases", async () => {
    const btn2 = screen.getByTestId("btn2");
    expect(btn2).toBeInTheDocument;
    expect(btn2.textContent).toEqual(
      t("AccountPayment:SpecialPayablesScan_btn2")
    );
  });
  test("Custom Test Cases for btn2", () => {
    // START_USER_CODE-USER_btn2_TEST
    // END_USER_CODE-USER_btn2_TEST
  });
  test("btn3(Button Widget) Test Cases", async () => {
    const btn3 = screen.getByTestId("btn3");
    expect(btn3).toBeInTheDocument;
    expect(btn3.textContent).toEqual(
      t("AccountPayment:SpecialPayablesScan_btn3")
    );
  });
  test("Custom Test Cases for btn3", () => {
    // START_USER_CODE-USER_btn3_TEST
    // END_USER_CODE-USER_btn3_TEST
  });
  test("btnCancel(Button Widget) Test Cases", async () => {
    const btnCancel = screen.getByTestId("btnCancel");
    expect(btnCancel).toBeInTheDocument;
    expect(btnCancel.textContent).toEqual(
      t("AccountPayment:SpecialPayablesScan_btnCancel")
    );
  });
  test("Custom Test Cases for btnCancel", () => {
    // START_USER_CODE-USER_btnCancel_TEST
    // END_USER_CODE-USER_btnCancel_TEST
  });
  test("btnCancelSave(Button Widget) Test Cases", async () => {
    const btnCancelSave = screen.getByTestId("btnCancelSave");
    expect(btnCancelSave).toBeInTheDocument;
    expect(btnCancelSave.textContent).toEqual(
      t("AccountPayment:SpecialPayablesScan_btnCancelSave")
    );
  });
  test("Custom Test Cases for btnCancelSave", () => {
    // START_USER_CODE-USER_btnCancelSave_TEST
    // END_USER_CODE-USER_btnCancelSave_TEST
  });
  test("btnCancelScan(Button Widget) Test Cases", async () => {
    const btnCancelScan = screen.getByTestId("btnCancelScan");
    expect(btnCancelScan).toBeInTheDocument;
    expect(btnCancelScan.textContent).toEqual(
      t("AccountPayment:SpecialPayablesScan_btnCancelScan")
    );
  });
  test("Custom Test Cases for btnCancelScan", () => {
    // START_USER_CODE-USER_btnCancelScan_TEST
    // END_USER_CODE-USER_btnCancelScan_TEST
  });
  test("btnSaveFromExistingFile(Button Widget) Test Cases", async () => {
    const btnSaveFromExistingFile = screen.getByTestId(
      "btnSaveFromExistingFile"
    );
    expect(btnSaveFromExistingFile).toBeInTheDocument;
    expect(btnSaveFromExistingFile.textContent).toEqual(
      t("AccountPayment:SpecialPayablesScan_btnSaveFromExistingFile")
    );
  });
  test("Custom Test Cases for btnSaveFromExistingFile", () => {
    // START_USER_CODE-USER_btnSaveFromExistingFile_TEST
    // END_USER_CODE-USER_btnSaveFromExistingFile_TEST
  });
  test("btnScan(Button Widget) Test Cases", async () => {
    const btnScan = screen.getByTestId("btnScan");
    expect(btnScan).toBeInTheDocument;
    expect(btnScan.textContent).toEqual(
      t("AccountPayment:SpecialPayablesScan_btnScan")
    );
  });
  test("Custom Test Cases for btnScan", () => {
    // START_USER_CODE-USER_btnScan_TEST
    // END_USER_CODE-USER_btnScan_TEST
  });
  test("btnScanUsingDocFeeder(Button Widget) Test Cases", async () => {
    const btnScanUsingDocFeeder = screen.getByTestId("btnScanUsingDocFeeder");
    expect(btnScanUsingDocFeeder).toBeInTheDocument;
    expect(btnScanUsingDocFeeder.textContent).toEqual(
      t("AccountPayment:SpecialPayablesScan_btnScanUsingDocFeeder")
    );
  });
  test("Custom Test Cases for btnScanUsingDocFeeder", () => {
    // START_USER_CODE-USER_btnScanUsingDocFeeder_TEST
    // END_USER_CODE-USER_btnScanUsingDocFeeder_TEST
  });
  test("btnScanUsingFlatbed(Button Widget) Test Cases", async () => {
    const btnScanUsingFlatbed = screen.getByTestId("btnScanUsingFlatbed");
    expect(btnScanUsingFlatbed).toBeInTheDocument;
    expect(btnScanUsingFlatbed.textContent).toEqual(
      t("AccountPayment:SpecialPayablesScan_btnScanUsingFlatbed")
    );
  });
  test("Custom Test Cases for btnScanUsingFlatbed", () => {
    // START_USER_CODE-USER_btnScanUsingFlatbed_TEST
    // END_USER_CODE-USER_btnScanUsingFlatbed_TEST
  });
  test("buttonwidget5(Button Widget) Test Cases", async () => {
    const buttonwidget5 = screen.getByTestId("buttonwidget5");
    expect(buttonwidget5).toBeInTheDocument;
    expect(buttonwidget5.textContent).toEqual(
      t("AccountPayment:SpecialPayablesScan_buttonwidget5")
    );
  });
  test("Custom Test Cases for buttonwidget5", () => {
    // START_USER_CODE-USER_buttonwidget5_TEST
    // END_USER_CODE-USER_buttonwidget5_TEST
  });
  test("grpbxDocumentDetails(GroupBox Widget) Test Cases", async () => {
    const grpbxDocumentDetails = screen.getByTestId("grpbxDocumentDetails");
    expect(grpbxDocumentDetails.tagName).toBe("BUTTON");
    expect(grpbxDocumentDetails.type).toBe("button");
    expect(grpbxDocumentDetails.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxDocumentDetails", () => {
    // START_USER_CODE-USER_grpbxDocumentDetails_TEST
    // END_USER_CODE-USER_grpbxDocumentDetails_TEST
  });
  test("grpbxSavingDocumentOptions(GroupBox Widget) Test Cases", async () => {
    const grpbxSavingDocumentOptions = screen.getByTestId(
      "grpbxSavingDocumentOptions"
    );
    expect(grpbxSavingDocumentOptions.tagName).toBe("BUTTON");
    expect(grpbxSavingDocumentOptions.type).toBe("button");
    expect(grpbxSavingDocumentOptions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxSavingDocumentOptions", () => {
    // START_USER_CODE-USER_grpbxSavingDocumentOptions_TEST
    // END_USER_CODE-USER_grpbxSavingDocumentOptions_TEST
  });
  test("grpbxScanningOptions(GroupBox Widget) Test Cases", async () => {
    const grpbxScanningOptions = screen.getByTestId("grpbxScanningOptions");
    expect(grpbxScanningOptions.tagName).toBe("BUTTON");
    expect(grpbxScanningOptions.type).toBe("button");
    expect(grpbxScanningOptions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxScanningOptions", () => {
    // START_USER_CODE-USER_grpbxScanningOptions_TEST
    // END_USER_CODE-USER_grpbxScanningOptions_TEST
  });
  test("grpbxVerificationResult(GroupBox Widget) Test Cases", async () => {
    const grpbxVerificationResult = screen.getByTestId(
      "grpbxVerificationResult"
    );
    expect(grpbxVerificationResult.tagName).toBe("BUTTON");
    expect(grpbxVerificationResult.type).toBe("button");
    expect(grpbxVerificationResult.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVerificationResult", () => {
    // START_USER_CODE-USER_grpbxVerificationResult_TEST
    // END_USER_CODE-USER_grpbxVerificationResult_TEST
  });
  test("lblMemo(Label Widget) Test Cases", async () => {
    const lblMemo = screen.getByTestId("lblMemo");
    expect(lblMemo.tagName).toBe("LABEL");
    expect(lblMemo.classList).toContain("form-label");
    expect(lblMemo.textContent).toEqual(
      t("AccountPayment:SpecialPayablesScan_lblMemo")
    );
  });
  test("Custom Test Cases for lblMemo", () => {
    // START_USER_CODE-USER_lblMemo_TEST
    // END_USER_CODE-USER_lblMemo_TEST
  });
  test("lblMemoValue(Label Widget) Test Cases", async () => {
    const lblMemoValue = screen.getByTestId("lblMemoValue");
    expect(lblMemoValue.tagName).toBe("LABEL");
    expect(lblMemoValue.classList).toContain("form-label");
    expect(lblMemoValue.textContent).toEqual(
      t("AccountPayment:SpecialPayablesScan_lblMemoValue")
    );
  });
  test("Custom Test Cases for lblMemoValue", () => {
    // START_USER_CODE-USER_lblMemoValue_TEST
    // END_USER_CODE-USER_lblMemoValue_TEST
  });
  test("lblSavingDocumentOptions(Label Widget) Test Cases", async () => {
    const lblSavingDocumentOptions = screen.getByTestId(
      "lblSavingDocumentOptions"
    );
    expect(lblSavingDocumentOptions.tagName).toBe("LABEL");
    expect(lblSavingDocumentOptions.classList).toContain("form-label");
    expect(lblSavingDocumentOptions.textContent).toEqual(
      t("AccountPayment:SpecialPayablesScan_lblSavingDocumentOptions")
    );
  });
  test("Custom Test Cases for lblSavingDocumentOptions", () => {
    // START_USER_CODE-USER_lblSavingDocumentOptions_TEST
    // END_USER_CODE-USER_lblSavingDocumentOptions_TEST
  });
  test("lblScanningOptions(Label Widget) Test Cases", async () => {
    const lblScanningOptions = screen.getByTestId("lblScanningOptions");
    expect(lblScanningOptions.tagName).toBe("LABEL");
    expect(lblScanningOptions.classList).toContain("form-label");
    expect(lblScanningOptions.textContent).toEqual(
      t("AccountPayment:SpecialPayablesScan_lblScanningOptions")
    );
  });
  test("Custom Test Cases for lblScanningOptions", () => {
    // START_USER_CODE-USER_lblScanningOptions_TEST
    // END_USER_CODE-USER_lblScanningOptions_TEST
  });
  test("lblScngSts(Label Widget) Test Cases", async () => {
    const lblScngSts = screen.getByTestId("lblScngSts");
    expect(lblScngSts.tagName).toBe("LABEL");
    expect(lblScngSts.classList).toContain("form-label");
    expect(lblScngSts.textContent).toEqual(
      t("AccountPayment:SpecialPayablesScan_lblScngSts")
    );
  });
  test("Custom Test Cases for lblScngSts", () => {
    // START_USER_CODE-USER_lblScngSts_TEST
    // END_USER_CODE-USER_lblScngSts_TEST
  });
  test("lblScngStsVal(Label Widget) Test Cases", async () => {
    const lblScngStsVal = screen.getByTestId("lblScngStsVal");
    expect(lblScngStsVal.tagName).toBe("LABEL");
    expect(lblScngStsVal.classList).toContain("form-label");
    expect(lblScngStsVal.textContent).toEqual(
      t("AccountPayment:SpecialPayablesScan_lblScngStsVal")
    );
  });
  test("Custom Test Cases for lblScngStsVal", () => {
    // START_USER_CODE-USER_lblScngStsVal_TEST
    // END_USER_CODE-USER_lblScngStsVal_TEST
  });
  test("lblSpecialPayDocInfo(Label Widget) Test Cases", async () => {
    const lblSpecialPayDocInfo = screen.getByTestId("lblSpecialPayDocInfo");
    expect(lblSpecialPayDocInfo.tagName).toBe("LABEL");
    expect(lblSpecialPayDocInfo.classList).toContain("form-label");
    expect(lblSpecialPayDocInfo.textContent).toEqual(
      t("AccountPayment:SpecialPayablesScan_lblSpecialPayDocInfo")
    );
  });
  test("Custom Test Cases for lblSpecialPayDocInfo", () => {
    // START_USER_CODE-USER_lblSpecialPayDocInfo_TEST
    // END_USER_CODE-USER_lblSpecialPayDocInfo_TEST
  });
  test("lblSpoolLocation(Label Widget) Test Cases", async () => {
    const lblSpoolLocation = screen.getByTestId("lblSpoolLocation");
    expect(lblSpoolLocation.tagName).toBe("LABEL");
    expect(lblSpoolLocation.classList).toContain("form-label");
    expect(lblSpoolLocation.textContent).toEqual(
      t("AccountPayment:SpecialPayablesScan_lblSpoolLocation")
    );
  });
  test("Custom Test Cases for lblSpoolLocation", () => {
    // START_USER_CODE-USER_lblSpoolLocation_TEST
    // END_USER_CODE-USER_lblSpoolLocation_TEST
  });
  test("lblSpoolLocationValue(Label Widget) Test Cases", async () => {
    const lblSpoolLocationValue = screen.getByTestId("lblSpoolLocationValue");
    expect(lblSpoolLocationValue.tagName).toBe("LABEL");
    expect(lblSpoolLocationValue.classList).toContain("form-label");
    expect(lblSpoolLocationValue.textContent).toEqual(
      t("AccountPayment:SpecialPayablesScan_lblSpoolLocationValue")
    );
  });
  test("Custom Test Cases for lblSpoolLocationValue", () => {
    // START_USER_CODE-USER_lblSpoolLocationValue_TEST
    // END_USER_CODE-USER_lblSpoolLocationValue_TEST
  });
  test("lblVendor(Label Widget) Test Cases", async () => {
    const lblVendor = screen.getByTestId("lblVendor");
    expect(lblVendor.tagName).toBe("LABEL");
    expect(lblVendor.classList).toContain("form-label");
    expect(lblVendor.textContent).toEqual(
      t("AccountPayment:SpecialPayablesScan_lblVendor")
    );
  });
  test("Custom Test Cases for lblVendor", () => {
    // START_USER_CODE-USER_lblVendor_TEST
    // END_USER_CODE-USER_lblVendor_TEST
  });
  test("lblVendorValue(Label Widget) Test Cases", async () => {
    const lblVendorValue = screen.getByTestId("lblVendorValue");
    expect(lblVendorValue.tagName).toBe("LABEL");
    expect(lblVendorValue.classList).toContain("form-label");
    expect(lblVendorValue.textContent).toEqual(
      t("AccountPayment:SpecialPayablesScan_lblVendorValue")
    );
  });
  test("Custom Test Cases for lblVendorValue", () => {
    // START_USER_CODE-USER_lblVendorValue_TEST
    // END_USER_CODE-USER_lblVendorValue_TEST
  });
  test("lblVerifictnResult(Label Widget) Test Cases", async () => {
    const lblVerifictnResult = screen.getByTestId("lblVerifictnResult");
    expect(lblVerifictnResult.tagName).toBe("LABEL");
    expect(lblVerifictnResult.classList).toContain("form-label");
    expect(lblVerifictnResult.textContent).toEqual(
      t("AccountPayment:SpecialPayablesScan_lblVerifictnResult")
    );
  });
  test("Custom Test Cases for lblVerifictnResult", () => {
    // START_USER_CODE-USER_lblVerifictnResult_TEST
    // END_USER_CODE-USER_lblVerifictnResult_TEST
  });
  test("lblVerifictnResultVal(Label Widget) Test Cases", async () => {
    const lblVerifictnResultVal = screen.getByTestId("lblVerifictnResultVal");
    expect(lblVerifictnResultVal.tagName).toBe("LABEL");
    expect(lblVerifictnResultVal.classList).toContain("form-label");
    expect(lblVerifictnResultVal.textContent).toEqual(
      t("AccountPayment:SpecialPayablesScan_lblVerifictnResultVal")
    );
  });
  test("Custom Test Cases for lblVerifictnResultVal", () => {
    // START_USER_CODE-USER_lblVerifictnResultVal_TEST
    // END_USER_CODE-USER_lblVerifictnResultVal_TEST
  });
  test("txtDocument(Textbox Widget) Test Cases", async () => {
    const txtDocument = screen.getByTestId("txtDocument");
    expect(txtDocument.tagName).toBe("INPUT");
    expect(txtDocument.type).toBe("text");
    expect(txtDocument.classList).toContain("textboxWidgetClass");
    expect(txtDocument.previousElementSibling.textContent).toEqual(
      t("AccountPayment:SpecialPayablesScan_txtDocument")
    );
    await act(async () => {
      userEvent.type(txtDocument, "1");
    });
  });
  test("Custom Test Cases for txtDocument", () => {
    // START_USER_CODE-USER_txtDocument_TEST
    // END_USER_CODE-USER_txtDocument_TEST
  });
});
