/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  CommandContainerWidget,
  ButtonWidget,
  setValue,
  setData,
  getData,
  getValue,
  goTo,
  navigate
} from "../../shared/WindowImports";

import "./NoSaleVoidFV95.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { SettlementService } from "../../Settlements/Service/SettlementService";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function Settlements_NoSaleVoidFV95(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES
  let userid = sessionStorage.getItem("userid");
  let cropYear = JSON.parse(sessionStorage.getItem('year'));
  let compId = sessionStorage.getItem('compId');

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "NoSaleVoidFV95",
    windowName: "NoSaleVoidFV95",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "Settlements.NoSaleVoidFV95",
    // START_USER_CODE-USER_NoSaleVoidFV95_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "No Sale/Void SC95",
      scrCode: "PN1040A",
    },
    // END_USER_CODE-USER_NoSaleVoidFV95_PROPERTIES
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "commandcontainerwidget1",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    btnUpdate: {
      name: "btnUpdate",
      type: "ButtonWidget",
      parent: "commandcontainerwidget1",
      Label: "Update",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnUpdate_PROPERTIES

      // END_USER_CODE-USER_btnUpdate_PROPERTIES
    },
    ddLocation: {
      name: "ddLocation",
      type: "DropDownFieldWidget",
      parent: "grpbxNoSaleVoidFV95",
      Label: "Location:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddLocation_PROPERTIES

      // END_USER_CODE-USER_ddLocation_PROPERTIES
    },
    ddReason: {
      name: "ddReason",
      type: "DropDownFieldWidget",
      parent: "grpbxNoSaleVoidFV95",
      Label: "Reason:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddReason_PROPERTIES

      // END_USER_CODE-USER_ddReason_PROPERTIES
    },
    txtSC95: {
      name: "txtSC95",
      type: "TextBoxWidget",
      parent: "grpbxNoSaleVoidFV95",
      Label: "SC95:",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSC95_PROPERTIES

      // END_USER_CODE-USER_txtSC95_PROPERTIES
    },
    grpbxNoSaleVoidFV95: {
      name: "grpbxNoSaleVoidFV95",
      type: "GroupBoxWidget",
      parent: "NoSaleVoidFV95",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxNoSaleVoidFV95_PROPERTIES

      // END_USER_CODE-USER_grpbxNoSaleVoidFV95_PROPERTIES
    },
    commandcontainerwidget1: {
      name: "commandcontainerwidget1",
      type: "CommandContainerWidget",
      parent: "NoSaleVoidFV95",
      Label: "commandcontainerwidget1",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_commandcontainerwidget1_PROPERTIES

      // END_USER_CODE-USER_commandcontainerwidget1_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "ContractManagement#MainMenu": {},
      },
      REVERSE: {
        "ContractManagement#MainMenu": {},
      },
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {
    // btnCancel: {
    //   DEFAULT: [
    //     "ContractManagement#MainMenu#DEFAULT#false#Click",
    //   ],
    // },
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS

    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  useEffect(() => {
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad()
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS
  let strSource = ''
  let nosellonly = ''
  let InspectionNumberStatus = ''
  let CheckRTP = false
  const [lstBuyingPointID, setlstBuyingPointID] = useState('');
  const [lstVoidAction, setlstVoidAction] = useState();

  function FormLoad() {
    bFillBuyingPointList()
    DisplaySettingPendingNoSale()
  }

  const bFillBuyingPointList = async () => {
    //getting Data from FV95 Screen
    let dataFromFv95 = getData(thisObj, 'NoSaleVoidFV95')
    let buyPoint = ""
    let txtFV95 = ""
    let js = []
    let response = await ContractManagementService.RetrieveBuyingPointControlDetails('PN1010', null, null, null, null)
    if (response != undefined && response != null) {
      for (var i = 0; i < response.length; i++) {
        js.push({
          key: response[i].buy_pt_id,
          description: response[i].buy_pt_id.trim() + ' - ' + response[i].buy_pt_name.trim()
        })
      }
    }
    thisObj.setState(current => {
      return {
        ...current,
        ddLocation: {
          ...state["ddLocation"],
          valueList: js
        }
      }
    })
    setData(thisObj, "locationlist", js)

    if (dataFromFv95 != undefined && dataFromFv95 != "" && dataFromFv95 != null) {
      let buypt = getData(thisObj, "locationlist");
      buyPoint = dataFromFv95.BuyingPoint
      txtFV95 = dataFromFv95.FV95Number

      let collectiveDataFromDropdown = buyPoint
      let comparingKey = (buypt.filter(elem => elem.key === collectiveDataFromDropdown));

      setValue(thisObj, 'ddLocation', buyPoint);
      setlstBuyingPointID(buyPoint)
      setValue(thisObj, "txtSC95", txtFV95)
    }
    else {
      setValue(thisObj, 'ddLocation', js.at(0).key);
      setlstBuyingPointID(js.at(0).key)
    }

  }

  const bFillReasonList = async (nosellonly) => {
    let js = []
    let verifyaccess = 'y'
    let response = await SettlementService.RetrieveVoidReasonDetails(null, nosellonly, verifyaccess)
    if (response != undefined && response != null) {
      for (var i = 0; i < response.length; i++) {
        js.push({
          key: response[i].voidReasonCode,
          description: response[i].voidReasonCode + ' - ' + response[i].voidReasonDesc,
          voidReasonCode: response[i].voidReasonCode,
          actionCode: response[i].actionCode
        })
      }
    }

    thisObj.setState(current => {
      return {
        ...current,
        ddReason: {
          ...state["ddReason"],
          valueList: js
        }
      }
    })
    setValue(thisObj, 'ddReason', js.at(0).key);
    setData(thisObj, "lstVoidReason", js.at(0).voidReasonCode)
    setlstVoidAction((js.at(0).actionCode).toUpperCase())
  }

  const onddReasonChange = () => {
    let voidActn = thisObj.state['ddReason'].valueList;
    if (voidActn !== null && voidActn !== undefined) {
      let gettingDataFromDropdown = getValue(thisObj, "ddReason")
      let comparingKeyFromDropdown = (voidActn.filter(elem => elem.key === gettingDataFromDropdown));
      setlstVoidAction((comparingKeyFromDropdown[0].actionCode).toUpperCase())
      setData(thisObj, "lstVoidReason", comparingKeyFromDropdown[0].voidReasonCode)
    }
  }
  thisObj.onddReasonChange = onddReasonChange;

  const onddLocationChange = () => {
    let lstBuyingPtID = thisObj.state['ddLocation'].valueList;
    let SelectedlstBuyingPointID = thisObj.values['ddLocation'];
    if (lstBuyingPtID !== null && lstBuyingPtID !== undefined) {
      let setValue = (lstBuyingPtID.find(elem => elem.key === SelectedlstBuyingPointID).key);
      for (var i = 0; i < lstBuyingPtID.length; i++) {
        if (lstBuyingPtID[i].key == setValue) {
          break;
        }
      }
      setlstBuyingPointID(lstBuyingPtID[i].key)
      DisplaySettingPendingNoSale()
    }
  }
  thisObj.onddLocationChange = onddLocationChange;

  const bValid = async () => {
    let lstVoidReason = getData(thisObj, "lstVoidReason")
    let bValidFlag = false
    if (lstBuyingPointID == '') {
      alert("Please select the buying point location.")
      bValidFlag = false
      return;
    }
    var inspNum = (txtSC95.value)//(getValue(thisObj,"txtSC95")).toUpperCase()
    if (inspNum === undefined) {
      inspNum = (getValue(thisObj, "txtSC95")).toUpperCase()
    }
    else {
      inspNum = (txtSC95.value).toUpperCase()
    }
    if ((inspNum) == '') {
      alert("Please enter the SC95 number.")
      bValidFlag = false
      return;
    }
    if (lstVoidReason == '') {
      alert("Please select the void reason.")
      return;
    }
    let lstrxml = await SettlementService.RetrieveVoidInspectionDetails(compId, cropYear, lstBuyingPointID, inspNum)
    if (lstrxml.length != 0) {
      bValidFlag = false

      if (lstrxml[0].insp_num != '') {
        alert("SC95 - Already Voided in the System.")
        bValidFlag = false
        return;
      }
    }
    bValidFlag = true;
    return bValidFlag;
  }

  const NoSell = async () => {
    if (!await bValid()) {
      return;
    }
    const response = confirm("Are you sure you wish to mark this SC95 Inspection as a NoSale?");
    if (response) {
      let insp_num = getValue(thisObj, "txtSC95")//txtSC95.value
      let buy_pt_id = lstBuyingPointID
      let void_reason_code = getData(thisObj, "lstVoidReason")
      let dataObj = {
        supersedingFV95: '',
      }
      let lstrxml = await SettlementService.RetrieveInspectionHeaderList(insp_num, null, null, null)
      if (lstrxml.length != 0) {
        if (lstrxml[0].insp_num != '') {
          if (lstrxml[0].buy_pt_id != lstBuyingPointID) {
            alert("This inspection already exists at location - " + lstrxml[0].buy_pt_id + ". Cannot No Sale")
            return;
          }
        }
      }

      lstrxml = await SettlementService.UpdateVoidNosellInspectionDetails(buy_pt_id, insp_num, void_reason_code, dataObj)
      if (lstrxml.status != 200) {
        alert("An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists." + "\n" + "Error: can not void inspection when already settled. Must void 1007 first")
        return;
      }
      strSource = "No Sell Inspection"
      await SendToKC()

      alert("SC95 Inspection has been successfully NoSaled!!!")

      //integration with frmFV95 
      // let frmFV95 = getData(thisObj,'NoSaleVoidFV95')
      // if (frmFV95 != undefined && frmFV95 != null && frmFV95 != "") {
      //   if (frmFV95 != '') {
      //     return;
      //   }
      // }

      setValue(thisObj, "txtSC95", '')
      //Screen Opening From FV95 Screen after Successful changing in status close the screen
      setData(thisObj, "UpdatedNoSaleVoidFV95", "updatedValueOfNosellData")
      if (document.getElementById("Settlements_NoSaleVoidFV95PopUp") != null) {
        document.getElementById("Settlements_NoSaleVoidFV95PopUp").childNodes[0].click();
      }
    }
    else {
      return;
    }

  }

  const Void = async () => {
    if (!await bValid()) {
      return;
    }

    const response = confirm("Are you sure you wish to mark this SC95 Inspection as Voided?");
    if (response) {
      let insp_num = getValue(thisObj, "txtSC95")//txtSC95.value
      let buy_pt_id = lstBuyingPointID
      let void_reason_code = getData(thisObj, "lstVoidReason")
      let dataObj = {
        supersedingFV95: ''
      }
      let lstrxml = await SettlementService.RetrieveInspectionHeaderList(insp_num, null, null, null)
      if (lstrxml.length != 0) {
        if (lstrxml[0].insp_num != '') {
          if (lstrxml[0].buy_pt_id != lstBuyingPointID) {
            alert("This inspection already exists at location - " + lstrxml[0].buy_pt_id + ". Cannot Void")
            return;
          }
        }
      }

      lstrxml = await SettlementService.UpdateVoidNosellInspectionDetails(buy_pt_id, insp_num, void_reason_code, dataObj)
      if (lstrxml.status != 200) {
        alert("An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists." + "\n" + "Error: can not void inspection when already settled. Must void 1007 first")
        return;
      }
      strSource = "Void Inspection"
      await SendToKC()

      alert("SC95 Inspection has been successfully Voided!!!")
      setValue(thisObj, "txtSC95", '')

      //Screen Opening From FV95 Screen after Successful changing in status close the screen
      setData(thisObj, "UpdatedNoSaleVoidFV95", "updatedValueOfNosellData")
      if (document.getElementById("Settlements_NoSaleVoidFV95PopUp") != null) {
        document.getElementById("Settlements_NoSaleVoidFV95PopUp").childNodes[0].click();
      }

    }

    else {
      return;
    }

  }

  const SendToKC = async () => {
    let strMessage = ''
    let inspection = getValue(thisObj, "txtSC95")//txtSC95.value
    let resp = await ContractManagementService.RetrieveAccessPermissionDetails('PN1040', '002', 'U', null)
    if (resp[0].permission === 'Y') {
      const response = confirm("Send NoSale Inspection to Kansas City?");
      if (!response) {
        let lstrxml = await SettlementService.UpdateMarkVoidTableDetails(lstBuyingPointID, inspection)
        if (lstrxml.status === 200) {
          strMessage = strSource + " " + inspection + " for location " + lstBuyingPointID + ", user " + userid + ".  Not being sent to USDA in Kansas City."

          let dataObj = {
            trans_num: 0,
            message: strMessage
          }

          let LstrList = await ContractManagementService.CreateInspectUploadLogDetails(lstBuyingPointID, dataObj)
        }
      }
    }
  }

  const InspectionnumberStatus = async () => {
    let insp_num = getData(thisObj, "NoSaleVoidFV95");
    var FV95
    if (insp_num == undefined || insp_num == null) {
      FV95 = ""
    }
    else {
      FV95 = insp_num.FV95Number
    }

    if ((FV95).toString() == '') {
      InspectionNumberStatus = ''
      return;
    }
    //func_id=PN1040 GIVEN IN Old but not in API And asp.
    let UpdatedBuyingPoint = getValue(thisObj, "ddLocation")
    if (UpdatedBuyingPoint === undefined) {
      UpdatedBuyingPoint = lstBuyingPointID
    }
    else {
      UpdatedBuyingPoint = UpdatedBuyingPoint
    }
    let lstrxml = await SettlementService.RetrieveInspectionHeaderList(FV95, UpdatedBuyingPoint, null, null)
    if (lstrxml.length != 0) {
      InspectionNumberStatus = (lstrxml[0].insp_status).toUpperCase()
      return InspectionNumberStatus
    }
    else {
      InspectionNumberStatus = ''
      return InspectionNumberStatus
    }
  }

  const DisplaySettingPendingNoSale = async () => {
    await InspectionnumberStatus()
    if (InspectionNumberStatus === 'X') {
      nosellonly = "Y"
      await bFillReasonList(nosellonly)
    }
    else {
      nosellonly = "N"
      await bFillReasonList(nosellonly)
    }
  }

  const Supersede = async () => {
    if (!await bValid()) {
      return;
    }
    var insp_num = getValue(thisObj, "txtSC95")//txtSC95.value
    let cboVendor = []
    let sSupersededFV95 = ''
    let bCanBeSuperseded = false
    let sHeader = ''
    let sVendorSplits = ''
    let sVendorApplication = ''
    let bWRExists = false
    let sSupersedingFV95 = ''
    let lstrxml = ''
    const response = confirm("Are you sure you wish to supersede this SC95 Inspection?");
    if (response) {

      lstrxml = await SettlementService.RetrieveInspectionHeaderList(insp_num, null, null, null)
      if (lstrxml.length != 0) {
        if (lstrxml[0].insp_num != '') {
          if (lstrxml[0].buy_pt_id != lstBuyingPointID) {
            alert("This inspection already exists at location - " + lstrxml[0].buy_pt_id + ". Cannot Void")
            return;
          }
          sSupersededFV95 = lstrxml[0].insp_num
        }
      }
    }
    else {
      return;
    }

    bCanBeSuperseded = true
    if (sSupersededFV95 != '') {
      sHeader = lstrxml[0]
      let sVendorSplit = lstrxml[0].inspect_vendor_splits
      let VendorApplication = lstrxml[0].inspect_vendor_applications

      if (sHeader.insp_status === 'V') {
        alert("SC95 - Already Voided in the System.")
      }
      if (sVendorSplit.length != 0) {
        for (var i = 0; i < sVendorSplit.length; i++) {
          sVendorSplits = (sVendorSplit[i].split_vendor)
        }
        cboVendor.push(sVendorSplits)
      }

      if (VendorApplication.length != 0) {
        for (var i = 0; i < VendorApplication.length; i++) {
          sVendorApplication = VendorApplication[i].rcpt_vendor
          if (sVendorApplication == cboVendor[i]) {
            bWRExists = true
          }
          sVendorApplication = VendorApplication
        }
      }

      if (bWRExists) {
        bCanBeSuperseded = false
        if (sHeader.freE1007 == 'Y') {
          bCanBeSuperseded = true
        }
      }
      if (bCanBeSuperseded == false) {
        alert("SC95 #:" + insp_num + " cannot be superseded.  SC95 must be freed.")
        return;
      }

      setData(thisObj, "settlementNum", lstrxml[0].settlement_num)
      setData(thisObj, "buy_pt_id", lstrxml[0].buy_pt_id)
      await bCheckRTP()
      if (CheckRTP === false) {
        return;
      }
    }

    sSupersedingFV95 = await prompt("Enter superceding SC95 #:")
    if (sSupersedingFV95 == '' || sSupersedingFV95 == null || sSupersedingFV95 == undefined) {
      alert("You must enter a superseding SC95 #.")
      return;
    }

    lstrxml = await SettlementService.RetrieveInspectionHeaderList(sSupersedingFV95, null, null, null)
    if (lstrxml.length != 0) {
      sHeader = lstrxml[0]
      if (sHeader.insp_status == 'V') {
        alert("SC95 #:" + insp_num + " cannot be voided.")
        return;
      }
    }

    lstrxml = await SettlementService.RetrieveVoidInspectionDetails(compId, cropYear, lstBuyingPointID, sSupersedingFV95)
    if (lstrxml.length != 0) {
      if (lstrxml[0].insp_num != '') {
        alert("SC95 - Already Voided in the System.")
        return;
      }
    }
    var dataObj = {
      supersedingFV95: sSupersedingFV95
    }
    var lstVoidReason = getData(thisObj, "lstVoidReason")
    lstrxml = await SettlementService.UpdateVoidNosellInspectionDetails(lstBuyingPointID, insp_num, lstVoidReason, dataObj)
    if (lstrxml.status != 200) {
      alert("An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists." + "\n" + "Error: " + lstrxml.message.split(":")[2].trim())
      return;
    }
    strSource = "Supercede Inspection"
    await SendToKC()
    await alert("SC95 Inspection has been successfully Superseded!!!")

    setValue(thisObj, "txtSC95", '')

    //Screen Opening From FV95 Screen after Successful changing in status close the screen
    setData(thisObj, "UpdatedNoSaleVoidFV95", "updatedValueOfNosellData")
    if (document.getElementById("Settlements_NoSaleVoidFV95PopUp") != null) {
      document.getElementById("Settlements_NoSaleVoidFV95PopUp").childNodes[0].click();
    }

    return;
  }

  const bCheckRTP = async () => {
    let LstrHTTP = ''
    let js = []
    let s1007Num = getData(thisObj, "settlementNum")
    let sBuyPt = getData(thisObj, "buy_pt_id")

    let response = await ContractManagementService.RetrieveBatchCheckReadyToPayDetails(null, sBuyPt, null, null, null, s1007Num);
    if (response.length != 0) {
      if (response[0].record_count > 0) {
        LstrHTTP = ''
        let batch = response[0].batch
        if (batch.length != 0) {
          for (var i = 0; i < batch.length; i++) {
            LstrHTTP = "\n" + batch[i].payee_vendor
            js.push(LstrHTTP)
          }
        }
        await alert("Pending Batched Check: " + "\n" + "You must release the pending payment to the following vendor(s) ... " + "\n" + js + "\n" + "before supersede can be allowed.")
        CheckRTP = false
      }
      else {
        CheckRTP = true
      }
    }
  }

  const onbtnUpdateClick = async () => {
    try {
      switch (lstVoidAction) {
        case "NO SALE":
          NoSell()
          break;

        case "VOID":
          Void()
          break;

        case "SUPERSEDE":
          Supersede()
          break;
      }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error while click on onbtnUpdateClick Button"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnUpdateClick = onbtnUpdateClick;

  const onbtnCancelClick = async () => {
    try {
      if (document.getElementById("Settlements_NoSaleVoidFV95PopUp") != null) {
        document.getElementById("Settlements_NoSaleVoidFV95PopUp").childNodes[0].click();
      }
      else {
        navigate(thisObj, "/ContractManagement_MainMenu")
      }

    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error while click on onbtnCancelClick Button"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCancelClick = onbtnCancelClick;

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_NoSaleVoidFV95*/}

              {/* END_USER_CODE-USER_BEFORE_NoSaleVoidFV95*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxNoSaleVoidFV95*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxNoSaleVoidFV95*/}

              <GroupBoxWidget
                conf={state.grpbxNoSaleVoidFV95}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_ddLocation*/}

                {/* END_USER_CODE-USER_BEFORE_ddLocation*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddLocation}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddLocation*/}

                {/* END_USER_CODE-USER_AFTER_ddLocation*/}
                {/* START_USER_CODE-USER_BEFORE_txtSC95*/}

                {/* END_USER_CODE-USER_BEFORE_txtSC95*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtSC95}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtSC95*/}

                {/* END_USER_CODE-USER_AFTER_txtSC95*/}
                {/* START_USER_CODE-USER_BEFORE_ddReason*/}

                {/* END_USER_CODE-USER_BEFORE_ddReason*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddReason}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddReason*/}

                {/* END_USER_CODE-USER_AFTER_ddReason*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxNoSaleVoidFV95*/}

              {/* END_USER_CODE-USER_AFTER_grpbxNoSaleVoidFV95*/}
              {/* START_USER_CODE-USER_BEFORE_commandcontainerwidget1*/}

              {/* END_USER_CODE-USER_BEFORE_commandcontainerwidget1*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.commandcontainerwidget1}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnUpdate*/}

                {/* END_USER_CODE-USER_BEFORE_btnUpdate*/}

                <ButtonWidget
                  conf={state.btnUpdate}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnUpdate*/}

                {/* END_USER_CODE-USER_AFTER_btnUpdate*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_commandcontainerwidget1*/}

              {/* END_USER_CODE-USER_AFTER_commandcontainerwidget1*/}

              {/* START_USER_CODE-USER_AFTER_NoSaleVoidFV95*/}

              {/* END_USER_CODE-USER_AFTER_NoSaleVoidFV95*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(Settlements_NoSaleVoidFV95);
