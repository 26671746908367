/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  useAppContext,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  ButtonWidget,
  GridWidget,
  LabelWidget,
  getData,
  setValue,
  setData,
  hide,
  disable,
  enable,
  showMessage,
  getValue,
  goTo,
  hideColumn,
  navigate
} from "../../shared/WindowImports";
import "./PrintChecks.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService"
import { AccountPayment } from "../../AccountPayment/Service/AccountPayment"
import { response } from "msw";
import Loading from "../../../Loader/Loading";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function AccountPayment_PrintChecks(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  // START_USER_CODE-USER_PROPERTIES
  const [loading, setLoading] = useState(true);
  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "PrintChecks",
    windowName: "PrintChecks",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "AccountPayment.PrintChecks",
    // START_USER_CODE-USER_PrintChecks_PROPERTIES
    headerData: {
      scrName: "Print Batch Checks",
      scrCode: "PN1130B",
    },
    horizontalForm: true,
    // END_USER_CODE-USER_PrintChecks_PROPERTIES
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    btncolNav: {
      name: "btncolNav",
      type: "ButtonWidget",
      colName: "colNav",
      parent: "gridBatchedReadyToPays",
      Label: ".",
      CharWidth: "6",
      Height: "",
      Width: "",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btncolNav_PROPERTIES
      iconName: 'fa-search'
      // END_USER_CODE-USER_btncolNav_PROPERTIES
    },
    btnPrintChecks: {
      name: "btnPrintChecks",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Print Checks",
      CharWidth: "26",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnPrintChecks_PROPERTIES

      // END_USER_CODE-USER_btnPrintChecks_PROPERTIES
    },
    btnSearch: {
      name: "btnSearch",
      type: "ButtonWidget",
      parent: "grpbxCheckDetails",
      Label: "Search",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnSearch_PROPERTIES

      // END_USER_CODE-USER_btnSearch_PROPERTIES
    },
    btnVendor: {
      name: "btnVendor",
      type: "ButtonWidget",
      parent: "grpbxCheckDetails",
      Label: "...",
      ColSpan: "2",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnVendor_PROPERTIES

      // END_USER_CODE-USER_btnVendor_PROPERTIES
    },
    txtVndrName: {
      name: "txtVndrName",
      type: "TextBoxWidget",
      parent: "grpbxSearch",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLocation_PROPERTIES
      ReadOnly: true,
      Enabled: false,
      // END_USER_CODE-USER_txtLocation_PROPERTIES
    },
    chkbox1: {
      name: "chkbox1",
      type: "CheckBoxWidget",
      colName: "colchkbox",
      parent: "gridBatchedReadyToPays",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkbox1_PROPERTIES

      // END_USER_CODE-USER_chkbox1_PROPERTIES
    },
    colchkbox: {
      name: "colchkbox",
      type: "GridColumnWidget",
      parent: "gridBatchedReadyToPays",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colchkbox_PROPERTIES

      // END_USER_CODE-USER_colchkbox_PROPERTIES
    },
    colNav: {
      name: "colNav",
      type: "GridColumnWidget",
      parent: "gridBatchedReadyToPays",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colNav_PROPERTIES

      // END_USER_CODE-USER_colNav_PROPERTIES
    },
    colProceeds: {
      name: "colProceeds",
      type: "GridColumnWidget",
      parent: "gridBatchedReadyToPays",
      Label: "Proceeds",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colProceeds_PROPERTIES

      // END_USER_CODE-USER_colProceeds_PROPERTIES
    },
    colCheck: {
      name: "colCheck",
      type: "GridColumnWidget",
      parent: "gridBatchedReadyToPays",
      Label: "Checks",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colProceeds_PROPERTIES

      // END_USER_CODE-USER_colProceeds_PROPERTIES
    },
    colVendor: {
      name: "colVendor",
      type: "GridColumnWidget",
      parent: "gridBatchedReadyToPays",
      Label: "Vendor",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colVendor_PROPERTIES

      // END_USER_CODE-USER_colVendor_PROPERTIES
    },
    colremit: {
      name: "colremit",
      type: "GridColumnWidget",
      parent: "gridBatchedReadyToPays",
      Label: "Remit",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colVendor_PROPERTIES

      // END_USER_CODE-USER_colVendor_PROPERTIES
    },
    colbatchnumber: {
      name: "colbatchnumber",
      type: "GridColumnWidget",
      parent: "gridBatchedReadyToPays",
      Label: "Batch Number",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colVendor_PROPERTIES

      // END_USER_CODE-USER_colVendor_PROPERTIES
    },
    ddLocation: {
      name: "ddLocation",
      type: "DropDownFieldWidget",
      parent: "grpbxCheckDetails",
      Label: "Location:",
      ColSpan: "4",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddLocation_PROPERTIES

      // END_USER_CODE-USER_ddLocation_PROPERTIES
    },
    ddRemitTo: {
      name: "ddRemitTo",
      type: "DropDownFieldWidget",
      parent: "grpbxCheckDetails",
      Label: "Remit To:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddRemitTo_PROPERTIES

      // END_USER_CODE-USER_ddRemitTo_PROPERTIES
    },
    gridBatchedReadyToPays: {
      name: "gridBatchedReadyToPays",
      type: "GridWidget",
      parent: "grpbxCheckDetails",
      gridCellsOrder: "txtcolCheck,txtcolVendor,txtcolProceeds,btncolNav,txtcolremit,txtcolbatchnumber",
      ColSpan: "4",
      Pagination: false,
      ColsForTabLandscape: "6",
      HasLabel: false,
      Cols: "6",
      ColsForTabPotrait: "6",
      ColsForLargeDesktop: "6",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridBatchedReadyToPays_PROPERTIES      
      isEditable: true,
      isMultiSelect: true,
      hideAllCheckBox: false,
      needGridChange: true
      // END_USER_CODE-USER_gridBatchedReadyToPays_PROPERTIES
    },
    lblBatchedReadyToPays: {
      name: "lblBatchedReadyToPays",
      type: "LabelWidget",
      parent: "grpbxCheckDetails",
      Label: "Batched Ready To Pays:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",

      // START_USER_CODE-USER_lblBatchedReadyToPays_PROPERTIES

      // END_USER_CODE-USER_lblBatchedReadyToPays_PROPERTIES
    },
    lblChecksSelected: {
      name: "lblChecksSelected",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "# of Checks Selected:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChecksSelected_PROPERTIES

      // END_USER_CODE-USER_lblChecksSelected_PROPERTIES
    },
    lblChecksSelectedValue: {
      name: "lblChecksSelectedValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      // Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChecksSelectedValue_PROPERTIES

      // END_USER_CODE-USER_lblChecksSelectedValue_PROPERTIES
    },
    txtcolCheck: {
      name: "txtcolCheck",
      type: "TextBoxWidget",
      colName: "colCheck",
      parent: "gridBatchedReadyToPays",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Visible: false
      // START_USER_CODE-USER_txtcolProceeds_PROPERTIES

      // END_USER_CODE-USER_txtcolProceeds_PROPERTIES
    },
    txtcolProceeds: {
      name: "txtcolProceeds",
      type: "TextBoxWidget",
      colName: "colProceeds",
      parent: "gridBatchedReadyToPays",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolProceeds_PROPERTIES

      // END_USER_CODE-USER_txtcolProceeds_PROPERTIES
    },
    txtcolVendor: {
      name: "txtcolVendor",
      type: "TextBoxWidget",
      colName: "colVendor",
      parent: "gridBatchedReadyToPays",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolVendor_PROPERTIES

      // END_USER_CODE-USER_txtcolVendor_PROPERTIES
    },
    txtcolremit: {
      name: "txtcolremit",
      type: "TextBoxWidget",
      colName: "colremit",
      parent: "gridBatchedReadyToPays",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolVendor_PROPERTIES

      // END_USER_CODE-USER_txtcolVendor_PROPERTIES
    },
    txtcolbatchnumber: {
      name: "txtcolbatchnumber",
      type: "TextBoxWidget",
      colName: "colbatchnumber",
      parent: "gridBatchedReadyToPays",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolVendor_PROPERTIES

      // END_USER_CODE-USER_txtcolVendor_PROPERTIES
    },
    txtEndingCheckNumber: {
      name: "txtEndingCheckNumber",
      type: "TextBoxWidget",
      parent: "grpbxCheckDetails",
      Label: "Ending Check #:",
      HasLabel: false,
      Visible: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtEndingCheckNumber_PROPERTIES

      // END_USER_CODE-USER_txtEndingCheckNumber_PROPERTIES
    },
    txtStartingCheck: {
      name: "txtStartingCheck",
      type: "TextBoxWidget",
      parent: "grpbxCheckDetails",
      Label: "Starting Check #:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Visible: false,
      // START_USER_CODE-USER_txtStartingCheck_PROPERTIES

      // END_USER_CODE-USER_txtStartingCheck_PROPERTIES
    },
    txtStartingReceivableNumber: {
      name: "txtStartingReceivableNumber",
      type: "TextBoxWidget",
      parent: "grpbxCheckDetails",
      HasLabel: false,
      Visible: false,
      Label: "Starting Receiveable  #:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtStartingReceivableNumber_PROPERTIES

      // END_USER_CODE-USER_txtStartingReceivableNumber_PROPERTIES
    },
    txtVendor: {
      name: "txtVendor",
      type: "TextBoxWidget",
      parent: "grpbxCheckDetails",
      Label: "Vendor #:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor_PROPERTIES

      // END_USER_CODE-USER_txtVendor_PROPERTIES
    },
    grpbxCheckDetails: {
      name: "grpbxCheckDetails",
      type: "GroupBoxWidget",
      parent: "PrintChecks",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxCheckDetails_PROPERTIES

      // END_USER_CODE-USER_grpbxCheckDetails_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "PrintChecks",
      Height: "",
      Width: "",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {

        "ContractManagement#MainMenu": {},
        "ContractManagement#VendorDisclosure": {},
        "AccoutPayment#CheckDetails": {}
      },
      REVERSE: {

        "ContractManagement#MainMenu": {},
        "ContractManagement#VendorDisclosure": {},
        "AccoutPayment#CheckDetails": {}
      },
    },
  };

  const useridFromLS = () => (sessionStorage.getItem('userid') || '');
  let userid = useridFromLS();
  let checknumber = '';
  let checkselect = 0;
  let collectionNum = '';
  let _buttonServices = {};
  let _buttonNavigation = {
    btnVendor: {
      DEFAULT: ["ContractManagement#VendorLookup#DEFAULT#true#Click"],
    },
    // btnCancel: {
    //   DEFAULT: ["ContractManagement#MainMenu#DEFAULT#false#Click"],
    // },   
  };

  let _winServices = {};
  const [state, setState] = useState(states);
  const [buyPtId, setbuyPtId] = useState('');
  const [colBuyPtId, setcolBuyPtId] = useState('');
  const [batchNumber, setbatchNumber] = useState(0);
  const [location, setLocation] = useState('');
  let startCheck = 0;
  let endCheckNumber = 0;
  let startReceiveNumber = 0;
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    _kaledo.thisObj = thisObj
    parentWindow(thisObj);  
    
  });
  useEffect(() => {
    let data = getData(thisObj, 'refreshprintcheck');
    if (data == true) {
      onbtnSearchClick();
    }
  }, [getData(thisObj, 'refreshprintcheck')])
  
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    hideColumn(thisObj,'gridBatchedReadyToPays','txtcolCheck')
    hideColumn(thisObj,'gridBatchedReadyToPays','txtcolbatchnumber')
    hideColumn(thisObj,'gridBatchedReadyToPays','txtcolremit')

    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    EnableDisableControl();
    document.getElementsByClassName("txtVndrName")[0].style.visibility = "hidden";
    setValue(thisObj, "lblChecksSelectedValue", "0"); 
    setValue(thisObj,"txtVendor","");
    setValue(thisObj,"txtVndrName",""); 
    setValue(thisObj,"ddRemitTo",""); 
    FormLoad();
    hide(thisObj, "chkbox1");

    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);
 
  useEffect(() => {
    let vendor_lookup = getData(thisObj, 'vendorDetails')
    if ((vendor_lookup !== null) && (vendor_lookup !== "") && (vendor_lookup !== undefined)) {
      thisObj.setFieldValue("txtVendor", vendor_lookup.VendorNumber);
      document.getElementsByClassName("txtVndrName")[0].style.visibility = "visible";
      thisObj.setFieldValue("txtVndrName", vendor_lookup.key);
      VendorDetails(vendor_lookup.VendorNumber);
    }
    else {
      document.getElementsByClassName("txtVndrName")[0].style.visibility = "hidden";
    }
  }, [getData(thisObj, 'vendorDetails')]);
  

  // Start Method for Enable and Disable Controls
  const EnableDisableControl = () => {
    disable(thisObj, "btnPrintChecks");
  }
  // End Method for Enable and Disable Controls

  function FormLoad() {
    setData(thisObj, 'vendorDetails',null);
    bFillBuyingPointList();
  } 

  // Start Method for Selected Grid value changing 
  const ongridBatchedReadyToPaysRowSelect = (rowSelected) => {
    try {
      let data = rowSelected.selectedRows;
      setData(thisObj, 'rowSelected', rowSelected.selectedRows);
      if (data !== undefined && data !== null) {
        enable(thisObj, "btnPrintChecks");
        for (var i = 0; i < data.length; i++) {
          if((data[i].txtcolProceeds) > 0 ){
            checkselect = checkselect + 1;            
          }
          else{
            setValue(thisObj, "lblChecksSelectedValue", checkselect);
          }         
        }
        if(data.length >= 1){
          enable(thisObj, "btnPrintChecks");
        }
        else{
          disable(thisObj, "btnPrintChecks");
        }
      }      
      if (checkselect == 0) {
        setValue(thisObj, "lblChecksSelectedValue", "0")
      }
      else {
        setValue(thisObj, "lblChecksSelectedValue", checkselect)
      }
    }
    catch (err) {
    }
    return true;
  };
  thisObj.ongridBatchedReadyToPaysRowSelect = ongridBatchedReadyToPaysRowSelect;
  // End Method for Selected Grid value changing

  // Start Method for binding Buying Points
  const bFillBuyingPointList = () => {
    try{
      let js = []
    ContractManagementService.RetrieveBuyingPointControlDetails('PN1100', null, null, null, null).then(response => {
      let data = response
      for (var i = 0; i < data.length; i++) {
        collectionNum = data[i].coll_pt_id;
        setLocation(data[i].buy_pt_id);
        let obj = { key: data[i].buy_pt_id, description: data[i].buy_pt_id.trim() + '-' + data[i].buy_pt_name.trim() }
        js.push(obj)
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddLocation: {
            ...state["ddLocation"],
            valueList: js
          }
        }
      })
      setValue(thisObj, 'ddLocation', js.at(0).key);
      setLoading(false)
    })
    }
    catch(err){
      setLoading(false)
    }
  }
  // End for binding Buying Points 

  //Start method for vendor textbox change event to bind the vendor name
  const ontxtVendorChange = async () => {
    try {
      let VendorNumber = thisObj.values.txtVendor;
      if (VendorNumber.length == 6) {
        setVendorNameBasedOnVendorNumber(VendorNumber)
        VendorDetails(VendorNumber)
      }
      else {
        document.getElementsByClassName("txtVndrName")[0].style.visibility = "hidden";
        setData(thisObj,"ddRemitTo",">>> All <<<");
      }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:txtVendor event:Change"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.ontxtVendorChange = ontxtVendorChange;
  //End method for vendor textbox change event to bind the vendor name

   // Start Method to get Vendor details
   const VendorDetails = (VendorNumber) => {
    if (VendorNumber !== null) {
      ContractManagementService.RetieveRemitDetails(VendorNumber).then(response => {
        let data = response;
        if (data.length > 0) {
          let js = [];
          js.push({ key: '', description: '>>> ALL <<<' });
          for (var i = 0; i < data.length; i++) {
            let description = data[i].remittoid + '-' + data[i].name + '-' + data[i].city + ',' + data[i].state;
            let obj = { key: data[i].remittoid, description: description };
            js.push(obj);
          }
          thisObj.setState(current => {
            return {
              ...current,
              ddRemitTo: {
                ...state["ddRemitTo"],
                valueList: js
              },
            }
          });
          setValue(thisObj, 'ddRemitTo', js.at(0).key);
        }
      });
    }

  };
  // End Method to get Vendor details

  //Start method to bind the vendor name and called vendor textbox change event
  const setVendorNameBasedOnVendorNumber = async (VendorNumber) => {
    let venname = null
    let vendornamevisible = false
    await ContractManagementService.RetrieveVendorByNumber(VendorNumber).then(response => {
      let data = response
      if (data !== null && data !== undefined) {
        if (data.length == 0) {
          return;
        }
        else {
          venname = data[0].name
          vendornamevisible = true
        }
      }
      if (vendornamevisible == true) {
        document.getElementsByClassName("txtVndrName")[0].style.visibility = "visible";
        setValue(thisObj, "txtVndrName", venname)
      }
      else {
        document.getElementsByClassName("txtVndrName")[0].style.visibility = "hidden";
      }
    })
  }
  //End method to bind the vendor name and called vendor textbox change event

  // Start Method for search button functionality
  const onbtnSearchClick = () => {
    try {
      setLoading(true)
      let payee_vendor = getValue(thisObj, 'txtVendor');
      let buy_pt_id = thisObj.values['ddLocation'];
      let payee_remit = thisObj.values['ddRemitTo'];
      let lblCollectionPoint = '';
      if (buy_pt_id == '') {
        alert("Location is required!!!");
        return false;
      } else {
        AccountPayment.RetrieveBatchReadyToPaymentDetails(buy_pt_id, payee_vendor, payee_remit)
          .then(response => {
            let data1 = response;
            let js = [];
            let obj = {};
            for (var i = 0; i < data1.length; i++) {
              setbatchNumber(data1[i].batch_number);
              obj.txtcolVendor = data1[i].payee_vendor + '-' + data1[i].vendor_name;
              obj.txtcolProceeds = data1[i].rtp_proceeds;
              obj.txtcolremit = data1[i].payee_remit;
              obj.txtcolbatchnumber = data1[i].batch_number;
              js.push(obj);
              obj = {};
            }
            setValue(thisObj, "gridBatchedReadyToPays", js);
            setData(thisObj, 'gridBatchedReadyToPays', js);
            ContractManagementService.RetrieveUserControlDetails(userid).then(response => {
              let userData = response;
              lblCollectionPoint = userData[0].buyPtId;
              setcolBuyPtId(userData[0].buyPtId);
              if (userData.length > 0) {
                ContractManagementService.RetrieveBuyingPointControlDetails(null, null, null, null, lblCollectionPoint).then(response => {
                  let collectionData = response;
                  for (let i = 0; i < collectionData.length; i++) {
                    setbuyPtId(collectionData[i].buy_pt_id);
                    endCheckNumber = collectionData[i].end_check;
                    startCheck = collectionData[i].next_check;
                    startReceiveNumber = collectionData[i].new_receivable_no;
                    //NumberChecks();
                  }
                  setValue(thisObj, 'txtStartingCheck', startCheck);
                  setValue(thisObj, 'txtEndingCheckNumber', endCheckNumber);
                  setValue(thisObj, 'txtStartingReceivableNumber', startReceiveNumber);
                });
              }
            });
          });
      }           
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnSearch event:Click");
      } return false;
    }
    finally{
      setLoading(false)
    }
    return true;
  };
  thisObj.onbtnSearchClick = onbtnSearchClick;
  // End Method for search button functionality

  // Start functions called in text box change event for Print button
  function NumberChecks() {
    let LlngNextCheckNumber = 0;
    let LlngNextReceivableNumber = 0;
    let LlngEndingCheckNumber = 0;
    LlngNextCheckNumber = startCheck - 1;
    LlngNextReceivableNumber = startReceiveNumber - 1;
    LlngEndingCheckNumber = endCheckNumber;
    let isSelectedValue = getData(thisObj, "gridBatchedReadyToPays")
    let js = [];
    let obj = {};
    if (isSelectedValue.length > 0) {
      for (let i = 0; i < isSelectedValue.length; i++) {
        if ((isSelectedValue[i].txtcolProceeds) > 0) {
          if (LlngNextCheckNumber <= LlngEndingCheckNumber) {
            let count = i + 1
            obj.txtcolCheck = (Number(LlngNextCheckNumber) + 1);
            obj.txtcolVendor = isSelectedValue[i].txtcolVendor;
            obj.txtcolProceeds = isSelectedValue[i].txtcolProceeds;
            obj.txtcolremit = isSelectedValue[i].txtcolremit;
            obj.txtcolbatchnumber = isSelectedValue[i].txtcolbatchnumber;
            js.push(obj);
            obj = {};

            LlngNextCheckNumber = Number(LlngNextCheckNumber) + 1;
          }
          else {
            if (LlngNextCheckNumber >= LlngEndingCheckNumber) {
              alert("Check number range exceeded. Check number " + LlngNextCheckNumber + " will not be processed!");
              return false;
            }
          }
        }
        else {
          if (LlngNextReceivableNumber <= 99999) {
              obj.txtcolCheck = "S000" + (Number(LlngNextReceivableNumber) + 1);
              obj.txtcolVendor = isSelectedValue[i].txtcolVendor;
              obj.txtcolProceeds = isSelectedValue[i].txtcolProceeds;
              obj.txtcolremit = isSelectedValue[i].txtcolremit;
              obj.txtcolbatchnumber = isSelectedValue[i].txtcolbatchnumber;
              js.push(obj);
              obj = {};            
            LlngNextReceivableNumber = Number(LlngNextReceivableNumber) + 1;
            if (LlngNextReceivableNumber > 99999) {
              alert("Maximum allowable System Void check number encountered, checks with numbers > S99999 will not be processed!");
              return false;
            }
          }
        }
      }
    }

    setValue(thisObj, "gridBatchedReadyToPays", js);
    setData(thisObj, 'gridBatchedReadyToPays', js);
  }
  // End functions called in text box change event for Print button

  // Start Method for button called in GridView
  const onbtncolNavClick = () => {
    try {
      let buy_pt_id = getValue(_kaledo.thisObj, "ddLocation");     
      let data =  getData(thisObj, 'rowSelected');
      let rtpProceed = "";
      if (data !== null && data !== undefined && data !== "") {
        for (var i = 0; i< data.length; i++) {
          rtpProceed = data[i].txtcolProceeds;
          let obj = {
            lstrvendor: data[i].txtcolVendor.substr(0, 6),
            LstrRemitTo: data[i].txtcolremit,
            LstrBatchNumber: data[i].txtcolbatchnumber,
            lblLocation: buy_pt_id
          };          
          setData(thisObj, "PrintCheck", obj);
          setData(thisObj, 'refreshprintcheck', false)
          if(rtpProceed !== null && rtpProceed !== undefined && rtpProceed !== ""){
            goTo(thisObj, "AccountPayment#CheckDetails#DEFAULT#true#Click");
            return false;
          }
          else {
            alert("An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.");
            return false;
          }
        }
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:onbtncolNavClick event:Click");
      } return false;
    }
    return true;
  }
  thisObj.onbtncolNavClick = onbtncolNavClick
  // End Method for button called in GridView

  // Start Method for Print Button functionality
  const onbtnPrintChecksClick = () => {
    try {
      let LstrBatchNumbers = "";
      let LlngLowCheck = 0;
      let LlngHighCheck = 0;
      let LlngRowCount = getValue(thisObj, "lblChecksSelectedValue");
      let data = getData(thisObj, "rowSelected");
      if (data !== undefined && data !== null && data !== "") {
        for (let i = 0; i < data.length; i++) {
          if (data[i].txtcolbatchnumber !== null && data[i].txtcolbatchnumber !== undefined && data[i].txtcolbatchnumber !== "") {
            LstrBatchNumbers = (LstrBatchNumbers + data[i].txtcolbatchnumber + "|" + data[i].txtcolremit + ",");
            if ((Number(LlngRowCount)) && (LlngRowCount) !== '') {
              if (LlngLowCheck == 0 || LlngLowCheck > data[i].txtcolCheck) {
                LlngLowCheck = data[i].txtcolCheck;
              }
              if (LlngHighCheck == 0 || LlngHighCheck < data[i].txtcolCheck) {
                LlngHighCheck = data[i].txtcolCheck;
              }
            }
          }
        }
      }
      else {
        alert("No Checks Selected! Please select the checks to be printed to continue.");
        return false;
      }
      if (LstrBatchNumbers > 8000) {
        lstrCMD = (LstrBatchNumbers.length - 8000) / ((LstrBatchNumbers.length - 22),LstrBatchNumbers.split("|"));
        alert("Too many Checks! Reduce the number of checks to be printed to continue.");
        return false;
      }
      if (LlngRowCount > 0) {
        let tempVal = '';
        tempVal = prompt("Enter starting check number")
        if (isNaN(tempVal)) {
          alert("Check Number must be numeric");
          return false;
        }
        if (tempVal <= 0) {
          alert("The Check Number cannot be Zero");
          return false;
        }
        if (txtEndingCheckNumber.value < tempVal) {
          alert("The Check Number exceeds number range!");
          return false;
        }
        checknumber = tempVal
        if (LlngHighCheck !== 0 && LlngLowCheck !== 0) {
          let response = AccountPayment.RetrieveVerifyChecksUnusedDetails(LlngLowCheck, LlngHighCheck);
          if (response.status == 200) {
            if (response !== '' && response !== null && response !== undefined) {
              for (var i = 0; i < response.length; i++) {
                alert("This check number " + response[i].check_num + " already exists!!!");
                return false;
              }
            }
            else {
              alert(response.message);
              return false;
            }
          }      
        }        
      }
      else {
        checknumber = 0;
      }
      BATCH_RTP(LstrBatchNumbers.replace(/,\s*$/, ""));
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(thisObj,
          "Exception in PreSubmit Event code for widget:lnkReturn event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnPrintChecksClick = onbtnPrintChecksClick;
  // Start Method for Print Button functionality

  // Start Method for Print button functionality
  async function BATCH_RTP(LstrBatchNumbers) {
    let lstrXMLChecks = "";
    let CheckInfo = "";
    let Proceeds = "";
    let LstrCheckInfo = "";
    let arr = [];
    let buyPtId = thisObj.values.ddLocation;    
    if (LstrBatchNumbers !== null) {
      let response = await AccountPayment.RetrieveBatchReadyToPaymentIssueDetails(LstrBatchNumbers, buyPtId, colBuyPtId, checknumber)
      if (response.length == 0) {
        enable(thisObj, 'btnPrintChecks');
        alert("An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists. \n\n\n" + response.message);
        return false;
      }
      else {
        if(response.message != '' && response.length ==undefined){
          alert(response.message)
          return;
        }
        for (var i = 0; i < response.length; i++) {
          lstrXMLChecks = "<check>" +
            "<batch_number>" + response[i].batch_number + "</batch_number>" +
            "<check_num>" + response[i].check_num + "</check_num>" +
            "<buy_pt_id>" + response[i].buy_pt_id + "</buy_pt_id>" +
            "<acct_vendor>" + response[i].acct_vendor + "</acct_vendor>" +
            "<check_proceeds>" + response[i].check_proceeds + "</check_proceeds>" +
            "</check>"
          let rowdata = getData(thisObj, "rowSelected");
          if (lstrXMLChecks !== "") {
            LstrCheckInfo = {
              buying_Point: response[i].buy_pt_id,
              vendor: response[i].acct_vendor,
              check_num: response[i].check_num,
              Proceeds: rowdata[i].txtcolProceeds,
              Title: "ORIGIN BUYING POINT",
              ScreenName: "PrintChecks"
            }
            if (LstrCheckInfo !== '') {
              arr.push(LstrCheckInfo);
              LstrCheckInfo = {};
            }
            else {
              alert("BATCHED CHECK ERROR! An error was encountered while retrieving the just recorded batched check numbers.");
              return false;
            }
          }
        }
        setData(thisObj, "ReportPreviewCheck", arr);
        goTo(thisObj, "AccountPayment#ReportPreviewCheck#DEFAULT#true#Click");
        //onbtnSearchClick();
      }
    }
  }
  // Start Method for Print button functionality

  // Start Method for onchange of Starting Check Number
  const ontxtStartingCheckChange = async () => {
    try {
      enable(thisObj, 'btnPrintChecks');
      let start_check_num = thisObj.values.txtStartingCheck;
      if (start_check_num !== "" && start_check_num !== null && start_check_num !== undefined) {
        NumberChecks();
      }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during txtStartingCheck change event"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.ontxtStartingCheckChange = ontxtStartingCheckChange
  // End Method for onchange of Starting Check Number

  // Start Method for onchange of Starting Receivable Number
  const ontxtStartingReceivableNumberChange = () => {
    try {
      let start_recv_num = thisObj.values.txtStartingReceivableNumber;
      if (start_recv_num !== "" && start_recv_num !== null && start_recv_num !== undefined) {
        NumberChecks();
      }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during txtStartingCheck change event"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.ontxtStartingReceivableNumberChange = ontxtStartingReceivableNumberChange;
  // End Method for onchange of Starting Receivable Number

  const onbtnCancelClick = () => {
    try{
      setData(thisObj, 'refreshprintcheck', false)
      navigate(thisObj, "/ContractManagement_MainMenu")
    }
    catch(err){
      showMessage(thisObj, err.message)
    }
  }
  thisObj.onbtnCancelClick = onbtnCancelClick;

  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12"+ " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            <Loading loading={loading}></Loading>
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />

            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_PrintChecks*/}

              {/* END_USER_CODE-USER_BEFORE_PrintChecks*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxCheckDetails*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxCheckDetails*/}

              <GroupBoxWidget conf={state.grpbxCheckDetails} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_ddLocation*/}

                {/* END_USER_CODE-USER_BEFORE_ddLocation*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddLocation}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddLocation*/}

                {/* END_USER_CODE-USER_AFTER_ddLocation*/}
                {/* START_USER_CODE-USER_BEFORE_txtVendor*/}

                {/* END_USER_CODE-USER_BEFORE_txtVendor*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtVendor}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtVendor*/}

                {/* END_USER_CODE-USER_AFTER_txtVendor*/}
                {/* START_USER_CODE-USER_BEFORE_btnVendor*/}

                {/* END_USER_CODE-USER_BEFORE_btnVendor*/}

                <ButtonWidget
                  conf={state.btnVendor}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnVendor*/}

                {/* END_USER_CODE-USER_AFTER_btnVendor*/}
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtVndrName}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_BEFORE_ddRemitTo*/}

                {/* END_USER_CODE-USER_BEFORE_ddRemitTo*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddRemitTo}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddRemitTo*/}

                {/* END_USER_CODE-USER_AFTER_ddRemitTo*/}
                {/* START_USER_CODE-USER_BEFORE_txtEndingCheckNumber*/}

                {/* END_USER_CODE-USER_BEFORE_txtEndingCheckNumber*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtEndingCheckNumber}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtEndingCheckNumber*/}

                {/* END_USER_CODE-USER_AFTER_txtEndingCheckNumber*/}
                {/* START_USER_CODE-USER_BEFORE_txtStartingReceivableNumber*/}

                {/* END_USER_CODE-USER_BEFORE_txtStartingReceivableNumber*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtStartingReceivableNumber}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtStartingReceivableNumber*/}

                {/* END_USER_CODE-USER_AFTER_txtStartingReceivableNumber*/}
                {/* START_USER_CODE-USER_BEFORE_lblBatchedReadyToPays*/}

                {/* END_USER_CODE-USER_BEFORE_lblBatchedReadyToPays*/}

                <LabelWidget
                  values={values}
                  conf={state.lblBatchedReadyToPays}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblBatchedReadyToPays*/}

                {/* END_USER_CODE-USER_AFTER_lblBatchedReadyToPays*/}
                {/* START_USER_CODE-USER_BEFORE_btnSearch*/}

                {/* END_USER_CODE-USER_BEFORE_btnSearch*/}

                <ButtonWidget
                  conf={state.btnSearch}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnSearch*/}

                {/* END_USER_CODE-USER_AFTER_btnSearch*/}
                {/* START_USER_CODE-USER_BEFORE_txtStartingCheck*/}

                {/* END_USER_CODE-USER_BEFORE_txtStartingCheck*/}
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtStartingCheck}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>

                {/* START_USER_CODE-USER_AFTER_txtStartingCheck*/}

                {/* END_USER_CODE-USER_AFTER_txtStartingCheck*/}
                {/* START_USER_CODE-USER_BEFORE_gridBatchedReadyToPays*/}

                {/* END_USER_CODE-USER_BEFORE_gridBatchedReadyToPays*/}

                <GridWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  conf={state.gridBatchedReadyToPays}
                  screenConf={state}
                  linkClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onRowChange={selectedRows => onGridRowChange(selectedRows)}
                  errors={errors}
                  touched={touched}
                  rows={values.gridBatchedReadyToPays}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  refObject={thisObj}
                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_gridBatchedReadyToPays*/}

                {/* END_USER_CODE-USER_AFTER_gridBatchedReadyToPays*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxCheckDetails*/}

              {/* END_USER_CODE-USER_AFTER_grpbxCheckDetails*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_lblChecksSelected*/}

                {/* END_USER_CODE-USER_BEFORE_lblChecksSelected*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChecksSelected}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChecksSelected*/}

                {/* END_USER_CODE-USER_AFTER_lblChecksSelected*/}
                {/* START_USER_CODE-USER_BEFORE_lblChecksSelectedValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblChecksSelectedValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChecksSelectedValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChecksSelectedValue*/}

                {/* END_USER_CODE-USER_AFTER_lblChecksSelectedValue*/}
                {/* START_USER_CODE-USER_BEFORE_btnPrintChecks*/}

                {/* END_USER_CODE-USER_BEFORE_btnPrintChecks*/}

                <ButtonWidget
                  conf={state.btnPrintChecks}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnPrintChecks*/}

                {/* END_USER_CODE-USER_AFTER_btnPrintChecks*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_PrintChecks*/}

              {/* END_USER_CODE-USER_AFTER_PrintChecks*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(AccountPayment_PrintChecks);