/* eslint-disable*/
import React from "react";
import AccountPayment_ReportPreviewCheck from "./ReportPreviewCheck";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("ReportPreviewCheck Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<AccountPayment_ReportPreviewCheck />);
    });
  });
  afterEach(cleanup);
  test("is ReportPreviewCheck Loads Successfully", () => {
    expect(screen.getByText("ReportPreviewCheck")).toBeInTheDocument;
  });
  test("Custom Test Cases for ReportPreviewCheck", () => {
    // START_USER_CODE-USER_ReportPreviewCheck_Custom_Test_Case
    // END_USER_CODE-USER_ReportPreviewCheck_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<AccountPayment_ReportPreviewCheck />);
    });
  });
  afterEach(cleanup);
  test("btn1(Button Widget) Test Cases", async () => {
    const btn1 = screen.getByTestId("btn1");
    expect(btn1).toBeInTheDocument;
    expect(btn1.textContent).toEqual(
      t("AccountPayment:ReportPreviewCheck_btn1")
    );
  });
  test("Custom Test Cases for btn1", () => {
    // START_USER_CODE-USER_btn1_TEST
    // END_USER_CODE-USER_btn1_TEST
  });
  test("btn2(Button Widget) Test Cases", async () => {
    const btn2 = screen.getByTestId("btn2");
    expect(btn2).toBeInTheDocument;
    expect(btn2.textContent).toEqual(
      t("AccountPayment:ReportPreviewCheck_btn2")
    );
  });
  test("Custom Test Cases for btn2", () => {
    // START_USER_CODE-USER_btn2_TEST
    // END_USER_CODE-USER_btn2_TEST
  });
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("AccountPayment:ReportPreviewCheck_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnPrint(Button Widget) Test Cases", async () => {
    const btnPrint = screen.getByTestId("btnPrint");
    expect(btnPrint).toBeInTheDocument;
    expect(btnPrint.textContent).toEqual(
      t("AccountPayment:ReportPreviewCheck_btnPrint")
    );
  });
  test("Custom Test Cases for btnPrint", () => {
    // START_USER_CODE-USER_btnPrint_TEST
    // END_USER_CODE-USER_btnPrint_TEST
  });
  test("btnPrintSetup(Button Widget) Test Cases", async () => {
    const btnPrintSetup = screen.getByTestId("btnPrintSetup");
    expect(btnPrintSetup).toBeInTheDocument;
    expect(btnPrintSetup.textContent).toEqual(
      t("AccountPayment:ReportPreviewCheck_btnPrintSetup")
    );
  });
  test("Custom Test Cases for btnPrintSetup", () => {
    // START_USER_CODE-USER_btnPrintSetup_TEST
    // END_USER_CODE-USER_btnPrintSetup_TEST
  });
  test("btnSaveasPDF(Button Widget) Test Cases", async () => {
    const btnSaveasPDF = screen.getByTestId("btnSaveasPDF");
    expect(btnSaveasPDF).toBeInTheDocument;
    expect(btnSaveasPDF.textContent).toEqual(
      t("AccountPayment:ReportPreviewCheck_btnSaveasPDF")
    );
  });
  test("Custom Test Cases for btnSaveasPDF", () => {
    // START_USER_CODE-USER_btnSaveasPDF_TEST
    // END_USER_CODE-USER_btnSaveasPDF_TEST
  });
  test("grpbxPreviewActions(GroupBox Widget) Test Cases", async () => {
    const grpbxPreviewActions = screen.getByTestId("grpbxPreviewActions");
    expect(grpbxPreviewActions.tagName).toBe("BUTTON");
    expect(grpbxPreviewActions.type).toBe("button");
    expect(grpbxPreviewActions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxPreviewActions", () => {
    // START_USER_CODE-USER_grpbxPreviewActions_TEST
    // END_USER_CODE-USER_grpbxPreviewActions_TEST
  });
});
