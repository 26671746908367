/* eslint-disable*/
import React from "react";
import Settlements_TradeAgreeProfile from "./TradeAgreeProfile";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("TradeAgreeProfile Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<Settlements_TradeAgreeProfile />);
    });
  });
  afterEach(cleanup);
  test("is TradeAgreeProfile Loads Successfully", () => {
    expect(screen.getByText("TradeAgreeProfile")).toBeInTheDocument;
  });
  test("Custom Test Cases for TradeAgreeProfile", () => {
    // START_USER_CODE-USER_TradeAgreeProfile_Custom_Test_Case
    // END_USER_CODE-USER_TradeAgreeProfile_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<Settlements_TradeAgreeProfile />);
    });
  });
  afterEach(cleanup);
  test("btn1(Button Widget) Test Cases", async () => {
    const btn1 = screen.getByTestId("btn1");
    expect(btn1).toBeInTheDocument;
    expect(btn1.textContent).toEqual(t("Settlements:TradeAgreeProfile_btn1"));
  });
  test("Custom Test Cases for btn1", () => {
    // START_USER_CODE-USER_btn1_TEST
    // END_USER_CODE-USER_btn1_TEST
  });
  test("btn2(Button Widget) Test Cases", async () => {
    const btn2 = screen.getByTestId("btn2");
    expect(btn2).toBeInTheDocument;
    expect(btn2.textContent).toEqual(t("Settlements:TradeAgreeProfile_btn2"));
  });
  test("Custom Test Cases for btn2", () => {
    // START_USER_CODE-USER_btn2_TEST
    // END_USER_CODE-USER_btn2_TEST
  });
  test("btn3(Button Widget) Test Cases", async () => {
    const btn3 = screen.getByTestId("btn3");
    expect(btn3).toBeInTheDocument;
    expect(btn3.textContent).toEqual(t("Settlements:TradeAgreeProfile_btn3"));
  });
  test("Custom Test Cases for btn3", () => {
    // START_USER_CODE-USER_btn3_TEST
    // END_USER_CODE-USER_btn3_TEST
  });
  test("btn4(Button Widget) Test Cases", async () => {
    const btn4 = screen.getByTestId("btn4");
    expect(btn4).toBeInTheDocument;
    expect(btn4.textContent).toEqual(t("Settlements:TradeAgreeProfile_btn4"));
  });
  test("Custom Test Cases for btn4", () => {
    // START_USER_CODE-USER_btn4_TEST
    // END_USER_CODE-USER_btn4_TEST
  });
  test("btn5(Button Widget) Test Cases", async () => {
    const btn5 = screen.getByTestId("btn5");
    expect(btn5).toBeInTheDocument;
    expect(btn5.textContent).toEqual(t("Settlements:TradeAgreeProfile_btn5"));
  });
  test("Custom Test Cases for btn5", () => {
    // START_USER_CODE-USER_btn5_TEST
    // END_USER_CODE-USER_btn5_TEST
  });
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("Settlements:TradeAgreeProfile_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnDelete(Button Widget) Test Cases", async () => {
    const btnDelete = screen.getByTestId("btnDelete");
    expect(btnDelete).toBeInTheDocument;
    expect(btnDelete.textContent).toEqual(
      t("Settlements:TradeAgreeProfile_btnDelete")
    );
  });
  test("Custom Test Cases for btnDelete", () => {
    // START_USER_CODE-USER_btnDelete_TEST
    // END_USER_CODE-USER_btnDelete_TEST
  });
  test("btnPrint(Button Widget) Test Cases", async () => {
    const btnPrint = screen.getByTestId("btnPrint");
    expect(btnPrint).toBeInTheDocument;
    expect(btnPrint.textContent).toEqual(
      t("Settlements:TradeAgreeProfile_btnPrint")
    );
  });
  test("Custom Test Cases for btnPrint", () => {
    // START_USER_CODE-USER_btnPrint_TEST
    // END_USER_CODE-USER_btnPrint_TEST
  });
  test("btnUpdate(Button Widget) Test Cases", async () => {
    const btnUpdate = screen.getByTestId("btnUpdate");
    expect(btnUpdate).toBeInTheDocument;
    expect(btnUpdate.textContent).toEqual(
      t("Settlements:TradeAgreeProfile_btnUpdate")
    );
  });
  test("Custom Test Cases for btnUpdate", () => {
    // START_USER_CODE-USER_btnUpdate_TEST
    // END_USER_CODE-USER_btnUpdate_TEST
  });
  test("gridApplicationInfo(Grid Widget) Test Cases", async () => {
    let gridApplicationInfo = screen.getByTestId("gridApplicationInfo");
    let gridApplicationInfobtn =
      gridApplicationInfo.nextElementSibling.firstElementChild;
    gridApplicationInfo =
      gridApplicationInfo.parentElement.parentElement.parentElement;
    expect(gridApplicationInfo.tagName).toBe("DIV");
    expect(gridApplicationInfo.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("Settlements:TradeAgreeProfile_gridApplicationInfo")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridApplicationInfo", () => {
    // START_USER_CODE-USER_gridApplicationInfo_TEST
    // END_USER_CODE-USER_gridApplicationInfo_TEST
  });
  test("grpbxActions(GroupBox Widget) Test Cases", async () => {
    const grpbxActions = screen.getByTestId("grpbxActions");
    expect(grpbxActions.tagName).toBe("BUTTON");
    expect(grpbxActions.type).toBe("button");
    expect(grpbxActions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxActions", () => {
    // START_USER_CODE-USER_grpbxActions_TEST
    // END_USER_CODE-USER_grpbxActions_TEST
  });
  test("grpbxAgreementInfo(GroupBox Widget) Test Cases", async () => {
    const grpbxAgreementInfo = screen.getByTestId("grpbxAgreementInfo");
    expect(grpbxAgreementInfo.tagName).toBe("BUTTON");
    expect(grpbxAgreementInfo.type).toBe("button");
    expect(grpbxAgreementInfo.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxAgreementInfo", () => {
    // START_USER_CODE-USER_grpbxAgreementInfo_TEST
    // END_USER_CODE-USER_grpbxAgreementInfo_TEST
  });
  test("grpbxComments(GroupBox Widget) Test Cases", async () => {
    const grpbxComments = screen.getByTestId("grpbxComments");
    expect(grpbxComments.tagName).toBe("BUTTON");
    expect(grpbxComments.type).toBe("button");
    expect(grpbxComments.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxComments", () => {
    // START_USER_CODE-USER_grpbxComments_TEST
    // END_USER_CODE-USER_grpbxComments_TEST
  });
  test("grpbxPeanutInfo(GroupBox Widget) Test Cases", async () => {
    const grpbxPeanutInfo = screen.getByTestId("grpbxPeanutInfo");
    expect(grpbxPeanutInfo.tagName).toBe("BUTTON");
    expect(grpbxPeanutInfo.type).toBe("button");
    expect(grpbxPeanutInfo.classList).toContain("btn");
    expect(grpbxPeanutInfo.textContent).toEqual(
      t("Settlements:TradeAgreeProfile_grpbxPeanutInfo")
    );
  });
  test("Custom Test Cases for grpbxPeanutInfo", () => {
    // START_USER_CODE-USER_grpbxPeanutInfo_TEST
    // END_USER_CODE-USER_grpbxPeanutInfo_TEST
  });
  test("grpbxPricingInfo(GroupBox Widget) Test Cases", async () => {
    const grpbxPricingInfo = screen.getByTestId("grpbxPricingInfo");
    expect(grpbxPricingInfo.tagName).toBe("BUTTON");
    expect(grpbxPricingInfo.type).toBe("button");
    expect(grpbxPricingInfo.classList).toContain("btn");
    expect(grpbxPricingInfo.textContent).toEqual(
      t("Settlements:TradeAgreeProfile_grpbxPricingInfo")
    );
  });
  test("Custom Test Cases for grpbxPricingInfo", () => {
    // START_USER_CODE-USER_grpbxPricingInfo_TEST
    // END_USER_CODE-USER_grpbxPricingInfo_TEST
  });
  test("grpbxTrdSalePrchs(GroupBox Widget) Test Cases", async () => {
    const grpbxTrdSalePrchs = screen.getByTestId("grpbxTrdSalePrchs");
    expect(grpbxTrdSalePrchs.tagName).toBe("BUTTON");
    expect(grpbxTrdSalePrchs.type).toBe("button");
    expect(grpbxTrdSalePrchs.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxTrdSalePrchs", () => {
    // START_USER_CODE-USER_grpbxTrdSalePrchs_TEST
    // END_USER_CODE-USER_grpbxTrdSalePrchs_TEST
  });
  test("grpbxVendorSplits(GroupBox Widget) Test Cases", async () => {
    const grpbxVendorSplits = screen.getByTestId("grpbxVendorSplits");
    expect(grpbxVendorSplits.tagName).toBe("BUTTON");
    expect(grpbxVendorSplits.type).toBe("button");
    expect(grpbxVendorSplits.classList).toContain("btn");
    expect(grpbxVendorSplits.textContent).toEqual(
      t("Settlements:TradeAgreeProfile_grpbxVendorSplits")
    );
  });
  test("Custom Test Cases for grpbxVendorSplits", () => {
    // START_USER_CODE-USER_grpbxVendorSplits_TEST
    // END_USER_CODE-USER_grpbxVendorSplits_TEST
  });
  test("grpbxVendorSplitSub(GroupBox Widget) Test Cases", async () => {
    const grpbxVendorSplitSub = screen.getByTestId("grpbxVendorSplitSub");
    expect(grpbxVendorSplitSub.tagName).toBe("BUTTON");
    expect(grpbxVendorSplitSub.type).toBe("button");
    expect(grpbxVendorSplitSub.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVendorSplitSub", () => {
    // START_USER_CODE-USER_grpbxVendorSplitSub_TEST
    // END_USER_CODE-USER_grpbxVendorSplitSub_TEST
  });
  test("grpbxVndrPrcngInfo(GroupBox Widget) Test Cases", async () => {
    const grpbxVndrPrcngInfo = screen.getByTestId("grpbxVndrPrcngInfo");
    expect(grpbxVndrPrcngInfo.tagName).toBe("BUTTON");
    expect(grpbxVndrPrcngInfo.type).toBe("button");
    expect(grpbxVndrPrcngInfo.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxVndrPrcngInfo", () => {
    // START_USER_CODE-USER_grpbxVndrPrcngInfo_TEST
    // END_USER_CODE-USER_grpbxVndrPrcngInfo_TEST
  });
  test("lblCropYear2(Label Widget) Test Cases", async () => {
    const lblCropYear2 = screen.getByTestId("lblCropYear2");
    expect(lblCropYear2.tagName).toBe("LABEL");
    expect(lblCropYear2.classList).toContain("form-label");
    expect(lblCropYear2.textContent).toEqual(
      t("Settlements:TradeAgreeProfile_lblCropYear2")
    );
  });
  test("Custom Test Cases for lblCropYear2", () => {
    // START_USER_CODE-USER_lblCropYear2_TEST
    // END_USER_CODE-USER_lblCropYear2_TEST
  });
  test("lblPnutInfo(Label Widget) Test Cases", async () => {
    const lblPnutInfo = screen.getByTestId("lblPnutInfo");
    expect(lblPnutInfo.tagName).toBe("LABEL");
    expect(lblPnutInfo.classList).toContain("form-label");
    expect(lblPnutInfo.textContent).toEqual(
      t("Settlements:TradeAgreeProfile_lblPnutInfo")
    );
  });
  test("Custom Test Cases for lblPnutInfo", () => {
    // START_USER_CODE-USER_lblPnutInfo_TEST
    // END_USER_CODE-USER_lblPnutInfo_TEST
  });
  test("lblPricingInfo(Label Widget) Test Cases", async () => {
    const lblPricingInfo = screen.getByTestId("lblPricingInfo");
    expect(lblPricingInfo.tagName).toBe("LABEL");
    expect(lblPricingInfo.classList).toContain("form-label");
    expect(lblPricingInfo.textContent).toEqual(
      t("Settlements:TradeAgreeProfile_lblPricingInfo")
    );
  });
  test("Custom Test Cases for lblPricingInfo", () => {
    // START_USER_CODE-USER_lblPricingInfo_TEST
    // END_USER_CODE-USER_lblPricingInfo_TEST
  });
  test("lblRemitTo(Label Widget) Test Cases", async () => {
    const lblRemitTo = screen.getByTestId("lblRemitTo");
    expect(lblRemitTo.tagName).toBe("LABEL");
    expect(lblRemitTo.classList).toContain("form-label");
    expect(lblRemitTo.textContent).toEqual(
      t("Settlements:TradeAgreeProfile_lblRemitTo")
    );
  });
  test("Custom Test Cases for lblRemitTo", () => {
    // START_USER_CODE-USER_lblRemitTo_TEST
    // END_USER_CODE-USER_lblRemitTo_TEST
  });
  test("lblSharePercentage(Label Widget) Test Cases", async () => {
    const lblSharePercentage = screen.getByTestId("lblSharePercentage");
    expect(lblSharePercentage.tagName).toBe("LABEL");
    expect(lblSharePercentage.classList).toContain("form-label");
    expect(lblSharePercentage.textContent).toEqual(
      t("Settlements:TradeAgreeProfile_lblSharePercentage")
    );
  });
  test("Custom Test Cases for lblSharePercentage", () => {
    // START_USER_CODE-USER_lblSharePercentage_TEST
    // END_USER_CODE-USER_lblSharePercentage_TEST
  });
  test("lblVendor(Label Widget) Test Cases", async () => {
    const lblVendor = screen.getByTestId("lblVendor");
    expect(lblVendor.tagName).toBe("LABEL");
    expect(lblVendor.classList).toContain("form-label");
    expect(lblVendor.textContent).toEqual(
      t("Settlements:TradeAgreeProfile_lblVendor")
    );
  });
  test("Custom Test Cases for lblVendor", () => {
    // START_USER_CODE-USER_lblVendor_TEST
    // END_USER_CODE-USER_lblVendor_TEST
  });
  test("lblVndrSplts(Label Widget) Test Cases", async () => {
    const lblVndrSplts = screen.getByTestId("lblVndrSplts");
    expect(lblVndrSplts.tagName).toBe("LABEL");
    expect(lblVndrSplts.classList).toContain("form-label");
    expect(lblVndrSplts.textContent).toEqual(
      t("Settlements:TradeAgreeProfile_lblVndrSplts")
    );
  });
  test("Custom Test Cases for lblVndrSplts", () => {
    // START_USER_CODE-USER_lblVndrSplts_TEST
    // END_USER_CODE-USER_lblVndrSplts_TEST
  });
  test("txtAddedBy(Textbox Widget) Test Cases", async () => {
    const txtAddedBy = screen.getByTestId("txtAddedBy");
    expect(txtAddedBy.tagName).toBe("INPUT");
    expect(txtAddedBy.type).toBe("text");
    expect(txtAddedBy.classList).toContain("textboxWidgetClass");
    expect(txtAddedBy.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeAgreeProfile_txtAddedBy")
    );
    await act(async () => {
      userEvent.type(txtAddedBy, "1");
    });
  });
  test("Custom Test Cases for txtAddedBy", () => {
    // START_USER_CODE-USER_txtAddedBy_TEST
    // END_USER_CODE-USER_txtAddedBy_TEST
  });
  test("txtAgreedPounds(Textbox Widget) Test Cases", async () => {
    const txtAgreedPounds = screen.getByTestId("txtAgreedPounds");
    expect(txtAgreedPounds.tagName).toBe("INPUT");
    expect(txtAgreedPounds.type).toBe("text");
    expect(txtAgreedPounds.classList).toContain("textboxWidgetClass");
    expect(txtAgreedPounds.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeAgreeProfile_txtAgreedPounds")
    );
    await act(async () => {
      userEvent.type(txtAgreedPounds, "1");
    });
  });
  test("Custom Test Cases for txtAgreedPounds", () => {
    // START_USER_CODE-USER_txtAgreedPounds_TEST
    // END_USER_CODE-USER_txtAgreedPounds_TEST
  });
  test("txtAgreement(Textbox Widget) Test Cases", async () => {
    const txtAgreement = screen.getByTestId("txtAgreement");
    expect(txtAgreement.tagName).toBe("INPUT");
    expect(txtAgreement.type).toBe("text");
    expect(txtAgreement.classList).toContain("textboxWidgetClass");
    expect(txtAgreement.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeAgreeProfile_txtAgreement")
    );
    await act(async () => {
      userEvent.type(txtAgreement, "1");
    });
  });
  test("Custom Test Cases for txtAgreement", () => {
    // START_USER_CODE-USER_txtAgreement_TEST
    // END_USER_CODE-USER_txtAgreement_TEST
  });
  test("txtAgreementDate(Textbox Widget) Test Cases", async () => {
    const txtAgreementDate = screen.getByTestId("txtAgreementDate");
    expect(txtAgreementDate.tagName).toBe("INPUT");
    expect(txtAgreementDate.type).toBe("text");
    expect(txtAgreementDate.classList).toContain("textboxWidgetClass");
    expect(txtAgreementDate.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeAgreeProfile_txtAgreementDate")
    );
    await act(async () => {
      userEvent.type(txtAgreementDate, "1");
    });
  });
  test("Custom Test Cases for txtAgreementDate", () => {
    // START_USER_CODE-USER_txtAgreementDate_TEST
    // END_USER_CODE-USER_txtAgreementDate_TEST
  });
  test("txtAppliedPounds(Textbox Widget) Test Cases", async () => {
    const txtAppliedPounds = screen.getByTestId("txtAppliedPounds");
    expect(txtAppliedPounds.tagName).toBe("INPUT");
    expect(txtAppliedPounds.type).toBe("text");
    expect(txtAppliedPounds.classList).toContain("textboxWidgetClass");
    expect(txtAppliedPounds.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeAgreeProfile_txtAppliedPounds")
    );
    await act(async () => {
      userEvent.type(txtAppliedPounds, "1");
    });
  });
  test("Custom Test Cases for txtAppliedPounds", () => {
    // START_USER_CODE-USER_txtAppliedPounds_TEST
    // END_USER_CODE-USER_txtAppliedPounds_TEST
  });
  test("txtChangedBy(Textbox Widget) Test Cases", async () => {
    const txtChangedBy = screen.getByTestId("txtChangedBy");
    expect(txtChangedBy.tagName).toBe("INPUT");
    expect(txtChangedBy.type).toBe("text");
    expect(txtChangedBy.classList).toContain("textboxWidgetClass");
    expect(txtChangedBy.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeAgreeProfile_txtChangedBy")
    );
    await act(async () => {
      userEvent.type(txtChangedBy, "1");
    });
  });
  test("Custom Test Cases for txtChangedBy", () => {
    // START_USER_CODE-USER_txtChangedBy_TEST
    // END_USER_CODE-USER_txtChangedBy_TEST
  });
  test("gridApplicationInfo_txtcolAppldLbs(Grid Widget) Test Cases", async () => {
    let gridApplicationInfo_txtcolAppldLbs = screen.getByTestId(
      "gridApplicationInfo"
    );
    let gridApplicationInfo_txtcolAppldLbsbtn =
      gridApplicationInfo_txtcolAppldLbs.nextElementSibling.firstElementChild;
    gridApplicationInfo_txtcolAppldLbs =
      gridApplicationInfo_txtcolAppldLbs.parentElement.parentElement
        .parentElement;
    expect(gridApplicationInfo_txtcolAppldLbs.tagName).toBe("DIV");
    expect(gridApplicationInfo_txtcolAppldLbs.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("Settlements:TradeAgreeProfile_gridApplicationInfo")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolAppldLbs", () => {
    // START_USER_CODE-USER_txtcolAppldLbs_TEST
    // END_USER_CODE-USER_txtcolAppldLbs_TEST
  });
  test("gridApplicationInfo_txtcolIsStsClm(Grid Widget) Test Cases", async () => {
    let gridApplicationInfo_txtcolIsStsClm = screen.getByTestId(
      "gridApplicationInfo"
    );
    let gridApplicationInfo_txtcolIsStsClmbtn =
      gridApplicationInfo_txtcolIsStsClm.nextElementSibling.firstElementChild;
    gridApplicationInfo_txtcolIsStsClm =
      gridApplicationInfo_txtcolIsStsClm.parentElement.parentElement
        .parentElement;
    expect(gridApplicationInfo_txtcolIsStsClm.tagName).toBe("DIV");
    expect(gridApplicationInfo_txtcolIsStsClm.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("Settlements:TradeAgreeProfile_gridApplicationInfo")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolIsStsClm", () => {
    // START_USER_CODE-USER_txtcolIsStsClm_TEST
    // END_USER_CODE-USER_txtcolIsStsClm_TEST
  });
  test("gridApplicationInfo_txtcolLoc(Grid Widget) Test Cases", async () => {
    let gridApplicationInfo_txtcolLoc = screen.getByTestId(
      "gridApplicationInfo"
    );
    let gridApplicationInfo_txtcolLocbtn =
      gridApplicationInfo_txtcolLoc.nextElementSibling.firstElementChild;
    gridApplicationInfo_txtcolLoc =
      gridApplicationInfo_txtcolLoc.parentElement.parentElement.parentElement;
    expect(gridApplicationInfo_txtcolLoc.tagName).toBe("DIV");
    expect(gridApplicationInfo_txtcolLoc.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("Settlements:TradeAgreeProfile_gridApplicationInfo")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolLoc", () => {
    // START_USER_CODE-USER_txtcolLoc_TEST
    // END_USER_CODE-USER_txtcolLoc_TEST
  });
  test("gridApplicationInfo_txtcolRcvdDt(Grid Widget) Test Cases", async () => {
    let gridApplicationInfo_txtcolRcvdDt = screen.getByTestId(
      "gridApplicationInfo"
    );
    let gridApplicationInfo_txtcolRcvdDtbtn =
      gridApplicationInfo_txtcolRcvdDt.nextElementSibling.firstElementChild;
    gridApplicationInfo_txtcolRcvdDt =
      gridApplicationInfo_txtcolRcvdDt.parentElement.parentElement
        .parentElement;
    expect(gridApplicationInfo_txtcolRcvdDt.tagName).toBe("DIV");
    expect(gridApplicationInfo_txtcolRcvdDt.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("Settlements:TradeAgreeProfile_gridApplicationInfo")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolRcvdDt", () => {
    // START_USER_CODE-USER_txtcolRcvdDt_TEST
    // END_USER_CODE-USER_txtcolRcvdDt_TEST
  });
  test("gridApplicationInfo_txtcolSegVal(Grid Widget) Test Cases", async () => {
    let gridApplicationInfo_txtcolSegVal = screen.getByTestId(
      "gridApplicationInfo"
    );
    let gridApplicationInfo_txtcolSegValbtn =
      gridApplicationInfo_txtcolSegVal.nextElementSibling.firstElementChild;
    gridApplicationInfo_txtcolSegVal =
      gridApplicationInfo_txtcolSegVal.parentElement.parentElement
        .parentElement;
    expect(gridApplicationInfo_txtcolSegVal.tagName).toBe("DIV");
    expect(gridApplicationInfo_txtcolSegVal.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("Settlements:TradeAgreeProfile_gridApplicationInfo")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolSegVal", () => {
    // START_USER_CODE-USER_txtcolSegVal_TEST
    // END_USER_CODE-USER_txtcolSegVal_TEST
  });
  test("gridApplicationInfo_txtcolShippdDt(Grid Widget) Test Cases", async () => {
    let gridApplicationInfo_txtcolShippdDt = screen.getByTestId(
      "gridApplicationInfo"
    );
    let gridApplicationInfo_txtcolShippdDtbtn =
      gridApplicationInfo_txtcolShippdDt.nextElementSibling.firstElementChild;
    gridApplicationInfo_txtcolShippdDt =
      gridApplicationInfo_txtcolShippdDt.parentElement.parentElement
        .parentElement;
    expect(gridApplicationInfo_txtcolShippdDt.tagName).toBe("DIV");
    expect(gridApplicationInfo_txtcolShippdDt.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("Settlements:TradeAgreeProfile_gridApplicationInfo")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolShippdDt", () => {
    // START_USER_CODE-USER_txtcolShippdDt_TEST
    // END_USER_CODE-USER_txtcolShippdDt_TEST
  });
  test("gridApplicationInfo_txtcolTrdInsp(Grid Widget) Test Cases", async () => {
    let gridApplicationInfo_txtcolTrdInsp = screen.getByTestId(
      "gridApplicationInfo"
    );
    let gridApplicationInfo_txtcolTrdInspbtn =
      gridApplicationInfo_txtcolTrdInsp.nextElementSibling.firstElementChild;
    gridApplicationInfo_txtcolTrdInsp =
      gridApplicationInfo_txtcolTrdInsp.parentElement.parentElement
        .parentElement;
    expect(gridApplicationInfo_txtcolTrdInsp.tagName).toBe("DIV");
    expect(gridApplicationInfo_txtcolTrdInsp.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("Settlements:TradeAgreeProfile_gridApplicationInfo")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolTrdInsp", () => {
    // START_USER_CODE-USER_txtcolTrdInsp_TEST
    // END_USER_CODE-USER_txtcolTrdInsp_TEST
  });
  test("gridApplicationInfo_txtcolTrdSettle(Grid Widget) Test Cases", async () => {
    let gridApplicationInfo_txtcolTrdSettle = screen.getByTestId(
      "gridApplicationInfo"
    );
    let gridApplicationInfo_txtcolTrdSettlebtn =
      gridApplicationInfo_txtcolTrdSettle.nextElementSibling.firstElementChild;
    gridApplicationInfo_txtcolTrdSettle =
      gridApplicationInfo_txtcolTrdSettle.parentElement.parentElement
        .parentElement;
    expect(gridApplicationInfo_txtcolTrdSettle.tagName).toBe("DIV");
    expect(gridApplicationInfo_txtcolTrdSettle.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t("Settlements:TradeAgreeProfile_gridApplicationInfo")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolTrdSettle", () => {
    // START_USER_CODE-USER_txtcolTrdSettle_TEST
    // END_USER_CODE-USER_txtcolTrdSettle_TEST
  });
  test("txtOpenBalance(Textbox Widget) Test Cases", async () => {
    const txtOpenBalance = screen.getByTestId("txtOpenBalance");
    expect(txtOpenBalance.tagName).toBe("INPUT");
    expect(txtOpenBalance.type).toBe("text");
    expect(txtOpenBalance.classList).toContain("textboxWidgetClass");
    expect(txtOpenBalance.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeAgreeProfile_txtOpenBalance")
    );
    await act(async () => {
      userEvent.type(txtOpenBalance, "1");
    });
  });
  test("Custom Test Cases for txtOpenBalance", () => {
    // START_USER_CODE-USER_txtOpenBalance_TEST
    // END_USER_CODE-USER_txtOpenBalance_TEST
  });
  test("txtPrice(Textbox Widget) Test Cases", async () => {
    const txtPrice = screen.getByTestId("txtPrice");
    expect(txtPrice.tagName).toBe("INPUT");
    expect(txtPrice.type).toBe("text");
    expect(txtPrice.classList).toContain("textboxWidgetClass");
    expect(txtPrice.previousElementSibling.textContent).toEqual(
      t("Settlements:TradeAgreeProfile_txtPrice")
    );
    await act(async () => {
      userEvent.type(txtPrice, "1");
    });
  });
  test("Custom Test Cases for txtPrice", () => {
    // START_USER_CODE-USER_txtPrice_TEST
    // END_USER_CODE-USER_txtPrice_TEST
  });
  test("txtSharePercentage1(Textbox Widget) Test Cases", async () => {
    const txtSharePercentage1 = screen.getByTestId("txtSharePercentage1");
    expect(txtSharePercentage1.tagName).toBe("INPUT");
    expect(txtSharePercentage1.type).toBe("text");
    expect(txtSharePercentage1.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePercentage1, "1");
    });
  });
  test("Custom Test Cases for txtSharePercentage1", () => {
    // START_USER_CODE-USER_txtSharePercentage1_TEST
    // END_USER_CODE-USER_txtSharePercentage1_TEST
  });
  test("txtSharePercentage2(Textbox Widget) Test Cases", async () => {
    const txtSharePercentage2 = screen.getByTestId("txtSharePercentage2");
    expect(txtSharePercentage2.tagName).toBe("INPUT");
    expect(txtSharePercentage2.type).toBe("text");
    expect(txtSharePercentage2.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePercentage2, "1");
    });
  });
  test("Custom Test Cases for txtSharePercentage2", () => {
    // START_USER_CODE-USER_txtSharePercentage2_TEST
    // END_USER_CODE-USER_txtSharePercentage2_TEST
  });
  test("txtSharePercentage3(Textbox Widget) Test Cases", async () => {
    const txtSharePercentage3 = screen.getByTestId("txtSharePercentage3");
    expect(txtSharePercentage3.tagName).toBe("INPUT");
    expect(txtSharePercentage3.type).toBe("text");
    expect(txtSharePercentage3.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePercentage3, "1");
    });
  });
  test("Custom Test Cases for txtSharePercentage3", () => {
    // START_USER_CODE-USER_txtSharePercentage3_TEST
    // END_USER_CODE-USER_txtSharePercentage3_TEST
  });
  test("txtSharePercentage4(Textbox Widget) Test Cases", async () => {
    const txtSharePercentage4 = screen.getByTestId("txtSharePercentage4");
    expect(txtSharePercentage4.tagName).toBe("INPUT");
    expect(txtSharePercentage4.type).toBe("text");
    expect(txtSharePercentage4.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtSharePercentage4, "1");
    });
  });
  test("Custom Test Cases for txtSharePercentage4", () => {
    // START_USER_CODE-USER_txtSharePercentage4_TEST
    // END_USER_CODE-USER_txtSharePercentage4_TEST
  });
  test("txtVendor1(Textbox Widget) Test Cases", async () => {
    const txtVendor1 = screen.getByTestId("txtVendor1");
    expect(txtVendor1.tagName).toBe("INPUT");
    expect(txtVendor1.type).toBe("text");
    expect(txtVendor1.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor1, "1");
    });
  });
  test("Custom Test Cases for txtVendor1", () => {
    // START_USER_CODE-USER_txtVendor1_TEST
    // END_USER_CODE-USER_txtVendor1_TEST
  });
  test("txtVendor2(Textbox Widget) Test Cases", async () => {
    const txtVendor2 = screen.getByTestId("txtVendor2");
    expect(txtVendor2.tagName).toBe("INPUT");
    expect(txtVendor2.type).toBe("text");
    expect(txtVendor2.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor2, "1");
    });
  });
  test("Custom Test Cases for txtVendor2", () => {
    // START_USER_CODE-USER_txtVendor2_TEST
    // END_USER_CODE-USER_txtVendor2_TEST
  });
  test("txtVendor3(Textbox Widget) Test Cases", async () => {
    const txtVendor3 = screen.getByTestId("txtVendor3");
    expect(txtVendor3.tagName).toBe("INPUT");
    expect(txtVendor3.type).toBe("text");
    expect(txtVendor3.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor3, "1");
    });
  });
  test("Custom Test Cases for txtVendor3", () => {
    // START_USER_CODE-USER_txtVendor3_TEST
    // END_USER_CODE-USER_txtVendor3_TEST
  });
  test("txtVendor4(Textbox Widget) Test Cases", async () => {
    const txtVendor4 = screen.getByTestId("txtVendor4");
    expect(txtVendor4.tagName).toBe("INPUT");
    expect(txtVendor4.type).toBe("text");
    expect(txtVendor4.classList).toContain("textboxWidgetClass");
    await act(async () => {
      userEvent.type(txtVendor4, "1");
    });
  });
  test("Custom Test Cases for txtVendor4", () => {
    // START_USER_CODE-USER_txtVendor4_TEST
    // END_USER_CODE-USER_txtVendor4_TEST
  });
});
