export const DefaultPropsData = {
    GridWidget: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        clonedExtId: "",
        Cols: "3",
        ColsForLargeDesktop: "3",
        ColsForMobile: "1",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        ColSpan: "3",
        ColSpanForLargeDesktop: "3",
        ColSpanForMobile: "3",
        ColSpanForTabLandscape: "3",
        ColSpanForTabPotrait: "3",
        inlineValidate: true,
        defaultCollapsed: false,
        isCollapsible: true,
        DisplaySize: "10",
        Export: false,
        gridRowLabel: "",
        gridRowLableRequired: false,
        gridRowNumberRequired: true,
        gridWidgetType: "NormalGrid",
        isAddRowRequired: true,
        isDeleteRowRequired: true,
        isEditable: false,
        isGridRowCollapsed: false,
        isMultiSelect: false,
        lazyLoading: false,
        PageSize: "10",
        Pagination: true,
        Printing: false,
        ToolBarPosition: "BOTH",
        isCobolTable: false
    },
    GroupBoxWidget: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        clonedExtId: "",
        Cols: "3",
        ColsForLargeDesktop: "3",
        ColsForMobile: "1",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        ColSpan: "3",
        ColSpanForLargeDesktop: "3",
        ColSpanForMobile: "3",
        ColSpanForTabLandscape: "3",
        ColSpanForTabPotrait: "3",
        inlineValidate: true,
        defaultCollapsed: false,
        isCollapsible: true,
        componentModuleName: "",
        componentScope: "",
        componentScreenName: ""
    },
    TreeWidget: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        clonedExtId: "",
        Cols: "3",
        ColsForLargeDesktop: "3",
        ColsForMobile: "1",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        ColSpan: "3",
        ColSpanForLargeDesktop: "3",
        ColSpanForMobile: "3",
        ColSpanForTabLandscape: "3",
        ColSpanForTabPotrait: "3",
        inlineValidate: true,
        defaultCollapsed: false,
        isCollapsible: true,
        isContextMenuRequired: false,
        Mandatory: false,
        MultiNodeSelection: false,
        RowSpan: "4",
        RowSpanForLargeDesktop: "4",
        RowSpanForMobile: "4",
        RowSpanForTabLandscape: "4",
        RowSpanForTabPotrait: "4",
        TreeMetaData: "",
        treeNodeDragDrop: "None",
        TreeSearch: "None"
    },
    ChartWidget: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        clonedExtId: "",
        Cols: "3",
        ColsForLargeDesktop: "3",
        ColsForMobile: "1",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        ColSpan: "3",
        ColSpanForLargeDesktop: "3",
        ColSpanForMobile: "3",
        ColSpanForTabLandscape: "3",
        ColSpanForTabPotrait: "3",
        inlineValidate: true,
        chartCaption: "",
        chartSubtitle: "",
        chartTitle: "",
        chartType: "candlestickBarChart",
        xAxisTitle: "X-Axis",
        yAxisTitle: "Y-Axis"
    },
    CommandContainerWidget: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        clonedExtId: "",
        Cols: "3",
        ColsForLargeDesktop: "3",
        ColsForMobile: "1",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        ColSpan: "3",
        ColSpanForLargeDesktop: "3",
        ColSpanForMobile: "3",
        ColSpanForTabLandscape: "3",
        ColSpanForTabPotrait: "3",
        inlineValidate: true,
        AlignChildren: "Left"
    },
    UDCInstanceWidget: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        clonedExtId: "",
        Cols: "3",
        ColsForLargeDesktop: "3",
        ColsForMobile: "1",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        ColSpan: "3",
        ColSpanForLargeDesktop: "3",
        ColSpanForMobile: "3",
        ColSpanForTabLandscape: "3",
        ColSpanForTabPotrait: "3",
        inlineValidate: true,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        ExtraProps: ""
    },
    UDCWidget: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        clonedExtId: "",
        Cols: "3",
        ColsForLargeDesktop: "3",
        ColsForMobile: "1",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        ColSpan: "3",
        ColSpanForLargeDesktop: "3",
        ColSpanForMobile: "3",
        ColSpanForTabLandscape: "3",
        ColSpanForTabPotrait: "3",
        inlineValidate: true,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        DefaultProps: "",
        FileDetails: "",
        NavigationDependency: "",
        OperationDependency: ""
    },
    IFrameWidget: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        clonedExtId: "",
        Cols: "3",
        ColsForLargeDesktop: "3",
        ColsForMobile: "1",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        ColSpan: "3",
        ColSpanForLargeDesktop: "3",
        ColSpanForMobile: "3",
        ColSpanForTabLandscape: "3",
        ColSpanForTabPotrait: "3",
        inlineValidate: true,
        src: ""
    },
    TabContainerWidget: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        clonedExtId: "",
        Cols: "3",
        ColsForLargeDesktop: "3",
        ColsForMobile: "1",
        ColsForTabLandscape: "3",
        ColsForTabPotrait: "3",
        ColSpan: "3",
        ColSpanForLargeDesktop: "3",
        ColSpanForMobile: "3",
        ColSpanForTabLandscape: "3",
        ColSpanForTabPotrait: "3",
        inlineValidate: true,
        ActiveArea: "ALL"
    },
    ButtonWidget: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        Category: "Submit Page",
        isSynchronous: true,
        HasImage: false
    },
    LinkWidget: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        Category: "Submit Page",
        isSynchronous: true
    },

    CheckBoxGroupFieldWidget: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        ReadOnly: false,
        key: "",
        Options: "Option1:1,Option2:2",
        OptionsAlignment: "Horizontal"
    },
    ListBoxFieldWidget: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        ReadOnly: false,
        key: "",
        Options: "",
        DataProviderForListBox: "ReferenceMetaData",
        listSize: "4"
    },
    ListSelectDeselectFieldWidget: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        ReadOnly: false,
        key: "",
        Options: "Option1:1,Option2:2"
    },
    DropDownFieldWidget: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        ReadOnly: false,
        key: "",
        Options: "",
        DataProviderForDropDown: "ReferenceMetaData",
        SpecialFirstOption: "Select"
    },
    RadioButtonGroupFieldWidget: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        ReadOnly: false,
        key: "",
        Options: "Yes:1,No:2",
        OptionsAlignment: "Vertical"
    },
    RangeWidget: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        ReadOnly: false,
        max: "",
        min: "",
        Value: "",
        StepForRangeWidget: ""
    },
    AutocompleteWidget: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        ReadOnly: false,
        dataProviderForAutocomplete: "inline",
        minLength: "",
        valueListForAutocomplete: ""
    },
    TextAreaWidget: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        ReadOnly: false,
        maxlength: "",
        PlaceholderForTextArea: "",
        textareaType: "default",
        wrap: "None"
    },
    DateTimeWidget: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        ReadOnly: false,
        calType: "Default",
        is24HourClock: false,
        minutesRequired: true,
        secondsRequired: true,
        stepHour: "1",
        stepMinute: "5",
        stepSec: "5",
        sysDate: true
    },
    TextBoxWidget: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        ReadOnly: false,
        AutoCompleteForTextBox: "off",
        formattingReqd: false,
        PatternForTextBox: "",
        PlaceholderForTextBox: "",
        TextBoxType: "None"
    },
    DateWidget: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        ReadOnly: false,
        calendarType: "Default",
        SystemDate: false
    },
    TimeWidget: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        ReadOnly: false,
        calendarTimeType: "Default",
        isTime24HourClock: false,
        minuteTimeRequired: true,
        secondTimeRequired: false,
        stepTimeHour: "1",
        stepTimeMinute: "5",
        stepTimeSecond: "5",
        sysTime: true
    },
    PasswordBoxWidget: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        ReadOnly: false,
    },
    SpinnerWidget: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        ReadOnly: false,
        maxValue: "",
        minValue: "",
        Step: "1"
    },
    CheckBoxWidget: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        ReadOnly: false,
        isMultiSelectAlternate: false
    },
    FileWidget: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        ReadOnly: false,
        AllowedExtensions: "jpg,gif",
        AltText: "",
        FileCount: "",
        Multiplicity: false,
        ShowAs: "Attachment",
        SizeLimit: ""
    },
    ImageWidget: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        ReadOnly: false,
        AltText: "",
        value: {
            source: ""
        },
        widgetState: "static"
    },
    WhiteSpaceWidget: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        ReadOnly: false
    },
    GridColumnWidget: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        isStatusColumn: false,
        queryColIndex: "",
        sortable: false
    },
    TabWidget: {
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: "",
        Cols: "2",
        ColsForLargeDesktop: "2",
        ColsForMobile: "2",
        ColsForTabLandscape: "2",
        ColsForTabPotrait: "2",
        lazyLoad: false
    },
    LabelWidget: {
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        clonedExtId: "",
        inlineValidate: true,
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: ""
    },
    SpanWidget: {
        Alignment: "Left",
        CharWidth: "70",
        ColSpan: "1",
        ColSpanForLargeDesktop: "1",
        ColSpanForMobile: "1",
        ColSpanForTabLandscape: "1",
        ColSpanForTabPotrait: "1",
        DefaultValue: "",
        LOVForwardCopyString: "",
        LOVReverseCopyString: "",
        LOVScreenMode: "DEFAULT",
        Mandatory: false,
        RowSpan: "1",
        RowSpanForLargeDesktop: "1",
        RowSpanForMobile: "1",
        RowSpanForTabLandscape: "1",
        RowSpanForTabPotrait: "1",
        clonedExtId: "",
        inlineValidate: true,
        Enabled: true,
        HasLabel: true,
        Height: "",
        Help: "",
        IsHidable: false,
        Label: "",
        Left: "",
        ProcessingText: "",
        Render: true,
        ShortcutKey: "",
        ToolTip: "",
        Top: "",
        Visible: true,
        VisibleForLargeDesktop: true,
        VisibleForMobile: true,
        VisibleForTabLandscape: true,
        VisibleForTabPotrait: true,
        WidgetDescription: "",
        Width: ""
    }
};