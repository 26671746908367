/* eslint-disable*/
import React from "react";
import SystemMaintenanceMasterManagement_FreightRateProfile from "./FreightRateProfile";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("FreightRateProfile Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceMasterManagement_FreightRateProfile />
      );
    });
  });
  afterEach(cleanup);
  test("is FreightRateProfile Loads Successfully", () => {
    expect(screen.getByText("FreightRateProfile")).toBeInTheDocument;
  });
  test("Custom Test Cases for FreightRateProfile", () => {
    // START_USER_CODE-USER_FreightRateProfile_Custom_Test_Case
    // END_USER_CODE-USER_FreightRateProfile_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceMasterManagement_FreightRateProfile />
      );
    });
  });
  afterEach(cleanup);
  test("btnOK(Button Widget) Test Cases", async () => {
    const btnOK = screen.getByTestId("btnOK");
    expect(btnOK).toBeInTheDocument;
    expect(btnOK.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FreightRateProfile_btnOK")
    );
  });
  test("Custom Test Cases for btnOK", () => {
    // START_USER_CODE-USER_btnOK_TEST
    // END_USER_CODE-USER_btnOK_TEST
  });
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FreightRateProfile_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnCopyRate(Button Widget) Test Cases", async () => {
    const btnCopyRate = screen.getByTestId("btnCopyRate");
    expect(btnCopyRate).toBeInTheDocument;
    expect(btnCopyRate.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FreightRateProfile_btnCopyRate")
    );
  });
  test("Custom Test Cases for btnCopyRate", () => {
    // START_USER_CODE-USER_btnCopyRate_TEST
    // END_USER_CODE-USER_btnCopyRate_TEST
  });
  test("btnCpyCarr(Button Widget) Test Cases", async () => {
    const btnCpyCarr = screen.getByTestId("btnCpyCarr");
    expect(btnCpyCarr).toBeInTheDocument;
    expect(btnCpyCarr.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FreightRateProfile_btnCpyCarr")
    );
  });
  test("Custom Test Cases for btnCpyCarr", () => {
    // START_USER_CODE-USER_btnCpyCarr_TEST
    // END_USER_CODE-USER_btnCpyCarr_TEST
  });
  test("btnCpyLoc(Button Widget) Test Cases", async () => {
    const btnCpyLoc = screen.getByTestId("btnCpyLoc");
    expect(btnCpyLoc).toBeInTheDocument;
    expect(btnCpyLoc.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FreightRateProfile_btnCpyLoc")
    );
  });
  test("Custom Test Cases for btnCpyLoc", () => {
    // START_USER_CODE-USER_btnCpyLoc_TEST
    // END_USER_CODE-USER_btnCpyLoc_TEST
  });
  test("btnPrint(Button Widget) Test Cases", async () => {
    const btnPrint = screen.getByTestId("btnPrint");
    expect(btnPrint).toBeInTheDocument;
    expect(btnPrint.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FreightRateProfile_btnPrint")
    );
  });
  test("Custom Test Cases for btnPrint", () => {
    // START_USER_CODE-USER_btnPrint_TEST
    // END_USER_CODE-USER_btnPrint_TEST
  });
  test("dtEffective(Date Widget) Test Cases", async () => {
    const dtEffective = screen.getByTestId("dtEffective");
    expect(dtEffective.tagName).toBe("INPUT");
    expect(dtEffective.type).toBe("text");
    expect(dtEffective.classList).toContain("datetimepicker-input");
    expect(
      dtEffective.parentElement.previousElementSibling.textContent
    ).toEqual(
      t("SystemMaintenanceMasterManagement:FreightRateProfile_dtEffective")
    );
    await act(async () => {
      userEvent.click(dtEffective.nextElementSibling, { button: 0 });
    });
  });
  test("Custom Test Cases for dtEffective", () => {
    // START_USER_CODE-USER_dtEffective_TEST
    // END_USER_CODE-USER_dtEffective_TEST
  });
  test("gridFrghtRate(Grid Widget) Test Cases", async () => {
    let gridFrghtRate = screen.getByTestId("gridFrghtRate");
    let gridFrghtRatebtn = gridFrghtRate.nextElementSibling.firstElementChild;
    gridFrghtRate = gridFrghtRate.parentElement.parentElement.parentElement;
    expect(gridFrghtRate.tagName).toBe("DIV");
    expect(gridFrghtRate.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceMasterManagement:FreightRateProfile_gridFrghtRate")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridFrghtRate", () => {
    // START_USER_CODE-USER_gridFrghtRate_TEST
    // END_USER_CODE-USER_gridFrghtRate_TEST
  });
  test("grpbxActions(GroupBox Widget) Test Cases", async () => {
    const grpbxActions = screen.getByTestId("grpbxActions");
    expect(grpbxActions.tagName).toBe("BUTTON");
    expect(grpbxActions.type).toBe("button");
    expect(grpbxActions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxActions", () => {
    // START_USER_CODE-USER_grpbxActions_TEST
    // END_USER_CODE-USER_grpbxActions_TEST
  });
  test("grpbxFreightProf(GroupBox Widget) Test Cases", async () => {
    const grpbxFreightProf = screen.getByTestId("grpbxFreightProf");
    expect(grpbxFreightProf.tagName).toBe("BUTTON");
    expect(grpbxFreightProf.type).toBe("button");
    expect(grpbxFreightProf.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxFreightProf", () => {
    // START_USER_CODE-USER_grpbxFreightProf_TEST
    // END_USER_CODE-USER_grpbxFreightProf_TEST
  });
  test("lblAddedBy(Label Widget) Test Cases", async () => {
    const lblAddedBy = screen.getByTestId("lblAddedBy");
    expect(lblAddedBy.tagName).toBe("LABEL");
    expect(lblAddedBy.classList).toContain("form-label");
    expect(lblAddedBy.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FreightRateProfile_lblAddedBy")
    );
  });
  test("Custom Test Cases for lblAddedBy", () => {
    // START_USER_CODE-USER_lblAddedBy_TEST
    // END_USER_CODE-USER_lblAddedBy_TEST
  });
  test("lblAddedByVal(Label Widget) Test Cases", async () => {
    const lblAddedByVal = screen.getByTestId("lblAddedByVal");
    expect(lblAddedByVal.tagName).toBe("LABEL");
    expect(lblAddedByVal.classList).toContain("form-label");
  });
  test("Custom Test Cases for lblAddedByVal", () => {
    // START_USER_CODE-USER_lblAddedByVal_TEST
    // END_USER_CODE-USER_lblAddedByVal_TEST
  });
  test("lblChangedBy(Label Widget) Test Cases", async () => {
    const lblChangedBy = screen.getByTestId("lblChangedBy");
    expect(lblChangedBy.tagName).toBe("LABEL");
    expect(lblChangedBy.classList).toContain("form-label");
    expect(lblChangedBy.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FreightRateProfile_lblChangedBy")
    );
  });
  test("Custom Test Cases for lblChangedBy", () => {
    // START_USER_CODE-USER_lblChangedBy_TEST
    // END_USER_CODE-USER_lblChangedBy_TEST
  });
  test("lblChangedByVal(Label Widget) Test Cases", async () => {
    const lblChangedByVal = screen.getByTestId("lblChangedByVal");
    expect(lblChangedByVal.tagName).toBe("LABEL");
    expect(lblChangedByVal.classList).toContain("form-label");
  });
  test("Custom Test Cases for lblChangedByVal", () => {
    // START_USER_CODE-USER_lblChangedByVal_TEST
    // END_USER_CODE-USER_lblChangedByVal_TEST
  });
  test("lblPounds(Label Widget) Test Cases", async () => {
    const lblPounds = screen.getByTestId("lblPounds");
    expect(lblPounds.tagName).toBe("LABEL");
    expect(lblPounds.classList).toContain("form-label");
    expect(lblPounds.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FreightRateProfile_lblPounds")
    );
  });
  test("Custom Test Cases for lblPounds", () => {
    // START_USER_CODE-USER_lblPounds_TEST
    // END_USER_CODE-USER_lblPounds_TEST
  });
  test("txtCarrAbbr(Textbox Widget) Test Cases", async () => {
    const txtCarrAbbr = screen.getByTestId("txtCarrAbbr");
    expect(txtCarrAbbr.tagName).toBe("INPUT");
    expect(txtCarrAbbr.type).toBe("text");
    expect(txtCarrAbbr.classList).toContain("textboxWidgetClass");
    expect(txtCarrAbbr.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FreightRateProfile_txtCarrAbbr")
    );
    await act(async () => {
      userEvent.type(txtCarrAbbr, "1");
    });
  });
  test("Custom Test Cases for txtCarrAbbr", () => {
    // START_USER_CODE-USER_txtCarrAbbr_TEST
    // END_USER_CODE-USER_txtCarrAbbr_TEST
  });
  test("gridFrghtRate_txtColFrghtRate(Grid Widget) Test Cases", async () => {
    let gridFrghtRate_txtColFrghtRate = screen.getByTestId("gridFrghtRate");
    let gridFrghtRate_txtColFrghtRatebtn =
      gridFrghtRate_txtColFrghtRate.nextElementSibling.firstElementChild;
    gridFrghtRate_txtColFrghtRate =
      gridFrghtRate_txtColFrghtRate.parentElement.parentElement.parentElement;
    expect(gridFrghtRate_txtColFrghtRate.tagName).toBe("DIV");
    expect(gridFrghtRate_txtColFrghtRate.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceMasterManagement:FreightRateProfile_gridFrghtRate")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtColFrghtRate", () => {
    // START_USER_CODE-USER_txtColFrghtRate_TEST
    // END_USER_CODE-USER_txtColFrghtRate_TEST
  });
  test("gridFrghtRate_txtColMaxMile(Grid Widget) Test Cases", async () => {
    let gridFrghtRate_txtColMaxMile = screen.getByTestId("gridFrghtRate");
    let gridFrghtRate_txtColMaxMilebtn =
      gridFrghtRate_txtColMaxMile.nextElementSibling.firstElementChild;
    gridFrghtRate_txtColMaxMile =
      gridFrghtRate_txtColMaxMile.parentElement.parentElement.parentElement;
    expect(gridFrghtRate_txtColMaxMile.tagName).toBe("DIV");
    expect(gridFrghtRate_txtColMaxMile.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceMasterManagement:FreightRateProfile_gridFrghtRate")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtColMaxMile", () => {
    // START_USER_CODE-USER_txtColMaxMile_TEST
    // END_USER_CODE-USER_txtColMaxMile_TEST
  });
  test("gridFrghtRate_txtColMinMile(Grid Widget) Test Cases", async () => {
    let gridFrghtRate_txtColMinMile = screen.getByTestId("gridFrghtRate");
    let gridFrghtRate_txtColMinMilebtn =
      gridFrghtRate_txtColMinMile.nextElementSibling.firstElementChild;
    gridFrghtRate_txtColMinMile =
      gridFrghtRate_txtColMinMile.parentElement.parentElement.parentElement;
    expect(gridFrghtRate_txtColMinMile.tagName).toBe("DIV");
    expect(gridFrghtRate_txtColMinMile.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t("SystemMaintenanceMasterManagement:FreightRateProfile_gridFrghtRate")
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtColMinMile", () => {
    // START_USER_CODE-USER_txtColMinMile_TEST
    // END_USER_CODE-USER_txtColMinMile_TEST
  });
  test("txtMinWght(Textbox Widget) Test Cases", async () => {
    const txtMinWght = screen.getByTestId("txtMinWght");
    expect(txtMinWght.tagName).toBe("INPUT");
    expect(txtMinWght.type).toBe("text");
    expect(txtMinWght.classList).toContain("textboxWidgetClass");
    expect(txtMinWght.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceMasterManagement:FreightRateProfile_txtMinWght")
    );
    await act(async () => {
      userEvent.type(txtMinWght, "1");
    });
  });
  test("Custom Test Cases for txtMinWght", () => {
    // START_USER_CODE-USER_txtMinWght_TEST
    // END_USER_CODE-USER_txtMinWght_TEST
  });
});
