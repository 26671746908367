/* eslint-disable*/
import React from "react";
import SystemMaintenanceApplicationSupport_ManualRTPHistView from "./ManualRTPHistView";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("ManualRTPHistView Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceApplicationSupport_ManualRTPHistView />
      );
    });
  });
  afterEach(cleanup);
  test("is ManualRTPHistView Loads Successfully", () => {
    expect(screen.getByText("ManualRTPHistView")).toBeInTheDocument;
  });
  test("Custom Test Cases for ManualRTPHistView", () => {
    // START_USER_CODE-USER_ManualRTPHistView_Custom_Test_Case
    // END_USER_CODE-USER_ManualRTPHistView_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceApplicationSupport_ManualRTPHistView />
      );
    });
  });
  afterEach(cleanup);
  test("btnExit(Button Widget) Test Cases", async () => {
    const btnExit = screen.getByTestId("btnExit");
    expect(btnExit).toBeInTheDocument;
    expect(btnExit.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPHistView_btnExit")
    );
  });
  test("Custom Test Cases for btnExit", () => {
    // START_USER_CODE-USER_btnExit_TEST
    // END_USER_CODE-USER_btnExit_TEST
  });
  test("grpbxAfter(GroupBox Widget) Test Cases", async () => {
    const grpbxAfter = screen.getByTestId("grpbxAfter");
    expect(grpbxAfter.tagName).toBe("BUTTON");
    expect(grpbxAfter.type).toBe("button");
    expect(grpbxAfter.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxAfter", () => {
    // START_USER_CODE-USER_grpbxAfter_TEST
    // END_USER_CODE-USER_grpbxAfter_TEST
  });
  test("grpbxBefore(GroupBox Widget) Test Cases", async () => {
    const grpbxBefore = screen.getByTestId("grpbxBefore");
    expect(grpbxBefore.tagName).toBe("BUTTON");
    expect(grpbxBefore.type).toBe("button");
    expect(grpbxBefore.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxBefore", () => {
    // START_USER_CODE-USER_grpbxBefore_TEST
    // END_USER_CODE-USER_grpbxBefore_TEST
  });
  test("grpbxManualRTPHistView(GroupBox Widget) Test Cases", async () => {
    const grpbxManualRTPHistView = screen.getByTestId("grpbxManualRTPHistView");
    expect(grpbxManualRTPHistView.tagName).toBe("BUTTON");
    expect(grpbxManualRTPHistView.type).toBe("button");
    expect(grpbxManualRTPHistView.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxManualRTPHistView", () => {
    // START_USER_CODE-USER_grpbxManualRTPHistView_TEST
    // END_USER_CODE-USER_grpbxManualRTPHistView_TEST
  });
  test("lblAfter(Label Widget) Test Cases", async () => {
    const lblAfter = screen.getByTestId("lblAfter");
    expect(lblAfter.tagName).toBe("LABEL");
    expect(lblAfter.classList).toContain("form-label");
    expect(lblAfter.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPHistView_lblAfter")
    );
  });
  test("Custom Test Cases for lblAfter", () => {
    // START_USER_CODE-USER_lblAfter_TEST
    // END_USER_CODE-USER_lblAfter_TEST
  });
  test("lblBefore(Label Widget) Test Cases", async () => {
    const lblBefore = screen.getByTestId("lblBefore");
    expect(lblBefore.tagName).toBe("LABEL");
    expect(lblBefore.classList).toContain("form-label");
    expect(lblBefore.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPHistView_lblBefore")
    );
  });
  test("Custom Test Cases for lblBefore", () => {
    // START_USER_CODE-USER_lblBefore_TEST
    // END_USER_CODE-USER_lblBefore_TEST
  });
  test("txtAction(Textbox Widget) Test Cases", async () => {
    const txtAction = screen.getByTestId("txtAction");
    expect(txtAction.tagName).toBe("INPUT");
    expect(txtAction.type).toBe("text");
    expect(txtAction.classList).toContain("textboxWidgetClass");
    expect(txtAction.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPHistView_txtAction")
    );
    await act(async () => {
      userEvent.type(txtAction, "1");
    });
  });
  test("Custom Test Cases for txtAction", () => {
    // START_USER_CODE-USER_txtAction_TEST
    // END_USER_CODE-USER_txtAction_TEST
  });
  test("txtRTPContract(Textbox Widget) Test Cases", async () => {
    const txtRTPContract = screen.getByTestId("txtRTPContract");
    expect(txtRTPContract.tagName).toBe("INPUT");
    expect(txtRTPContract.type).toBe("text");
    expect(txtRTPContract.classList).toContain("textboxWidgetClass");
    expect(txtRTPContract.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPHistView_txtRTPContract")
    );
    await act(async () => {
      userEvent.type(txtRTPContract, "1");
    });
  });
  test("Custom Test Cases for txtRTPContract", () => {
    // START_USER_CODE-USER_txtRTPContract_TEST
    // END_USER_CODE-USER_txtRTPContract_TEST
  });
  test("txtContract1(Textbox Widget) Test Cases", async () => {
    const txtContract1 = screen.getByTestId("txtContract1");
    expect(txtContract1.tagName).toBe("INPUT");
    expect(txtContract1.type).toBe("text");
    expect(txtContract1.classList).toContain("textboxWidgetClass");
    expect(txtContract1.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPHistView_txtContract1")
    );
    await act(async () => {
      userEvent.type(txtContract1, "1");
    });
  });
  test("Custom Test Cases for txtContract1", () => {
    // START_USER_CODE-USER_txtContract1_TEST
    // END_USER_CODE-USER_txtContract1_TEST
  });
  test("txtCounty(Textbox Widget) Test Cases", async () => {
    const txtCounty = screen.getByTestId("txtCounty");
    expect(txtCounty.tagName).toBe("INPUT");
    expect(txtCounty.type).toBe("text");
    expect(txtCounty.classList).toContain("textboxWidgetClass");
    expect(txtCounty.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPHistView_txtCounty")
    );
    await act(async () => {
      userEvent.type(txtCounty, "1");
    });
  });
  test("Custom Test Cases for txtCounty", () => {
    // START_USER_CODE-USER_txtCounty_TEST
    // END_USER_CODE-USER_txtCounty_TEST
  });
  test("txtCounty1(Textbox Widget) Test Cases", async () => {
    const txtCounty1 = screen.getByTestId("txtCounty1");
    expect(txtCounty1.tagName).toBe("INPUT");
    expect(txtCounty1.type).toBe("text");
    expect(txtCounty1.classList).toContain("textboxWidgetClass");
    expect(txtCounty1.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPHistView_txtCounty1")
    );
    await act(async () => {
      userEvent.type(txtCounty1, "1");
    });
  });
  test("Custom Test Cases for txtCounty1", () => {
    // START_USER_CODE-USER_txtCounty1_TEST
    // END_USER_CODE-USER_txtCounty1_TEST
  });
  test("txtRTPDeliveryAgreement(Textbox Widget) Test Cases", async () => {
    const txtRTPDeliveryAgreement = screen.getByTestId("txtRTPDeliveryAgreement");
    expect(txtRTPDeliveryAgreement.tagName).toBe("INPUT");
    expect(txtRTPDeliveryAgreement.type).toBe("text");
    expect(txtRTPDeliveryAgreement.classList).toContain("textboxWidgetClass");
    expect(txtRTPDeliveryAgreement.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualRTPHistView_txtRTPDeliveryAgreement"
      )
    );
    await act(async () => {
      userEvent.type(txtRTPDeliveryAgreement, "1");
    });
  });
  test("Custom Test Cases for txtRTPDeliveryAgreement", () => {
    // START_USER_CODE-USER_txtRTPDeliveryAgreement_TEST
    // END_USER_CODE-USER_txtRTPDeliveryAgreement_TEST
  });
  test("txtDeliveryAgreement1(Textbox Widget) Test Cases", async () => {
    const txtDeliveryAgreement1 = screen.getByTestId("txtDeliveryAgreement1");
    expect(txtDeliveryAgreement1.tagName).toBe("INPUT");
    expect(txtDeliveryAgreement1.type).toBe("text");
    expect(txtDeliveryAgreement1.classList).toContain("textboxWidgetClass");
    expect(txtDeliveryAgreement1.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualRTPHistView_txtDeliveryAgreement1"
      )
    );
    await act(async () => {
      userEvent.type(txtDeliveryAgreement1, "1");
    });
  });
  test("Custom Test Cases for txtDeliveryAgreement1", () => {
    // START_USER_CODE-USER_txtDeliveryAgreement1_TEST
    // END_USER_CODE-USER_txtDeliveryAgreement1_TEST
  });
  test("txtRTPFarm(Textbox Widget) Test Cases", async () => {
    const txtRTPFarm = screen.getByTestId("txtRTPFarm");
    expect(txtRTPFarm.tagName).toBe("INPUT");
    expect(txtRTPFarm.type).toBe("text");
    expect(txtRTPFarm.classList).toContain("textboxWidgetClass");
    expect(txtRTPFarm.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPHistView_txtRTPFarm")
    );
    await act(async () => {
      userEvent.type(txtRTPFarm, "1");
    });
  });
  test("Custom Test Cases for txtRTPFarm", () => {
    // START_USER_CODE-USER_txtRTPFarm_TEST
    // END_USER_CODE-USER_txtRTPFarm_TEST
  });
  test("txtFarm1(Textbox Widget) Test Cases", async () => {
    const txtFarm1 = screen.getByTestId("txtFarm1");
    expect(txtFarm1.tagName).toBe("INPUT");
    expect(txtFarm1.type).toBe("text");
    expect(txtFarm1.classList).toContain("textboxWidgetClass");
    expect(txtFarm1.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPHistView_txtFarm1")
    );
    await act(async () => {
      userEvent.type(txtFarm1, "1");
    });
  });
  test("Custom Test Cases for txtFarm1", () => {
    // START_USER_CODE-USER_txtFarm1_TEST
    // END_USER_CODE-USER_txtFarm1_TEST
  });
  test("txtFarmSettle(Textbox Widget) Test Cases", async () => {
    const txtFarmSettle = screen.getByTestId("txtFarmSettle");
    expect(txtFarmSettle.tagName).toBe("INPUT");
    expect(txtFarmSettle.type).toBe("text");
    expect(txtFarmSettle.classList).toContain("textboxWidgetClass");
    expect(txtFarmSettle.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPHistView_txtFarmSettle")
    );
    await act(async () => {
      userEvent.type(txtFarmSettle, "1");
    });
  });
  test("Custom Test Cases for txtFarmSettle", () => {
    // START_USER_CODE-USER_txtFarmSettle_TEST
    // END_USER_CODE-USER_txtFarmSettle_TEST
  });
  test("txtfarmSettle1(Textbox Widget) Test Cases", async () => {
    const txtfarmSettle1 = screen.getByTestId("txtfarmSettle1");
    expect(txtfarmSettle1.tagName).toBe("INPUT");
    expect(txtfarmSettle1.type).toBe("text");
    expect(txtfarmSettle1.classList).toContain("textboxWidgetClass");
    expect(txtfarmSettle1.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPHistView_txtfarmSettle1")
    );
    await act(async () => {
      userEvent.type(txtfarmSettle1, "1");
    });
  });
  test("Custom Test Cases for txtfarmSettle1", () => {
    // START_USER_CODE-USER_txtfarmSettle1_TEST
    // END_USER_CODE-USER_txtfarmSettle1_TEST
  });
  test("txtRTPFreightMemo(Textbox Widget) Test Cases", async () => {
    const txtRTPFreightMemo = screen.getByTestId("txtRTPFreightMemo");
    expect(txtRTPFreightMemo.tagName).toBe("INPUT");
    expect(txtRTPFreightMemo.type).toBe("text");
    expect(txtRTPFreightMemo.classList).toContain("textboxWidgetClass");
    expect(txtRTPFreightMemo.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPHistView_txtRTPFreightMemo")
    );
    await act(async () => {
      userEvent.type(txtRTPFreightMemo, "1");
    });
  });
  test("Custom Test Cases for txtRTPFreightMemo", () => {
    // START_USER_CODE-USER_txtRTPFreightMemo_TEST
    // END_USER_CODE-USER_txtRTPFreightMemo_TEST
  });
  test("txtFreightMemo1(Textbox Widget) Test Cases", async () => {
    const txtFreightMemo1 = screen.getByTestId("txtFreightMemo1");
    expect(txtFreightMemo1.tagName).toBe("INPUT");
    expect(txtFreightMemo1.type).toBe("text");
    expect(txtFreightMemo1.classList).toContain("textboxWidgetClass");
    expect(txtFreightMemo1.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPHistView_txtFreightMemo1")
    );
    await act(async () => {
      userEvent.type(txtFreightMemo1, "1");
    });
  });
  test("Custom Test Cases for txtFreightMemo1", () => {
    // START_USER_CODE-USER_txtFreightMemo1_TEST
    // END_USER_CODE-USER_txtFreightMemo1_TEST
  });
  test("txtInspection(Textbox Widget) Test Cases", async () => {
    const txtInspection = screen.getByTestId("txtInspection");
    expect(txtInspection.tagName).toBe("INPUT");
    expect(txtInspection.type).toBe("text");
    expect(txtInspection.classList).toContain("textboxWidgetClass");
    expect(txtInspection.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPHistView_txtInspection")
    );
    await act(async () => {
      userEvent.type(txtInspection, "1");
    });
  });
  test("Custom Test Cases for txtInspection", () => {
    // START_USER_CODE-USER_txtInspection_TEST
    // END_USER_CODE-USER_txtInspection_TEST
  });
  test("txtInspection1(Textbox Widget) Test Cases", async () => {
    const txtInspection1 = screen.getByTestId("txtInspection1");
    expect(txtInspection1.tagName).toBe("INPUT");
    expect(txtInspection1.type).toBe("text");
    expect(txtInspection1.classList).toContain("textboxWidgetClass");
    expect(txtInspection1.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPHistView_txtInspection1")
    );
    await act(async () => {
      userEvent.type(txtInspection1, "1");
    });
  });
  test("Custom Test Cases for txtInspection1", () => {
    // START_USER_CODE-USER_txtInspection1_TEST
    // END_USER_CODE-USER_txtInspection1_TEST
  });
  test("txtLoanRecductionInd(Textbox Widget) Test Cases", async () => {
    const txtLoanRecductionInd = screen.getByTestId("txtLoanRecductionInd");
    expect(txtLoanRecductionInd.tagName).toBe("INPUT");
    expect(txtLoanRecductionInd.type).toBe("text");
    expect(txtLoanRecductionInd.classList).toContain("textboxWidgetClass");
    expect(txtLoanRecductionInd.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualRTPHistView_txtLoanRecductionInd"
      )
    );
    await act(async () => {
      userEvent.type(txtLoanRecductionInd, "1");
    });
  });
  test("Custom Test Cases for txtLoanRecductionInd", () => {
    // START_USER_CODE-USER_txtLoanRecductionInd_TEST
    // END_USER_CODE-USER_txtLoanRecductionInd_TEST
  });
  test("txtLoanReductionInd1(Textbox Widget) Test Cases", async () => {
    const txtLoanReductionInd1 = screen.getByTestId("txtLoanReductionInd1");
    expect(txtLoanReductionInd1.tagName).toBe("INPUT");
    expect(txtLoanReductionInd1.type).toBe("text");
    expect(txtLoanReductionInd1.classList).toContain("textboxWidgetClass");
    expect(txtLoanReductionInd1.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualRTPHistView_txtLoanReductionInd1"
      )
    );
    await act(async () => {
      userEvent.type(txtLoanReductionInd1, "1");
    });
  });
  test("Custom Test Cases for txtLoanReductionInd1", () => {
    // START_USER_CODE-USER_txtLoanReductionInd1_TEST
    // END_USER_CODE-USER_txtLoanReductionInd1_TEST
  });
  test("txtLocation(Textbox Widget) Test Cases", async () => {
    const txtLocation = screen.getByTestId("txtLocation");
    expect(txtLocation.tagName).toBe("INPUT");
    expect(txtLocation.type).toBe("text");
    expect(txtLocation.classList).toContain("textboxWidgetClass");
    expect(txtLocation.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPHistView_txtLocation")
    );
    await act(async () => {
      userEvent.type(txtLocation, "1");
    });
  });
  test("Custom Test Cases for txtLocation", () => {
    // START_USER_CODE-USER_txtLocation_TEST
    // END_USER_CODE-USER_txtLocation_TEST
  });
  test("txtOption(Textbox Widget) Test Cases", async () => {
    const txtOption = screen.getByTestId("txtOption");
    expect(txtOption.tagName).toBe("INPUT");
    expect(txtOption.type).toBe("text");
    expect(txtOption.classList).toContain("textboxWidgetClass");
    expect(txtOption.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPHistView_txtOption")
    );
    await act(async () => {
      userEvent.type(txtOption, "1");
    });
  });
  test("Custom Test Cases for txtOption", () => {
    // START_USER_CODE-USER_txtOption_TEST
    // END_USER_CODE-USER_txtOption_TEST
  });
  test("txtOption1(Textbox Widget) Test Cases", async () => {
    const txtOption1 = screen.getByTestId("txtOption1");
    expect(txtOption1.tagName).toBe("INPUT");
    expect(txtOption1.type).toBe("text");
    expect(txtOption1.classList).toContain("textboxWidgetClass");
    expect(txtOption1.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPHistView_txtOption1")
    );
    await act(async () => {
      userEvent.type(txtOption1, "1");
    });
  });
  test("Custom Test Cases for txtOption1", () => {
    // START_USER_CODE-USER_txtOption1_TEST
    // END_USER_CODE-USER_txtOption1_TEST
  });
  test("txtRTPProceeds(Textbox Widget) Test Cases", async () => {
    const txtRTPProceeds = screen.getByTestId("txtRTPProceeds");
    expect(txtRTPProceeds.tagName).toBe("INPUT");
    expect(txtRTPProceeds.type).toBe("text");
    expect(txtRTPProceeds.classList).toContain("textboxWidgetClass");
    expect(txtRTPProceeds.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPHistView_txtRTPProceeds")
    );
    await act(async () => {
      userEvent.type(txtRTPProceeds, "1");
    });
  });
  test("Custom Test Cases for txtRTPProceeds", () => {
    // START_USER_CODE-USER_txtRTPProceeds_TEST
    // END_USER_CODE-USER_txtRTPProceeds_TEST
  });
  test("txtRTPProceeds1(Textbox Widget) Test Cases", async () => {
    const txtRTPProceeds1 = screen.getByTestId("txtRTPProceeds1");
    expect(txtRTPProceeds1.tagName).toBe("INPUT");
    expect(txtRTPProceeds1.type).toBe("text");
    expect(txtRTPProceeds1.classList).toContain("textboxWidgetClass");
    expect(txtRTPProceeds1.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPHistView_txtRTPProceeds1")
    );
    await act(async () => {
      userEvent.type(txtRTPProceeds1, "1");
    });
  });
  test("Custom Test Cases for txtRTPProceeds1", () => {
    // START_USER_CODE-USER_txtRTPProceeds1_TEST
    // END_USER_CODE-USER_txtRTPProceeds1_TEST
  });
  test("txtReason(Textbox Widget) Test Cases", async () => {
    const txtReason = screen.getByTestId("txtReason");
    expect(txtReason.tagName).toBe("INPUT");
    expect(txtReason.type).toBe("text");
    expect(txtReason.classList).toContain("textboxWidgetClass");
    expect(txtReason.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPHistView_txtReason")
    );
    await act(async () => {
      userEvent.type(txtReason, "1");
    });
  });
  test("Custom Test Cases for txtReason", () => {
    // START_USER_CODE-USER_txtReason_TEST
    // END_USER_CODE-USER_txtReason_TEST
  });
  test("txtRemitTo(Textbox Widget) Test Cases", async () => {
    const txtRemitTo = screen.getByTestId("txtRemitTo");
    expect(txtRemitTo.tagName).toBe("INPUT");
    expect(txtRemitTo.type).toBe("text");
    expect(txtRemitTo.classList).toContain("textboxWidgetClass");
    expect(txtRemitTo.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPHistView_txtRemitTo")
    );
    await act(async () => {
      userEvent.type(txtRemitTo, "1");
    });
  });
  test("Custom Test Cases for txtRemitTo", () => {
    // START_USER_CODE-USER_txtRemitTo_TEST
    // END_USER_CODE-USER_txtRemitTo_TEST
  });
  test("txtRemitTo1(Textbox Widget) Test Cases", async () => {
    const txtRemitTo1 = screen.getByTestId("txtRemitTo1");
    expect(txtRemitTo1.tagName).toBe("INPUT");
    expect(txtRemitTo1.type).toBe("text");
    expect(txtRemitTo1.classList).toContain("textboxWidgetClass");
    expect(txtRemitTo1.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPHistView_txtRemitTo1")
    );
    await act(async () => {
      userEvent.type(txtRemitTo1, "1");
    });
  });
  test("Custom Test Cases for txtRemitTo1", () => {
    // START_USER_CODE-USER_txtRemitTo1_TEST
    // END_USER_CODE-USER_txtRemitTo1_TEST
  });
  test("txtSettlement(Textbox Widget) Test Cases", async () => {
    const txtSettlement = screen.getByTestId("txtSettlement");
    expect(txtSettlement.tagName).toBe("INPUT");
    expect(txtSettlement.type).toBe("text");
    expect(txtSettlement.classList).toContain("textboxWidgetClass");
    expect(txtSettlement.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPHistView_txtSettlement")
    );
    await act(async () => {
      userEvent.type(txtSettlement, "1");
    });
  });
  test("Custom Test Cases for txtSettlement", () => {
    // START_USER_CODE-USER_txtSettlement_TEST
    // END_USER_CODE-USER_txtSettlement_TEST
  });
  test("txtSettlement1(Textbox Widget) Test Cases", async () => {
    const txtSettlement1 = screen.getByTestId("txtSettlement1");
    expect(txtSettlement1.tagName).toBe("INPUT");
    expect(txtSettlement1.type).toBe("text");
    expect(txtSettlement1.classList).toContain("textboxWidgetClass");
    expect(txtSettlement1.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPHistView_txtSettlement1")
    );
    await act(async () => {
      userEvent.type(txtSettlement1, "1");
    });
  });
  test("Custom Test Cases for txtSettlement1", () => {
    // START_USER_CODE-USER_txtSettlement1_TEST
    // END_USER_CODE-USER_txtSettlement1_TEST
  });
  test("txtShrinkPayment(Textbox Widget) Test Cases", async () => {
    const txtShrinkPayment = screen.getByTestId("txtShrinkPayment");
    expect(txtShrinkPayment.tagName).toBe("INPUT");
    expect(txtShrinkPayment.type).toBe("text");
    expect(txtShrinkPayment.classList).toContain("textboxWidgetClass");
    expect(txtShrinkPayment.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualRTPHistView_txtShrinkPayment"
      )
    );
    await act(async () => {
      userEvent.type(txtShrinkPayment, "1");
    });
  });
  test("Custom Test Cases for txtShrinkPayment", () => {
    // START_USER_CODE-USER_txtShrinkPayment_TEST
    // END_USER_CODE-USER_txtShrinkPayment_TEST
  });
  test("txtShrinkPayment1(Textbox Widget) Test Cases", async () => {
    const txtShrinkPayment1 = screen.getByTestId("txtShrinkPayment1");
    expect(txtShrinkPayment1.tagName).toBe("INPUT");
    expect(txtShrinkPayment1.type).toBe("text");
    expect(txtShrinkPayment1.classList).toContain("textboxWidgetClass");
    expect(txtShrinkPayment1.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualRTPHistView_txtShrinkPayment1"
      )
    );
    await act(async () => {
      userEvent.type(txtShrinkPayment1, "1");
    });
  });
  test("Custom Test Cases for txtShrinkPayment1", () => {
    // START_USER_CODE-USER_txtShrinkPayment1_TEST
    // END_USER_CODE-USER_txtShrinkPayment1_TEST
  });
  test("txtState(Textbox Widget) Test Cases", async () => {
    const txtState = screen.getByTestId("txtState");
    expect(txtState.tagName).toBe("INPUT");
    expect(txtState.type).toBe("text");
    expect(txtState.classList).toContain("textboxWidgetClass");
    expect(txtState.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPHistView_txtState")
    );
    await act(async () => {
      userEvent.type(txtState, "1");
    });
  });
  test("Custom Test Cases for txtState", () => {
    // START_USER_CODE-USER_txtState_TEST
    // END_USER_CODE-USER_txtState_TEST
  });
  test("txtState1(Textbox Widget) Test Cases", async () => {
    const txtState1 = screen.getByTestId("txtState1");
    expect(txtState1.tagName).toBe("INPUT");
    expect(txtState1.type).toBe("text");
    expect(txtState1.classList).toContain("textboxWidgetClass");
    expect(txtState1.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPHistView_txtState1")
    );
    await act(async () => {
      userEvent.type(txtState1, "1");
    });
  });
  test("Custom Test Cases for txtState1", () => {
    // START_USER_CODE-USER_txtState1_TEST
    // END_USER_CODE-USER_txtState1_TEST
  });
  test("txtStatus(Textbox Widget) Test Cases", async () => {
    const txtStatus = screen.getByTestId("txtStatus");
    expect(txtStatus.tagName).toBe("INPUT");
    expect(txtStatus.type).toBe("text");
    expect(txtStatus.classList).toContain("textboxWidgetClass");
    expect(txtStatus.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPHistView_txtStatus")
    );
    await act(async () => {
      userEvent.type(txtStatus, "1");
    });
  });
  test("Custom Test Cases for txtStatus", () => {
    // START_USER_CODE-USER_txtStatus_TEST
    // END_USER_CODE-USER_txtStatus_TEST
  });
  test("txtStatus1(Textbox Widget) Test Cases", async () => {
    const txtStatus1 = screen.getByTestId("txtStatus1");
    expect(txtStatus1.tagName).toBe("INPUT");
    expect(txtStatus1.type).toBe("text");
    expect(txtStatus1.classList).toContain("textboxWidgetClass");
    expect(txtStatus1.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPHistView_txtStatus1")
    );
    await act(async () => {
      userEvent.type(txtStatus1, "1");
    });
  });
  test("Custom Test Cases for txtStatus1", () => {
    // START_USER_CODE-USER_txtStatus1_TEST
    // END_USER_CODE-USER_txtStatus1_TEST
  });
  test("txtStorageHandlingInd(Textbox Widget) Test Cases", async () => {
    const txtStorageHandlingInd = screen.getByTestId("txtStorageHandlingInd");
    expect(txtStorageHandlingInd.tagName).toBe("INPUT");
    expect(txtStorageHandlingInd.type).toBe("text");
    expect(txtStorageHandlingInd.classList).toContain("textboxWidgetClass");
    expect(txtStorageHandlingInd.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualRTPHistView_txtStorageHandlingInd"
      )
    );
    await act(async () => {
      userEvent.type(txtStorageHandlingInd, "1");
    });
  });
  test("Custom Test Cases for txtStorageHandlingInd", () => {
    // START_USER_CODE-USER_txtStorageHandlingInd_TEST
    // END_USER_CODE-USER_txtStorageHandlingInd_TEST
  });
  test("txtStorageHandlingInd1(Textbox Widget) Test Cases", async () => {
    const txtStorageHandlingInd1 = screen.getByTestId("txtStorageHandlingInd1");
    expect(txtStorageHandlingInd1.tagName).toBe("INPUT");
    expect(txtStorageHandlingInd1.type).toBe("text");
    expect(txtStorageHandlingInd1.classList).toContain("textboxWidgetClass");
    expect(txtStorageHandlingInd1.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualRTPHistView_txtStorageHandlingInd1"
      )
    );
    await act(async () => {
      userEvent.type(txtStorageHandlingInd1, "1");
    });
  });
  test("Custom Test Cases for txtStorageHandlingInd1", () => {
    // START_USER_CODE-USER_txtStorageHandlingInd1_TEST
    // END_USER_CODE-USER_txtStorageHandlingInd1_TEST
  });
  test("txtTradeInsp(Textbox Widget) Test Cases", async () => {
    const txtTradeInsp = screen.getByTestId("txtTradeInsp");
    expect(txtTradeInsp.tagName).toBe("INPUT");
    expect(txtTradeInsp.type).toBe("text");
    expect(txtTradeInsp.classList).toContain("textboxWidgetClass");
    expect(txtTradeInsp.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPHistView_txtTradeInsp")
    );
    await act(async () => {
      userEvent.type(txtTradeInsp, "1");
    });
  });
  test("Custom Test Cases for txtTradeInsp", () => {
    // START_USER_CODE-USER_txtTradeInsp_TEST
    // END_USER_CODE-USER_txtTradeInsp_TEST
  });
  test("txtTradeInsp1(Textbox Widget) Test Cases", async () => {
    const txtTradeInsp1 = screen.getByTestId("txtTradeInsp1");
    expect(txtTradeInsp1.tagName).toBe("INPUT");
    expect(txtTradeInsp1.type).toBe("text");
    expect(txtTradeInsp1.classList).toContain("textboxWidgetClass");
    expect(txtTradeInsp1.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPHistView_txtTradeInsp1")
    );
    await act(async () => {
      userEvent.type(txtTradeInsp1, "1");
    });
  });
  test("Custom Test Cases for txtTradeInsp1", () => {
    // START_USER_CODE-USER_txtTradeInsp1_TEST
    // END_USER_CODE-USER_txtTradeInsp1_TEST
  });
  test("txtTradeSettle(Textbox Widget) Test Cases", async () => {
    const txtTradeSettle = screen.getByTestId("txtTradeSettle");
    expect(txtTradeSettle.tagName).toBe("INPUT");
    expect(txtTradeSettle.type).toBe("text");
    expect(txtTradeSettle.classList).toContain("textboxWidgetClass");
    expect(txtTradeSettle.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPHistView_txtTradeSettle")
    );
    await act(async () => {
      userEvent.type(txtTradeSettle, "1");
    });
  });
  test("Custom Test Cases for txtTradeSettle", () => {
    // START_USER_CODE-USER_txtTradeSettle_TEST
    // END_USER_CODE-USER_txtTradeSettle_TEST
  });
  test("txtTradeSettle1(Textbox Widget) Test Cases", async () => {
    const txtTradeSettle1 = screen.getByTestId("txtTradeSettle1");
    expect(txtTradeSettle1.tagName).toBe("INPUT");
    expect(txtTradeSettle1.type).toBe("text");
    expect(txtTradeSettle1.classList).toContain("textboxWidgetClass");
    expect(txtTradeSettle1.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPHistView_txtTradeSettle1")
    );
    await act(async () => {
      userEvent.type(txtTradeSettle1, "1");
    });
  });
  test("Custom Test Cases for txtTradeSettle1", () => {
    // START_USER_CODE-USER_txtTradeSettle1_TEST
    // END_USER_CODE-USER_txtTradeSettle1_TEST
  });
  test("txtUser(Textbox Widget) Test Cases", async () => {
    const txtUser = screen.getByTestId("txtUser");
    expect(txtUser.tagName).toBe("INPUT");
    expect(txtUser.type).toBe("text");
    expect(txtUser.classList).toContain("textboxWidgetClass");
    expect(txtUser.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPHistView_txtUser")
    );
    await act(async () => {
      userEvent.type(txtUser, "1");
    });
  });
  test("Custom Test Cases for txtUser", () => {
    // START_USER_CODE-USER_txtUser_TEST
    // END_USER_CODE-USER_txtUser_TEST
  });
  test("txtRTPVendor(Textbox Widget) Test Cases", async () => {
    const txtRTPVendor = screen.getByTestId("txtRTPVendor");
    expect(txtRTPVendor.tagName).toBe("INPUT");
    expect(txtRTPVendor.type).toBe("text");
    expect(txtRTPVendor.classList).toContain("textboxWidgetClass");
    expect(txtRTPVendor.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPHistView_txtRTPVendor")
    );
    await act(async () => {
      userEvent.type(txtRTPVendor, "1");
    });
  });
  test("Custom Test Cases for txtRTPVendor", () => {
    // START_USER_CODE-USER_txtRTPVendor_TEST
    // END_USER_CODE-USER_txtRTPVendor_TEST
  });
  test("txtVendor1(Textbox Widget) Test Cases", async () => {
    const txtVendor1 = screen.getByTestId("txtVendor1");
    expect(txtVendor1.tagName).toBe("INPUT");
    expect(txtVendor1.type).toBe("text");
    expect(txtVendor1.classList).toContain("textboxWidgetClass");
    expect(txtVendor1.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPHistView_txtVendor1")
    );
    await act(async () => {
      userEvent.type(txtVendor1, "1");
    });
  });
  test("Custom Test Cases for txtVendor1", () => {
    // START_USER_CODE-USER_txtVendor1_TEST
    // END_USER_CODE-USER_txtVendor1_TEST
  });
  test("txtwhseRcpt(Textbox Widget) Test Cases", async () => {
    const txtwhseRcpt = screen.getByTestId("txtwhseRcpt");
    expect(txtwhseRcpt.tagName).toBe("INPUT");
    expect(txtwhseRcpt.type).toBe("text");
    expect(txtwhseRcpt.classList).toContain("textboxWidgetClass");
    expect(txtwhseRcpt.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPHistView_txtwhseRcpt")
    );
    await act(async () => {
      userEvent.type(txtwhseRcpt, "1");
    });
  });
  test("Custom Test Cases for txtwhseRcpt", () => {
    // START_USER_CODE-USER_txtwhseRcpt_TEST
    // END_USER_CODE-USER_txtwhseRcpt_TEST
  });
  test("txtWhseRcpt1(Textbox Widget) Test Cases", async () => {
    const txtWhseRcpt1 = screen.getByTestId("txtWhseRcpt1");
    expect(txtWhseRcpt1.tagName).toBe("INPUT");
    expect(txtWhseRcpt1.type).toBe("text");
    expect(txtWhseRcpt1.classList).toContain("textboxWidgetClass");
    expect(txtWhseRcpt1.previousElementSibling.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:ManualRTPHistView_txtWhseRcpt1")
    );
    await act(async () => {
      userEvent.type(txtWhseRcpt1, "1");
    });
  });
  test("Custom Test Cases for txtWhseRcpt1", () => {
    // START_USER_CODE-USER_txtWhseRcpt1_TEST
    // END_USER_CODE-USER_txtWhseRcpt1_TEST
  });
  test("txtWhseReceiptLoc(Textbox Widget) Test Cases", async () => {
    const txtWhseReceiptLoc = screen.getByTestId("txtWhseReceiptLoc");
    expect(txtWhseReceiptLoc.tagName).toBe("INPUT");
    expect(txtWhseReceiptLoc.type).toBe("text");
    expect(txtWhseReceiptLoc.classList).toContain("textboxWidgetClass");
    expect(txtWhseReceiptLoc.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualRTPHistView_txtWhseReceiptLoc"
      )
    );
    await act(async () => {
      userEvent.type(txtWhseReceiptLoc, "1");
    });
  });
  test("Custom Test Cases for txtWhseReceiptLoc", () => {
    // START_USER_CODE-USER_txtWhseReceiptLoc_TEST
    // END_USER_CODE-USER_txtWhseReceiptLoc_TEST
  });
  test("txtWhseReceiptLoc1(Textbox Widget) Test Cases", async () => {
    const txtWhseReceiptLoc1 = screen.getByTestId("txtWhseReceiptLoc1");
    expect(txtWhseReceiptLoc1.tagName).toBe("INPUT");
    expect(txtWhseReceiptLoc1.type).toBe("text");
    expect(txtWhseReceiptLoc1.classList).toContain("textboxWidgetClass");
    expect(txtWhseReceiptLoc1.previousElementSibling.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:ManualRTPHistView_txtWhseReceiptLoc1"
      )
    );
    await act(async () => {
      userEvent.type(txtWhseReceiptLoc1, "1");
    });
  });
  test("Custom Test Cases for txtWhseReceiptLoc1", () => {
    // START_USER_CODE-USER_txtWhseReceiptLoc1_TEST
    // END_USER_CODE-USER_txtWhseReceiptLoc1_TEST
  });
});
