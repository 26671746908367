/* eslint-disable*/
import React from "react";
import WarehouseReceipts_EWRListRRI from "./EWRListRRI";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("EWRListRRI Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<WarehouseReceipts_EWRListRRI />);
    });
  });
  afterEach(cleanup);
  test("is EWRListRRI Loads Successfully", () => {
    expect(screen.getByText("EWRListRRI")).toBeInTheDocument;
  });
  test("Custom Test Cases for EWRListRRI", () => {
    // START_USER_CODE-USER_EWRListRRI_Custom_Test_Case
    // END_USER_CODE-USER_EWRListRRI_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<WarehouseReceipts_EWRListRRI />);
    });
  });
  afterEach(cleanup);
  test("btnAdd(Button Widget) Test Cases", async () => {
    const btnAdd = screen.getByTestId("btnAdd");
    expect(btnAdd).toBeInTheDocument;
    expect(btnAdd.textContent).toEqual(
      t("WarehouseReceipts:EWRListRRI_btnAdd")
    );
  });
  test("Custom Test Cases for btnAdd", () => {
    // START_USER_CODE-USER_btnAdd_TEST
    // END_USER_CODE-USER_btnAdd_TEST
  });
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("WarehouseReceipts:EWRListRRI_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnSearch(Button Widget) Test Cases", async () => {
    const btnSearch = screen.getByTestId("btnSearch");
    expect(btnSearch).toBeInTheDocument;
    expect(btnSearch.textContent).toEqual(
      t("WarehouseReceipts:EWRListRRI_btnSearch")
    );
  });
  test("Custom Test Cases for btnSearch", () => {
    // START_USER_CODE-USER_btnSearch_TEST
    // END_USER_CODE-USER_btnSearch_TEST
  });
  test("gridListRRI(Grid Widget) Test Cases", async () => {
    let gridListRRI = screen.getByTestId("gridListRRI");
    let gridListRRIbtn = gridListRRI.nextElementSibling.firstElementChild;
    gridListRRI = gridListRRI.parentElement.parentElement.parentElement;
    expect(gridListRRI.tagName).toBe("DIV");
    expect(gridListRRI.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("WarehouseReceipts:EWRListRRI_gridListRRI")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for gridListRRI", () => {
    // START_USER_CODE-USER_gridListRRI_TEST
    // END_USER_CODE-USER_gridListRRI_TEST
  });
  test("grpbxDetails(GroupBox Widget) Test Cases", async () => {
    const grpbxDetails = screen.getByTestId("grpbxDetails");
    expect(grpbxDetails.tagName).toBe("BUTTON");
    expect(grpbxDetails.type).toBe("button");
    expect(grpbxDetails.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxDetails", () => {
    // START_USER_CODE-USER_grpbxDetails_TEST
    // END_USER_CODE-USER_grpbxDetails_TEST
  });
  test("grpbxEWRListRRI(GroupBox Widget) Test Cases", async () => {
    const grpbxEWRListRRI = screen.getByTestId("grpbxEWRListRRI");
    expect(grpbxEWRListRRI.tagName).toBe("BUTTON");
    expect(grpbxEWRListRRI.type).toBe("button");
    expect(grpbxEWRListRRI.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxEWRListRRI", () => {
    // START_USER_CODE-USER_grpbxEWRListRRI_TEST
    // END_USER_CODE-USER_grpbxEWRListRRI_TEST
  });
  test("gridListRRI_txtcolAddDate(Grid Widget) Test Cases", async () => {
    let gridListRRI_txtcolAddDate = screen.getByTestId("gridListRRI");
    let gridListRRI_txtcolAddDatebtn =
      gridListRRI_txtcolAddDate.nextElementSibling.firstElementChild;
    gridListRRI_txtcolAddDate =
      gridListRRI_txtcolAddDate.parentElement.parentElement.parentElement;
    expect(gridListRRI_txtcolAddDate.tagName).toBe("DIV");
    expect(gridListRRI_txtcolAddDate.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("WarehouseReceipts:EWRListRRI_gridListRRI")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolAddDate", () => {
    // START_USER_CODE-USER_txtcolAddDate_TEST
    // END_USER_CODE-USER_txtcolAddDate_TEST
  });
  test("gridListRRI_txtcolAddUser(Grid Widget) Test Cases", async () => {
    let gridListRRI_txtcolAddUser = screen.getByTestId("gridListRRI");
    let gridListRRI_txtcolAddUserbtn =
      gridListRRI_txtcolAddUser.nextElementSibling.firstElementChild;
    gridListRRI_txtcolAddUser =
      gridListRRI_txtcolAddUser.parentElement.parentElement.parentElement;
    expect(gridListRRI_txtcolAddUser.tagName).toBe("DIV");
    expect(gridListRRI_txtcolAddUser.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("WarehouseReceipts:EWRListRRI_gridListRRI")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolAddUser", () => {
    // START_USER_CODE-USER_txtcolAddUser_TEST
    // END_USER_CODE-USER_txtcolAddUser_TEST
  });
  test("gridListRRI_txtcolBPNum(Grid Widget) Test Cases", async () => {
    let gridListRRI_txtcolBPNum = screen.getByTestId("gridListRRI");
    let gridListRRI_txtcolBPNumbtn =
      gridListRRI_txtcolBPNum.nextElementSibling.firstElementChild;
    gridListRRI_txtcolBPNum =
      gridListRRI_txtcolBPNum.parentElement.parentElement.parentElement;
    expect(gridListRRI_txtcolBPNum.tagName).toBe("DIV");
    expect(gridListRRI_txtcolBPNum.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("WarehouseReceipts:EWRListRRI_gridListRRI")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolBPNum", () => {
    // START_USER_CODE-USER_txtcolBPNum_TEST
    // END_USER_CODE-USER_txtcolBPNum_TEST
  });
  test("gridListRRI_txtcolFarmNum(Grid Widget) Test Cases", async () => {
    let gridListRRI_txtcolFarmNum = screen.getByTestId("gridListRRI");
    let gridListRRI_txtcolFarmNumbtn =
      gridListRRI_txtcolFarmNum.nextElementSibling.firstElementChild;
    gridListRRI_txtcolFarmNum =
      gridListRRI_txtcolFarmNum.parentElement.parentElement.parentElement;
    expect(gridListRRI_txtcolFarmNum.tagName).toBe("DIV");
    expect(gridListRRI_txtcolFarmNum.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("WarehouseReceipts:EWRListRRI_gridListRRI")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolFarmNum", () => {
    // START_USER_CODE-USER_txtcolFarmNum_TEST
    // END_USER_CODE-USER_txtcolFarmNum_TEST
  });
  test("gridListRRI_txtcolFileNum(Grid Widget) Test Cases", async () => {
    let gridListRRI_txtcolFileNum = screen.getByTestId("gridListRRI");
    let gridListRRI_txtcolFileNumbtn =
      gridListRRI_txtcolFileNum.nextElementSibling.firstElementChild;
    gridListRRI_txtcolFileNum =
      gridListRRI_txtcolFileNum.parentElement.parentElement.parentElement;
    expect(gridListRRI_txtcolFileNum.tagName).toBe("DIV");
    expect(gridListRRI_txtcolFileNum.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("WarehouseReceipts:EWRListRRI_gridListRRI")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolFileNum", () => {
    // START_USER_CODE-USER_txtcolFileNum_TEST
    // END_USER_CODE-USER_txtcolFileNum_TEST
  });
  test("gridListRRI_txtcolLicenseNum(Grid Widget) Test Cases", async () => {
    let gridListRRI_txtcolLicenseNum = screen.getByTestId("gridListRRI");
    let gridListRRI_txtcolLicenseNumbtn =
      gridListRRI_txtcolLicenseNum.nextElementSibling.firstElementChild;
    gridListRRI_txtcolLicenseNum =
      gridListRRI_txtcolLicenseNum.parentElement.parentElement.parentElement;
    expect(gridListRRI_txtcolLicenseNum.tagName).toBe("DIV");
    expect(gridListRRI_txtcolLicenseNum.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("WarehouseReceipts:EWRListRRI_gridListRRI")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolLicenseNum", () => {
    // START_USER_CODE-USER_txtcolLicenseNum_TEST
    // END_USER_CODE-USER_txtcolLicenseNum_TEST
  });
  test("gridListRRI_txtcolLoanNum(Grid Widget) Test Cases", async () => {
    let gridListRRI_txtcolLoanNum = screen.getByTestId("gridListRRI");
    let gridListRRI_txtcolLoanNumbtn =
      gridListRRI_txtcolLoanNum.nextElementSibling.firstElementChild;
    gridListRRI_txtcolLoanNum =
      gridListRRI_txtcolLoanNum.parentElement.parentElement.parentElement;
    expect(gridListRRI_txtcolLoanNum.tagName).toBe("DIV");
    expect(gridListRRI_txtcolLoanNum.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("WarehouseReceipts:EWRListRRI_gridListRRI")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolLoanNum", () => {
    // START_USER_CODE-USER_txtcolLoanNum_TEST
    // END_USER_CODE-USER_txtcolLoanNum_TEST
  });
  test("gridListRRI_txtcolLoanStatus(Grid Widget) Test Cases", async () => {
    let gridListRRI_txtcolLoanStatus = screen.getByTestId("gridListRRI");
    let gridListRRI_txtcolLoanStatusbtn =
      gridListRRI_txtcolLoanStatus.nextElementSibling.firstElementChild;
    gridListRRI_txtcolLoanStatus =
      gridListRRI_txtcolLoanStatus.parentElement.parentElement.parentElement;
    expect(gridListRRI_txtcolLoanStatus.tagName).toBe("DIV");
    expect(gridListRRI_txtcolLoanStatus.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("WarehouseReceipts:EWRListRRI_gridListRRI")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolLoanStatus", () => {
    // START_USER_CODE-USER_txtcolLoanStatus_TEST
    // END_USER_CODE-USER_txtcolLoanStatus_TEST
  });
  test("gridListRRI_txtcolPnutType(Grid Widget) Test Cases", async () => {
    let gridListRRI_txtcolPnutType = screen.getByTestId("gridListRRI");
    let gridListRRI_txtcolPnutTypebtn =
      gridListRRI_txtcolPnutType.nextElementSibling.firstElementChild;
    gridListRRI_txtcolPnutType =
      gridListRRI_txtcolPnutType.parentElement.parentElement.parentElement;
    expect(gridListRRI_txtcolPnutType.tagName).toBe("DIV");
    expect(gridListRRI_txtcolPnutType.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("WarehouseReceipts:EWRListRRI_gridListRRI")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolPnutType", () => {
    // START_USER_CODE-USER_txtcolPnutType_TEST
    // END_USER_CODE-USER_txtcolPnutType_TEST
  });
  test("gridListRRI_txtcolRcptNum(Grid Widget) Test Cases", async () => {
    let gridListRRI_txtcolRcptNum = screen.getByTestId("gridListRRI");
    let gridListRRI_txtcolRcptNumbtn =
      gridListRRI_txtcolRcptNum.nextElementSibling.firstElementChild;
    gridListRRI_txtcolRcptNum =
      gridListRRI_txtcolRcptNum.parentElement.parentElement.parentElement;
    expect(gridListRRI_txtcolRcptNum.tagName).toBe("DIV");
    expect(gridListRRI_txtcolRcptNum.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("WarehouseReceipts:EWRListRRI_gridListRRI")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolRcptNum", () => {
    // START_USER_CODE-USER_txtcolRcptNum_TEST
    // END_USER_CODE-USER_txtcolRcptNum_TEST
  });
  test("gridListRRI_txtcolRcptStatus(Grid Widget) Test Cases", async () => {
    let gridListRRI_txtcolRcptStatus = screen.getByTestId("gridListRRI");
    let gridListRRI_txtcolRcptStatusbtn =
      gridListRRI_txtcolRcptStatus.nextElementSibling.firstElementChild;
    gridListRRI_txtcolRcptStatus =
      gridListRRI_txtcolRcptStatus.parentElement.parentElement.parentElement;
    expect(gridListRRI_txtcolRcptStatus.tagName).toBe("DIV");
    expect(gridListRRI_txtcolRcptStatus.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("WarehouseReceipts:EWRListRRI_gridListRRI")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolRcptStatus", () => {
    // START_USER_CODE-USER_txtcolRcptStatus_TEST
    // END_USER_CODE-USER_txtcolRcptStatus_TEST
  });
  test("gridListRRI_txtcolRRIStatus(Grid Widget) Test Cases", async () => {
    let gridListRRI_txtcolRRIStatus = screen.getByTestId("gridListRRI");
    let gridListRRI_txtcolRRIStatusbtn =
      gridListRRI_txtcolRRIStatus.nextElementSibling.firstElementChild;
    gridListRRI_txtcolRRIStatus =
      gridListRRI_txtcolRRIStatus.parentElement.parentElement.parentElement;
    expect(gridListRRI_txtcolRRIStatus.tagName).toBe("DIV");
    expect(gridListRRI_txtcolRRIStatus.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("WarehouseReceipts:EWRListRRI_gridListRRI")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolRRIStatus", () => {
    // START_USER_CODE-USER_txtcolRRIStatus_TEST
    // END_USER_CODE-USER_txtcolRRIStatus_TEST
  });
  test("gridListRRI_txtcolWhseCode(Grid Widget) Test Cases", async () => {
    let gridListRRI_txtcolWhseCode = screen.getByTestId("gridListRRI");
    let gridListRRI_txtcolWhseCodebtn =
      gridListRRI_txtcolWhseCode.nextElementSibling.firstElementChild;
    gridListRRI_txtcolWhseCode =
      gridListRRI_txtcolWhseCode.parentElement.parentElement.parentElement;
    expect(gridListRRI_txtcolWhseCode.tagName).toBe("DIV");
    expect(gridListRRI_txtcolWhseCode.classList).toContain("GridWidget");
    expect(screen.getAllByText(t("WarehouseReceipts:EWRListRRI_gridListRRI")))
      .toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolWhseCode", () => {
    // START_USER_CODE-USER_txtcolWhseCode_TEST
    // END_USER_CODE-USER_txtcolWhseCode_TEST
  });
  test("txtFarmNumber(Textbox Widget) Test Cases", async () => {
    const txtFarmNumber = screen.getByTestId("txtFarmNumber");
    expect(txtFarmNumber.tagName).toBe("INPUT");
    expect(txtFarmNumber.type).toBe("text");
    expect(txtFarmNumber.classList).toContain("textboxWidgetClass");
    expect(txtFarmNumber.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:EWRListRRI_txtFarmNumber")
    );
    await act(async () => {
      userEvent.type(txtFarmNumber, "1");
    });
  });
  test("Custom Test Cases for txtFarmNumber", () => {
    // START_USER_CODE-USER_txtFarmNumber_TEST
    // END_USER_CODE-USER_txtFarmNumber_TEST
  });
  test("txtFedBPNumber(Textbox Widget) Test Cases", async () => {
    const txtFedBPNumber = screen.getByTestId("txtFedBPNumber");
    expect(txtFedBPNumber.tagName).toBe("INPUT");
    expect(txtFedBPNumber.type).toBe("text");
    expect(txtFedBPNumber.classList).toContain("textboxWidgetClass");
    expect(txtFedBPNumber.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:EWRListRRI_txtFedBPNumber")
    );
    await act(async () => {
      userEvent.type(txtFedBPNumber, "1");
    });
  });
  test("Custom Test Cases for txtFedBPNumber", () => {
    // START_USER_CODE-USER_txtFedBPNumber_TEST
    // END_USER_CODE-USER_txtFedBPNumber_TEST
  });
  test("txtFileNumber(Textbox Widget) Test Cases", async () => {
    const txtFileNumber = screen.getByTestId("txtFileNumber");
    expect(txtFileNumber.tagName).toBe("INPUT");
    expect(txtFileNumber.type).toBe("text");
    expect(txtFileNumber.classList).toContain("textboxWidgetClass");
    expect(txtFileNumber.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:EWRListRRI_txtFileNumber")
    );
    await act(async () => {
      userEvent.type(txtFileNumber, "1");
    });
  });
  test("Custom Test Cases for txtFileNumber", () => {
    // START_USER_CODE-USER_txtFileNumber_TEST
    // END_USER_CODE-USER_txtFileNumber_TEST
  });
  test("txtLicenseNumber(Textbox Widget) Test Cases", async () => {
    const txtLicenseNumber = screen.getByTestId("txtLicenseNumber");
    expect(txtLicenseNumber.tagName).toBe("INPUT");
    expect(txtLicenseNumber.type).toBe("text");
    expect(txtLicenseNumber.classList).toContain("textboxWidgetClass");
    expect(txtLicenseNumber.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:EWRListRRI_txtLicenseNumber")
    );
    await act(async () => {
      userEvent.type(txtLicenseNumber, "1");
    });
  });
  test("Custom Test Cases for txtLicenseNumber", () => {
    // START_USER_CODE-USER_txtLicenseNumber_TEST
    // END_USER_CODE-USER_txtLicenseNumber_TEST
  });
  test("txtLoanNumber(Textbox Widget) Test Cases", async () => {
    const txtLoanNumber = screen.getByTestId("txtLoanNumber");
    expect(txtLoanNumber.tagName).toBe("INPUT");
    expect(txtLoanNumber.type).toBe("text");
    expect(txtLoanNumber.classList).toContain("textboxWidgetClass");
    expect(txtLoanNumber.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:EWRListRRI_txtLoanNumber")
    );
    await act(async () => {
      userEvent.type(txtLoanNumber, "1");
    });
  });
  test("Custom Test Cases for txtLoanNumber", () => {
    // START_USER_CODE-USER_txtLoanNumber_TEST
    // END_USER_CODE-USER_txtLoanNumber_TEST
  });
  test("txtReceiptNumber(Textbox Widget) Test Cases", async () => {
    const txtReceiptNumber = screen.getByTestId("txtReceiptNumber");
    expect(txtReceiptNumber.tagName).toBe("INPUT");
    expect(txtReceiptNumber.type).toBe("text");
    expect(txtReceiptNumber.classList).toContain("textboxWidgetClass");
    expect(txtReceiptNumber.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:EWRListRRI_txtReceiptNumber")
    );
    await act(async () => {
      userEvent.type(txtReceiptNumber, "1");
    });
  });
  test("Custom Test Cases for txtReceiptNumber", () => {
    // START_USER_CODE-USER_txtReceiptNumber_TEST
    // END_USER_CODE-USER_txtReceiptNumber_TEST
  });
  test("txtWarehouseCode(Textbox Widget) Test Cases", async () => {
    const txtWarehouseCode = screen.getByTestId("txtWarehouseCode");
    expect(txtWarehouseCode.tagName).toBe("INPUT");
    expect(txtWarehouseCode.type).toBe("text");
    expect(txtWarehouseCode.classList).toContain("textboxWidgetClass");
    expect(txtWarehouseCode.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:EWRListRRI_txtWarehouseCode")
    );
    await act(async () => {
      userEvent.type(txtWarehouseCode, "1");
    });
  });
  test("Custom Test Cases for txtWarehouseCode", () => {
    // START_USER_CODE-USER_txtWarehouseCode_TEST
    // END_USER_CODE-USER_txtWarehouseCode_TEST
  });
});
