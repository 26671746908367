/* eslint-disable*/
import React from "react";
import WarehouseReceipts_EWRSendRRI from "./EWRSendRRI";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("EWRSendRRI Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<WarehouseReceipts_EWRSendRRI />);
    });
  });
  afterEach(cleanup);
  test("is EWRSendRRI Loads Successfully", () => {
    expect(screen.getByText("EWRSendRRI")).toBeInTheDocument;
  });
  test("Custom Test Cases for EWRSendRRI", () => {
    // START_USER_CODE-USER_EWRSendRRI_Custom_Test_Case
    // END_USER_CODE-USER_EWRSendRRI_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<WarehouseReceipts_EWRSendRRI />);
    });
  });
  afterEach(cleanup);
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("WarehouseReceipts:EWRSendRRI_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnSend(Button Widget) Test Cases", async () => {
    const btnSend = screen.getByTestId("btnSend");
    expect(btnSend).toBeInTheDocument;
    expect(btnSend.textContent).toEqual(
      t("WarehouseReceipts:EWRSendRRI_btnSend")
    );
  });
  test("Custom Test Cases for btnSend", () => {
    // START_USER_CODE-USER_btnSend_TEST
    // END_USER_CODE-USER_btnSend_TEST
  });
  test("grpbxEWRSendRRI(GroupBox Widget) Test Cases", async () => {
    const grpbxEWRSendRRI = screen.getByTestId("grpbxEWRSendRRI");
    expect(grpbxEWRSendRRI.tagName).toBe("BUTTON");
    expect(grpbxEWRSendRRI.type).toBe("button");
    expect(grpbxEWRSendRRI.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxEWRSendRRI", () => {
    // START_USER_CODE-USER_grpbxEWRSendRRI_TEST
    // END_USER_CODE-USER_grpbxEWRSendRRI_TEST
  });
  test("lblFileNumber(Label Widget) Test Cases", async () => {
    const lblFileNumber = screen.getByTestId("lblFileNumber");
    expect(lblFileNumber.tagName).toBe("LABEL");
    expect(lblFileNumber.classList).toContain("form-label");
    expect(lblFileNumber.textContent).toEqual(
      t("WarehouseReceipts:EWRSendRRI_lblFileNumber")
    );
  });
  test("Custom Test Cases for lblFileNumber", () => {
    // START_USER_CODE-USER_lblFileNumber_TEST
    // END_USER_CODE-USER_lblFileNumber_TEST
  });
  test("lblFileNumberValue(Label Widget) Test Cases", async () => {
    const lblFileNumberValue = screen.getByTestId("lblFileNumberValue");
    expect(lblFileNumberValue.tagName).toBe("LABEL");
    expect(lblFileNumberValue.classList).toContain("form-label");
    expect(lblFileNumberValue.textContent).toEqual(
      t("WarehouseReceipts:EWRSendRRI_lblFileNumberValue")
    );
  });
  test("Custom Test Cases for lblFileNumberValue", () => {
    // START_USER_CODE-USER_lblFileNumberValue_TEST
    // END_USER_CODE-USER_lblFileNumberValue_TEST
  });
  test("txtLicenseNumber(Textbox Widget) Test Cases", async () => {
    const txtLicenseNumber = screen.getByTestId("txtLicenseNumber");
    expect(txtLicenseNumber.tagName).toBe("INPUT");
    expect(txtLicenseNumber.type).toBe("text");
    expect(txtLicenseNumber.classList).toContain("textboxWidgetClass");
    expect(txtLicenseNumber.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:EWRSendRRI_txtLicenseNumber")
    );
    await act(async () => {
      userEvent.type(txtLicenseNumber, "1");
    });
  });
  test("Custom Test Cases for txtLicenseNumber", () => {
    // START_USER_CODE-USER_txtLicenseNumber_TEST
    // END_USER_CODE-USER_txtLicenseNumber_TEST
  });
  test("txtReceiptNumber(Textbox Widget) Test Cases", async () => {
    const txtReceiptNumber = screen.getByTestId("txtReceiptNumber");
    expect(txtReceiptNumber.tagName).toBe("INPUT");
    expect(txtReceiptNumber.type).toBe("text");
    expect(txtReceiptNumber.classList).toContain("textboxWidgetClass");
    expect(txtReceiptNumber.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:EWRSendRRI_txtReceiptNumber")
    );
    await act(async () => {
      userEvent.type(txtReceiptNumber, "1");
    });
  });
  test("Custom Test Cases for txtReceiptNumber", () => {
    // START_USER_CODE-USER_txtReceiptNumber_TEST
    // END_USER_CODE-USER_txtReceiptNumber_TEST
  });
  test("txtWarehouseCode(Textbox Widget) Test Cases", async () => {
    const txtWarehouseCode = screen.getByTestId("txtWarehouseCode");
    expect(txtWarehouseCode.tagName).toBe("INPUT");
    expect(txtWarehouseCode.type).toBe("text");
    expect(txtWarehouseCode.classList).toContain("textboxWidgetClass");
    expect(txtWarehouseCode.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:EWRSendRRI_txtWarehouseCode")
    );
    await act(async () => {
      userEvent.type(txtWarehouseCode, "1");
    });
  });
  test("Custom Test Cases for txtWarehouseCode", () => {
    // START_USER_CODE-USER_txtWarehouseCode_TEST
    // END_USER_CODE-USER_txtWarehouseCode_TEST
  });
});
