/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  CommandContainerWidget,
  ButtonWidget,
  LabelWidget,
  hide,
  show,
  setValue,
  getValue,
  setData,
  getData,
  enable,
  disable
} from "../../shared/WindowImports";

import "./PremiumDeductionsVendorSplitsTrade.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { isEnabled, isVisible, showColumn } from "../../shared/ParentWindow";
// END_USER_CODE-USER_IMPORTS
// END_USER_CODE-USER_IMPORTS
function Settlements_PremiumDeductionsVendorSplitsTrade(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "PremiumDeductionsVendorSplitsTrade",
    windowName: "PremiumDeductionsVendorSplitsTrade",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "Settlements.PremiumDeductionsVendorSplitsTrade",
    // START_USER_CODE-USER_PremiumDeductionsVendorSplitsTrade_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Premiums/Deductions",
      scrCode: "PM0050B",
    },
    // END_USER_CODE-USER_PremiumDeductionsVendorSplitsTrade_PROPERTIES
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    btnOk: {
      name: "btnOk",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Ok",
      CharWidth: "8",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnOk_PROPERTIES

      // END_USER_CODE-USER_btnOk_PROPERTIES
    },
    grpbxPrchsLabels: {
      name: "grpbxPrchsLabels",
      type: "GroupBoxWidget",
      parent: "grpbxPurchase",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxPrchsLabels_PROPERTIES

      // END_USER_CODE-USER_grpbxPrchsLabels_PROPERTIES
    },
    grpbxPrchsRow10: {
      name: "grpbxPrchsRow10",
      type: "GroupBoxWidget",
      parent: "grpbxPurchase",
      Height: "",
      Width: "",
      ColsForMobile: "4",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxPrchsRow10_PROPERTIES

      // END_USER_CODE-USER_grpbxPrchsRow10_PROPERTIES
    },
    grpbxPrchsRow11: {
      name: "grpbxPrchsRow11",
      type: "GroupBoxWidget",
      parent: "grpbxPurchase",
      Height: "",
      Width: "",
      ColsForMobile: "4",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxPrchsRow11_PROPERTIES

      // END_USER_CODE-USER_grpbxPrchsRow11_PROPERTIES
    },
    grpbxPrchsRow12: {
      name: "grpbxPrchsRow12",
      type: "GroupBoxWidget",
      parent: "grpbxPurchase",
      Height: "",
      Width: "",
      ColsForMobile: "4",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxPrchsRow12_PROPERTIES

      // END_USER_CODE-USER_grpbxPrchsRow12_PROPERTIES
    },
    grpbxPrchsRow13: {
      name: "grpbxPrchsRow13",
      type: "GroupBoxWidget",
      parent: "grpbxPurchase",
      Height: "",
      Width: "",
      ColsForMobile: "4",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxPrchsRow13_PROPERTIES

      // END_USER_CODE-USER_grpbxPrchsRow13_PROPERTIES
    },
    grpbxPrchsRow14: {
      name: "grpbxPrchsRow14",
      type: "GroupBoxWidget",
      parent: "grpbxPurchase",
      Height: "",
      Width: "",
      ColsForMobile: "4",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxPrchsRow14_PROPERTIES

      // END_USER_CODE-USER_grpbxPrchsRow14_PROPERTIES
    },
    grpbxPrchsRow15: {
      name: "grpbxPrchsRow15",
      type: "GroupBoxWidget",
      parent: "grpbxPurchase",
      Height: "",
      Width: "",
      ColsForMobile: "4",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxPrchsRow15_PROPERTIES

      // END_USER_CODE-USER_grpbxPrchsRow15_PROPERTIES
    },
    grpbxPrchsRow16: {
      name: "grpbxPrchsRow16",
      type: "GroupBoxWidget",
      parent: "grpbxPurchase",
      Height: "",
      Width: "",
      ColsForMobile: "4",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxPrchsRow16_PROPERTIES

      // END_USER_CODE-USER_grpbxPrchsRow16_PROPERTIES
    },
    grpbxPrchsRow17: {
      name: "grpbxPrchsRow17",
      type: "GroupBoxWidget",
      parent: "grpbxPurchase",
      Height: "",
      Width: "",
      ColsForMobile: "4",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxPrchsRow17_PROPERTIES

      // END_USER_CODE-USER_grpbxPrchsRow17_PROPERTIES
    },
    grpbxPrchsRow18: {
      name: "grpbxPrchsRow18",
      type: "GroupBoxWidget",
      parent: "grpbxPurchase",
      Height: "",
      Width: "",
      ColsForMobile: "4",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxPrchsRow18_PROPERTIES

      // END_USER_CODE-USER_grpbxPrchsRow18_PROPERTIES
    },
    grpbxPrchsRow19: {
      name: "grpbxPrchsRow19",
      type: "GroupBoxWidget",
      parent: "grpbxPurchase",
      Height: "",
      Width: "",
      ColsForMobile: "4",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxPrchsRow19_PROPERTIES

      // END_USER_CODE-USER_grpbxPrchsRow19_PROPERTIES
    },
    grpbxPrchsRow1: {
      name: "grpbxPrchsRow1",
      type: "GroupBoxWidget",
      parent: "grpbxPurchase",
      Height: "",
      Width: "",
      ColsForMobile: "4",
      clonedExtId: "58881",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxPrchsRow1_PROPERTIES

      // END_USER_CODE-USER_grpbxPrchsRow1_PROPERTIES
    },
    grpbxPrchsRow20: {
      name: "grpbxPrchsRow20",
      type: "GroupBoxWidget",
      parent: "grpbxPurchase",
      Height: "",
      Width: "",
      ColsForMobile: "4",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxPrchsRow20_PROPERTIES

      // END_USER_CODE-USER_grpbxPrchsRow20_PROPERTIES
    },
    grpbxPrchsRow2: {
      name: "grpbxPrchsRow2",
      type: "GroupBoxWidget",
      parent: "grpbxPurchase",
      Height: "",
      Width: "",
      ColsForMobile: "4",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxPrchsRow2_PROPERTIES

      // END_USER_CODE-USER_grpbxPrchsRow2_PROPERTIES
    },
    grpbxPrchsRow3: {
      name: "grpbxPrchsRow3",
      type: "GroupBoxWidget",
      parent: "grpbxPurchase",
      Height: "",
      Width: "",
      ColsForMobile: "4",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxPrchsRow3_PROPERTIES

      // END_USER_CODE-USER_grpbxPrchsRow3_PROPERTIES
    },
    grpbxPrchsRow4: {
      name: "grpbxPrchsRow4",
      type: "GroupBoxWidget",
      parent: "grpbxPurchase",
      Height: "",
      Width: "",
      ColsForMobile: "4",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxPrchsRow4_PROPERTIES

      // END_USER_CODE-USER_grpbxPrchsRow4_PROPERTIES
    },
    grpbxPrchsRow5: {
      name: "grpbxPrchsRow5",
      type: "GroupBoxWidget",
      parent: "grpbxPurchase",
      Height: "",
      Width: "",
      ColsForMobile: "4",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxPrchsRow5_PROPERTIES

      // END_USER_CODE-USER_grpbxPrchsRow5_PROPERTIES
    },
    grpbxPrchsRow6: {
      name: "grpbxPrchsRow6",
      type: "GroupBoxWidget",
      parent: "grpbxPurchase",
      Height: "",
      Width: "",
      ColsForMobile: "4",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxPrchsRow6_PROPERTIES

      // END_USER_CODE-USER_grpbxPrchsRow6_PROPERTIES
    },
    grpbxPrchsRow7: {
      name: "grpbxPrchsRow7",
      type: "GroupBoxWidget",
      parent: "grpbxPurchase",
      Height: "",
      Width: "",
      ColsForMobile: "4",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxPrchsRow7_PROPERTIES

      // END_USER_CODE-USER_grpbxPrchsRow7_PROPERTIES
    },
    grpbxPrchsRow8: {
      name: "grpbxPrchsRow8",
      type: "GroupBoxWidget",
      parent: "grpbxPurchase",
      Height: "",
      Width: "",
      ColsForMobile: "4",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxPrchsRow8_PROPERTIES

      // END_USER_CODE-USER_grpbxPrchsRow8_PROPERTIES
    },
    grpbxPrchsRow9: {
      name: "grpbxPrchsRow9",
      type: "GroupBoxWidget",
      parent: "grpbxPurchase",
      Height: "",
      Width: "",
      ColsForMobile: "4",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxPrchsRow9_PROPERTIES

      // END_USER_CODE-USER_grpbxPrchsRow9_PROPERTIES
    },
    grpbxPurchase: {
      name: "grpbxPurchase",
      type: "GroupBoxWidget",
      parent: "grpbxPremiumDeductionsVendorSplitsTrade",
      ColSpan: "1",
      Height: "",
      Width: "",
      clonedExtId: "58982",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxPurchase_PROPERTIES

      // END_USER_CODE-USER_grpbxPurchase_PROPERTIES
    },
    grpbxStorage: {
      name: "grpbxStorage",
      type: "GroupBoxWidget",
      parent: "grpbxPremiumDeductionsVendorSplitsTrade",
      ColSpan: "1",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxStorage_PROPERTIES

      // END_USER_CODE-USER_grpbxStorage_PROPERTIES
    },
    grpbxStrgLabels: {
      name: "grpbxStrgLabels",
      type: "GroupBoxWidget",
      parent: "grpbxStorage",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxStrgLabels_PROPERTIES

      // END_USER_CODE-USER_grpbxStrgLabels_PROPERTIES
    },
    grpbxStrgRow10: {
      name: "grpbxStrgRow10",
      type: "GroupBoxWidget",
      parent: "grpbxStorage",
      Height: "",
      Width: "",
      ColsForMobile: "4",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxStrgRow10_PROPERTIES

      // END_USER_CODE-USER_grpbxStrgRow10_PROPERTIES
    },
    grpbxStrgRow11: {
      name: "grpbxStrgRow11",
      type: "GroupBoxWidget",
      parent: "grpbxStorage",
      Height: "",
      Width: "",
      ColsForMobile: "4",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxStrgRow11_PROPERTIES

      // END_USER_CODE-USER_grpbxStrgRow11_PROPERTIES
    },
    grpbxStrgRow12: {
      name: "grpbxStrgRow12",
      type: "GroupBoxWidget",
      parent: "grpbxStorage",
      Height: "",
      Width: "",
      ColsForMobile: "4",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxStrgRow12_PROPERTIES

      // END_USER_CODE-USER_grpbxStrgRow12_PROPERTIES
    },
    grpbxStrgRow13: {
      name: "grpbxStrgRow13",
      type: "GroupBoxWidget",
      parent: "grpbxStorage",
      Height: "",
      Width: "",
      ColsForMobile: "4",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxStrgRow13_PROPERTIES

      // END_USER_CODE-USER_grpbxStrgRow13_PROPERTIES
    },
    grpbxStrgRow14: {
      name: "grpbxStrgRow14",
      type: "GroupBoxWidget",
      parent: "grpbxStorage",
      Height: "",
      Width: "",
      ColsForMobile: "4",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxStrgRow14_PROPERTIES

      // END_USER_CODE-USER_grpbxStrgRow14_PROPERTIES
    },
    grpbxStrgRow15: {
      name: "grpbxStrgRow15",
      type: "GroupBoxWidget",
      parent: "grpbxStorage",
      Height: "",
      Width: "",
      ColsForMobile: "4",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxStrgRow15_PROPERTIES

      // END_USER_CODE-USER_grpbxStrgRow15_PROPERTIES
    },
    grpbxStrgRow16: {
      name: "grpbxStrgRow16",
      type: "GroupBoxWidget",
      parent: "grpbxStorage",
      Height: "",
      Width: "",
      ColsForMobile: "4",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxStrgRow16_PROPERTIES

      // END_USER_CODE-USER_grpbxStrgRow16_PROPERTIES
    },
    grpbxStrgRow17: {
      name: "grpbxStrgRow17",
      type: "GroupBoxWidget",
      parent: "grpbxStorage",
      Height: "",
      Width: "",
      ColsForMobile: "4",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxStrgRow17_PROPERTIES

      // END_USER_CODE-USER_grpbxStrgRow17_PROPERTIES
    },
    grpbxStrgRow18: {
      name: "grpbxStrgRow18",
      type: "GroupBoxWidget",
      parent: "grpbxStorage",
      Height: "",
      Width: "",
      ColsForMobile: "4",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxStrgRow18_PROPERTIES

      // END_USER_CODE-USER_grpbxStrgRow18_PROPERTIES
    },
    grpbxStrgRow19: {
      name: "grpbxStrgRow19",
      type: "GroupBoxWidget",
      parent: "grpbxStorage",
      Height: "",
      Width: "",
      ColsForMobile: "4",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxStrgRow19_PROPERTIES

      // END_USER_CODE-USER_grpbxStrgRow19_PROPERTIES
    },
    grpbxStrgRow1: {
      name: "grpbxStrgRow1",
      type: "GroupBoxWidget",
      parent: "grpbxStorage",
      Height: "",
      Width: "",
      ColsForMobile: "4",
      clonedExtId: "58881",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxStrgRow1_PROPERTIES

      // END_USER_CODE-USER_grpbxStrgRow1_PROPERTIES
    },
    grpbxStrgRow20: {
      name: "grpbxStrgRow20",
      type: "GroupBoxWidget",
      parent: "grpbxStorage",
      Height: "",
      Width: "",
      ColsForMobile: "4",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxStrgRow20_PROPERTIES

      // END_USER_CODE-USER_grpbxStrgRow20_PROPERTIES
    },
    grpbxStrgRow2: {
      name: "grpbxStrgRow2",
      type: "GroupBoxWidget",
      parent: "grpbxStorage",
      Height: "",
      Width: "",
      ColsForMobile: "4",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxStrgRow2_PROPERTIES

      // END_USER_CODE-USER_grpbxStrgRow2_PROPERTIES
    },
    grpbxStrgRow3: {
      name: "grpbxStrgRow3",
      type: "GroupBoxWidget",
      parent: "grpbxStorage",
      Height: "",
      Width: "",
      ColsForMobile: "4",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxStrgRow3_PROPERTIES

      // END_USER_CODE-USER_grpbxStrgRow3_PROPERTIES
    },
    grpbxStrgRow4: {
      name: "grpbxStrgRow4",
      type: "GroupBoxWidget",
      parent: "grpbxStorage",
      Height: "",
      Width: "",
      ColsForMobile: "4",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxStrgRow4_PROPERTIES

      // END_USER_CODE-USER_grpbxStrgRow4_PROPERTIES
    },
    grpbxStrgRow5: {
      name: "grpbxStrgRow5",
      type: "GroupBoxWidget",
      parent: "grpbxStorage",
      Height: "",
      Width: "",
      ColsForMobile: "4",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxStrgRow5_PROPERTIES

      // END_USER_CODE-USER_grpbxStrgRow5_PROPERTIES
    },
    grpbxStrgRow6: {
      name: "grpbxStrgRow6",
      type: "GroupBoxWidget",
      parent: "grpbxStorage",
      Height: "",
      Width: "",
      ColsForMobile: "4",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxStrgRow6_PROPERTIES

      // END_USER_CODE-USER_grpbxStrgRow6_PROPERTIES
    },
    grpbxStrgRow7: {
      name: "grpbxStrgRow7",
      type: "GroupBoxWidget",
      parent: "grpbxStorage",
      Height: "",
      Width: "",
      ColsForMobile: "4",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxStrgRow7_PROPERTIES

      // END_USER_CODE-USER_grpbxStrgRow7_PROPERTIES
    },
    grpbxStrgRow8: {
      name: "grpbxStrgRow8",
      type: "GroupBoxWidget",
      parent: "grpbxStorage",
      Height: "",
      Width: "",
      ColsForMobile: "4",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxStrgRow8_PROPERTIES

      // END_USER_CODE-USER_grpbxStrgRow8_PROPERTIES
    },
    grpbxStrgRow9: {
      name: "grpbxStrgRow9",
      type: "GroupBoxWidget",
      parent: "grpbxStorage",
      Height: "",
      Width: "",
      ColsForMobile: "4",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxStrgRow9_PROPERTIES

      // END_USER_CODE-USER_grpbxStrgRow9_PROPERTIES
    },
    grpbxTotalPrchs: {
      name: "grpbxTotalPrchs",
      type: "GroupBoxWidget",
      parent: "grpbxPurchase",
      Height: "",
      Width: "",
      ColsForMobile: "2",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxTotalPrchs_PROPERTIES

      // END_USER_CODE-USER_grpbxTotalPrchs_PROPERTIES
    },
    grpbxTotalStrg: {
      name: "grpbxTotalStrg",
      type: "GroupBoxWidget",
      parent: "grpbxStorage",
      Height: "",
      Width: "",
      ColsForMobile: "2",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxTotalStrg_PROPERTIES

      // END_USER_CODE-USER_grpbxTotalStrg_PROPERTIES
    },
    lbl10s: {
      name: "lbl10s",
      type: "LabelWidget",
      parent: "grpbxStrgRow10",
      Label: "10:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lbl10s_PROPERTIES

      // END_USER_CODE-USER_lbl10s_PROPERTIES
    },
    lbl10: {
      name: "lbl10",
      type: "LabelWidget",
      parent: "grpbxPrchsRow10",
      Label: "10:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lbl10_PROPERTIES

      // END_USER_CODE-USER_lbl10_PROPERTIES
    },
    lbl11s: {
      name: "lbl11s",
      type: "LabelWidget",
      parent: "grpbxStrgRow11",
      Label: "11:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lbl11s_PROPERTIES

      // END_USER_CODE-USER_lbl11s_PROPERTIES
    },
    lbl11: {
      name: "lbl11",
      type: "LabelWidget",
      parent: "grpbxPrchsRow11",
      Label: "11:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lbl11_PROPERTIES

      // END_USER_CODE-USER_lbl11_PROPERTIES
    },
    lbl12s: {
      name: "lbl12s",
      type: "LabelWidget",
      parent: "grpbxStrgRow12",
      Label: "12:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lbl12s_PROPERTIES

      // END_USER_CODE-USER_lbl12s_PROPERTIES
    },
    lbl12: {
      name: "lbl12",
      type: "LabelWidget",
      parent: "grpbxPrchsRow12",
      Label: "12:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lbl12_PROPERTIES

      // END_USER_CODE-USER_lbl12_PROPERTIES
    },
    lbl13s: {
      name: "lbl13s",
      type: "LabelWidget",
      parent: "grpbxStrgRow13",
      Label: "13:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lbl13s_PROPERTIES

      // END_USER_CODE-USER_lbl13s_PROPERTIES
    },
    lbl13: {
      name: "lbl13",
      type: "LabelWidget",
      parent: "grpbxPrchsRow13",
      Label: "13:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lbl13_PROPERTIES

      // END_USER_CODE-USER_lbl13_PROPERTIES
    },
    lbl14s: {
      name: "lbl14s",
      type: "LabelWidget",
      parent: "grpbxStrgRow14",
      Label: "14:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lbl14s_PROPERTIES

      // END_USER_CODE-USER_lbl14s_PROPERTIES
    },
    lbl14: {
      name: "lbl14",
      type: "LabelWidget",
      parent: "grpbxPrchsRow14",
      Label: "14:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lbl14_PROPERTIES

      // END_USER_CODE-USER_lbl14_PROPERTIES
    },
    lbl15s: {
      name: "lbl15s",
      type: "LabelWidget",
      parent: "grpbxStrgRow15",
      Label: "15:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lbl15s_PROPERTIES

      // END_USER_CODE-USER_lbl15s_PROPERTIES
    },
    lbl15: {
      name: "lbl15",
      type: "LabelWidget",
      parent: "grpbxPrchsRow15",
      Label: "15:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lbl15_PROPERTIES

      // END_USER_CODE-USER_lbl15_PROPERTIES
    },
    lbl16s: {
      name: "lbl16s",
      type: "LabelWidget",
      parent: "grpbxStrgRow16",
      Label: "16:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lbl16s_PROPERTIES

      // END_USER_CODE-USER_lbl16s_PROPERTIES
    },
    lbl16: {
      name: "lbl16",
      type: "LabelWidget",
      parent: "grpbxPrchsRow16",
      Label: "16:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lbl16_PROPERTIES

      // END_USER_CODE-USER_lbl16_PROPERTIES
    },
    lbl17s: {
      name: "lbl17s",
      type: "LabelWidget",
      parent: "grpbxStrgRow17",
      Label: "17:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lbl17s_PROPERTIES

      // END_USER_CODE-USER_lbl17s_PROPERTIES
    },
    lbl17: {
      name: "lbl17",
      type: "LabelWidget",
      parent: "grpbxPrchsRow17",
      Label: "17:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lbl17_PROPERTIES

      // END_USER_CODE-USER_lbl17_PROPERTIES
    },
    lbl18s: {
      name: "lbl18s",
      type: "LabelWidget",
      parent: "grpbxStrgRow18",
      Label: "18:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lbl18s_PROPERTIES

      // END_USER_CODE-USER_lbl18s_PROPERTIES
    },
    lbl18: {
      name: "lbl18",
      type: "LabelWidget",
      parent: "grpbxPrchsRow18",
      Label: "18:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lbl18_PROPERTIES

      // END_USER_CODE-USER_lbl18_PROPERTIES
    },
    lbl19s: {
      name: "lbl19s",
      type: "LabelWidget",
      parent: "grpbxStrgRow19",
      Label: "19:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lbl19s_PROPERTIES

      // END_USER_CODE-USER_lbl19s_PROPERTIES
    },
    lbl19: {
      name: "lbl19",
      type: "LabelWidget",
      parent: "grpbxPrchsRow19",
      Label: "19:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lbl19_PROPERTIES

      // END_USER_CODE-USER_lbl19_PROPERTIES
    },
    lbl1s: {
      name: "lbl1s",
      type: "LabelWidget",
      parent: "grpbxStrgRow1",
      Label: "1:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lbl1s_PROPERTIES

      // END_USER_CODE-USER_lbl1s_PROPERTIES
    },
    lbl1: {
      name: "lbl1",
      type: "LabelWidget",
      parent: "grpbxPrchsRow1",
      Label: "1:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lbl1_PROPERTIES

      // END_USER_CODE-USER_lbl1_PROPERTIES
    },
    lbl20s: {
      name: "lbl20s",
      type: "LabelWidget",
      parent: "grpbxStrgRow20",
      Label: "20:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lbl20s_PROPERTIES

      // END_USER_CODE-USER_lbl20s_PROPERTIES
    },
    lbl20: {
      name: "lbl20",
      type: "LabelWidget",
      parent: "grpbxPrchsRow20",
      Label: "20:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lbl20_PROPERTIES

      // END_USER_CODE-USER_lbl20_PROPERTIES
    },
    lbl2s: {
      name: "lbl2s",
      type: "LabelWidget",
      parent: "grpbxStrgRow2",
      Label: "2:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lbl2s_PROPERTIES

      // END_USER_CODE-USER_lbl2s_PROPERTIES
    },
    lbl2: {
      name: "lbl2",
      type: "LabelWidget",
      parent: "grpbxPrchsRow2",
      Label: "2:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lbl2_PROPERTIES

      // END_USER_CODE-USER_lbl2_PROPERTIES
    },
    lbl3s: {
      name: "lbl3s",
      type: "LabelWidget",
      parent: "grpbxStrgRow3",
      Label: "3:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lbl3s_PROPERTIES

      // END_USER_CODE-USER_lbl3s_PROPERTIES
    },
    lbl3: {
      name: "lbl3",
      type: "LabelWidget",
      parent: "grpbxPrchsRow3",
      Label: "3:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lbl3_PROPERTIES

      // END_USER_CODE-USER_lbl3_PROPERTIES
    },
    lbl4s: {
      name: "lbl4s",
      type: "LabelWidget",
      parent: "grpbxStrgRow4",
      Label: "4:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lbl4s_PROPERTIES

      // END_USER_CODE-USER_lbl4s_PROPERTIES
    },
    lbl4: {
      name: "lbl4",
      type: "LabelWidget",
      parent: "grpbxPrchsRow4",
      Label: "4:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lbl4_PROPERTIES

      // END_USER_CODE-USER_lbl4_PROPERTIES
    },
    lbl5s: {
      name: "lbl5s",
      type: "LabelWidget",
      parent: "grpbxStrgRow5",
      Label: "5:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lbl5s_PROPERTIES

      // END_USER_CODE-USER_lbl5s_PROPERTIES
    },
    lbl5: {
      name: "lbl5",
      type: "LabelWidget",
      parent: "grpbxPrchsRow5",
      Label: "5:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lbl5_PROPERTIES

      // END_USER_CODE-USER_lbl5_PROPERTIES
    },
    lbl6s: {
      name: "lbl6s",
      type: "LabelWidget",
      parent: "grpbxStrgRow6",
      Label: "6:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lbl6s_PROPERTIES

      // END_USER_CODE-USER_lbl6s_PROPERTIES
    },
    lbl6: {
      name: "lbl6",
      type: "LabelWidget",
      parent: "grpbxPrchsRow6",
      Label: "6:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lbl6_PROPERTIES

      // END_USER_CODE-USER_lbl6_PROPERTIES
    },
    lbl7s: {
      name: "lbl7s",
      type: "LabelWidget",
      parent: "grpbxStrgRow7",
      Label: "7:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lbl7s_PROPERTIES

      // END_USER_CODE-USER_lbl7s_PROPERTIES
    },
    lbl7: {
      name: "lbl7",
      type: "LabelWidget",
      parent: "grpbxPrchsRow7",
      Label: "7:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lbl7_PROPERTIES

      // END_USER_CODE-USER_lbl7_PROPERTIES
    },
    lbl8s: {
      name: "lbl8s",
      type: "LabelWidget",
      parent: "grpbxStrgRow8",
      Label: "8:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lbl8s_PROPERTIES

      // END_USER_CODE-USER_lbl8s_PROPERTIES
    },
    lbl8: {
      name: "lbl8",
      type: "LabelWidget",
      parent: "grpbxPrchsRow8",
      Label: "8:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lbl8_PROPERTIES

      // END_USER_CODE-USER_lbl8_PROPERTIES
    },
    lbl9s: {
      name: "lbl9s",
      type: "LabelWidget",
      parent: "grpbxStrgRow9",
      Label: "9:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lbl9s_PROPERTIES

      // END_USER_CODE-USER_lbl9s_PROPERTIES
    },
    lbl9: {
      name: "lbl9",
      type: "LabelWidget",
      parent: "grpbxPrchsRow9",
      Label: "9:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lbl9_PROPERTIES

      // END_USER_CODE-USER_lbl9_PROPERTIES
    },
    lblPurchase: {
      name: "lblPurchase",
      type: "LabelWidget",
      parent: "grpbxPrchsLabels",
      Label: "Purchase",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPurchase_PROPERTIES

      // END_USER_CODE-USER_lblPurchase_PROPERTIES
    },
    lblShareAmountPrchs: {
      name: "lblShareAmountPrchs",
      type: "LabelWidget",
      parent: "grpbxPrchsLabels",
      Label: "Share Amount",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblShareAmountPrchs_PROPERTIES

      // END_USER_CODE-USER_lblShareAmountPrchs_PROPERTIES
    },
    lblShareAmountStrg: {
      name: "lblShareAmountStrg",
      type: "LabelWidget",
      parent: "grpbxStrgLabels",
      Label: "Share Amount",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblShareAmountStrg_PROPERTIES

      // END_USER_CODE-USER_lblShareAmountStrg_PROPERTIES
    },
    lblSharePctPrchs: {
      name: "lblSharePctPrchs",
      type: "LabelWidget",
      parent: "grpbxPrchsLabels",
      Label: "Share %",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblSharePctPrchs_PROPERTIES

      // END_USER_CODE-USER_lblSharePctPrchs_PROPERTIES
    },
    lblSharePctStrg: {
      name: "lblSharePctStrg",
      type: "LabelWidget",
      parent: "grpbxStrgLabels",
      Label: "Share %",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblSharePctStrg_PROPERTIES

      // END_USER_CODE-USER_lblSharePctStrg_PROPERTIES
    },
    lblStorage: {
      name: "lblStorage",
      type: "LabelWidget",
      parent: "grpbxStrgLabels",
      Label: "Storage",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblStorage_PROPERTIES

      // END_USER_CODE-USER_lblStorage_PROPERTIES
    },
    lblVendorPrchs: {
      name: "lblVendorPrchs",
      type: "LabelWidget",
      parent: "grpbxPrchsLabels",
      Label: "Vendor",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblVendorPrchs_PROPERTIES

      // END_USER_CODE-USER_lblVendorPrchs_PROPERTIES
    },
    lblVendorStrg: {
      name: "lblVendorStrg",
      type: "LabelWidget",
      parent: "grpbxStrgLabels",
      Label: "Vendor",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblVendorStrg_PROPERTIES

      // END_USER_CODE-USER_lblVendorStrg_PROPERTIES
    },
    txtShareAmount10s: {
      name: "txtShareAmount10s",
      type: "TextBoxWidget",
      parent: "grpbxStrgRow10",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtShareAmount10s_PROPERTIES

      // END_USER_CODE-USER_txtShareAmount10s_PROPERTIES
    },
    txtShareAmount10: {
      name: "txtShareAmount10",
      type: "TextBoxWidget",
      parent: "grpbxPrchsRow10",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtShareAmount10_PROPERTIES

      // END_USER_CODE-USER_txtShareAmount10_PROPERTIES
    },
    txtShareAmount11s: {
      name: "txtShareAmount11s",
      type: "TextBoxWidget",
      parent: "grpbxStrgRow11",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtShareAmount11s_PROPERTIES

      // END_USER_CODE-USER_txtShareAmount11s_PROPERTIES
    },
    txtShareAmount11: {
      name: "txtShareAmount11",
      type: "TextBoxWidget",
      parent: "grpbxPrchsRow11",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtShareAmount11_PROPERTIES

      // END_USER_CODE-USER_txtShareAmount11_PROPERTIES
    },
    txtShareAmount12s: {
      name: "txtShareAmount12s",
      type: "TextBoxWidget",
      parent: "grpbxStrgRow12",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtShareAmount12s_PROPERTIES

      // END_USER_CODE-USER_txtShareAmount12s_PROPERTIES
    },
    txtShareAmount12: {
      name: "txtShareAmount12",
      type: "TextBoxWidget",
      parent: "grpbxPrchsRow12",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtShareAmount12_PROPERTIES

      // END_USER_CODE-USER_txtShareAmount12_PROPERTIES
    },
    txtShareAmount13s: {
      name: "txtShareAmount13s",
      type: "TextBoxWidget",
      parent: "grpbxStrgRow13",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtShareAmount13s_PROPERTIES

      // END_USER_CODE-USER_txtShareAmount13s_PROPERTIES
    },
    txtShareAmount13: {
      name: "txtShareAmount13",
      type: "TextBoxWidget",
      parent: "grpbxPrchsRow13",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtShareAmount13_PROPERTIES

      // END_USER_CODE-USER_txtShareAmount13_PROPERTIES
    },
    txtShareAmount14s: {
      name: "txtShareAmount14s",
      type: "TextBoxWidget",
      parent: "grpbxStrgRow14",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtShareAmount14s_PROPERTIES

      // END_USER_CODE-USER_txtShareAmount14s_PROPERTIES
    },
    txtShareAmount14: {
      name: "txtShareAmount14",
      type: "TextBoxWidget",
      parent: "grpbxPrchsRow14",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtShareAmount14_PROPERTIES

      // END_USER_CODE-USER_txtShareAmount14_PROPERTIES
    },
    txtShareAmount15s: {
      name: "txtShareAmount15s",
      type: "TextBoxWidget",
      parent: "grpbxStrgRow15",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtShareAmount15s_PROPERTIES

      // END_USER_CODE-USER_txtShareAmount15s_PROPERTIES
    },
    txtShareAmount15: {
      name: "txtShareAmount15",
      type: "TextBoxWidget",
      parent: "grpbxPrchsRow15",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtShareAmount15_PROPERTIES

      // END_USER_CODE-USER_txtShareAmount15_PROPERTIES
    },
    txtShareAmount16s: {
      name: "txtShareAmount16s",
      type: "TextBoxWidget",
      parent: "grpbxStrgRow16",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtShareAmount16s_PROPERTIES

      // END_USER_CODE-USER_txtShareAmount16s_PROPERTIES
    },
    txtShareAmount16: {
      name: "txtShareAmount16",
      type: "TextBoxWidget",
      parent: "grpbxPrchsRow16",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtShareAmount16_PROPERTIES

      // END_USER_CODE-USER_txtShareAmount16_PROPERTIES
    },
    txtShareAmount17s: {
      name: "txtShareAmount17s",
      type: "TextBoxWidget",
      parent: "grpbxStrgRow17",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtShareAmount17s_PROPERTIES

      // END_USER_CODE-USER_txtShareAmount17s_PROPERTIES
    },
    txtShareAmount17: {
      name: "txtShareAmount17",
      type: "TextBoxWidget",
      parent: "grpbxPrchsRow17",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtShareAmount17_PROPERTIES

      // END_USER_CODE-USER_txtShareAmount17_PROPERTIES
    },
    txtShareAmount18s: {
      name: "txtShareAmount18s",
      type: "TextBoxWidget",
      parent: "grpbxStrgRow18",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtShareAmount18s_PROPERTIES

      // END_USER_CODE-USER_txtShareAmount18s_PROPERTIES
    },
    txtShareAmount18: {
      name: "txtShareAmount18",
      type: "TextBoxWidget",
      parent: "grpbxPrchsRow18",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtShareAmount18_PROPERTIES

      // END_USER_CODE-USER_txtShareAmount18_PROPERTIES
    },
    txtShareAmount19s: {
      name: "txtShareAmount19s",
      type: "TextBoxWidget",
      parent: "grpbxStrgRow19",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtShareAmount19s_PROPERTIES

      // END_USER_CODE-USER_txtShareAmount19s_PROPERTIES
    },
    txtShareAmount19: {
      name: "txtShareAmount19",
      type: "TextBoxWidget",
      parent: "grpbxPrchsRow19",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtShareAmount19_PROPERTIES

      // END_USER_CODE-USER_txtShareAmount19_PROPERTIES
    },
    txtShareAmount1s: {
      name: "txtShareAmount1s",
      type: "TextBoxWidget",
      parent: "grpbxStrgRow1",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtShareAmount1s_PROPERTIES

      // END_USER_CODE-USER_txtShareAmount1s_PROPERTIES
    },
    txtShareAmount1: {
      name: "txtShareAmount1",
      type: "TextBoxWidget",
      parent: "grpbxPrchsRow1",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtShareAmount1_PROPERTIES

      // END_USER_CODE-USER_txtShareAmount1_PROPERTIES
    },
    txtShareAmount20s: {
      name: "txtShareAmount20s",
      type: "TextBoxWidget",
      parent: "grpbxStrgRow20",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtShareAmount20s_PROPERTIES

      // END_USER_CODE-USER_txtShareAmount20s_PROPERTIES
    },
    txtShareAmount20: {
      name: "txtShareAmount20",
      type: "TextBoxWidget",
      parent: "grpbxPrchsRow20",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtShareAmount20_PROPERTIES

      // END_USER_CODE-USER_txtShareAmount20_PROPERTIES
    },
    txtShareAmount2s: {
      name: "txtShareAmount2s",
      type: "TextBoxWidget",
      parent: "grpbxStrgRow2",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtShareAmount2s_PROPERTIES

      // END_USER_CODE-USER_txtShareAmount2s_PROPERTIES
    },
    txtShareAmount2: {
      name: "txtShareAmount2",
      type: "TextBoxWidget",
      parent: "grpbxPrchsRow2",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtShareAmount2_PROPERTIES

      // END_USER_CODE-USER_txtShareAmount2_PROPERTIES
    },
    txtShareAmount3s: {
      name: "txtShareAmount3s",
      type: "TextBoxWidget",
      parent: "grpbxStrgRow3",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtShareAmount3s_PROPERTIES

      // END_USER_CODE-USER_txtShareAmount3s_PROPERTIES
    },
    txtShareAmount3: {
      name: "txtShareAmount3",
      type: "TextBoxWidget",
      parent: "grpbxPrchsRow3",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtShareAmount3_PROPERTIES

      // END_USER_CODE-USER_txtShareAmount3_PROPERTIES
    },
    txtShareAmount4s: {
      name: "txtShareAmount4s",
      type: "TextBoxWidget",
      parent: "grpbxStrgRow4",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtShareAmount4s_PROPERTIES

      // END_USER_CODE-USER_txtShareAmount4s_PROPERTIES
    },
    txtShareAmount4: {
      name: "txtShareAmount4",
      type: "TextBoxWidget",
      parent: "grpbxPrchsRow4",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtShareAmount4_PROPERTIES

      // END_USER_CODE-USER_txtShareAmount4_PROPERTIES
    },
    txtShareAmount5s: {
      name: "txtShareAmount5s",
      type: "TextBoxWidget",
      parent: "grpbxStrgRow5",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtShareAmount5s_PROPERTIES

      // END_USER_CODE-USER_txtShareAmount5s_PROPERTIES
    },
    txtShareAmount5: {
      name: "txtShareAmount5",
      type: "TextBoxWidget",
      parent: "grpbxPrchsRow5",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtShareAmount5_PROPERTIES

      // END_USER_CODE-USER_txtShareAmount5_PROPERTIES
    },
    txtShareAmount6s: {
      name: "txtShareAmount6s",
      type: "TextBoxWidget",
      parent: "grpbxStrgRow6",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtShareAmount6s_PROPERTIES

      // END_USER_CODE-USER_txtShareAmount6s_PROPERTIES
    },
    txtShareAmount6: {
      name: "txtShareAmount6",
      type: "TextBoxWidget",
      parent: "grpbxPrchsRow6",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtShareAmount6_PROPERTIES

      // END_USER_CODE-USER_txtShareAmount6_PROPERTIES
    },
    txtShareAmount7s: {
      name: "txtShareAmount7s",
      type: "TextBoxWidget",
      parent: "grpbxStrgRow7",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtShareAmount7s_PROPERTIES

      // END_USER_CODE-USER_txtShareAmount7s_PROPERTIES
    },
    txtShareAmount7: {
      name: "txtShareAmount7",
      type: "TextBoxWidget",
      parent: "grpbxPrchsRow7",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtShareAmount7_PROPERTIES

      // END_USER_CODE-USER_txtShareAmount7_PROPERTIES
    },
    txtShareAmount8s: {
      name: "txtShareAmount8s",
      type: "TextBoxWidget",
      parent: "grpbxStrgRow8",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtShareAmount8s_PROPERTIES

      // END_USER_CODE-USER_txtShareAmount8s_PROPERTIES
    },
    txtShareAmount8: {
      name: "txtShareAmount8",
      type: "TextBoxWidget",
      parent: "grpbxPrchsRow8",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtShareAmount8_PROPERTIES

      // END_USER_CODE-USER_txtShareAmount8_PROPERTIES
    },
    txtShareAmount9s: {
      name: "txtShareAmount9s",
      type: "TextBoxWidget",
      parent: "grpbxStrgRow9",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtShareAmount9s_PROPERTIES

      // END_USER_CODE-USER_txtShareAmount9s_PROPERTIES
    },
    txtShareAmount9: {
      name: "txtShareAmount9",
      type: "TextBoxWidget",
      parent: "grpbxPrchsRow9",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtShareAmount9_PROPERTIES

      // END_USER_CODE-USER_txtShareAmount9_PROPERTIES
    },
    txtSharePct10s: {
      name: "txtSharePct10s",
      type: "TextBoxWidget",
      parent: "grpbxStrgRow10",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct10s_PROPERTIES
      Enabled: false,
      formattingReqd: true,
      // END_USER_CODE-USER_txtSharePct10s_PROPERTIES
    },
    txtSharePct10: {
      name: "txtSharePct10",
      type: "TextBoxWidget",
      parent: "grpbxPrchsRow10",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct10_PROPERTIES
      Enabled: false,
      formattingReqd: true,
      // END_USER_CODE-USER_txtSharePct10_PROPERTIES
    },
    txtSharePct11s: {
      name: "txtSharePct11s",
      type: "TextBoxWidget",
      parent: "grpbxStrgRow11",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct11s_PROPERTIES
      Enabled: false,
      formattingReqd: true,
      // END_USER_CODE-USER_txtSharePct11s_PROPERTIES
    },
    txtSharePct11: {
      name: "txtSharePct11",
      type: "TextBoxWidget",
      parent: "grpbxPrchsRow11",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct11_PROPERTIES
      Enabled: false,
      formattingReqd: true,
      // END_USER_CODE-USER_txtSharePct11_PROPERTIES
    },
    txtSharePct12s: {
      name: "txtSharePct12s",
      type: "TextBoxWidget",
      parent: "grpbxStrgRow12",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct12s_PROPERTIES
      Enabled: false,
      formattingReqd: true,
      // END_USER_CODE-USER_txtSharePct12s_PROPERTIES
    },
    txtSharePct12: {
      name: "txtSharePct12",
      type: "TextBoxWidget",
      parent: "grpbxPrchsRow12",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct12_PROPERTIES
      Enabled: false,
      formattingReqd: true,
      // END_USER_CODE-USER_txtSharePct12_PROPERTIES
    },
    txtSharePct13s: {
      name: "txtSharePct13s",
      type: "TextBoxWidget",
      parent: "grpbxStrgRow13",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct13s_PROPERTIES
      Enabled: false,
      formattingReqd: true,
      // END_USER_CODE-USER_txtSharePct13s_PROPERTIES
    },
    txtSharePct13: {
      name: "txtSharePct13",
      type: "TextBoxWidget",
      parent: "grpbxPrchsRow13",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct13_PROPERTIES
      Enabled: false,
      formattingReqd: true,
      // END_USER_CODE-USER_txtSharePct13_PROPERTIES
    },
    txtSharePct14s: {
      name: "txtSharePct14s",
      type: "TextBoxWidget",
      parent: "grpbxStrgRow14",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct14s_PROPERTIES
      Enabled: false,
      formattingReqd: true,
      // END_USER_CODE-USER_txtSharePct14s_PROPERTIES
    },
    txtSharePct14: {
      name: "txtSharePct14",
      type: "TextBoxWidget",
      parent: "grpbxPrchsRow14",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct14_PROPERTIES
      Enabled: false,
      formattingReqd: true,
      // END_USER_CODE-USER_txtSharePct14_PROPERTIES
    },
    txtSharePct15s: {
      name: "txtSharePct15s",
      type: "TextBoxWidget",
      parent: "grpbxStrgRow15",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct15s_PROPERTIES
      Enabled: false,
      formattingReqd: true,
      // END_USER_CODE-USER_txtSharePct15s_PROPERTIES
    },
    txtSharePct15: {
      name: "txtSharePct15",
      type: "TextBoxWidget",
      parent: "grpbxPrchsRow15",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct15_PROPERTIES
      Enabled: false,
      formattingReqd: true,
      // END_USER_CODE-USER_txtSharePct15_PROPERTIES
    },
    txtSharePct16s: {
      name: "txtSharePct16s",
      type: "TextBoxWidget",
      parent: "grpbxStrgRow16",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct16s_PROPERTIES
      Enabled: false,
      formattingReqd: true,
      // END_USER_CODE-USER_txtSharePct16s_PROPERTIES
    },
    txtSharePct16: {
      name: "txtSharePct16",
      type: "TextBoxWidget",
      parent: "grpbxPrchsRow16",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct16_PROPERTIES
      Enabled: false,
      formattingReqd: true,
      // END_USER_CODE-USER_txtSharePct16_PROPERTIES
    },
    txtSharePct17s: {
      name: "txtSharePct17s",
      type: "TextBoxWidget",
      parent: "grpbxStrgRow17",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct17s_PROPERTIES
      Enabled: false,
      formattingReqd: true,
      // END_USER_CODE-USER_txtSharePct17s_PROPERTIES
    },
    txtSharePct17: {
      name: "txtSharePct17",
      type: "TextBoxWidget",
      parent: "grpbxPrchsRow17",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct17_PROPERTIES
      Enabled: false,
      formattingReqd: true,
      // END_USER_CODE-USER_txtSharePct17_PROPERTIES
    },
    txtSharePct18s: {
      name: "txtSharePct18s",
      type: "TextBoxWidget",
      parent: "grpbxStrgRow18",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct18s_PROPERTIES
      Enabled: false,
      formattingReqd: true,
      // END_USER_CODE-USER_txtSharePct18s_PROPERTIES
    },
    txtSharePct18: {
      name: "txtSharePct18",
      type: "TextBoxWidget",
      parent: "grpbxPrchsRow18",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct18_PROPERTIES
      Enabled: false,
      formattingReqd: true,
      // END_USER_CODE-USER_txtSharePct18_PROPERTIES
    },
    txtSharePct19s: {
      name: "txtSharePct19s",
      type: "TextBoxWidget",
      parent: "grpbxStrgRow19",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct19s_PROPERTIES
      Enabled: false,
      formattingReqd: true,
      // END_USER_CODE-USER_txtSharePct19s_PROPERTIES
    },
    txtSharePct19: {
      name: "txtSharePct19",
      type: "TextBoxWidget",
      parent: "grpbxPrchsRow19",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct19_PROPERTIES
      Enabled: false,
      formattingReqd: true,
      // END_USER_CODE-USER_txtSharePct19_PROPERTIES
    },
    txtSharePct1s: {
      name: "txtSharePct1s",
      type: "TextBoxWidget",
      parent: "grpbxStrgRow1",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct1s_PROPERTIES
      Enabled: false,
      formattingReqd: true,
      // END_USER_CODE-USER_txtSharePct1s_PROPERTIES
    },
    txtSharePct1: {
      name: "txtSharePct1",
      type: "TextBoxWidget",
      parent: "grpbxPrchsRow1",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct1_PROPERTIES
      Enabled: false,
      formattingReqd: true,
      // END_USER_CODE-USER_txtSharePct1_PROPERTIES
    },
    txtSharePct20s: {
      name: "txtSharePct20s",
      type: "TextBoxWidget",
      parent: "grpbxStrgRow20",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct20s_PROPERTIES
      Enabled: false,
      formattingReqd: true,
      // END_USER_CODE-USER_txtSharePct20s_PROPERTIES
    },
    txtSharePct20: {
      name: "txtSharePct20",
      type: "TextBoxWidget",
      parent: "grpbxPrchsRow20",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct20_PROPERTIES
      Enabled: false,
      formattingReqd: true,
      // END_USER_CODE-USER_txtSharePct20_PROPERTIES
    },
    txtSharePct2s: {
      name: "txtSharePct2s",
      type: "TextBoxWidget",
      parent: "grpbxStrgRow2",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct2s_PROPERTIES
      Enabled: false,
      formattingReqd: true,
      // END_USER_CODE-USER_txtSharePct2s_PROPERTIES
    },
    txtSharePct2: {
      name: "txtSharePct2",
      type: "TextBoxWidget",
      parent: "grpbxPrchsRow2",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct2_PROPERTIES
      Enabled: false,
      formattingReqd: true,
      // END_USER_CODE-USER_txtSharePct2_PROPERTIES
    },
    txtSharePct3s: {
      name: "txtSharePct3s",
      type: "TextBoxWidget",
      parent: "grpbxStrgRow3",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct3s_PROPERTIES
      Enabled: false,
      formattingReqd: true,
      // END_USER_CODE-USER_txtSharePct3s_PROPERTIES
    },
    txtSharePct3: {
      name: "txtSharePct3",
      type: "TextBoxWidget",
      parent: "grpbxPrchsRow3",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct3_PROPERTIES
      Enabled: false,
      formattingReqd: true,
      // END_USER_CODE-USER_txtSharePct3_PROPERTIES
    },
    txtSharePct4s: {
      name: "txtSharePct4s",
      type: "TextBoxWidget",
      parent: "grpbxStrgRow4",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct4s_PROPERTIES
      Enabled: false,
      formattingReqd: true,
      // END_USER_CODE-USER_txtSharePct4s_PROPERTIES
    },
    txtSharePct4: {
      name: "txtSharePct4",
      type: "TextBoxWidget",
      parent: "grpbxPrchsRow4",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct4_PROPERTIES
      Enabled: false,
      formattingReqd: true,
      // END_USER_CODE-USER_txtSharePct4_PROPERTIES
    },
    txtSharePct5s: {
      name: "txtSharePct5s",
      type: "TextBoxWidget",
      parent: "grpbxStrgRow5",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct5s_PROPERTIES
      Enabled: false,
      formattingReqd: true,
      // END_USER_CODE-USER_txtSharePct5s_PROPERTIES
    },
    txtSharePct5: {
      name: "txtSharePct5",
      type: "TextBoxWidget",
      parent: "grpbxPrchsRow5",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct5_PROPERTIES
      Enabled: false,
      formattingReqd: true,
      // END_USER_CODE-USER_txtSharePct5_PROPERTIES
    },
    txtSharePct6s: {
      name: "txtSharePct6s",
      type: "TextBoxWidget",
      parent: "grpbxStrgRow6",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct6s_PROPERTIES
      Enabled: false,
      formattingReqd: true,
      // END_USER_CODE-USER_txtSharePct6s_PROPERTIES
    },
    txtSharePct6: {
      name: "txtSharePct6",
      type: "TextBoxWidget",
      parent: "grpbxPrchsRow6",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct6_PROPERTIES
      Enabled: false,
      formattingReqd: true,
      // END_USER_CODE-USER_txtSharePct6_PROPERTIES
    },
    txtSharePct7s: {
      name: "txtSharePct7s",
      type: "TextBoxWidget",
      parent: "grpbxStrgRow7",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct7s_PROPERTIES
      Enabled: false,
      formattingReqd: true,
      // END_USER_CODE-USER_txtSharePct7s_PROPERTIES
    },
    txtSharePct7: {
      name: "txtSharePct7",
      type: "TextBoxWidget",
      parent: "grpbxPrchsRow7",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct7_PROPERTIES
      Enabled: false,
      formattingReqd: true,
      // END_USER_CODE-USER_txtSharePct7_PROPERTIES
    },
    txtSharePct8s: {
      name: "txtSharePct8s",
      type: "TextBoxWidget",
      parent: "grpbxStrgRow8",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct8s_PROPERTIES
      Enabled: false,
      formattingReqd: true,
      // END_USER_CODE-USER_txtSharePct8s_PROPERTIES
    },
    txtSharePct8: {
      name: "txtSharePct8",
      type: "TextBoxWidget",
      parent: "grpbxPrchsRow8",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct8_PROPERTIES
      Enabled: false,
      formattingReqd: true,
      // END_USER_CODE-USER_txtSharePct8_PROPERTIES
    },
    txtSharePct9s: {
      name: "txtSharePct9s",
      type: "TextBoxWidget",
      parent: "grpbxStrgRow9",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct9s_PROPERTIES
      Enabled: false,
      formattingReqd: true,
      // END_USER_CODE-USER_txtSharePct9s_PROPERTIES
    },
    txtSharePct9: {
      name: "txtSharePct9",
      type: "TextBoxWidget",
      parent: "grpbxPrchsRow9",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSharePct9_PROPERTIES
      Enabled: false,
      formattingReqd: true,
      // END_USER_CODE-USER_txtSharePct9_PROPERTIES
    },
    txtTotalShareAmounts: {
      name: "txtTotalShareAmounts",
      type: "TextBoxWidget",
      parent: "grpbxTotalStrg",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalShareAmounts_PROPERTIES

      // END_USER_CODE-USER_txtTotalShareAmounts_PROPERTIES
    },
    txtTotalShareAmount: {
      name: "txtTotalShareAmount",
      type: "TextBoxWidget",
      parent: "grpbxTotalPrchs",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalShareAmount_PROPERTIES

      // END_USER_CODE-USER_txtTotalShareAmount_PROPERTIES
    },
    txtTotalSharePcts: {
      name: "txtTotalSharePcts",
      type: "TextBoxWidget",
      parent: "grpbxTotalStrg",
      Label: "Total:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalSharePcts_PROPERTIES

      // END_USER_CODE-USER_txtTotalSharePcts_PROPERTIES
    },
    txtTotalSharePct: {
      name: "txtTotalSharePct",
      type: "TextBoxWidget",
      parent: "grpbxTotalPrchs",
      Label: "Total:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalSharePct_PROPERTIES

      // END_USER_CODE-USER_txtTotalSharePct_PROPERTIES
    },
    txtVendor10s: {
      name: "txtVendor10s",
      type: "TextBoxWidget",
      parent: "grpbxStrgRow10",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor10s_PROPERTIES

      // END_USER_CODE-USER_txtVendor10s_PROPERTIES
    },
    txtVendor10: {
      name: "txtVendor10",
      type: "TextBoxWidget",
      parent: "grpbxPrchsRow10",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor10_PROPERTIES

      // END_USER_CODE-USER_txtVendor10_PROPERTIES
    },
    txtVendor11s: {
      name: "txtVendor11s",
      type: "TextBoxWidget",
      parent: "grpbxStrgRow11",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor11s_PROPERTIES

      // END_USER_CODE-USER_txtVendor11s_PROPERTIES
    },
    txtVendor11: {
      name: "txtVendor11",
      type: "TextBoxWidget",
      parent: "grpbxPrchsRow11",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor11_PROPERTIES

      // END_USER_CODE-USER_txtVendor11_PROPERTIES
    },
    txtVendor12s: {
      name: "txtVendor12s",
      type: "TextBoxWidget",
      parent: "grpbxStrgRow12",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor12s_PROPERTIES

      // END_USER_CODE-USER_txtVendor12s_PROPERTIES
    },
    txtVendor12: {
      name: "txtVendor12",
      type: "TextBoxWidget",
      parent: "grpbxPrchsRow12",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor12_PROPERTIES

      // END_USER_CODE-USER_txtVendor12_PROPERTIES
    },
    txtVendor13s: {
      name: "txtVendor13s",
      type: "TextBoxWidget",
      parent: "grpbxStrgRow13",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor13s_PROPERTIES

      // END_USER_CODE-USER_txtVendor13s_PROPERTIES
    },
    txtVendor13: {
      name: "txtVendor13",
      type: "TextBoxWidget",
      parent: "grpbxPrchsRow13",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor13_PROPERTIES

      // END_USER_CODE-USER_txtVendor13_PROPERTIES
    },
    txtVendor14s: {
      name: "txtVendor14s",
      type: "TextBoxWidget",
      parent: "grpbxStrgRow14",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor14s_PROPERTIES

      // END_USER_CODE-USER_txtVendor14s_PROPERTIES
    },
    txtVendor14: {
      name: "txtVendor14",
      type: "TextBoxWidget",
      parent: "grpbxPrchsRow14",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor14_PROPERTIES

      // END_USER_CODE-USER_txtVendor14_PROPERTIES
    },
    txtVendor15s: {
      name: "txtVendor15s",
      type: "TextBoxWidget",
      parent: "grpbxStrgRow15",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor15s_PROPERTIES

      // END_USER_CODE-USER_txtVendor15s_PROPERTIES
    },
    txtVendor15: {
      name: "txtVendor15",
      type: "TextBoxWidget",
      parent: "grpbxPrchsRow15",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor15_PROPERTIES

      // END_USER_CODE-USER_txtVendor15_PROPERTIES
    },
    txtVendor16s: {
      name: "txtVendor16s",
      type: "TextBoxWidget",
      parent: "grpbxStrgRow16",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor16s_PROPERTIES

      // END_USER_CODE-USER_txtVendor16s_PROPERTIES
    },
    txtVendor16: {
      name: "txtVendor16",
      type: "TextBoxWidget",
      parent: "grpbxPrchsRow16",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor16_PROPERTIES

      // END_USER_CODE-USER_txtVendor16_PROPERTIES
    },
    txtVendor17s: {
      name: "txtVendor17s",
      type: "TextBoxWidget",
      parent: "grpbxStrgRow17",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor17s_PROPERTIES

      // END_USER_CODE-USER_txtVendor17s_PROPERTIES
    },
    txtVendor17: {
      name: "txtVendor17",
      type: "TextBoxWidget",
      parent: "grpbxPrchsRow17",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor17_PROPERTIES

      // END_USER_CODE-USER_txtVendor17_PROPERTIES
    },
    txtVendor18s: {
      name: "txtVendor18s",
      type: "TextBoxWidget",
      parent: "grpbxStrgRow18",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor18s_PROPERTIES

      // END_USER_CODE-USER_txtVendor18s_PROPERTIES
    },
    txtVendor18: {
      name: "txtVendor18",
      type: "TextBoxWidget",
      parent: "grpbxPrchsRow18",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor18_PROPERTIES

      // END_USER_CODE-USER_txtVendor18_PROPERTIES
    },
    txtVendor19s: {
      name: "txtVendor19s",
      type: "TextBoxWidget",
      parent: "grpbxStrgRow19",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor19s_PROPERTIES

      // END_USER_CODE-USER_txtVendor19s_PROPERTIES
    },
    txtVendor19: {
      name: "txtVendor19",
      type: "TextBoxWidget",
      parent: "grpbxPrchsRow19",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor19_PROPERTIES

      // END_USER_CODE-USER_txtVendor19_PROPERTIES
    },
    txtVendor1s: {
      name: "txtVendor1s",
      type: "TextBoxWidget",
      parent: "grpbxStrgRow1",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor1s_PROPERTIES

      // END_USER_CODE-USER_txtVendor1s_PROPERTIES
    },
    txtVendor1: {
      name: "txtVendor1",
      type: "TextBoxWidget",
      parent: "grpbxPrchsRow1",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor1_PROPERTIES

      // END_USER_CODE-USER_txtVendor1_PROPERTIES
    },
    txtVendor20s: {
      name: "txtVendor20s",
      type: "TextBoxWidget",
      parent: "grpbxStrgRow20",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor20s_PROPERTIES

      // END_USER_CODE-USER_txtVendor20s_PROPERTIES
    },
    txtVendor20: {
      name: "txtVendor20",
      type: "TextBoxWidget",
      parent: "grpbxPrchsRow20",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor20_PROPERTIES

      // END_USER_CODE-USER_txtVendor20_PROPERTIES
    },
    txtVendor2s: {
      name: "txtVendor2s",
      type: "TextBoxWidget",
      parent: "grpbxStrgRow2",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor2s_PROPERTIES

      // END_USER_CODE-USER_txtVendor2s_PROPERTIES
    },
    txtVendor2: {
      name: "txtVendor2",
      type: "TextBoxWidget",
      parent: "grpbxPrchsRow2",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor2_PROPERTIES

      // END_USER_CODE-USER_txtVendor2_PROPERTIES
    },
    txtVendor3s: {
      name: "txtVendor3s",
      type: "TextBoxWidget",
      parent: "grpbxStrgRow3",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor3s_PROPERTIES

      // END_USER_CODE-USER_txtVendor3s_PROPERTIES
    },
    txtVendor3: {
      name: "txtVendor3",
      type: "TextBoxWidget",
      parent: "grpbxPrchsRow3",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor3_PROPERTIES

      // END_USER_CODE-USER_txtVendor3_PROPERTIES
    },
    txtVendor4s: {
      name: "txtVendor4s",
      type: "TextBoxWidget",
      parent: "grpbxStrgRow4",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor4s_PROPERTIES

      // END_USER_CODE-USER_txtVendor4s_PROPERTIES
    },
    txtVendor4: {
      name: "txtVendor4",
      type: "TextBoxWidget",
      parent: "grpbxPrchsRow4",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor4_PROPERTIES

      // END_USER_CODE-USER_txtVendor4_PROPERTIES
    },
    txtVendor5s: {
      name: "txtVendor5s",
      type: "TextBoxWidget",
      parent: "grpbxStrgRow5",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor5s_PROPERTIES

      // END_USER_CODE-USER_txtVendor5s_PROPERTIES
    },
    txtVendor5: {
      name: "txtVendor5",
      type: "TextBoxWidget",
      parent: "grpbxPrchsRow5",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor5_PROPERTIES

      // END_USER_CODE-USER_txtVendor5_PROPERTIES
    },
    txtVendor6s: {
      name: "txtVendor6s",
      type: "TextBoxWidget",
      parent: "grpbxStrgRow6",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor6s_PROPERTIES

      // END_USER_CODE-USER_txtVendor6s_PROPERTIES
    },
    txtVendor6: {
      name: "txtVendor6",
      type: "TextBoxWidget",
      parent: "grpbxPrchsRow6",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor6_PROPERTIES

      // END_USER_CODE-USER_txtVendor6_PROPERTIES
    },
    txtVendor7s: {
      name: "txtVendor7s",
      type: "TextBoxWidget",
      parent: "grpbxStrgRow7",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor7s_PROPERTIES

      // END_USER_CODE-USER_txtVendor7s_PROPERTIES
    },
    txtVendor7: {
      name: "txtVendor7",
      type: "TextBoxWidget",
      parent: "grpbxPrchsRow7",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor7_PROPERTIES

      // END_USER_CODE-USER_txtVendor7_PROPERTIES
    },
    txtVendor8s: {
      name: "txtVendor8s",
      type: "TextBoxWidget",
      parent: "grpbxStrgRow8",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor8s_PROPERTIES

      // END_USER_CODE-USER_txtVendor8s_PROPERTIES
    },
    txtVendor8: {
      name: "txtVendor8",
      type: "TextBoxWidget",
      parent: "grpbxPrchsRow8",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor8_PROPERTIES

      // END_USER_CODE-USER_txtVendor8_PROPERTIES
    },
    txtVendor9s: {
      name: "txtVendor9s",
      type: "TextBoxWidget",
      parent: "grpbxStrgRow9",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor9s_PROPERTIES

      // END_USER_CODE-USER_txtVendor9s_PROPERTIES
    },
    txtVendor9: {
      name: "txtVendor9",
      type: "TextBoxWidget",
      parent: "grpbxPrchsRow9",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor9_PROPERTIES

      // END_USER_CODE-USER_txtVendor9_PROPERTIES
    },
    grpbxPremiumDeductionsVendorSplitsTrade: {
      name: "grpbxPremiumDeductionsVendorSplitsTrade",
      type: "GroupBoxWidget",
      parent: "PremiumDeductionsVendorSplitsTrade",
      Height: "",
      Width: "",
      ColsForMobile: "2",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxPremiumDeductionsVendorSplitsTrade_PROPERTIES

      // END_USER_CODE-USER_grpbxPremiumDeductionsVendorSplitsTrade_PROPERTIES
    },
    cmmndCntnrActions: {
      name: "cmmndCntnrActions",
      type: "CommandContainerWidget",
      parent: "PremiumDeductionsVendorSplitsTrade",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrActions_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "Settlements#PremiumDeductionTrade": {},
      },
      REVERSE: {
        "Settlements#PremiumDeductionTrade": {},
      },
    },
  };
  let _buttonServices = { PremiumDeductionTrade: {} };
  let _buttonNavigation = {
    // btnCancel: {
    //   DEFAULT: [
    //     "Settlements#PremiumDeductionTrade#DEFAULT#true#Click",
    //   ],
    // },
  };
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    hide(thisObj, "grpbxPurchase")
    hide(thisObj, "grpbxStorage")
    RECALC()
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  let lblPTotalPCT = 0
  let lblSTotalPCT = 0
  let lblPTotalAMT = 0
  let lblSTotalAMT = 0
  let lblPAMT = {}
  let lblSAMT = []
  let txtPPCT = 0
  let txtSPCT = 0
  let lblPVendor = ""
  let lblSVendor = ""

  // START_USER_CODE-USER_METHODS

  const RECALC = async () => {
    let purchaseData = getData(thisObj, 'VPTData-purchaseData')
    let storageData = getData(thisObj, 'VPTData-storeageData')
    let purchamt = getData(thisObj, 'VPTData-PtotalAmt')
    let strAmt = getData(thisObj, 'VPTData-StotalAmt')
    //Purchase Column
    if (purchaseData.length != 0) {
      //show(thisObj, "grpbxPurchase")
      for (var i = 0; i < purchaseData.length; i++) {
        enable(thisObj, 'txtSharePct' + (i + 1).toString());
        setValue(thisObj, 'txtVendor' + (i + 1).toString(), purchaseData[i].vendor)

        let purchData = purchaseData[i].sharePct === "" ? purchaseData[i].sharePct : Number(purchaseData[i].sharePct).toFixed(2)
        let storageData = purchaseData[i].shareAmt === "" ? purchaseData[i].shareAmt : Number(purchaseData[i].shareAmt).toFixed(2)
        if (purchaseData[i].sharePct === "" && purchaseData[i].shareAmt === "") {
          disable(thisObj, 'txtSharePct' + (i + 1).toString());
          disable(thisObj, 'txtShareAmount' + (i + 1).toString());
        }
        setValue(thisObj, 'txtSharePct' + (i + 1).toString(), purchData)
        setValue(thisObj, 'txtShareAmount' + (i + 1).toString(), storageData)

        lblPTotalPCT += Number(purchaseData[i].sharePct)
        lblPTotalAMT += Number(purchaseData[i].shareAmt)
        lblPAMT = (Number(lblPTotalAMT) * (Number(purchaseData[i].sharePct) * 0.01)).toFixed(2)
        txtPPCT = Number(purchaseData[i].sharePct)
        lblPVendor = purchaseData[i].vendor

        if (purchaseData[i].vendor === "") {
          lblPAMT = ""
          txtPPCT = ""
        }
      }
      if (purchamt == 0) {
        setValue(thisObj, "txtTotalShareAmount", "0.00")
      }
      else {
        setValue(thisObj, "txtTotalShareAmount", (purchamt))
        show(thisObj, "grpbxPurchase")
      }
      setValue(thisObj, "txtTotalSharePct", (lblPTotalPCT).toFixed(2))
    }

    // Storage column
    if (storageData.length != 0) {
      // show(thisObj, "grpbxStorage")
      for (var i = 0; i < storageData.length; i++) {
        enable(thisObj, 'txtSharePct' + (i + 1).toString() + 's');
        setValue(thisObj, 'txtVendor' + (i + 1).toString() + 's', storageData[i].vendor)

        let purchsData = storageData[i].sharePct === "" ? storageData[i].sharePct : Number(storageData[i].sharePct).toFixed(2)
        let storagesData = storageData[i].shareAmt === "" ? storageData[i].shareAmt : Number(storageData[i].shareAmt).toFixed(2)
        if (storageData[i].sharePct === "" && storageData[i].shareAmt === "") {
          disable(thisObj, 'txtSharePct' + (i + 1).toString() + 's');
          disable(thisObj, 'txtShareAmount' + (i + 1).toString() + 's');
        }
        setValue(thisObj, 'txtSharePct' + (i + 1).toString() + 's', purchsData)
        setValue(thisObj, 'txtShareAmount' + (i + 1).toString() + 's', storagesData)

        lblSTotalPCT += Number(storageData[i].sharePct)
        lblSTotalAMT += Number(storageData[i].shareAmt)
        lblSAMT = (Number(lblSTotalAMT) * (Number(storageData[i].sharePct) * 0.01)).toFixed(2)
        txtSPCT = Number(storageData[i].sharePct)
        lblSVendor = storageData[i].vendor

        if (storageData[i].vendor === "") {
          lblSAMT = ""
          txtSPCT = ""
        }
      }
      if (strAmt == 0) {
        setValue(thisObj, "txtTotalShareAmounts", "0.00")
      }
      else {
        setValue(thisObj, "txtTotalShareAmounts", (strAmt))
        show(thisObj, "grpbxStorage")
      }
      setValue(thisObj, "txtTotalSharePcts", (lblSTotalPCT).toFixed(2))
    }

    let okButtonRelatedFlagBoolean = getData(thisObj, "VPTData-cmdOk.Tag")
    if (okButtonRelatedFlagBoolean === "false" && okButtonRelatedFlagBoolean != undefined && okButtonRelatedFlagBoolean != null) {
      if (txtVendor1.value != "") {
        if ((txtTotalSharePct.value) != "100.00") {
          disable(thisObj, "btnOk")
          okButtonRelatedFlagBoolean = "true"
        }
        else {
          enable(thisObj, "btnOk")
        }
      }
      //Storage GroupBox
      if (txtVendor1s.value != "") {
        if ((txtTotalSharePcts.value) != "100.00") {
          disable(thisObj, "btnOk")
          okButtonRelatedFlagBoolean = "true"
        }
        else {
          enable(thisObj, "btnOk")
        }
      }
    }
  }

  const textBoxChange = async () => {
    //==============================================Purchase GroupBox=====================================================
    let TotalPCT = 0
    let purchaseDataonchange = getData(thisObj, 'VPTData-purchaseData')
    let storageDataonChange = getData(thisObj, 'VPTData-storeageData')
    let okButtonRelatedFlagBooleanChange = getData(thisObj, "VPTData-cmdOk.Tag")
    if (purchaseDataonchange.length > 0) {
      TotalPCT = Number(txtSharePct1.value) + Number(txtSharePct2.value) + Number(txtSharePct3.value) + Number(txtSharePct4.value) + Number(txtSharePct5.value) + Number(txtSharePct6.value) + Number(txtSharePct7.value) + Number(txtSharePct8.value) + Number(txtSharePct9.value) + Number(txtSharePct10.value) + Number(txtSharePct11.value) + Number(txtSharePct12.value) + Number(txtSharePct13.value) + Number(txtSharePct14.value) + Number(txtSharePct15.value) + Number(txtSharePct16.value) + Number(txtSharePct17.value) + Number(txtSharePct18.value) + Number(txtSharePct19.value) + Number(txtSharePct20.value)

      lblPTotalPCT = Number(TotalPCT)
      setValue(thisObj, "txtTotalSharePct", (lblPTotalPCT).toFixed(2))
      if ((txtTotalSharePct.value != "") && (okButtonRelatedFlagBooleanChange != "false")) {
        if (lblPTotalPCT != 100) {
          disable(thisObj, "btnOk")
        }
        else {
          enable(thisObj, "btnOk")
        }
      }
      if (okButtonRelatedFlagBooleanChange != "false") {
        if (txtShareAmount1.value != "") {
          var indivisualSharePctP1 = Number(txtSharePct1.value) * (Number(txtTotalShareAmount.value) / 100)
          setValue(thisObj, "txtShareAmount1", (indivisualSharePctP1).toFixed(2))
        }
        if (txtShareAmount2.value != "") {
          var indivisualSharePctP2 = Number(txtSharePct2.value) * (Number(txtTotalShareAmount.value) / 100)
          setValue(thisObj, "txtShareAmount2", (indivisualSharePctP2).toFixed(2))
        }
        if (txtShareAmount3.value != "") {
          var indivisualSharePctP3 = Number(txtSharePct3.value) * (Number(txtTotalShareAmount.value) / 100)
          setValue(thisObj, "txtShareAmount3", (indivisualSharePctP3).toFixed(2))
        }

        if (txtSharePct4.value != "") {
          var indivisualSharePctP4 = Number(txtSharePct4.value) * (Number(txtTotalShareAmount.value) / 100)
          setValue(thisObj, "txtShareAmount4", (indivisualSharePctP4).toFixed(2))
        }

        if (txtSharePct5.value != "") {
          var indivisualSharePctP5 = Number(txtSharePct5.value) * (Number(txtTotalShareAmount.value) / 100)
          setValue(thisObj, "txtShareAmount5", (indivisualSharePctP5).toFixed(2))
        }
        if (txtSharePct6.value != "") {
          var indivisualSharePctP6 = Number(txtSharePct6.value) * (Number(txtTotalShareAmount.value) / 100)
          setValue(thisObj, "txtShareAmount6", (indivisualSharePctP6).toFixed(2))
        }
        if (txtSharePct7.value != "") {
          var indivisualSharePctP7 = Number(txtSharePct7.value) * (Number(txtTotalShareAmount.value) / 100)
          setValue(thisObj, "txtShareAmount7", (indivisualSharePctP7).toFixed(2))
        }
        if (txtSharePct8.value != "") {
          var indivisualSharePctP8 = Number(txtSharePct8.value) * (Number(txtTotalShareAmount.value) / 100)
          setValue(thisObj, "txtShareAmount8", (indivisualSharePctP8).toFixed(2))
        }
        if (txtSharePct9.value != "") {
          var indivisualSharePctP9 = Number(txtSharePct9.value) * (Number(txtTotalShareAmount.value) / 100)
          setValue(thisObj, "txtShareAmount9", (indivisualSharePctP9).toFixed(2))
        }
        if (txtSharePct10.value != "") {
          var indivisualSharePctP10 = Number(txtSharePct10.value) * (Number(txtTotalShareAmount.value) / 100)
          setValue(thisObj, "txtShareAmount10", (indivisualSharePctP10).toFixed(2))
        }
        if (txtSharePct11.value != "") {
          var indivisualSharePctP11 = Number(txtSharePct11.value) * (Number(txtTotalShareAmount.value) / 100)
          setValue(thisObj, "txtShareAmount11", (indivisualSharePctP11).toFixed(2))
        }
        if (txtSharePct12.value != "") {
          var indivisualSharePctP12 = Number(txtSharePct12.value) * (Number(txtTotalShareAmount.value) / 100)
          setValue(thisObj, "txtShareAmount12", (indivisualSharePctP12).toFixed(2))
        }
        if (txtSharePct13.value != "") {
          var indivisualSharePctP13 = Number(txtSharePct13.value) * (Number(txtTotalShareAmount.value) / 100)
          setValue(thisObj, "txtShareAmount13", (indivisualSharePctP13).toFixed(2))
        }
        if (txtSharePct14.value != "") {
          var indivisualSharePctP14 = Number(txtSharePct14.value) * (Number(txtTotalShareAmount.value) / 100)
          setValue(thisObj, "txtShareAmount14", (indivisualSharePctP14).toFixed(2))
        }
        if (txtSharePct15.value != "") {
          var indivisualSharePctP15 = Number(txtSharePct15.value) * (Number(txtTotalShareAmount.value) / 100)
          setValue(thisObj, "txtShareAmount15", (indivisualSharePctP15).toFixed(2))
        }
        if (txtSharePct16.value != "") {
          var indivisualSharePctP16 = Number(txtSharePct16.value) * (Number(txtTotalShareAmount.value) / 100)
          setValue(thisObj, "txtShareAmount16", (indivisualSharePctP16).toFixed(2))
        }
        if (txtSharePct17.value != "") {
          var indivisualSharePctP17 = Number(txtSharePct17.value) * (Number(txtTotalShareAmount.value) / 100)
          setValue(thisObj, "txtShareAmount17", (indivisualSharePctP17).toFixed(2))
        }
        if (txtSharePct18.value != "") {
          var indivisualSharePctP18 = Number(txtSharePct18.value) * (Number(txtTotalShareAmount.value) / 100)
          setValue(thisObj, "txtShareAmount18", (indivisualSharePctP18).toFixed(2))
        }
        if (txtSharePct19.value != "") {
          var indivisualSharePctP19 = Number(txtSharePct19.value) * (Number(txtTotalShareAmount.value) / 100)
          setValue(thisObj, "txtShareAmount19", (indivisualSharePctP19).toFixed(2))
        }
        if (txtSharePct20.value != "") {
          var indivisualSharePctP20 = Number(txtSharePct20.value) * (Number(txtTotalShareAmount.value) / 100)
          setValue(thisObj, "txtShareAmount20", (indivisualSharePctP20).toFixed(2))
        }
      }
    }
    //===============================Storage GroupBox=======================================================
    let TotalSCT = 0
    if (storageDataonChange.length > 0) {
      TotalSCT = Number(txtSharePct1s.value) + Number(txtSharePct2s.value) + Number(txtSharePct3s.value) + Number(txtSharePct4s.value) + Number(txtSharePct5s.value) + Number(txtSharePct6s.value) + Number(txtSharePct7s.value) + Number(txtSharePct8s.value) + Number(txtSharePct9s.value) + Number(txtSharePct10s.value) + Number(txtSharePct11s.value) + Number(txtSharePct12s.value) + Number(txtSharePct13s.value) + Number(txtSharePct14s.value) + Number(txtSharePct15s.value) + Number(txtSharePct16s.value) + Number(txtSharePct17s.value) + Number(txtSharePct18s.value) + Number(txtSharePct19s.value) + Number(txtSharePct20s.value)

      lblSTotalPCT = Number(TotalSCT)
      setValue(thisObj, "txtTotalSharePcts", (lblSTotalPCT).toFixed(2))

      if ((txtVendor1s.value != "") && (okButtonRelatedFlagBooleanChange != "false")) {
        if (lblSTotalPCT != 100) {
          disable(thisObj, "btnOk")
        }
        else {
          enable(thisObj, "btnOk")
        }
      }
      if (okButtonRelatedFlagBooleanChange != "false") {

        if (txtShareAmount1s.value != "") {
          var indivisualSharePctP1 = Number(txtSharePct1s.value) * (Number(txtTotalShareAmounts.value) / 100)
          setValue(thisObj, "txtShareAmount1s", (indivisualSharePctP1).toFixed(2))
        }
        if (txtShareAmount2s.value != "") {
          var indivisualSharePctP2 = Number(txtSharePct2s.value) * (Number(txtTotalShareAmounts.value) / 100)
          setValue(thisObj, "txtShareAmount2s", (indivisualSharePctP2).toFixed(2))
        }
        if (txtShareAmount3s.value != "") {
          var indivisualSharePctP3 = Number(txtSharePct3s.value) * (Number(txtTotalShareAmounts.value) / 100)
          setValue(thisObj, "txtShareAmount3s", (indivisualSharePctP3).toFixed(2))
        }

        if (txtSharePct4s.value != "") {
          var indivisualSharePctP4 = Number(txtSharePct4s.value) * (Number(txtTotalShareAmounts.value) / 100)
          setValue(thisObj, "txtShareAmount4s", (indivisualSharePctP4).toFixed(2))
        }

        if (txtSharePct5s.value != "") {
          var indivisualSharePctP5 = Number(txtSharePct5s.value) * (Number(txtTotalShareAmounts.value) / 100)
          setValue(thisObj, "txtShareAmount5s", (indivisualSharePctP5).toFixed(2))
        }
        if (txtSharePct6s.value != "") {
          var indivisualSharePctP6 = Number(txtSharePct6s.value) * (Number(txtTotalShareAmounts.value) / 100)
          setValue(thisObj, "txtShareAmount6s", (indivisualSharePctP6).toFixed(2))
        }
        if (txtSharePct7s.value != "") {
          var indivisualSharePctP7 = Number(txtSharePct7s.value) * (Number(txtTotalShareAmounts.value) / 100)
          setValue(thisObj, "txtShareAmount7s", (indivisualSharePctP7).toFixed(2))
        }
        if (txtSharePct8s.value != "") {
          var indivisualSharePctP8 = Number(txtSharePct8s.value) * (Number(txtTotalShareAmounts.value) / 100)
          setValue(thisObj, "txtShareAmount8s", (indivisualSharePctP8).toFixed(2))
        }
        if (txtSharePct9s.value != "") {
          var indivisualSharePctP9 = Number(txtSharePct9s.value) * (Number(txtTotalShareAmounts.value) / 100)
          setValue(thisObj, "txtShareAmount9s", (indivisualSharePctP9).toFixed(2))
        }
        if (txtSharePct10s.value != "") {
          var indivisualSharePctP10 = Number(txtSharePct10s.value) * (Number(txtTotalShareAmounts.value) / 100)
          setValue(thisObj, "txtShareAmount10s", (indivisualSharePctP10).toFixed(2))
        }
        if (txtSharePct11s.value != "") {
          var indivisualSharePctP11 = Number(txtSharePct11s.value) * (Number(txtTotalShareAmounts.value) / 100)
          setValue(thisObj, "txtShareAmount11s", (indivisualSharePctP11).toFixed(2))
        }
        if (txtSharePct12s.value != "") {
          var indivisualSharePctP12 = Number(txtSharePct12s.value) * (Number(txtTotalShareAmounts.value) / 100)
          setValue(thisObj, "txtShareAmount12s", (indivisualSharePctP12).toFixed(2))
        }
        if (txtSharePct13s.value != "") {
          var indivisualSharePctP13 = Number(txtSharePct13s.value) * (Number(txtTotalShareAmounts.value) / 100)
          setValue(thisObj, "txtShareAmount13s", (indivisualSharePctP13).toFixed(2))
        }
        if (txtSharePct14s.value != "") {
          var indivisualSharePctP14 = Number(txtSharePct14s.value) * (Number(txtTotalShareAmounts.value) / 100)
          setValue(thisObj, "txtShareAmount14s", (indivisualSharePctP14).toFixed(2))
        }
        if (txtSharePct15s.value != "") {
          var indivisualSharePctP15 = Number(txtSharePct15s.value) * (Number(txtTotalShareAmounts.value) / 100)
          setValue(thisObj, "txtShareAmount15s", (indivisualSharePctP15).toFixed(2))
        }
        if (txtSharePct16s.value != "") {
          var indivisualSharePctP16 = Number(txtSharePct16s.value) * (Number(txtTotalShareAmounts.value) / 100)
          setValue(thisObj, "txtShareAmount16s", (indivisualSharePctP16).toFixed(2))
        }
        if (txtSharePct17s.value != "") {
          var indivisualSharePctP17 = Number(txtSharePct17s.value) * (Number(txtTotalShareAmounts.value) / 100)
          setValue(thisObj, "txtShareAmount17s", (indivisualSharePctP17).toFixed(2))
        }
        if (txtSharePct18s.value != "") {
          var indivisualSharePctP18 = Number(txtSharePct18s.value) * (Number(txtTotalShareAmounts.value) / 100)
          setValue(thisObj, "txtShareAmount18s", (indivisualSharePctP18).toFixed(2))
        }
        if (txtSharePct19s.value != "") {
          var indivisualSharePctP19 = Number(txtSharePct19s.value) * (Number(txtTotalShareAmounts.value) / 100)
          setValue(thisObj, "txtShareAmount19s", (indivisualSharePctP19).toFixed(2))
        }
        if (txtSharePct20s.value != "") {
          var indivisualSharePctP20 = Number(txtSharePct20s.value) * (Number(txtTotalShareAmounts.value) / 100)
          setValue(thisObj, "txtShareAmount20s", (indivisualSharePctP20).toFixed(2))
        }
      }
    }
    okButtonRelatedFlagBooleanChange = "true"
  }

  const textBlurEvent = async () => {
    //Purchase GroupBox
    let textBlur = 0
    let Textblur = 0
    for (var i = 0; i < 20; i++) {
      if (isEnabled(thisObj, 'txtSharePct' + (i + 1).toString()) == true) {
        textBlur = getValue(thisObj, 'txtSharePct' + (i + 1).toString())
        if (textBlur != undefined) {
          textBlur = Number(textBlur)
          Textblur = (textBlur).toFixed(2)
          setValue(thisObj, 'txtSharePct' + (i + 1).toString(), Textblur)
        }
      }
    }
    //Storage GroupBox
    for (var i = 0; i < 20; i++) {
      if (isEnabled(thisObj, 'txtSharePct' + (i + 1).toString() + 's') == true) {
        let textsBlur = getValue(thisObj, 'txtSharePct' + (i + 1).toString() + 's')
        let Textsblur = 0
        if (textsBlur != undefined) {
          textsBlur = Number(textsBlur)
          Textsblur = (textsBlur).toFixed(2)
          setValue(thisObj, 'txtSharePct' + (i + 1).toString() + 's', Textsblur)
        }
      }
    }
  }

  const onbtnOkClick = async () => {
    try {
      let jsforPremiumDeductionPurchaseTrade = []
      let jsforPremiumDeductionStorageTrade = []
      if ((txtTotalSharePct.value) != "100.00" && (getValue(thisObj, "txtVendor1") != undefined || getValue(thisObj, "txtVendor2") != undefined || getValue(thisObj, "txtVendor3") != undefined || getValue(thisObj, "txtVendor4") != undefined || getValue(thisObj, "txtVendor5") != undefined || getValue(thisObj, "txtVendor6") != undefined || getValue(thisObj, "txtVendor7") != undefined || getValue(thisObj, "txtVendor8") != undefined || getValue(thisObj, "txtVendor9") != undefined || getValue(thisObj, "txtVendor10") != undefined || getValue(thisObj, "txtVendor11") != undefined || getValue(thisObj, "txtVendor12") != undefined || getValue(thisObj, "txtVendor13") != undefined || getValue(thisObj, "txtVendor14") != undefined || getValue(thiObj, "txtVendor15") != undefined || getValue(thiObj, "txtVendor16") != undefined || getValue(thiObj, "txtVendor17") != undefined || getValue(thisObj, "txtVendor18") != undefined || getValue(thisObj, "txtVendor19") != undefined || getValue(thiObj, "txtVendor20") != undefined)) {
        alert("Purchase Percentage Must Be Equal To 100%")
        return
      }
      if ((txtTotalSharePcts.value) !== "100.00" && (txtVendor1s.value != "" || txtVendor2s.value != "" || txtVendor3s.value != "" || txtVendor4s.value != "" || txtVendor5s.value != "" || txtVendor5s.value != "" || txtVendor7s.value != "" || txtVendor8s.value != "" || txtVendor9s.value != "" || txtVendor10s.value != "" || txtVendor11s.value != "" || txtVendor12s.value != "" || txtVendor13s.value != "" || txtVendor14s.value != "" || txtVendor15s.value != "" || txtVendor16s.value != "" || txtVendor17s.value != "" || txtVendor18s.value != "" || txtVendor19s.value != "" || txtVendor20s.value != "")) {
        alert("Storage Percentage Must Be Equal To 100%")
        return
      }

      for (var i = 0; i <= 19; i++) {
        var sharePct = getValue(thisObj, 'txtSharePct' + (i + 1).toString())

        if (sharePct != undefined) {
          jsforPremiumDeductionPurchaseTrade.push({
            txtPPCT: Number(sharePct).toFixed(2),
            lblPAMT: (Number(txtTotalShareAmount.value) * (Number(sharePct) * 0.01)).toFixed(2)
          })
        }
      }

      for (var j = 0; j <= 19; j++) {
        var sharesct = getValue(thisObj, 'txtSharePct' + (j + 1).toString() + 's')

        if (sharesct != undefined) {
          jsforPremiumDeductionStorageTrade.push({
            txtSPCT: Number(sharesct).toFixed(2),
            lblSAMT: (Number(txtTotalShareAmounts.value) * (Number(sharesct) * 0.01)).toFixed(2)
          })
        }
      }

      setData(thisObj, "dataPassingPremiumDeductionsVendorsplitTradePurchase", jsforPremiumDeductionPurchaseTrade)
      setData(thisObj, "dataPassingPremiumDeductionsVendorsplitTradeStorage", jsforPremiumDeductionStorageTrade)
      setData(thisObj, 'PremiumDeductionVendorSplitsTradeClose', true)
      if (document.getElementById("Settlements_PremiumDeductionsVendorSplitsTradePopUp") != null) {
        document.getElementById("Settlements_PremiumDeductionsVendorSplitsTradePopUp").childNodes[0].click();
      }

    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error while click on onbtnokClick"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnOkClick = onbtnOkClick;

  const onbtnCancelClick = async () => {
    try {
      if (document.getElementById("Settlements_PremiumDeductionsVendorSplitsTradePopUp") != null) {
        document.getElementById("Settlements_PremiumDeductionsVendorSplitsTradePopUp").childNodes[0].click();
      }

    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error while click on onbtnCancelClick Button"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnCancelClick = onbtnCancelClick;

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_PremiumDeductionsVendorSplitsTrade*/}

              {/* END_USER_CODE-USER_BEFORE_PremiumDeductionsVendorSplitsTrade*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxPremiumDeductionsVendorSplitsTrade*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxPremiumDeductionsVendorSplitsTrade*/}

              <GroupBoxWidget
                conf={state.grpbxPremiumDeductionsVendorSplitsTrade}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_grpbxPurchase*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxPurchase*/}

                <GroupBoxWidget
                  conf={state.grpbxPurchase}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_grpbxPrchsLabels*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxPrchsLabels*/}

                  <GroupBoxWidget
                    conf={state.grpbxPrchsLabels}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_lblPurchase*/}

                    {/* END_USER_CODE-USER_BEFORE_lblPurchase*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblPurchase}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblPurchase*/}

                    {/* END_USER_CODE-USER_AFTER_lblPurchase*/}
                    {/* START_USER_CODE-USER_BEFORE_lblVendorPrchs*/}

                    {/* END_USER_CODE-USER_BEFORE_lblVendorPrchs*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblVendorPrchs}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblVendorPrchs*/}

                    {/* END_USER_CODE-USER_AFTER_lblVendorPrchs*/}
                    {/* START_USER_CODE-USER_BEFORE_lblSharePctPrchs*/}

                    {/* END_USER_CODE-USER_BEFORE_lblSharePctPrchs*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblSharePctPrchs}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblSharePctPrchs*/}

                    {/* END_USER_CODE-USER_AFTER_lblSharePctPrchs*/}
                    {/* START_USER_CODE-USER_BEFORE_lblShareAmountPrchs*/}

                    {/* END_USER_CODE-USER_BEFORE_lblShareAmountPrchs*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblShareAmountPrchs}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblShareAmountPrchs*/}

                    {/* END_USER_CODE-USER_AFTER_lblShareAmountPrchs*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxPrchsLabels*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxPrchsLabels*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxPrchsRow1*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxPrchsRow1*/}

                  <GroupBoxWidget
                    conf={state.grpbxPrchsRow1}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_lbl1*/}

                    {/* END_USER_CODE-USER_BEFORE_lbl1*/}

                    <LabelWidget
                      values={values}
                      conf={state.lbl1}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lbl1*/}

                    {/* END_USER_CODE-USER_AFTER_lbl1*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor1*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor1*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor1}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor1*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor1*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct1*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct1*/}

                    <TextBoxWidget
                      onChange={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBoxChange()
                      }
                      }
                      onBlur={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBlurEvent()
                      }
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct1}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct1*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct1*/}
                    {/* START_USER_CODE-USER_BEFORE_txtShareAmount1*/}

                    {/* END_USER_CODE-USER_BEFORE_txtShareAmount1*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtShareAmount1}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtShareAmount1*/}

                    {/* END_USER_CODE-USER_AFTER_txtShareAmount1*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxPrchsRow1*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxPrchsRow1*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxPrchsRow2*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxPrchsRow2*/}

                  <GroupBoxWidget
                    conf={state.grpbxPrchsRow2}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_lbl2*/}

                    {/* END_USER_CODE-USER_BEFORE_lbl2*/}

                    <LabelWidget
                      values={values}
                      conf={state.lbl2}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lbl2*/}

                    {/* END_USER_CODE-USER_AFTER_lbl2*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor2*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor2*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor2}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor2*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor2*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct2*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct2*/}

                    <TextBoxWidget
                      onChange={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBoxChange()
                      }
                      }
                      onBlur={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBlurEvent()
                      }
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct2}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct2*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct2*/}
                    {/* START_USER_CODE-USER_BEFORE_txtShareAmount2*/}

                    {/* END_USER_CODE-USER_BEFORE_txtShareAmount2*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtShareAmount2}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtShareAmount2*/}

                    {/* END_USER_CODE-USER_AFTER_txtShareAmount2*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxPrchsRow2*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxPrchsRow2*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxPrchsRow3*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxPrchsRow3*/}

                  <GroupBoxWidget
                    conf={state.grpbxPrchsRow3}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_lbl3*/}

                    {/* END_USER_CODE-USER_BEFORE_lbl3*/}

                    <LabelWidget
                      values={values}
                      conf={state.lbl3}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lbl3*/}

                    {/* END_USER_CODE-USER_AFTER_lbl3*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor3*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor3*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor3}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor3*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor3*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct3*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct3*/}

                    <TextBoxWidget
                      onChange={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBoxChange()
                      }
                      }
                      onBlur={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBlurEvent()
                      }
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct3}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct3*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct3*/}
                    {/* START_USER_CODE-USER_BEFORE_txtShareAmount3*/}

                    {/* END_USER_CODE-USER_BEFORE_txtShareAmount3*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtShareAmount3}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtShareAmount3*/}

                    {/* END_USER_CODE-USER_AFTER_txtShareAmount3*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxPrchsRow3*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxPrchsRow3*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxPrchsRow4*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxPrchsRow4*/}

                  <GroupBoxWidget
                    conf={state.grpbxPrchsRow4}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_lbl4*/}

                    {/* END_USER_CODE-USER_BEFORE_lbl4*/}

                    <LabelWidget
                      values={values}
                      conf={state.lbl4}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lbl4*/}

                    {/* END_USER_CODE-USER_AFTER_lbl4*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor4*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor4*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor4}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor4*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor4*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct4*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct4*/}

                    <TextBoxWidget
                      onChange={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBoxChange()
                      }
                      }
                      onBlur={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBlurEvent()
                      }
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct4}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct4*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct4*/}
                    {/* START_USER_CODE-USER_BEFORE_txtShareAmount4*/}

                    {/* END_USER_CODE-USER_BEFORE_txtShareAmount4*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtShareAmount4}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtShareAmount4*/}

                    {/* END_USER_CODE-USER_AFTER_txtShareAmount4*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxPrchsRow4*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxPrchsRow4*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxPrchsRow5*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxPrchsRow5*/}

                  <GroupBoxWidget
                    conf={state.grpbxPrchsRow5}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_lbl5*/}

                    {/* END_USER_CODE-USER_BEFORE_lbl5*/}

                    <LabelWidget
                      values={values}
                      conf={state.lbl5}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lbl5*/}

                    {/* END_USER_CODE-USER_AFTER_lbl5*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor5*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor5*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor5}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor5*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor5*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct5*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct5*/}

                    <TextBoxWidget
                      onChange={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBoxChange()
                      }
                      }
                      onBlur={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBlurEvent()
                      }
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct5}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct5*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct5*/}
                    {/* START_USER_CODE-USER_BEFORE_txtShareAmount5*/}

                    {/* END_USER_CODE-USER_BEFORE_txtShareAmount5*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtShareAmount5}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtShareAmount5*/}

                    {/* END_USER_CODE-USER_AFTER_txtShareAmount5*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxPrchsRow5*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxPrchsRow5*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxPrchsRow6*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxPrchsRow6*/}

                  <GroupBoxWidget
                    conf={state.grpbxPrchsRow6}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_lbl6*/}

                    {/* END_USER_CODE-USER_BEFORE_lbl6*/}

                    <LabelWidget
                      values={values}
                      conf={state.lbl6}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lbl6*/}

                    {/* END_USER_CODE-USER_AFTER_lbl6*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor6*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor6*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor6}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor6*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor6*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct6*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct6*/}

                    <TextBoxWidget
                      onChange={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBoxChange()
                      }
                      }
                      onBlur={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBlurEvent()
                      }
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct6}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct6*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct6*/}
                    {/* START_USER_CODE-USER_BEFORE_txtShareAmount6*/}

                    {/* END_USER_CODE-USER_BEFORE_txtShareAmount6*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtShareAmount6}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtShareAmount6*/}

                    {/* END_USER_CODE-USER_AFTER_txtShareAmount6*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxPrchsRow6*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxPrchsRow6*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxPrchsRow7*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxPrchsRow7*/}

                  <GroupBoxWidget
                    conf={state.grpbxPrchsRow7}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_lbl7*/}

                    {/* END_USER_CODE-USER_BEFORE_lbl7*/}

                    <LabelWidget
                      values={values}
                      conf={state.lbl7}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lbl7*/}

                    {/* END_USER_CODE-USER_AFTER_lbl7*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor7*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor7*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor7}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor7*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor7*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct7*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct7*/}

                    <TextBoxWidget
                      onChange={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBoxChange()
                      }
                      }
                      onBlur={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBlurEvent()
                      }
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct7}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct7*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct7*/}
                    {/* START_USER_CODE-USER_BEFORE_txtShareAmount7*/}

                    {/* END_USER_CODE-USER_BEFORE_txtShareAmount7*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtShareAmount7}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtShareAmount7*/}

                    {/* END_USER_CODE-USER_AFTER_txtShareAmount7*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxPrchsRow7*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxPrchsRow7*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxPrchsRow8*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxPrchsRow8*/}

                  <GroupBoxWidget
                    conf={state.grpbxPrchsRow8}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_lbl8*/}

                    {/* END_USER_CODE-USER_BEFORE_lbl8*/}

                    <LabelWidget
                      values={values}
                      conf={state.lbl8}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lbl8*/}

                    {/* END_USER_CODE-USER_AFTER_lbl8*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor8*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor8*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor8}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor8*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor8*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct8*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct8*/}

                    <TextBoxWidget
                      onChange={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBoxChange()
                      }
                      }
                      onBlur={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBlurEvent()
                      }
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct8}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct8*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct8*/}
                    {/* START_USER_CODE-USER_BEFORE_txtShareAmount8*/}

                    {/* END_USER_CODE-USER_BEFORE_txtShareAmount8*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtShareAmount8}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtShareAmount8*/}

                    {/* END_USER_CODE-USER_AFTER_txtShareAmount8*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxPrchsRow8*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxPrchsRow8*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxPrchsRow9*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxPrchsRow9*/}

                  <GroupBoxWidget
                    conf={state.grpbxPrchsRow9}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_lbl9*/}

                    {/* END_USER_CODE-USER_BEFORE_lbl9*/}

                    <LabelWidget
                      values={values}
                      conf={state.lbl9}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lbl9*/}

                    {/* END_USER_CODE-USER_AFTER_lbl9*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor9*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor9*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor9}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor9*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor9*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct9*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct9*/}

                    <TextBoxWidget
                      onChange={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBoxChange()
                      }
                      }
                      onBlur={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBlurEvent()
                      }
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct9}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct9*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct9*/}
                    {/* START_USER_CODE-USER_BEFORE_txtShareAmount9*/}

                    {/* END_USER_CODE-USER_BEFORE_txtShareAmount9*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtShareAmount9}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtShareAmount9*/}

                    {/* END_USER_CODE-USER_AFTER_txtShareAmount9*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxPrchsRow9*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxPrchsRow9*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxPrchsRow10*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxPrchsRow10*/}

                  <GroupBoxWidget
                    conf={state.grpbxPrchsRow10}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_lbl10*/}

                    {/* END_USER_CODE-USER_BEFORE_lbl10*/}

                    <LabelWidget
                      values={values}
                      conf={state.lbl10}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lbl10*/}

                    {/* END_USER_CODE-USER_AFTER_lbl10*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor10*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor10*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor10}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor10*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor10*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct10*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct10*/}

                    <TextBoxWidget
                      onChange={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBoxChange()
                      }
                      }
                      onBlur={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBlurEvent()
                      }
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct10}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct10*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct10*/}
                    {/* START_USER_CODE-USER_BEFORE_txtShareAmount10*/}

                    {/* END_USER_CODE-USER_BEFORE_txtShareAmount10*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtShareAmount10}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtShareAmount10*/}

                    {/* END_USER_CODE-USER_AFTER_txtShareAmount10*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxPrchsRow10*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxPrchsRow10*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxPrchsRow11*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxPrchsRow11*/}

                  <GroupBoxWidget
                    conf={state.grpbxPrchsRow11}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_lbl11*/}

                    {/* END_USER_CODE-USER_BEFORE_lbl11*/}

                    <LabelWidget
                      values={values}
                      conf={state.lbl11}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lbl11*/}

                    {/* END_USER_CODE-USER_AFTER_lbl11*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor11*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor11*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor11}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor11*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor11*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct11*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct11*/}

                    <TextBoxWidget
                      onChange={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBoxChange()
                      }
                      }
                      onBlur={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBlurEvent()
                      }
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct11}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct11*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct11*/}
                    {/* START_USER_CODE-USER_BEFORE_txtShareAmount11*/}

                    {/* END_USER_CODE-USER_BEFORE_txtShareAmount11*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtShareAmount11}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtShareAmount11*/}

                    {/* END_USER_CODE-USER_AFTER_txtShareAmount11*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxPrchsRow11*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxPrchsRow11*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxPrchsRow12*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxPrchsRow12*/}

                  <GroupBoxWidget
                    conf={state.grpbxPrchsRow12}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_lbl12*/}

                    {/* END_USER_CODE-USER_BEFORE_lbl12*/}

                    <LabelWidget
                      values={values}
                      conf={state.lbl12}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lbl12*/}

                    {/* END_USER_CODE-USER_AFTER_lbl12*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor12*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor12*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor12}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor12*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor12*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct12*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct12*/}

                    <TextBoxWidget
                      onChange={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBoxChange()
                      }
                      }
                      onBlur={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBlurEvent()
                      }
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct12}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct12*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct12*/}
                    {/* START_USER_CODE-USER_BEFORE_txtShareAmount12*/}

                    {/* END_USER_CODE-USER_BEFORE_txtShareAmount12*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtShareAmount12}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtShareAmount12*/}

                    {/* END_USER_CODE-USER_AFTER_txtShareAmount12*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxPrchsRow12*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxPrchsRow12*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxPrchsRow13*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxPrchsRow13*/}

                  <GroupBoxWidget
                    conf={state.grpbxPrchsRow13}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_lbl13*/}

                    {/* END_USER_CODE-USER_BEFORE_lbl13*/}

                    <LabelWidget
                      values={values}
                      conf={state.lbl13}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lbl13*/}

                    {/* END_USER_CODE-USER_AFTER_lbl13*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor13*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor13*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor13}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor13*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor13*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct13*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct13*/}

                    <TextBoxWidget
                      onChange={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBoxChange()
                      }
                      }
                      onBlur={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBlurEvent()
                      }
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct13}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct13*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct13*/}
                    {/* START_USER_CODE-USER_BEFORE_txtShareAmount13*/}

                    {/* END_USER_CODE-USER_BEFORE_txtShareAmount13*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtShareAmount13}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtShareAmount13*/}

                    {/* END_USER_CODE-USER_AFTER_txtShareAmount13*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxPrchsRow13*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxPrchsRow13*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxPrchsRow14*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxPrchsRow14*/}

                  <GroupBoxWidget
                    conf={state.grpbxPrchsRow14}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_lbl14*/}

                    {/* END_USER_CODE-USER_BEFORE_lbl14*/}

                    <LabelWidget
                      values={values}
                      conf={state.lbl14}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lbl14*/}

                    {/* END_USER_CODE-USER_AFTER_lbl14*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor14*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor14*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor14}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor14*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor14*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct14*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct14*/}

                    <TextBoxWidget
                      onChange={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBoxChange()
                      }
                      }
                      onBlur={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBlurEvent()
                      }
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct14}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct14*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct14*/}
                    {/* START_USER_CODE-USER_BEFORE_txtShareAmount14*/}

                    {/* END_USER_CODE-USER_BEFORE_txtShareAmount14*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtShareAmount14}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtShareAmount14*/}

                    {/* END_USER_CODE-USER_AFTER_txtShareAmount14*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxPrchsRow14*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxPrchsRow14*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxPrchsRow15*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxPrchsRow15*/}

                  <GroupBoxWidget
                    conf={state.grpbxPrchsRow15}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_lbl15*/}

                    {/* END_USER_CODE-USER_BEFORE_lbl15*/}

                    <LabelWidget
                      values={values}
                      conf={state.lbl15}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lbl15*/}

                    {/* END_USER_CODE-USER_AFTER_lbl15*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor15*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor15*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor15}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor15*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor15*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct15*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct15*/}

                    <TextBoxWidget
                      onChange={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBoxChange()
                      }
                      }
                      onBlur={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBlurEvent()
                      }
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct15}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct15*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct15*/}
                    {/* START_USER_CODE-USER_BEFORE_txtShareAmount15*/}

                    {/* END_USER_CODE-USER_BEFORE_txtShareAmount15*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtShareAmount15}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtShareAmount15*/}

                    {/* END_USER_CODE-USER_AFTER_txtShareAmount15*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxPrchsRow15*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxPrchsRow15*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxPrchsRow16*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxPrchsRow16*/}

                  <GroupBoxWidget
                    conf={state.grpbxPrchsRow16}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_lbl16*/}

                    {/* END_USER_CODE-USER_BEFORE_lbl16*/}

                    <LabelWidget
                      values={values}
                      conf={state.lbl16}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lbl16*/}

                    {/* END_USER_CODE-USER_AFTER_lbl16*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor16*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor16*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor16}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor16*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor16*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct16*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct16*/}

                    <TextBoxWidget
                      onChange={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBoxChange()
                      }
                      }
                      onBlur={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBlurEvent()
                      }
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct16}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct16*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct16*/}
                    {/* START_USER_CODE-USER_BEFORE_txtShareAmount16*/}

                    {/* END_USER_CODE-USER_BEFORE_txtShareAmount16*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtShareAmount16}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtShareAmount16*/}

                    {/* END_USER_CODE-USER_AFTER_txtShareAmount16*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxPrchsRow16*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxPrchsRow16*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxPrchsRow17*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxPrchsRow17*/}

                  <GroupBoxWidget
                    conf={state.grpbxPrchsRow17}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_lbl17*/}

                    {/* END_USER_CODE-USER_BEFORE_lbl17*/}

                    <LabelWidget
                      values={values}
                      conf={state.lbl17}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lbl17*/}

                    {/* END_USER_CODE-USER_AFTER_lbl17*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor17*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor17*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor17}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor17*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor17*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct17*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct17*/}

                    <TextBoxWidget
                      onChange={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBoxChange()
                      }
                      }
                      onBlur={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBlurEvent()
                      }
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct17}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct17*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct17*/}
                    {/* START_USER_CODE-USER_BEFORE_txtShareAmount17*/}

                    {/* END_USER_CODE-USER_BEFORE_txtShareAmount17*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtShareAmount17}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtShareAmount17*/}

                    {/* END_USER_CODE-USER_AFTER_txtShareAmount17*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxPrchsRow17*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxPrchsRow17*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxPrchsRow18*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxPrchsRow18*/}

                  <GroupBoxWidget
                    conf={state.grpbxPrchsRow18}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_lbl18*/}

                    {/* END_USER_CODE-USER_BEFORE_lbl18*/}

                    <LabelWidget
                      values={values}
                      conf={state.lbl18}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lbl18*/}

                    {/* END_USER_CODE-USER_AFTER_lbl18*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor18*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor18*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor18}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor18*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor18*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct18*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct18*/}

                    <TextBoxWidget
                      onChange={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBoxChange()
                      }
                      }
                      onBlur={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBlurEvent()
                      }
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct18}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct18*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct18*/}
                    {/* START_USER_CODE-USER_BEFORE_txtShareAmount18*/}

                    {/* END_USER_CODE-USER_BEFORE_txtShareAmount18*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtShareAmount18}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtShareAmount18*/}

                    {/* END_USER_CODE-USER_AFTER_txtShareAmount18*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxPrchsRow18*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxPrchsRow18*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxPrchsRow19*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxPrchsRow19*/}

                  <GroupBoxWidget
                    conf={state.grpbxPrchsRow19}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_lbl19*/}

                    {/* END_USER_CODE-USER_BEFORE_lbl19*/}

                    <LabelWidget
                      values={values}
                      conf={state.lbl19}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lbl19*/}

                    {/* END_USER_CODE-USER_AFTER_lbl19*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor19*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor19*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor19}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor19*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor19*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct19*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct19*/}

                    <TextBoxWidget
                      onChange={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBoxChange()
                      }
                      }
                      onBlur={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBlurEvent()
                      }
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct19}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct19*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct19*/}
                    {/* START_USER_CODE-USER_BEFORE_txtShareAmount19*/}

                    {/* END_USER_CODE-USER_BEFORE_txtShareAmount19*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtShareAmount19}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtShareAmount19*/}

                    {/* END_USER_CODE-USER_AFTER_txtShareAmount19*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxPrchsRow19*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxPrchsRow19*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxPrchsRow20*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxPrchsRow20*/}

                  <GroupBoxWidget
                    conf={state.grpbxPrchsRow20}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_lbl20*/}

                    {/* END_USER_CODE-USER_BEFORE_lbl20*/}

                    <LabelWidget
                      values={values}
                      conf={state.lbl20}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lbl20*/}

                    {/* END_USER_CODE-USER_AFTER_lbl20*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor20*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor20*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor20}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor20*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor20*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct20*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct20*/}

                    <TextBoxWidget
                      onChange={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBoxChange()
                      }
                      }
                      onBlur={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBlurEvent()
                      }
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct20}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct20*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct20*/}
                    {/* START_USER_CODE-USER_BEFORE_txtShareAmount20*/}

                    {/* END_USER_CODE-USER_BEFORE_txtShareAmount20*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtShareAmount20}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtShareAmount20*/}

                    {/* END_USER_CODE-USER_AFTER_txtShareAmount20*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxPrchsRow20*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxPrchsRow20*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxTotalPrchs*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxTotalPrchs*/}

                  <GroupBoxWidget
                    conf={state.grpbxTotalPrchs}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_txtTotalSharePct*/}

                    {/* END_USER_CODE-USER_BEFORE_txtTotalSharePct*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtTotalSharePct}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtTotalSharePct*/}

                    {/* END_USER_CODE-USER_AFTER_txtTotalSharePct*/}
                    {/* START_USER_CODE-USER_BEFORE_txtTotalShareAmount*/}

                    {/* END_USER_CODE-USER_BEFORE_txtTotalShareAmount*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtTotalShareAmount}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtTotalShareAmount*/}

                    {/* END_USER_CODE-USER_AFTER_txtTotalShareAmount*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxTotalPrchs*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxTotalPrchs*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxPurchase*/}

                {/* END_USER_CODE-USER_AFTER_grpbxPurchase*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxStorage*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxStorage*/}

                <GroupBoxWidget
                  conf={state.grpbxStorage}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_grpbxStrgLabels*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxStrgLabels*/}

                  <GroupBoxWidget
                    conf={state.grpbxStrgLabels}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_lblStorage*/}

                    {/* END_USER_CODE-USER_BEFORE_lblStorage*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblStorage}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblStorage*/}

                    {/* END_USER_CODE-USER_AFTER_lblStorage*/}
                    {/* START_USER_CODE-USER_BEFORE_lblVendorStrg*/}

                    {/* END_USER_CODE-USER_BEFORE_lblVendorStrg*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblVendorStrg}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblVendorStrg*/}

                    {/* END_USER_CODE-USER_AFTER_lblVendorStrg*/}
                    {/* START_USER_CODE-USER_BEFORE_lblSharePctStrg*/}

                    {/* END_USER_CODE-USER_BEFORE_lblSharePctStrg*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblSharePctStrg}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblSharePctStrg*/}

                    {/* END_USER_CODE-USER_AFTER_lblSharePctStrg*/}
                    {/* START_USER_CODE-USER_BEFORE_lblShareAmountStrg*/}

                    {/* END_USER_CODE-USER_BEFORE_lblShareAmountStrg*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblShareAmountStrg}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblShareAmountStrg*/}

                    {/* END_USER_CODE-USER_AFTER_lblShareAmountStrg*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxStrgLabels*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxStrgLabels*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxStrgRow1*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxStrgRow1*/}

                  <GroupBoxWidget
                    conf={state.grpbxStrgRow1}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_lbl1s*/}

                    {/* END_USER_CODE-USER_BEFORE_lbl1s*/}

                    <LabelWidget
                      values={values}
                      conf={state.lbl1s}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lbl1s*/}

                    {/* END_USER_CODE-USER_AFTER_lbl1s*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor1s*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor1s*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor1s}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor1s*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor1s*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct1s*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct1s*/}

                    <TextBoxWidget
                      onChange={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBoxChange()
                      }
                      }
                      onBlur={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBlurEvent()
                      }
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct1s}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct1s*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct1s*/}
                    {/* START_USER_CODE-USER_BEFORE_txtShareAmount1s*/}

                    {/* END_USER_CODE-USER_BEFORE_txtShareAmount1s*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtShareAmount1s}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtShareAmount1s*/}

                    {/* END_USER_CODE-USER_AFTER_txtShareAmount1s*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxStrgRow1*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxStrgRow1*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxStrgRow2*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxStrgRow2*/}

                  <GroupBoxWidget
                    conf={state.grpbxStrgRow2}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_lbl2s*/}

                    {/* END_USER_CODE-USER_BEFORE_lbl2s*/}

                    <LabelWidget
                      values={values}
                      conf={state.lbl2s}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lbl2s*/}

                    {/* END_USER_CODE-USER_AFTER_lbl2s*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor2s*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor2s*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor2s}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor2s*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor2s*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct2s*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct2s*/}

                    <TextBoxWidget
                      onChange={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBoxChange()
                      }
                      }
                      onBlur={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBlurEvent()
                      }
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct2s}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct2s*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct2s*/}
                    {/* START_USER_CODE-USER_BEFORE_txtShareAmount2s*/}

                    {/* END_USER_CODE-USER_BEFORE_txtShareAmount2s*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtShareAmount2s}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtShareAmount2s*/}

                    {/* END_USER_CODE-USER_AFTER_txtShareAmount2s*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxStrgRow2*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxStrgRow2*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxStrgRow3*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxStrgRow3*/}

                  <GroupBoxWidget
                    conf={state.grpbxStrgRow3}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_lbl3s*/}

                    {/* END_USER_CODE-USER_BEFORE_lbl3s*/}

                    <LabelWidget
                      values={values}
                      conf={state.lbl3s}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lbl3s*/}

                    {/* END_USER_CODE-USER_AFTER_lbl3s*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor3s*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor3s*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor3s}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor3s*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor3s*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct3s*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct3s*/}

                    <TextBoxWidget
                      onChange={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBoxChange()
                      }
                      }
                      onBlur={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBlurEvent()
                      }
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct3s}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct3s*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct3s*/}
                    {/* START_USER_CODE-USER_BEFORE_txtShareAmount3s*/}

                    {/* END_USER_CODE-USER_BEFORE_txtShareAmount3s*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtShareAmount3s}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtShareAmount3s*/}

                    {/* END_USER_CODE-USER_AFTER_txtShareAmount3s*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxStrgRow3*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxStrgRow3*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxStrgRow4*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxStrgRow4*/}

                  <GroupBoxWidget
                    conf={state.grpbxStrgRow4}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_lbl4s*/}

                    {/* END_USER_CODE-USER_BEFORE_lbl4s*/}

                    <LabelWidget
                      values={values}
                      conf={state.lbl4s}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lbl4s*/}

                    {/* END_USER_CODE-USER_AFTER_lbl4s*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor4s*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor4s*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor4s}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor4s*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor4s*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct4s*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct4s*/}

                    <TextBoxWidget
                      onChange={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBoxChange()
                      }
                      }
                      onBlur={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBlurEvent()
                      }
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct4s}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct4s*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct4s*/}
                    {/* START_USER_CODE-USER_BEFORE_txtShareAmount4s*/}

                    {/* END_USER_CODE-USER_BEFORE_txtShareAmount4s*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtShareAmount4s}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtShareAmount4s*/}

                    {/* END_USER_CODE-USER_AFTER_txtShareAmount4s*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxStrgRow4*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxStrgRow4*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxStrgRow5*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxStrgRow5*/}

                  <GroupBoxWidget
                    conf={state.grpbxStrgRow5}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_lbl5s*/}

                    {/* END_USER_CODE-USER_BEFORE_lbl5s*/}

                    <LabelWidget
                      values={values}
                      conf={state.lbl5s}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lbl5s*/}

                    {/* END_USER_CODE-USER_AFTER_lbl5s*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor5s*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor5s*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor5s}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor5s*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor5s*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct5s*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct5s*/}

                    <TextBoxWidget
                      onChange={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBoxChange()
                      }
                      }
                      onBlur={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBlurEvent()
                      }
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct5s}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct5s*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct5s*/}
                    {/* START_USER_CODE-USER_BEFORE_txtShareAmount5s*/}

                    {/* END_USER_CODE-USER_BEFORE_txtShareAmount5s*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtShareAmount5s}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtShareAmount5s*/}

                    {/* END_USER_CODE-USER_AFTER_txtShareAmount5s*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxStrgRow5*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxStrgRow5*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxStrgRow6*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxStrgRow6*/}

                  <GroupBoxWidget
                    conf={state.grpbxStrgRow6}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_lbl6s*/}

                    {/* END_USER_CODE-USER_BEFORE_lbl6s*/}

                    <LabelWidget
                      values={values}
                      conf={state.lbl6s}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lbl6s*/}

                    {/* END_USER_CODE-USER_AFTER_lbl6s*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor6s*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor6s*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor6s}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor6s*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor6s*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct6s*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct6s*/}

                    <TextBoxWidget
                      onChange={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBoxChange()
                      }
                      }
                      onBlur={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBlurEvent()
                      }
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct6s}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct6s*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct6s*/}
                    {/* START_USER_CODE-USER_BEFORE_txtShareAmount6s*/}

                    {/* END_USER_CODE-USER_BEFORE_txtShareAmount6s*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtShareAmount6s}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtShareAmount6s*/}

                    {/* END_USER_CODE-USER_AFTER_txtShareAmount6s*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxStrgRow6*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxStrgRow6*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxStrgRow7*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxStrgRow7*/}

                  <GroupBoxWidget
                    conf={state.grpbxStrgRow7}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_lbl7s*/}

                    {/* END_USER_CODE-USER_BEFORE_lbl7s*/}

                    <LabelWidget
                      values={values}
                      conf={state.lbl7s}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lbl7s*/}

                    {/* END_USER_CODE-USER_AFTER_lbl7s*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor7s*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor7s*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor7s}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor7s*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor7s*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct7s*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct7s*/}

                    <TextBoxWidget
                      onChange={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBoxChange()
                      }
                      }
                      onBlur={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBlurEvent()
                      }
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct7s}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct7s*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct7s*/}
                    {/* START_USER_CODE-USER_BEFORE_txtShareAmount7s*/}

                    {/* END_USER_CODE-USER_BEFORE_txtShareAmount7s*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtShareAmount7s}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtShareAmount7s*/}

                    {/* END_USER_CODE-USER_AFTER_txtShareAmount7s*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxStrgRow7*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxStrgRow7*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxStrgRow8*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxStrgRow8*/}

                  <GroupBoxWidget
                    conf={state.grpbxStrgRow8}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_lbl8s*/}

                    {/* END_USER_CODE-USER_BEFORE_lbl8s*/}

                    <LabelWidget
                      values={values}
                      conf={state.lbl8s}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lbl8s*/}

                    {/* END_USER_CODE-USER_AFTER_lbl8s*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor8s*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor8s*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor8s}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor8s*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor8s*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct8s*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct8s*/}

                    <TextBoxWidget
                      onChange={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBoxChange()
                      }
                      }
                      onBlur={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBlurEvent()
                      }
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct8s}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct8s*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct8s*/}
                    {/* START_USER_CODE-USER_BEFORE_txtShareAmount8s*/}

                    {/* END_USER_CODE-USER_BEFORE_txtShareAmount8s*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtShareAmount8s}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtShareAmount8s*/}

                    {/* END_USER_CODE-USER_AFTER_txtShareAmount8s*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxStrgRow8*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxStrgRow8*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxStrgRow9*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxStrgRow9*/}

                  <GroupBoxWidget
                    conf={state.grpbxStrgRow9}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_lbl9s*/}

                    {/* END_USER_CODE-USER_BEFORE_lbl9s*/}

                    <LabelWidget
                      values={values}
                      conf={state.lbl9s}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lbl9s*/}

                    {/* END_USER_CODE-USER_AFTER_lbl9s*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor9s*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor9s*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor9s}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor9s*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor9s*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct9s*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct9s*/}

                    <TextBoxWidget
                      onChange={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBoxChange()
                      }
                      }
                      onBlur={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBlurEvent()
                      }
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct9s}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct9s*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct9s*/}
                    {/* START_USER_CODE-USER_BEFORE_txtShareAmount9s*/}

                    {/* END_USER_CODE-USER_BEFORE_txtShareAmount9s*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtShareAmount9s}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtShareAmount9s*/}

                    {/* END_USER_CODE-USER_AFTER_txtShareAmount9s*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxStrgRow9*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxStrgRow9*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxStrgRow10*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxStrgRow10*/}

                  <GroupBoxWidget
                    conf={state.grpbxStrgRow10}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_lbl10s*/}

                    {/* END_USER_CODE-USER_BEFORE_lbl10s*/}

                    <LabelWidget
                      values={values}
                      conf={state.lbl10s}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lbl10s*/}

                    {/* END_USER_CODE-USER_AFTER_lbl10s*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor10s*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor10s*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor10s}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor10s*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor10s*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct10s*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct10s*/}

                    <TextBoxWidget
                      onChange={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBoxChange()
                      }
                      }
                      onBlur={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBlurEvent()
                      }
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct10s}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct10s*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct10s*/}
                    {/* START_USER_CODE-USER_BEFORE_txtShareAmount10s*/}

                    {/* END_USER_CODE-USER_BEFORE_txtShareAmount10s*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtShareAmount10s}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtShareAmount10s*/}

                    {/* END_USER_CODE-USER_AFTER_txtShareAmount10s*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxStrgRow10*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxStrgRow10*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxStrgRow11*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxStrgRow11*/}

                  <GroupBoxWidget
                    conf={state.grpbxStrgRow11}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_lbl11s*/}

                    {/* END_USER_CODE-USER_BEFORE_lbl11s*/}

                    <LabelWidget
                      values={values}
                      conf={state.lbl11s}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lbl11s*/}

                    {/* END_USER_CODE-USER_AFTER_lbl11s*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor11s*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor11s*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor11s}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor11s*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor11s*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct11s*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct11s*/}

                    <TextBoxWidget
                      onChange={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBoxChange()
                      }
                      }
                      onBlur={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBlurEvent()
                      }
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct11s}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct11s*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct11s*/}
                    {/* START_USER_CODE-USER_BEFORE_txtShareAmount11s*/}

                    {/* END_USER_CODE-USER_BEFORE_txtShareAmount11s*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtShareAmount11s}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtShareAmount11s*/}

                    {/* END_USER_CODE-USER_AFTER_txtShareAmount11s*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxStrgRow11*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxStrgRow11*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxStrgRow12*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxStrgRow12*/}

                  <GroupBoxWidget
                    conf={state.grpbxStrgRow12}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_lbl12s*/}

                    {/* END_USER_CODE-USER_BEFORE_lbl12s*/}

                    <LabelWidget
                      values={values}
                      conf={state.lbl12s}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lbl12s*/}

                    {/* END_USER_CODE-USER_AFTER_lbl12s*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor12s*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor12s*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor12s}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor12s*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor12s*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct12s*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct12s*/}

                    <TextBoxWidget
                      onChange={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBoxChange()
                      }
                      }
                      onBlur={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBlurEvent()
                      }
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct12s}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct12s*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct12s*/}
                    {/* START_USER_CODE-USER_BEFORE_txtShareAmount12s*/}

                    {/* END_USER_CODE-USER_BEFORE_txtShareAmount12s*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtShareAmount12s}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtShareAmount12s*/}

                    {/* END_USER_CODE-USER_AFTER_txtShareAmount12s*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxStrgRow12*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxStrgRow12*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxStrgRow13*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxStrgRow13*/}

                  <GroupBoxWidget
                    conf={state.grpbxStrgRow13}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_lbl13s*/}

                    {/* END_USER_CODE-USER_BEFORE_lbl13s*/}

                    <LabelWidget
                      values={values}
                      conf={state.lbl13s}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lbl13s*/}

                    {/* END_USER_CODE-USER_AFTER_lbl13s*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor13s*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor13s*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor13s}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor13s*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor13s*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct13s*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct13s*/}

                    <TextBoxWidget
                      onChange={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBoxChange()
                      }
                      }
                      onBlur={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBlurEvent()
                      }
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct13s}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct13s*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct13s*/}
                    {/* START_USER_CODE-USER_BEFORE_txtShareAmount13s*/}

                    {/* END_USER_CODE-USER_BEFORE_txtShareAmount13s*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtShareAmount13s}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtShareAmount13s*/}

                    {/* END_USER_CODE-USER_AFTER_txtShareAmount13s*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxStrgRow13*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxStrgRow13*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxStrgRow14*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxStrgRow14*/}

                  <GroupBoxWidget
                    conf={state.grpbxStrgRow14}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_lbl14s*/}

                    {/* END_USER_CODE-USER_BEFORE_lbl14s*/}

                    <LabelWidget
                      values={values}
                      conf={state.lbl14s}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lbl14s*/}

                    {/* END_USER_CODE-USER_AFTER_lbl14s*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor14s*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor14s*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor14s}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor14s*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor14s*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct14s*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct14s*/}

                    <TextBoxWidget
                      onChange={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBoxChange()
                      }
                      }
                      onBlur={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBlurEvent()
                      }
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct14s}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct14s*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct14s*/}
                    {/* START_USER_CODE-USER_BEFORE_txtShareAmount14s*/}

                    {/* END_USER_CODE-USER_BEFORE_txtShareAmount14s*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtShareAmount14s}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtShareAmount14s*/}

                    {/* END_USER_CODE-USER_AFTER_txtShareAmount14s*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxStrgRow14*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxStrgRow14*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxStrgRow15*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxStrgRow15*/}

                  <GroupBoxWidget
                    conf={state.grpbxStrgRow15}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_lbl15s*/}

                    {/* END_USER_CODE-USER_BEFORE_lbl15s*/}

                    <LabelWidget
                      values={values}
                      conf={state.lbl15s}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lbl15s*/}

                    {/* END_USER_CODE-USER_AFTER_lbl15s*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor15s*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor15s*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor15s}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor15s*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor15s*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct15s*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct15s*/}

                    <TextBoxWidget
                      onChange={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBoxChange()
                      }
                      }
                      onBlur={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBlurEvent()
                      }
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct15s}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct15s*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct15s*/}
                    {/* START_USER_CODE-USER_BEFORE_txtShareAmount15s*/}

                    {/* END_USER_CODE-USER_BEFORE_txtShareAmount15s*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtShareAmount15s}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtShareAmount15s*/}

                    {/* END_USER_CODE-USER_AFTER_txtShareAmount15s*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxStrgRow15*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxStrgRow15*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxStrgRow16*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxStrgRow16*/}

                  <GroupBoxWidget
                    conf={state.grpbxStrgRow16}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_lbl16s*/}

                    {/* END_USER_CODE-USER_BEFORE_lbl16s*/}

                    <LabelWidget
                      values={values}
                      conf={state.lbl16s}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lbl16s*/}

                    {/* END_USER_CODE-USER_AFTER_lbl16s*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor16s*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor16s*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor16s}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor16s*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor16s*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct16s*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct16s*/}

                    <TextBoxWidget
                      onChange={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBoxChange()
                      }
                      }
                      onBlur={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBlurEvent()
                      }
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct16s}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct16s*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct16s*/}
                    {/* START_USER_CODE-USER_BEFORE_txtShareAmount16s*/}

                    {/* END_USER_CODE-USER_BEFORE_txtShareAmount16s*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtShareAmount16s}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtShareAmount16s*/}

                    {/* END_USER_CODE-USER_AFTER_txtShareAmount16s*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxStrgRow16*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxStrgRow16*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxStrgRow17*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxStrgRow17*/}

                  <GroupBoxWidget
                    conf={state.grpbxStrgRow17}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_lbl17s*/}

                    {/* END_USER_CODE-USER_BEFORE_lbl17s*/}

                    <LabelWidget
                      values={values}
                      conf={state.lbl17s}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lbl17s*/}

                    {/* END_USER_CODE-USER_AFTER_lbl17s*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor17s*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor17s*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor17s}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor17s*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor17s*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct17s*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct17s*/}

                    <TextBoxWidget
                      onChange={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBoxChange()
                      }
                      }
                      onBlur={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBlurEvent()
                      }
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct17s}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct17s*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct17s*/}
                    {/* START_USER_CODE-USER_BEFORE_txtShareAmount17s*/}

                    {/* END_USER_CODE-USER_BEFORE_txtShareAmount17s*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtShareAmount17s}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtShareAmount17s*/}

                    {/* END_USER_CODE-USER_AFTER_txtShareAmount17s*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxStrgRow17*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxStrgRow17*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxStrgRow18*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxStrgRow18*/}

                  <GroupBoxWidget
                    conf={state.grpbxStrgRow18}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_lbl18s*/}

                    {/* END_USER_CODE-USER_BEFORE_lbl18s*/}

                    <LabelWidget
                      values={values}
                      conf={state.lbl18s}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lbl18s*/}

                    {/* END_USER_CODE-USER_AFTER_lbl18s*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor18s*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor18s*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor18s}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor18s*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor18s*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct18s*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct18s*/}

                    <TextBoxWidget
                      onChange={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBoxChange()
                      }
                      }
                      onBlur={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBlurEvent()
                      }
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct18s}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct18s*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct18s*/}
                    {/* START_USER_CODE-USER_BEFORE_txtShareAmount18s*/}

                    {/* END_USER_CODE-USER_BEFORE_txtShareAmount18s*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtShareAmount18s}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtShareAmount18s*/}

                    {/* END_USER_CODE-USER_AFTER_txtShareAmount18s*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxStrgRow18*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxStrgRow18*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxStrgRow19*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxStrgRow19*/}

                  <GroupBoxWidget
                    conf={state.grpbxStrgRow19}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_lbl19s*/}

                    {/* END_USER_CODE-USER_BEFORE_lbl19s*/}

                    <LabelWidget
                      values={values}
                      conf={state.lbl19s}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lbl19s*/}

                    {/* END_USER_CODE-USER_AFTER_lbl19s*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor19s*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor19s*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor19s}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor19s*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor19s*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct19s*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct19s*/}

                    <TextBoxWidget
                      onChange={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBoxChange()
                      }
                      }
                      onBlur={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBlurEvent()
                      }
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct19s}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct19s*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct19s*/}
                    {/* START_USER_CODE-USER_BEFORE_txtShareAmount19s*/}

                    {/* END_USER_CODE-USER_BEFORE_txtShareAmount19s*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtShareAmount19s}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtShareAmount19s*/}

                    {/* END_USER_CODE-USER_AFTER_txtShareAmount19s*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxStrgRow19*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxStrgRow19*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxStrgRow20*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxStrgRow20*/}

                  <GroupBoxWidget
                    conf={state.grpbxStrgRow20}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_lbl20s*/}

                    {/* END_USER_CODE-USER_BEFORE_lbl20s*/}

                    <LabelWidget
                      values={values}
                      conf={state.lbl20s}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lbl20s*/}

                    {/* END_USER_CODE-USER_AFTER_lbl20s*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor20s*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor20s*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor20s}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor20s*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor20s*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSharePct20s*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSharePct20s*/}

                    <TextBoxWidget
                      onChange={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBoxChange()
                      }
                      }
                      onBlur={event => {
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                        textBlurEvent()
                      }
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSharePct20s}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSharePct20s*/}

                    {/* END_USER_CODE-USER_AFTER_txtSharePct20s*/}
                    {/* START_USER_CODE-USER_BEFORE_txtShareAmount20s*/}

                    {/* END_USER_CODE-USER_BEFORE_txtShareAmount20s*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtShareAmount20s}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtShareAmount20s*/}

                    {/* END_USER_CODE-USER_AFTER_txtShareAmount20s*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxStrgRow20*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxStrgRow20*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxTotalStrg*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxTotalStrg*/}

                  <GroupBoxWidget
                    conf={state.grpbxTotalStrg}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_txtTotalSharePcts*/}

                    {/* END_USER_CODE-USER_BEFORE_txtTotalSharePcts*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtTotalSharePcts}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtTotalSharePcts*/}

                    {/* END_USER_CODE-USER_AFTER_txtTotalSharePcts*/}
                    {/* START_USER_CODE-USER_BEFORE_txtTotalShareAmounts*/}

                    {/* END_USER_CODE-USER_BEFORE_txtTotalShareAmounts*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtTotalShareAmounts}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtTotalShareAmounts*/}

                    {/* END_USER_CODE-USER_AFTER_txtTotalShareAmounts*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxTotalStrg*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxTotalStrg*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxStorage*/}

                {/* END_USER_CODE-USER_AFTER_grpbxStorage*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxPremiumDeductionsVendorSplitsTrade*/}

              {/* END_USER_CODE-USER_AFTER_grpbxPremiumDeductionsVendorSplitsTrade*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

              <CommandContainerWidget
                conf={state.cmmndCntnrActions}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnOk*/}

                {/* END_USER_CODE-USER_BEFORE_btnOk*/}

                <ButtonWidget
                  conf={state.btnOk}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnOk*/}

                {/* END_USER_CODE-USER_AFTER_btnOk*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* START_USER_CODE-USER_AFTER_PremiumDeductionsVendorSplitsTrade*/}

              {/* END_USER_CODE-USER_AFTER_PremiumDeductionsVendorSplitsTrade*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(Settlements_PremiumDeductionsVendorSplitsTrade);
