/* eslint-disable*/
import React from "react";
import SystemMaintenanceApplicationSupport_OpenAccountAdjustments from "./OpenAccountAdjustments";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("OpenAccountAdjustments Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceApplicationSupport_OpenAccountAdjustments />
      );
    });
  });
  afterEach(cleanup);
  test("is OpenAccountAdjustments Loads Successfully", () => {
    expect(screen.getByText("OpenAccountAdjustments")).toBeInTheDocument;
  });
  test("Custom Test Cases for OpenAccountAdjustments", () => {
    // START_USER_CODE-USER_OpenAccountAdjustments_Custom_Test_Case
    // END_USER_CODE-USER_OpenAccountAdjustments_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(
        <SystemMaintenanceApplicationSupport_OpenAccountAdjustments />
      );
    });
  });
  afterEach(cleanup);
  test("btn1(Button Widget) Test Cases", async () => {
    const btn1 = screen.getByTestId("btn1");
    expect(btn1).toBeInTheDocument;
    expect(btn1.textContent).toEqual(
      t("SystemMaintenanceApplicationSupport:OpenAccountAdjustments_btn1")
    );
  });
  test("Custom Test Cases for btn1", () => {
    // START_USER_CODE-USER_btn1_TEST
    // END_USER_CODE-USER_btn1_TEST
  });
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:OpenAccountAdjustments_btnClose"
      )
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnExportToExcel(Button Widget) Test Cases", async () => {
    const btnExportToExcel = screen.getByTestId("btnExportToExcel");
    expect(btnExportToExcel).toBeInTheDocument;
    expect(btnExportToExcel.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:OpenAccountAdjustments_btnExportToExcel"
      )
    );
  });
  test("Custom Test Cases for btnExportToExcel", () => {
    // START_USER_CODE-USER_btnExportToExcel_TEST
    // END_USER_CODE-USER_btnExportToExcel_TEST
  });
  test("btnPrint(Button Widget) Test Cases", async () => {
    const btnPrint = screen.getByTestId("btnPrint");
    expect(btnPrint).toBeInTheDocument;
    expect(btnPrint.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:OpenAccountAdjustments_btnPrint"
      )
    );
  });
  test("Custom Test Cases for btnPrint", () => {
    // START_USER_CODE-USER_btnPrint_TEST
    // END_USER_CODE-USER_btnPrint_TEST
  });
  test("gridAdjustments(Grid Widget) Test Cases", async () => {
    let gridAdjustments = screen.getByTestId("gridAdjustments");
    let gridAdjustmentsbtn =
      gridAdjustments.nextElementSibling.firstElementChild;
    gridAdjustments =
      gridAdjustments.parentElement.parentElement.parentElement;
    expect(gridAdjustments.tagName).toBe("DIV");
    expect(gridAdjustments.classList).toContain("GridWidget");
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:OpenAccountAdjustments_gridAdjustments"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for gridAdjustments", () => {
    // START_USER_CODE-USER_gridAdjustments_TEST
    // END_USER_CODE-USER_gridAdjustments_TEST
  });
  test("grpbxActions(GroupBox Widget) Test Cases", async () => {
    const grpbxActions = screen.getByTestId("grpbxActions");
    expect(grpbxActions.tagName).toBe("BUTTON");
    expect(grpbxActions.type).toBe("button");
    expect(grpbxActions.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxActions", () => {
    // START_USER_CODE-USER_grpbxActions_TEST
    // END_USER_CODE-USER_grpbxActions_TEST
  });
  test("grpbxOpenAccountAdjustments(GroupBox Widget) Test Cases", async () => {
    const grpbxOpenAccountAdjustments = screen.getByTestId(
      "grpbxOpenAccountAdjustments"
    );
    expect(grpbxOpenAccountAdjustments.tagName).toBe("BUTTON");
    expect(grpbxOpenAccountAdjustments.type).toBe("button");
    expect(grpbxOpenAccountAdjustments.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxOpenAccountAdjustments", () => {
    // START_USER_CODE-USER_grpbxOpenAccountAdjustments_TEST
    // END_USER_CODE-USER_grpbxOpenAccountAdjustments_TEST
  });
  test("lblAdjustments(Label Widget) Test Cases", async () => {
    const lblAdjustments = screen.getByTestId("lblAdjustments");
    expect(lblAdjustments.tagName).toBe("LABEL");
    expect(lblAdjustments.classList).toContain("form-label");
    expect(lblAdjustments.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:OpenAccountAdjustments_lblAdjustments"
      )
    );
  });
  test("Custom Test Cases for lblAdjustments", () => {
    // START_USER_CODE-USER_lblAdjustments_TEST
    // END_USER_CODE-USER_lblAdjustments_TEST
  });
  test("lblARBalAdj(Label Widget) Test Cases", async () => {
    const lblARBalAdj = screen.getByTestId("lblARBalAdj");
    expect(lblARBalAdj.tagName).toBe("LABEL");
    expect(lblARBalAdj.classList).toContain("form-label");
    expect(lblARBalAdj.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:OpenAccountAdjustments_lblARBalAdj"
      )
    );
  });
  test("Custom Test Cases for lblARBalAdj", () => {
    // START_USER_CODE-USER_lblARBalAdj_TEST
    // END_USER_CODE-USER_lblARBalAdj_TEST
  });
  test("lblCRBalAdj(Label Widget) Test Cases", async () => {
    const lblCRBalAdj = screen.getByTestId("lblCRBalAdj");
    expect(lblCRBalAdj.tagName).toBe("LABEL");
    expect(lblCRBalAdj.classList).toContain("form-label");
    expect(lblCRBalAdj.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:OpenAccountAdjustments_lblCRBalAdj"
      )
    );
  });
  test("Custom Test Cases for lblCRBalAdj", () => {
    // START_USER_CODE-USER_lblCRBalAdj_TEST
    // END_USER_CODE-USER_lblCRBalAdj_TEST
  });
  test("lblOPBalAdj(Label Widget) Test Cases", async () => {
    const lblOPBalAdj = screen.getByTestId("lblOPBalAdj");
    expect(lblOPBalAdj.tagName).toBe("LABEL");
    expect(lblOPBalAdj.classList).toContain("form-label");
    expect(lblOPBalAdj.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:OpenAccountAdjustments_lblOPBalAdj"
      )
    );
  });
  test("Custom Test Cases for lblOPBalAdj", () => {
    // START_USER_CODE-USER_lblOPBalAdj_TEST
    // END_USER_CODE-USER_lblOPBalAdj_TEST
  });
  test("lblSRBalAdj(Label Widget) Test Cases", async () => {
    const lblSRBalAdj = screen.getByTestId("lblSRBalAdj");
    expect(lblSRBalAdj.tagName).toBe("LABEL");
    expect(lblSRBalAdj.classList).toContain("form-label");
    expect(lblSRBalAdj.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:OpenAccountAdjustments_lblSRBalAdj"
      )
    );
  });
  test("Custom Test Cases for lblSRBalAdj", () => {
    // START_USER_CODE-USER_lblSRBalAdj_TEST
    // END_USER_CODE-USER_lblSRBalAdj_TEST
  });
  test("lblTotals(Label Widget) Test Cases", async () => {
    const lblTotals = screen.getByTestId("lblTotals");
    expect(lblTotals.tagName).toBe("LABEL");
    expect(lblTotals.classList).toContain("form-label");
    expect(lblTotals.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:OpenAccountAdjustments_lblTotals"
      )
    );
  });
  test("Custom Test Cases for lblTotals", () => {
    // START_USER_CODE-USER_lblTotals_TEST
    // END_USER_CODE-USER_lblTotals_TEST
  });
  test("lblViewOpenAdjustmentsDetail(Label Widget) Test Cases", async () => {
    const lblViewOpenAdjustmentsDetail = screen.getByTestId(
      "lblViewOpenAdjustmentsDetail"
    );
    expect(lblViewOpenAdjustmentsDetail.tagName).toBe("LABEL");
    expect(lblViewOpenAdjustmentsDetail.classList).toContain("form-label");
    expect(lblViewOpenAdjustmentsDetail.textContent).toEqual(
      t(
        "SystemMaintenanceApplicationSupport:OpenAccountAdjustments_lblViewOpenAdjustmentsDetail"
      )
    );
  });
  test("Custom Test Cases for lblViewOpenAdjustmentsDetail", () => {
    // START_USER_CODE-USER_lblViewOpenAdjustmentsDetail_TEST
    // END_USER_CODE-USER_lblViewOpenAdjustmentsDetail_TEST
  });
  test("gridAdjustments_txtcolARBalAdj(Grid Widget) Test Cases", async () => {
    let gridAdjustments_txtcolARBalAdj = screen.getByTestId(
      "gridAdjustments"
    );
    let gridAdjustments_txtcolARBalAdjbtn =
      gridAdjustments_txtcolARBalAdj.nextElementSibling
        .firstElementChild;
    gridAdjustments_txtcolARBalAdj =
      gridAdjustments_txtcolARBalAdj.parentElement.parentElement
        .parentElement;
    expect(gridAdjustments_txtcolARBalAdj.tagName).toBe("DIV");
    expect(gridAdjustments_txtcolARBalAdj.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:OpenAccountAdjustments_gridAdjustments"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolARBalAdj", () => {
    // START_USER_CODE-USER_txtcolARBalAdj_TEST
    // END_USER_CODE-USER_txtcolARBalAdj_TEST
  });
  test("gridAdjustments_txtcolCheck(Grid Widget) Test Cases", async () => {
    let gridAdjustments_txtcolCheck = screen.getByTestId(
      "gridAdjustments"
    );
    let gridAdjustments_txtcolCheckbtn =
      gridAdjustments_txtcolCheck.nextElementSibling.firstElementChild;
    gridAdjustments_txtcolCheck =
      gridAdjustments_txtcolCheck.parentElement.parentElement
        .parentElement;
    expect(gridAdjustments_txtcolCheck.tagName).toBe("DIV");
    expect(gridAdjustments_txtcolCheck.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:OpenAccountAdjustments_gridAdjustments"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolCheck", () => {
    // START_USER_CODE-USER_txtcolCheck_TEST
    // END_USER_CODE-USER_txtcolCheck_TEST
  });
  test("gridAdjustments_txtcolComments(Grid Widget) Test Cases", async () => {
    let gridAdjustments_txtcolComments = screen.getByTestId(
      "gridAdjustments"
    );
    let gridAdjustments_txtcolCommentsbtn =
      gridAdjustments_txtcolComments.nextElementSibling
        .firstElementChild;
    gridAdjustments_txtcolComments =
      gridAdjustments_txtcolComments.parentElement.parentElement
        .parentElement;
    expect(gridAdjustments_txtcolComments.tagName).toBe("DIV");
    expect(gridAdjustments_txtcolComments.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:OpenAccountAdjustments_gridAdjustments"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolComments", () => {
    // START_USER_CODE-USER_txtcolComments_TEST
    // END_USER_CODE-USER_txtcolComments_TEST
  });
  test("gridAdjustments_txtcolContract(Grid Widget) Test Cases", async () => {
    let gridAdjustments_txtcolContract = screen.getByTestId(
      "gridAdjustments"
    );
    let gridAdjustments_txtcolContractbtn =
      gridAdjustments_txtcolContract.nextElementSibling
        .firstElementChild;
    gridAdjustments_txtcolContract =
      gridAdjustments_txtcolContract.parentElement.parentElement
        .parentElement;
    expect(gridAdjustments_txtcolContract.tagName).toBe("DIV");
    expect(gridAdjustments_txtcolContract.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:OpenAccountAdjustments_gridAdjustments"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolContract", () => {
    // START_USER_CODE-USER_txtcolContract_TEST
    // END_USER_CODE-USER_txtcolContract_TEST
  });
  test("gridAdjustments_txtcolCRBalAdj(Grid Widget) Test Cases", async () => {
    let gridAdjustments_txtcolCRBalAdj = screen.getByTestId(
      "gridAdjustments"
    );
    let gridAdjustments_txtcolCRBalAdjbtn =
      gridAdjustments_txtcolCRBalAdj.nextElementSibling
        .firstElementChild;
    gridAdjustments_txtcolCRBalAdj =
      gridAdjustments_txtcolCRBalAdj.parentElement.parentElement
        .parentElement;
    expect(gridAdjustments_txtcolCRBalAdj.tagName).toBe("DIV");
    expect(gridAdjustments_txtcolCRBalAdj.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:OpenAccountAdjustments_gridAdjustments"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolCRBalAdj", () => {
    // START_USER_CODE-USER_txtcolCRBalAdj_TEST
    // END_USER_CODE-USER_txtcolCRBalAdj_TEST
  });
  test("gridAdjustments_txtcolEffectiveDate(Grid Widget) Test Cases", async () => {
    let gridAdjustments_txtcolEffectiveDate = screen.getByTestId(
      "gridAdjustments"
    );
    let gridAdjustments_txtcolEffectiveDatebtn =
      gridAdjustments_txtcolEffectiveDate.nextElementSibling
        .firstElementChild;
    gridAdjustments_txtcolEffectiveDate =
      gridAdjustments_txtcolEffectiveDate.parentElement.parentElement
        .parentElement;
    expect(gridAdjustments_txtcolEffectiveDate.tagName).toBe("DIV");
    expect(gridAdjustments_txtcolEffectiveDate.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:OpenAccountAdjustments_gridAdjustments"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolEffectiveDate", () => {
    // START_USER_CODE-USER_txtcolEffectiveDate_TEST
    // END_USER_CODE-USER_txtcolEffectiveDate_TEST
  });
  test("gridAdjustments_txtcolEntryDate(Grid Widget) Test Cases", async () => {
    let gridAdjustments_txtcolEntryDate = screen.getByTestId(
      "gridAdjustments"
    );
    let gridAdjustments_txtcolEntryDatebtn =
      gridAdjustments_txtcolEntryDate.nextElementSibling
        .firstElementChild;
    gridAdjustments_txtcolEntryDate =
      gridAdjustments_txtcolEntryDate.parentElement.parentElement
        .parentElement;
    expect(gridAdjustments_txtcolEntryDate.tagName).toBe("DIV");
    expect(gridAdjustments_txtcolEntryDate.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:OpenAccountAdjustments_gridAdjustments"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolEntryDate", () => {
    // START_USER_CODE-USER_txtcolEntryDate_TEST
    // END_USER_CODE-USER_txtcolEntryDate_TEST
  });
  test("gridAdjustments_txtcolInspection(Grid Widget) Test Cases", async () => {
    let gridAdjustments_txtcolInspection = screen.getByTestId(
      "gridAdjustments"
    );
    let gridAdjustments_txtcolInspectionbtn =
      gridAdjustments_txtcolInspection.nextElementSibling
        .firstElementChild;
    gridAdjustments_txtcolInspection =
      gridAdjustments_txtcolInspection.parentElement.parentElement
        .parentElement;
    expect(gridAdjustments_txtcolInspection.tagName).toBe("DIV");
    expect(gridAdjustments_txtcolInspection.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:OpenAccountAdjustments_gridAdjustments"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolInspection", () => {
    // START_USER_CODE-USER_txtcolInspection_TEST
    // END_USER_CODE-USER_txtcolInspection_TEST
  });
  test("gridAdjustments_txtcolOilStock(Grid Widget) Test Cases", async () => {
    let gridAdjustments_txtcolOilStock = screen.getByTestId(
      "gridAdjustments"
    );
    let gridAdjustments_txtcolOilStockbtn =
      gridAdjustments_txtcolOilStock.nextElementSibling
        .firstElementChild;
    gridAdjustments_txtcolOilStock =
      gridAdjustments_txtcolOilStock.parentElement.parentElement
        .parentElement;
    expect(gridAdjustments_txtcolOilStock.tagName).toBe("DIV");
    expect(gridAdjustments_txtcolOilStock.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:OpenAccountAdjustments_gridAdjustments"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolOilStock", () => {
    // START_USER_CODE-USER_txtcolOilStock_TEST
    // END_USER_CODE-USER_txtcolOilStock_TEST
  });
  test("gridAdjustments_txtcolOPBalAdj(Grid Widget) Test Cases", async () => {
    let gridAdjustments_txtcolOPBalAdj = screen.getByTestId(
      "gridAdjustments"
    );
    let gridAdjustments_txtcolOPBalAdjbtn =
      gridAdjustments_txtcolOPBalAdj.nextElementSibling
        .firstElementChild;
    gridAdjustments_txtcolOPBalAdj =
      gridAdjustments_txtcolOPBalAdj.parentElement.parentElement
        .parentElement;
    expect(gridAdjustments_txtcolOPBalAdj.tagName).toBe("DIV");
    expect(gridAdjustments_txtcolOPBalAdj.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:OpenAccountAdjustments_gridAdjustments"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolOPBalAdj", () => {
    // START_USER_CODE-USER_txtcolOPBalAdj_TEST
    // END_USER_CODE-USER_txtcolOPBalAdj_TEST
  });
  test("gridAdjustments_txtcolSettlement(Grid Widget) Test Cases", async () => {
    let gridAdjustments_txtcolSettlement = screen.getByTestId(
      "gridAdjustments"
    );
    let gridAdjustments_txtcolSettlementbtn =
      gridAdjustments_txtcolSettlement.nextElementSibling
        .firstElementChild;
    gridAdjustments_txtcolSettlement =
      gridAdjustments_txtcolSettlement.parentElement.parentElement
        .parentElement;
    expect(gridAdjustments_txtcolSettlement.tagName).toBe("DIV");
    expect(gridAdjustments_txtcolSettlement.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:OpenAccountAdjustments_gridAdjustments"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolSettlement", () => {
    // START_USER_CODE-USER_txtcolSettlement_TEST
    // END_USER_CODE-USER_txtcolSettlement_TEST
  });
  test("gridAdjustments_txtcolSRBalAdj(Grid Widget) Test Cases", async () => {
    let gridAdjustments_txtcolSRBalAdj = screen.getByTestId(
      "gridAdjustments"
    );
    let gridAdjustments_txtcolSRBalAdjbtn =
      gridAdjustments_txtcolSRBalAdj.nextElementSibling
        .firstElementChild;
    gridAdjustments_txtcolSRBalAdj =
      gridAdjustments_txtcolSRBalAdj.parentElement.parentElement
        .parentElement;
    expect(gridAdjustments_txtcolSRBalAdj.tagName).toBe("DIV");
    expect(gridAdjustments_txtcolSRBalAdj.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:OpenAccountAdjustments_gridAdjustments"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolSRBalAdj", () => {
    // START_USER_CODE-USER_txtcolSRBalAdj_TEST
    // END_USER_CODE-USER_txtcolSRBalAdj_TEST
  });
  test("gridAdjustments_txtcolUser(Grid Widget) Test Cases", async () => {
    let gridAdjustments_txtcolUser = screen.getByTestId(
      "gridAdjustments"
    );
    let gridAdjustments_txtcolUserbtn =
      gridAdjustments_txtcolUser.nextElementSibling.firstElementChild;
    gridAdjustments_txtcolUser =
      gridAdjustments_txtcolUser.parentElement.parentElement
        .parentElement;
    expect(gridAdjustments_txtcolUser.tagName).toBe("DIV");
    expect(gridAdjustments_txtcolUser.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:OpenAccountAdjustments_gridAdjustments"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolUser", () => {
    // START_USER_CODE-USER_txtcolUser_TEST
    // END_USER_CODE-USER_txtcolUser_TEST
  });
  test("gridAdjustments_txtcolWhseRcpt(Grid Widget) Test Cases", async () => {
    let gridAdjustments_txtcolWhseRcpt = screen.getByTestId(
      "gridAdjustments"
    );
    let gridAdjustments_txtcolWhseRcptbtn =
      gridAdjustments_txtcolWhseRcpt.nextElementSibling
        .firstElementChild;
    gridAdjustments_txtcolWhseRcpt =
      gridAdjustments_txtcolWhseRcpt.parentElement.parentElement
        .parentElement;
    expect(gridAdjustments_txtcolWhseRcpt.tagName).toBe("DIV");
    expect(gridAdjustments_txtcolWhseRcpt.classList).toContain(
      "GridWidget"
    );
    expect(
      screen.getAllByText(
        t(
          "SystemMaintenanceApplicationSupport:OpenAccountAdjustments_gridAdjustments"
        )
      )
    ).toBeInTheDocument;
  });
  test("Custom Test Cases for txtcolWhseRcpt", () => {
    // START_USER_CODE-USER_txtcolWhseRcpt_TEST
    // END_USER_CODE-USER_txtcolWhseRcpt_TEST
  });
});
