/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import jsPDF from 'jspdf';
import Loading from "../../../Loader/Loading";
import { ExcelNumericDataConvert } from "../../Common/CommonFunctions"

import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  CommandContainerWidget,
  ButtonWidget,
  GridWidget,
  LabelWidget,
  CheckboxWidget,
  setValue,
  disable,
  enable,
  goTo,
  getValue,
  setData,
  getData,
  DateWidget,
  hide,
  getSelectedRowNumber
} from "../../shared/WindowImports";

import "./CheckInquiry.scss";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { AccountPayment } from "../../AccountPayment/Service/AccountPayment"
import * as XLSX from 'xlsx/xlsx.mjs';
import StripPic from "../../../../assets/img/PinStrip.png";
// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
// END_USER_CODE-USER_IMPORTS
function AccountPayment_CheckInquiry(props) {

  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const [stopFirstRender, setStopFirstRender] = useState(0)
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES
  const compIdFromLS = sessionStorage.getItem('compId');
  const cropYearFromLS = JSON.parse(sessionStorage.getItem('year'))
  let states = {
    Label: "CheckInquiry",
    windowName: "CheckInquiry",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "AccountPayment.CheckInquiry",
    // START_USER_CODE-USER_CheckInquiry_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Payment Inquiry",
      scrCode: "PN1100D",
    },
    // END_USER_CODE-USER_CheckInquiry_PROPERTIES
    btn1: {
      name: "btn1",
      type: "ButtonWidget",
      parent: "grpbxbtn1",
      CharWidth: "4",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn1_PROPERTIES

      // END_USER_CODE-USER_btn1_PROPERTIES
    },
    btn2: {
      name: "btn2",
      type: "ButtonWidget",
      parent: "grpbxCheckDetails1",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btn2_PROPERTIES

      // END_USER_CODE-USER_btn2_PROPERTIES
    },
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Close",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnCorrect: {
      name: "btnCorrect",
      type: "ButtonWidget",
      parent: "grpbxSearchResults",
      Label: "Correct",
      CharWidth: "17",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCorrect_PROPERTIES

      // END_USER_CODE-USER_btnCorrect_PROPERTIES
    },
    btnExportToExcel: {
      name: "btnExportToExcel",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Export to Excel",
      CharWidth: "32",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnExportToExcel_PROPERTIES

      // END_USER_CODE-USER_btnExportToExcel_PROPERTIES
    },
    btnPrint: {
      name: "btnPrint",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Print",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnPrint_PROPERTIES

      // END_USER_CODE-USER_btnPrint_PROPERTIES
    },
    btnReview: {
      name: "btnReview",
      type: "ButtonWidget",
      parent: "grpbxSearchResults",
      Label: "Review",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnReview_PROPERTIES

      // END_USER_CODE-USER_btnReview_PROPERTIES
    },
    btnSearch: {
      name: "btnSearch",
      type: "ButtonWidget",
      parent: "grpbxSearchResults",
      Label: "Search",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnSearch_PROPERTIES

      // END_USER_CODE-USER_btnSearch_PROPERTIES
    },
    btnVoid: {
      name: "btnVoid",
      type: "ButtonWidget",
      parent: "grpbxSearchResults",
      Label: "Void",
      CharWidth: "11",
      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnVoid_PROPERTIES

      // END_USER_CODE-USER_btnVoid_PROPERTIES
    },
    chkboxWireTransferData: {
      name: "chkboxWireTransferData",
      type: "CheckBoxWidget",
      parent: "grpbxCheckDetails1",
      Label: "Wire Xfer Data:",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxWireTransferData_PROPERTIES

      // END_USER_CODE-USER_chkboxWireTransferData_PROPERTIES
    },
    colCheck: {
      name: "colCheck",
      type: "GridColumnWidget",
      parent: "gridSearchResults",
      Label: "Check#",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colCheck_PROPERTIES

      // END_USER_CODE-USER_colCheck_PROPERTIES
    },
    colIssueDate: {
      name: "colIssueDate",
      type: "GridColumnWidget",
      parent: "gridSearchResults",
      Label: "Issue Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colIssueDate_PROPERTIES

      // END_USER_CODE-USER_colIssueDate_PROPERTIES
    },
    colLocation: {
      name: "colLocation",
      type: "GridColumnWidget",
      parent: "gridSearchResults",
      Label: "Location",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colLocation_PROPERTIES

      // END_USER_CODE-USER_colLocation_PROPERTIES
    },
    colProceeds: {
      name: "colProceeds",
      type: "GridColumnWidget",
      parent: "gridSearchResults",
      Label: "Proceeds",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colProceeds_PROPERTIES

      // END_USER_CODE-USER_colProceeds_PROPERTIES
    },
    colStatus: {
      name: "colStatus",
      type: "GridColumnWidget",
      parent: "gridSearchResults",
      Label: "Status",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colStatus_PROPERTIES

      // END_USER_CODE-USER_colStatus_PROPERTIES
    },
    colVendor: {
      name: "colVendor",
      type: "GridColumnWidget",
      parent: "gridSearchResults",
      Label: "Vendor",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colVendor_PROPERTIES

      // END_USER_CODE-USER_colVendor_PROPERTIES
    },
    colWireAcknowledgement: {
      name: "colWireAcknowledgement",
      type: "GridColumnWidget",
      parent: "gridSearchResults",
      Label: "Wire Acknowledgement",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colWireAcknowledgement_PROPERTIES

      // END_USER_CODE-USER_colWireAcknowledgement_PROPERTIES
    },
    colWireConfirmation: {
      name: "colWireConfirmation",
      type: "GridColumnWidget",
      parent: "gridSearchResults",
      Label: "Wire Confirmation",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colWireConfirmation_PROPERTIES

      // END_USER_CODE-USER_colWireConfirmation_PROPERTIES
    },
    colWireXferData: {
      name: "colWireXferData",
      type: "GridColumnWidget",
      parent: "gridSearchResults",
      Label: "Wire Xfer Data",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colWireXferData_PROPERTIES

      // END_USER_CODE-USER_colWireXferData_PROPERTIES
    },
    ddBuyingPoint: {
      name: "ddBuyingPoint",
      type: "DropDownFieldWidget",
      parent: "grpbxCheckDetails1",
      Label: "Buying Point:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_ddBuyingPoint_PROPERTIES
    },
    ddChargeLocation: {
      name: "ddChargeLocation",
      type: "DropDownFieldWidget",
      parent: "grpbxCheckDetails1",
      Label: "Charge Location:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddChargeLocation_PROPERTIES

      // END_USER_CODE-USER_ddChargeLocation_PROPERTIES
    },
    ddCheckStatus: {
      name: "ddCheckStatus",
      type: "DropDownFieldWidget",
      parent: "grpbxCheckDetails1",
      Label: "Check Status:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddCheckStatus_PROPERTIES

      // END_USER_CODE-USER_ddCheckStatus_PROPERTIES
    },
    ddItemCode: {
      name: "ddItemCode",
      type: "DropDownFieldWidget",
      parent: "grpbxCheckDetails1",
      Label: "Item Code:",
      DataProviderForDropDown: "inline",
      optionMaxLength: 17,
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddItemCode_PROPERTIES

      // END_USER_CODE-USER_ddItemCode_PROPERTIES
    },
    ddOleicInd: {
      name: "ddOleicInd",
      type: "DropDownFieldWidget",
      parent: "grpbxCheckDetails1",
      Label: "Oleic Ind:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddOleicInd_PROPERTIES

      // END_USER_CODE-USER_ddOleicInd_PROPERTIES
    },
    ddPeanutType: {
      name: "ddPeanutType",
      type: "DropDownFieldWidget",
      parent: "grpbxCheckDetails1",
      Label: "Peanut Type:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddPeanutType_PROPERTIES

      // END_USER_CODE-USER_ddPeanutType_PROPERTIES
    },
    ddRemitTo: {
      name: "ddRemitTo",
      type: "DropDownFieldWidget",
      parent: "grpbxCheckDetails1",
      Label: "Remit To:",
      ColSpan: "3",
      DataProviderForDropDown: "inline",
      Height: "1",
      Options: '',
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddRemitTo_PROPERTIES

      // END_USER_CODE-USER_ddRemitTo_PROPERTIES
    },
    ddSeedGeneration: {
      name: "ddSeedGeneration",
      type: "DropDownFieldWidget",
      parent: "grpbxCheckDetails1",
      Label: "Seed Generation:",
      ColSpan: "2",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddSeedGeneration_PROPERTIES

      // END_USER_CODE-USER_ddSeedGeneration_PROPERTIES
    },
    ddWireTransferData: {
      name: "ddWireTransferData",
      type: "DropDownFieldWidget",
      parent: "grpbxCheckDetails1",
      ColSpan: "2",
      DataProviderForDropDown: "inline",
      HasLabel: false,
      valueList: [],
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddWireTransferData_PROPERTIES

      // END_USER_CODE-USER_ddWireTransferData_PROPERTIES
    },
    gridSearchResults: {
      name: "gridSearchResults",
      type: "GridWidget",
      parent: "grpbxSearchResults",
      gridCellsOrder:
        "txtcolCheck,txtcolVendor,txt2,txtcolIssueDate,txtcolProceeds,txtcolStatus,txtcolWireXferData,txtcolWireAcknowledgement,txtcolWireConfirmation",
      ColSpan: "5",
      Pagination: false,
      HasLabel: false,
      Height: "",
      Width: "",
      needGridChange: true,//Change for Grid change
      // START_USER_CODE-USER_gridSearchResults_PROPERTIES

      // END_USER_CODE-USER_gridSearchResults_PROPERTIES
    },
    grpbxbtn1: {
      name: "grpbxbtn1",
      type: "GroupBoxWidget",
      parent: "grpbxSearchResults",
      ColSpan: "2",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxbtn1_PROPERTIES

      // END_USER_CODE-USER_grpbxbtn1_PROPERTIES
    },
    grpbxCheckDetails2: {
      name: "grpbxCheckDetails2",
      type: "GroupBoxWidget",
      parent: "grpbxSearchResults",
      Height: "",
      Width: "",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxCheckDetails2_PROPERTIES

      // END_USER_CODE-USER_grpbxCheckDetails2_PROPERTIES
    },
    lblCheckProceeds: {
      name: "lblCheckProceeds",
      type: "LabelWidget",
      parent: "grpbxCheckDetails2",
      Label: "Check Proceeds:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCheckProceeds_PROPERTIES

      // END_USER_CODE-USER_lblCheckProceeds_PROPERTIES
    },
    lblCheckProceedsValue: {
      name: "lblCheckProceedsValue",
      type: "LabelWidget",
      parent: "grpbxCheckDetails2",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblCheckProceedsValue_PROPERTIES

      // END_USER_CODE-USER_lblCheckProceedsValue_PROPERTIES
    },
    lblGrandTotal: {
      name: "lblGrandTotal",
      type: "LabelWidget",
      parent: "grpbxCheckDetails2",
      Label: "Grand Total:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblGrandTotal_PROPERTIES

      // END_USER_CODE-USER_lblGrandTotal_PROPERTIES
    },
    lblGrandTotalValue: {
      name: "lblGrandTotalValue",
      type: "LabelWidget",
      parent: "grpbxCheckDetails2",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblGrandTotalValue_PROPERTIES

      // END_USER_CODE-USER_lblGrandTotalValue_PROPERTIES
    },
    lblSearchResults: {
      name: "lblSearchResults",
      type: "LabelWidget",
      parent: "grpbxSearchResults",
      Label: "Search Results",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblSearchResults_PROPERTIES

      // END_USER_CODE-USER_lblSearchResults_PROPERTIES
    },
    lblSystemVoids: {
      name: "lblSystemVoids",
      type: "LabelWidget",
      parent: "grpbxCheckDetails2",
      Label: "System Voids:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblSystemVoids_PROPERTIES

      // END_USER_CODE-USER_lblSystemVoids_PROPERTIES
    },
    lblSystemVoidsValue: {
      name: "lblSystemVoidsValue",
      type: "LabelWidget",
      parent: "grpbxCheckDetails2",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblSystemVoidsValue_PROPERTIES

      // END_USER_CODE-USER_lblSystemVoidsValue_PROPERTIES
    },
    lblWireTransfers: {
      name: "lblWireTransfers",
      type: "LabelWidget",
      parent: "grpbxCheckDetails2",
      Label: "Wire Transfers:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblWireTransfers_PROPERTIES

      // END_USER_CODE-USER_lblWireTransfers_PROPERTIES
    },
    lblWireTransfersValue: {
      name: "lblWireTransfersValue",
      type: "LabelWidget",
      parent: "grpbxCheckDetails2",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblWireTransfersValue_PROPERTIES

      // END_USER_CODE-USER_lblWireTransfersValue_PROPERTIES
    },
    txt1007: {
      name: "txt1007",
      type: "TextBoxWidget",
      parent: "grpbxCheckDetails1",
      Label: "1007#:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txt1007_PROPERTIES

      // END_USER_CODE-USER_txt1007_PROPERTIES
    },
    txtWireXfer: {
      name: "txtWireXfer",
      type: "TextBoxWidget",
      parent: "grpbxCheckDetails1",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      HasLabel:false,
      // START_USER_CODE-USER_txt1007_PROPERTIES

      // END_USER_CODE-USER_txt1007_PROPERTIES
    },
    txt2: {
      name: "txt2",
      type: "TextBoxWidget",
      colName: "colLocation",
      parent: "gridSearchResults",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txt2_PROPERTIES

      // END_USER_CODE-USER_txt2_PROPERTIES
    },
    txtCarrierAbbr: {
      name: "txtCarrierAbbr",
      type: "TextBoxWidget",
      parent: "grpbxCheckDetails1",
      Label: "Carrier Abbr:",
      LengthRange: { MinLength: 0, MaxLength: 4 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCarrierAbbr_PROPERTIES

      // END_USER_CODE-USER_txtCarrierAbbr_PROPERTIES
    },
    txtCheck: {
      name: "txtCheck",
      type: "TextBoxWidget",
      parent: "grpbxCheckDetails1",
      Label: "Check #:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCheck_PROPERTIES

      // END_USER_CODE-USER_txtCheck_PROPERTIES
    },
    txtcolCheck: {
      name: "txtcolCheck",
      type: "TextBoxWidget",
      colName: "colCheck",
      parent: "gridSearchResults",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolCheck_PROPERTIES

      // END_USER_CODE-USER_txtcolCheck_PROPERTIES
    },
    txtcolIssueDate: {
      name: "txtcolIssueDate",
      type: "TextBoxWidget",
      colName: "colIssueDate",
      parent: "gridSearchResults",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolIssueDate_PROPERTIES

      // END_USER_CODE-USER_txtcolIssueDate_PROPERTIES
    },
    txtcolProceeds: {
      name: "txtcolProceeds",
      type: "TextBoxWidget",
      colName: "colProceeds",
      parent: "gridSearchResults",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolProceeds_PROPERTIES

      // END_USER_CODE-USER_txtcolProceeds_PROPERTIES
    },
    txtcolStatus: {
      name: "txtcolStatus",
      type: "TextBoxWidget",
      colName: "colStatus",
      parent: "gridSearchResults",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolStatus_PROPERTIES

      // END_USER_CODE-USER_txtcolStatus_PROPERTIES
    },
    txtcolVendor: {
      name: "txtcolVendor",
      type: "TextBoxWidget",
      colName: "colVendor",
      parent: "gridSearchResults",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolVendor_PROPERTIES

      // END_USER_CODE-USER_txtcolVendor_PROPERTIES
    },
    txtcolWireAcknowledgement: {
      name: "txtcolWireAcknowledgement",
      type: "TextBoxWidget",
      colName: "colWireAcknowledgement",
      parent: "gridSearchResults",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolWireAcknowledgement_PROPERTIES

      // END_USER_CODE-USER_txtcolWireAcknowledgement_PROPERTIES
    },
    txtcolWireConfirmation: {
      name: "txtcolWireConfirmation",
      type: "TextBoxWidget",
      colName: "colWireConfirmation",
      parent: "gridSearchResults",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolWireConfirmation_PROPERTIES

      // END_USER_CODE-USER_txtcolWireConfirmation_PROPERTIES
    },
    txtcolWireXferData: {
      name: "txtcolWireXferData",
      type: "TextBoxWidget",
      colName: "colWireXferData",
      parent: "gridSearchResults",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolWireXferData_PROPERTIES

      // END_USER_CODE-USER_txtcolWireXferData_PROPERTIES
    },
    txtEndDate: {
      name: "txtEndDate",
      type: "DateWidget",
      parent: "grpbxCheckDetails1",
      Label: "-",
      LengthRange: { MinLength: 0, MaxLength: 16 },
      ofTypeDomain: "d_date",
      // START_USER_CODE-USER_txtAgreementDate_PROPERTIES
      // END_USER_CODE-USER_txtAgreementDate_PROPERTIES
    },
    txtInvoice: {
      name: "txtInvoice",
      type: "TextBoxWidget",
      parent: "grpbxCheckDetails1",
      Label: "Invoice #:",
      LengthRange: { MinLength: 0, MaxLength: 10 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtInvoice_PROPERTIES

      // END_USER_CODE-USER_txtInvoice_PROPERTIES
    },
    txtMemo: {
      name: "txtMemo",
      type: "TextBoxWidget",
      parent: "grpbxCheckDetails1",
      Label: "Memo #:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtMemo_PROPERTIES

      // END_USER_CODE-USER_txtMemo_PROPERTIES
    },
    txtPayAmount: {
      name: "txtPayAmount",
      type: "TextBoxWidget",
      parent: "grpbxCheckDetails1",
      Label: "Pay Amount:",
      LengthRange: { MinLength: 0, MaxLength: 13 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPayAmount_PROPERTIES

      // END_USER_CODE-USER_txtPayAmount_PROPERTIES
    },
    txtStartDate: {
      name: "txtStartDate",
      type: "DateWidget",
      parent: "grpbxCheckDetails1",
      Label: "Date Range:",
      LengthRange: { MinLength: 0, MaxLength: 16 },
      ofTypeDomain: "d_date",
      // START_USER_CODE-USER_txtAgreementDate_PROPERTIES
      // END_USER_CODE-USER_txtAgreementDate_PROPERTIES
    },

    txtVendor: {
      name: "txtVendor",
      type: "TextBoxWidget",
      parent: "grpbxCheckDetails1",
      Label: "Vendor #:",
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor_PROPERTIES

      // END_USER_CODE-USER_txtVendor_PROPERTIES
    },
    txtVendorName: {
      name: "txtVendorName",
      type: "TextBoxWidget",
      parent: "grpbxCheckDetails1",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor_PROPERTIES
      ReasOnly: true,
      Enabled: false,
      // END_USER_CODE-USER_txtVendor_PROPERTIES
    },
    grpbxCheckDetails1: {
      name: "grpbxCheckDetails1",
      type: "GroupBoxWidget",
      parent: "CheckInquiry",
      Height: "",
      Width: "",
      ColsForTabLandscape: "6",
      HasLabel: false,
      Cols: "6",
      ColsForTabPotrait: "6",
      ColsForLargeDesktop: "6",
      // START_USER_CODE-USER_grpbxCheckDetails1_PROPERTIES

      // END_USER_CODE-USER_grpbxCheckDetails1_PROPERTIES
    },
    grpbxSearchResults: {
      name: "grpbxSearchResults",
      type: "GroupBoxWidget",
      parent: "CheckInquiry",
      Height: "",
      Width: "",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxSearchResults_PROPERTIES

      // END_USER_CODE-USER_grpbxSearchResults_PROPERTIES
    },
    ddbutton: {
      name: "ddbutton",
      type: "DropDownFieldWidget",
      parent: "grpbxPeanutInfo",
      Label: "ddbutton:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddOleic_PROPERTIES

      // END_USER_CODE-USER_ddOleic_PROPERTIES
    },
    cmmndCntnrActions: {
      name: "cmmndCntnrActions",
      type: "CommandContainerWidget",
      parent: "CheckInquiry",
      Label: "commandcontainerwidget70",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_cmmndCntnrActions_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "ContractManagement#MainMenu": {},
        "AccountPayment#ReviewCheck": {},
        "AccountPayment#PrintChecks": {},
        "AccountPayment#CorrectCheck": {},
      },
      REVERSE: {
        "ContractManagement#MainMenu": {},
        "AccountPayment#ReviewCheck": {},
        "AccountPayment#PrintChecks": {},
        "AccountPayment#CorrectCheck": {},
      },
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {
    btn2: {
      DEFAULT: ["ContractManagement#VendorLookup#DEFAULT#true#Click"],
    },
    btnReview: {
      DEFAULT: [
        "AccountPayment#ReviewCheck#DEFAULT#false#Click",
      ],
    },
    btnCorrect: {
      DEFAULT: [
        "AccountPayment#CorrectCheck#DEFAULT#false#Click",
      ],
    },
    // btnPrint: {
    //   DEFAULT: [
    //     "AccountPayment#PrintChecks#DEFAULT#false#Click",
    //   ],
    // },
    btnClose: {
      DEFAULT: [
        "ContractManagement#MainMenu#DEFAULT#false#Click",
      ],
    },
  };
  let _winServices = {};
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    FormLoad()
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS

    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);
  useEffect(() => {
    setStopFirstRender(stopFirstRender + 1)
    if (stopFirstRender >= 1) {
      let vndrnmbr = getData(thisObj, 'vendorDetails') == null ? '' : getData(thisObj, 'vendorDetails').VendorNumber
      VendorDetails(vndrnmbr)
    }
  }, [getData(thisObj, 'vendorDetails')])

  function FormLoad() {
    bFillBuyingPointList(); // Method for binding Buying Points
    bFillPeanutTypeList(); // Method for binding Peanut Type dropdown
    bFillOleicList(); // Method for binding Oleic values
    bFillSeedGeneration();
    bFillItemCode();
    bFillCheckStatus();
    bFillChargeLocationList()
    document.getElementById('ddWireTransferData').disabled = true;
    disable(thisObj, 'btnReview')
    disable(thisObj,'txtWireXfer')
    hide(thisObj, "btnVoid");
    hide(thisObj, "btnCorrect");
    setLoading(false)
  }

  const VendorDetails = async (vendor_n) => {
    let vendorDetails = getData(thisObj, 'vendorDetails');
    let getbuttonv = getValue(thisObj, 'ddbutton')
    let vendor1 = vendor_n
    // if (vendor_n !== "" && vendor_n !== null && vendor_n !== undefined) {
    //   vendor1 = vendor_n
    // } else if (vendorDetails !== null) {
    //   let vendorDetails_key = vendorDetails.key
    //   if (vendorDetails_key !== undefined) {
    //     vendor1 = vendorDetails.VendorNumber
    //   }
    // }
    if (vendor1 !== "" && vendor1 !== null && vendor1 !== undefined) {
      let GetScac = await AccountPayment.RetrieveScacVendorLookupDetails(vendor1, '');
      if (GetScac.length > 0) {
        setValue(thisObj, 'txtCarrierAbbr', GetScac[0].scac)
        setValue(thisObj, 'txtVendorName', GetScac[0].name)
      } else {
        setValue(thisObj, 'txtCarrierAbbr', '')
        setValue(thisObj, 'txtVendorName', '')
      }
      ContractManagementService.RetieveRemitDetails(vendor1).then(response => {
        let data = response;
        if (data.length > 0) {
          let js = [];
          for (var i = 0; i < data.length; i++) {
            let description = data[i].remittoid + '-' + data[i].name + '-' + data[i].address1 + '-' + data[i].city;
            let obj = { key: data[i].remittoid, description: description };
            js.push(obj);
          }
          if (getbuttonv == "btn2" || getbuttonv == "txtVendor") {
            setValue(thisObj, 'txtVendor', vendor1)
            setValue(thisObj, 'txtVendorName', data[0].name)
            thisObj.setState(current => {
              return {
                ...current,
                ddRemitTo: {
                  ...state["ddRemitTo"],
                  valueList: js
                },
              }
            });
          }
        } else {
          setValue(thisObj, 'txtVendor', vendor1)
          setValue(thisObj, 'txtCarrierAbbr', '')
          setValue(thisObj, 'txtVendorName', '')
          thisObj.setState(current => {
            return {
              ...current,
              ddRemitTo: {
                ...state["ddRemitTo"],
                valueList: []
              },
            }
          });
        }
      });
    } else {
      setValue(thisObj, 'txtCarrierAbbr', '')
      setValue(thisObj, 'txtVendorName', '')
      thisObj.setState(current => {
        return {
          ...current,
          ddRemitTo: {
            ...state["ddRemitTo"],
            valueList: []
          },
        }
      });
    }
  };
  
  const bFillBuyingPointList = () => {
    let js = []
    js.push({ key: '', description: '>>>All Buying Points<<<' });
    // Fixed INC6166914 New PPS website - Payment Inquiry - Buying Point selection
    ContractManagementService.RetrieveBuyingPointControlDetails('PN1100', null, null, null, null).then(response => {
      let data = response
      for (var i = 0; i < data.length; i++) {
        let obj = { key: data[i].buy_pt_id, description: data[i].buy_pt_id.trim() + '-' + data[i].buy_pt_name.trim() }
        js.push(obj)
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddBuyingPoint: {
            ...state["ddBuyingPoint"],
            valueList: js
          }
        }
      })
      setValue(thisObj, 'ddBuyingPoint', js.at(0).key);
    })


  }
  const bFillChargeLocationList = () => {
    let js = []
    js.push({ key: '', description: '>>>All Locations<<<' });
    ContractManagementService.RetrieveBuyingPointControlList(compIdFromLS, cropYearFromLS,null,  null, null).then(response => {
      let data = response
      const ids = data.map(o => o.buy_pt_id)
      const filtered = data.filter(({buy_pt_id}, index) => !ids.includes(buy_pt_id, index + 1))
      for (var i = 0; i < filtered.length; i++) {
        let buyptname = filtered[i].buy_pt_name !== null ? filtered[i].buy_pt_name.trim() : ''
        let obj = { key: filtered[i].buy_pt_id, description: filtered[i].buy_pt_id.trim() + '-' + buyptname }
        js.push(obj)
      }

      thisObj.setState(current => {
        return {
          ...current,
          ddChargeLocation: {
            ...state["ddChargeLocation"],
            valueList: js
          }
        }
      })
      setValue(thisObj, 'ddChargeLocation', js.at(0).key);
    })


  }
  //Method for binding Peanut Type dropdown
  const bFillPeanutTypeList = () => {
    let js = [];
    js.push({ key: '', description: '>>> All Types <<<' }) //Set All as default value
    ContractManagementService.RetrievePeanutTypeControls(null).then(response => {
      let data = response
      for (var i = 0; i < data.length; i++) {
        let obj = { key: data[i].pnutTypeId, description: data[i].pnutTypeName }
        js.push(obj)
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddPeanutType: {
            ...state["ddPeanutType"],
            valueList: js,
          }
        }
      })
    })
    setValue(thisObj, 'ddPeanutType', js.at(0).key);
  }

  // Method for binding Segment list  

  // Method for binding Oleic values
  const bFillOleicList = () => {
    let js = []
    js.push({ key: '', description: ">>> ALL OLEIC <<<" })
    js.push({ key: 'H', description: 'High' })
    js.push({ key: 'M', description: 'Mid' })
    js.push({ key: 'N', description: 'None' })
    thisObj.setState(current => {
      return {
        ...current,
        ddOleicInd: {
          ...state["ddOleicInd"],
          valueList: js
        }
      }
    })
    setValue(thisObj, 'ddOleicInd', js.at(0).key);
  }

  // Method for binding generation List
  const bFillSeedGeneration = () => {
    let js = []
    js.push({ key: '', description: '>>> ANY SEED <<<' })
    js.push({ key: 'F', description: 'Foundation' })
    js.push({ key: 'R', description: 'Registered' })
    js.push({ key: 'C', description: 'Certified' })
    thisObj.setState(current => {
      return {
        ...current,
        ddSeedGeneration: {
          ...state["ddSeedGeneration"],
          valueList: js
        }
      }
    })
    setValue(thisObj, 'ddSeedGeneration', js.at(0).key);
  }
  //Method for binding ItemCode dropdown
  const bFillItemCode = () => {
    let pd_code = null
    let js = [];
    js.push({ key: '', description: '' })
    js.push({ key: 'ALL_SP', description: '>>> All Special Pays <<<' }) // default value
    AccountPayment.RetrievePremiumDeductionMasterDetails(pd_code,  "Y").then(response => {
      if (response != undefined && response.length > 0) {
        let data = response
        for (var i = 0; i < data.length; i++) {
          let obj = { key: data[i].pd_code, description: data[i].pd_desc }
          js.push(obj)
        }
      }
      thisObj.setState(current => {
        return {
          ...current,
          ddItemCode: {
            ...state["ddItemCode"],
            valueList: js,
          }
        }
      })
    })
    setValue(thisObj, 'ddItemCode', js.at(0).key);
  }
  // Method for binding generation List
  const bFillCheckStatus = () => {
    let js = []
    js.push({ key: '', description: '>>> All Status <<<' })
    js.push({ key: 'C', description: 'Cleared' })
    js.push({ key: 'I', description: 'Issued' })
    js.push({ key: 'R', description: 'Sent to Recon' })
    js.push({ key: 'SV', description: 'System Void' })
    js.push({ key: 'V', description: 'Void' })
    js.push({ key: 'WT', description: 'Wire Transfer' })
    thisObj.setState(current => {
      return {
        ...current,
        ddCheckStatus: {
          ...state["ddCheckStatus"],
          valueList: js
        }
      }
    })
    setValue(thisObj, 'ddCheckStatus', js.at(0).key);
  }
  const numberFormat = (value) =>
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(value);

  const findspecial = (value) => {
      const find = '-';
      const replace_With = '(';
      let check_proceedsformat = value.split(find).join(replace_With)
      let replaceWithvalue = ''
      if( check_proceedsformat !==value){
        replaceWithvalue =check_proceedsformat+')'
      }else{
        replaceWithvalue = value
      }
      return replaceWithvalue;
  }
  const onbtnSearchClick = () => {
    setLoading(true)
    disable(thisObj,"btnReview")
    let func_id = 'PN1100',
      func_sub_id = '',
      buy_pt_id = thisObj.values['ddBuyingPoint'],
      payee_vendor = thisObj.values['txtVendor'],
      payee_remit = thisObj.values['ddRemitTo'],
      check_num = thisObj.values['txtCheck'],
      settlement_num = thisObj.values['txt1007'],
      wire_xfer_data = thisObj.values['ddWireTransferData'],
      memo_num = thisObj.values['txtMemo'],
      invoice_num = thisObj.values['txtInvoice'],
      item_code = thisObj.values['ddItemCode'],
      oleic_ind = thisObj.values['ddOleicInd'],
      charge_buy_pt_id = thisObj.values['ddChargeLocation'],
      start_date = dateIsValid(thisObj.values['txtStartDate']),
      end_date = dateIsValid(thisObj.values['txtEndDate']),
      // start_date =thisObj.values['txtStartDate'] == undefined  ? '' : moment(String(thisObj.values['txtStartDate'])).format('MM/DD/YYYY'),
      // end_date = thisObj.values['txtEndDate'] == undefined ? '' : moment(String(thisObj.values['txtEndDate'])).format('MM/DD/YYYY'),
      pnut_type_id = thisObj.values['ddPeanutType'],
      seed_gen = thisObj.values['ddSeedGeneration'],
      check_status = thisObj.values['ddCheckStatus'],
      pay_amount = thisObj.values['txtPayAmount'],
      delv_ind = 'N'
      const search = '#';
      const replaceWith = '~';
      let wire_xfer_data_conversion = ''
      if( wire_xfer_data !==''&& wire_xfer_data !==null && wire_xfer_data !==undefined && search == '#'){
         wire_xfer_data_conversion = wire_xfer_data.split(search).join(replaceWith);
      }else{
         wire_xfer_data_conversion = wire_xfer_data
      }
      if ((payee_vendor == null || payee_vendor == undefined || payee_vendor == "") &&
      (payee_remit == null || payee_remit == undefined || payee_remit == "") &&
      (buy_pt_id == null || buy_pt_id == undefined || buy_pt_id == "") &&
      (check_num == null || check_num == undefined || check_num == "") &&
      (settlement_num == null || settlement_num == undefined || settlement_num == "") &&
      (wire_xfer_data == null || wire_xfer_data == undefined || wire_xfer_data == "") &&
      (memo_num == null || memo_num == undefined || memo_num == "") &&
      (invoice_num == null || invoice_num == undefined || invoice_num == "") &&
      (item_code == null || item_code == undefined || item_code == "") &&
      (oleic_ind == null || oleic_ind == undefined || oleic_ind == "") &&
      (charge_buy_pt_id == null || charge_buy_pt_id == undefined || charge_buy_pt_id == "") &&
      (start_date == null || start_date == undefined || start_date == "") && (invoice_num == null || invoice_num == undefined || invoice_num == "") &&
      (end_date == null || end_date == undefined || end_date == "") &&
      (pnut_type_id == null || pnut_type_id == undefined || pnut_type_id == "") &&
      (seed_gen == null || seed_gen == undefined || seed_gen == "") &&
      (pay_amount == null || pay_amount == undefined || pay_amount == "")) {
      showMessage(thisObj, 'Must enter search criteria.')
      setLoading(false)
    } else {
      if (start_date !== '' && end_date == "") {
        setValue(thisObj, "txtEndDate", start_date)
      }
      if (end_date !== "" && start_date == '') {
        setValue(thisObj, "txtStartDate", end_date)
      }
      AccountPayment.RetrievePaymentActivity(func_id, func_sub_id, buy_pt_id, payee_vendor, payee_remit,
        check_num, settlement_num, wire_xfer_data_conversion, memo_num, invoice_num, item_code, oleic_ind, charge_buy_pt_id,
        start_date, end_date, pnut_type_id, seed_gen, check_status, pay_amount)
        .then(response => {
          let TransformedRowsArray = [];
          if (response != undefined && response.length > 0) {
            let data = response;
            let obj = {};
            let lblTotalCheckProceeds = 0.00;
            let lblTotalSystemVoids = 0.00, lblTotalWireTransfers = 0.00,
              lblGrandTotal = 0.00;

            for (let i = 0; i < data.length; i++) {
              let vendorname=data[i].payee_vendor + ' ' + data[i].payee_remit + ' - ' + data[i].vendor_name
              const find = '-';
              const replace_With = '(';
              
              obj.txtcolCheck = data[i].check_num
              obj.txtcolVendor = vendorname.length > 30 ?vendorname.substring(0,27)+'.'+'..':vendorname
              obj.txt2 = data[i].buy_pt_id + ' - ' + data[i].buy_pt_name
              obj.txtcolIssueDate = moment(data[i].issued_date).format('MM/DD/YYYY h:mm A')
              obj.txtcolProceeds =findspecial(`${numberFormat(data[i].check_proceeds)}`) 
              // obj.txtcolStatus=data[i].check_status
              obj.txtcolWireXferData = data[i].wire_xfer_data
              obj.txtcolWireAcknowledgement = data[i].wire_ack_ind
              obj.txtcolWireConfirmation = data[i].wire_confirm_ind
              obj.LstrBuyPtID = data[i].buy_pt_id
              obj.LstrPayeeVendor = data[i].payee_vendor
              obj.check_proceeds = data[i].check_proceeds
              obj.txtcolVendor1=data[i].payee_vendor + ' ' + data[i].payee_remit + ' - ' + data[i].vendor_name
              if (data[i].payee_vendor !== '') {
                obj.txtcolVendor =  vendorname.length > 30 ?vendorname.substring(0,27)+'.'+'..':vendorname
              } else {
                obj.txtcolVendor = 'No Payee'
              }
              switch (data[i].check_status) {
                case "C":
                  obj.txtcolStatus = 'Cleared';
                  lblTotalCheckProceeds = lblTotalCheckProceeds + parseFloat(data[i].check_proceeds);
                  break;
                case "I":
                  obj.txtcolStatus = 'Issued';
                  lblTotalCheckProceeds = lblTotalCheckProceeds + parseFloat(data[i].check_proceeds);
                  break;
                case "R":
                  obj.txtcolStatus = 'Sent to Recon';
                  lblTotalCheckProceeds = lblTotalCheckProceeds + parseFloat(data[i].check_proceeds);
                  break;
                case "V":
                  obj.txtcolStatus = 'Void';
                  break;
                case "S":

                  if (data[i].wire_xfer_data == '') {
                    obj.txtcolStatus = 'System Void';
                    lblTotalSystemVoids = lblTotalSystemVoids + parseFloat(data[i].check_proceeds);
                  } else {
                    obj.txtcolStatus = "Wire Transfer";
                    lblTotalWireTransfers = lblTotalWireTransfers + parseFloat(data[i].check_proceeds);
                  }

              }
              lblGrandTotal = lblTotalCheckProceeds + lblTotalSystemVoids + lblTotalWireTransfers;

              TransformedRowsArray.push(obj)
              obj = {}
            }
            setValue(thisObj, "gridSearchResults", TransformedRowsArray);
            setValue(thisObj, "lblCheckProceedsValue",findspecial(`${numberFormat(lblTotalCheckProceeds)}`) ); 
            setValue(thisObj, "lblSystemVoidsValue",findspecial(`${numberFormat(lblTotalSystemVoids)}`));
            setValue(thisObj, "lblWireTransfersValue", findspecial(`${numberFormat(lblTotalWireTransfers)}`))
            setValue(thisObj, "lblGrandTotalValue", findspecial(`${numberFormat(lblGrandTotal)}`))
            setLoading(false)
          } else {
            let TransformedRowsArray = [];
            disable(thisObj,'btnReview')
            let lblTotalCheckProceeds = 0.00;
            let lblTotalSystemVoids = 0.00, lblTotalWireTransfers = 0.00,
              lblGrandTotal = 0.00;
            setValue(thisObj, "gridSearchResults", TransformedRowsArray);
            setValue(thisObj, "lblCheckProceedsValue", `$ ${lblTotalCheckProceeds}`);
            setValue(thisObj, "lblSystemVoidsValue", `$ ${lblTotalSystemVoids}`);
            setValue(thisObj, "lblWireTransfersValue", `$ ${lblTotalWireTransfers}`)
            setValue(thisObj, "lblGrandTotalValue", `$ ${lblGrandTotal}`)
            setLoading(false)
          }
        })
    }
  };
  thisObj.onbtnSearchClick = onbtnSearchClick;


  //Method for binding Wire xfer data dropdown
  const CheckchkboxWireTransferDatar = () => {
    let chkboxWireTransferData = document.getElementById('chkboxWireTransferData').checked;
    if (chkboxWireTransferData) {
      let js = []
      js.push({ key: 'BNF=/AC-4992OBI=CCC/1/XXXXXX/SCH#XXXX', description: 'BNF=/AC-4992OBI=CCC/1/XXXXXX/SCH#XXXX' })
      js.push({ key: 'XXXXX/CP NAME', description: 'XXXXX/CP NAME' })
      thisObj.setState(current => {
        return {
          ...current,
          ddWireTransferData: {
            ...state["ddWireTransferData"],
            valueList: js
          }
        }
      })
      setValue(thisObj, 'ddWireTransferData', js.at(0).key)
      setValue(thisObj,'txtWireXfer',js.at(0).key)
      enable(thisObj,'txtWireXfer')
      document.getElementById('ddWireTransferData').disabled = false;
    } else {
      let js = []
      thisObj.setState(current => {
        return {
          ...current,
          ddWireTransferData: {
            ...state["ddWireTransferData"],
            valueList: js
          }
        }
      })
      setValue(thisObj, 'ddWireTransferData', '')
      setValue(thisObj,'txtWireXfer','')
      disable(thisObj,'txtWireXfer')
      document.getElementById('ddWireTransferData').disabled = true;
    }
  
  }
  // Checkbox Seed Grower Check Event
  const onchkboxWireTransferDataChange = () => {
    CheckchkboxWireTransferDatar();
  };
  thisObj.onchkboxWireTransferDataChange = onchkboxWireTransferDataChange;

  const onddWireTransferDataChange = () =>{
    setValue(thisObj,'txtWireXfer',getValue(thisObj,'ddWireTransferData'))
  }

  thisObj.onddWireTransferDataChange = onddWireTransferDataChange

  const ontxtWireXferChange = () =>{
    setValue(thisObj,'ddWireTransferData',getValue(thisObj,'txtWireXfer'))
  }

  thisObj.ontxtWireXferChange = ontxtWireXferChange

  const ongridSearchResultsRowSelect = (rowSelected) => {
    let data3 = rowSelected;
    if (data3.selectedRows) {
      data3 = data3.selectedRows[0];
      enable(thisObj, 'btnReview')
      setData(thisObj, 'reviewdata', data3)
    }

  };
  thisObj.ongridSearchResultsRowSelect = ongridSearchResultsRowSelect;

  const validateTextBox = (data) => {
    var res = ''
    for (var i = 0; i < data.length; i++) {
      if (data[i].charCodeAt(0) >= 48 && data[i].charCodeAt(0) <= 57) {
        res += data[i]
      }
    }
    return res;
  }
  const validatePayamount = (data) => {
    var res = ''
    let match = data.match(/^[0-9-.]*$/)
    if (match !== null) {
      res = data
    }
    return res;
  }

  // START_USER_CODE-USER_METHODS
  const ontxt1007Change = () => {
    try {
      setValue(thisObj, "txt1007", validateTextBox(txt1007.value))
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during txt1007 change event"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.ontxt1007Change = ontxt1007Change;

  const ontxtMemoChange = () => {
    try {
      setValue(thisObj, "txtMemo", validateTextBox(txtMemo.value))
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during txtMemo change event"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.ontxtMemoChange = ontxtMemoChange;

  const ontxtPayAmountChange = () => {
    try {

      setValue(thisObj, "txtPayAmount", validatePayamount(txtPayAmount.value))
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during txtPayAmount change event"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.ontxtPayAmountChange = ontxtPayAmountChange;

  const dateIsValid = (dateEntered) => {
    let datavaild = ''
    if (dateEntered !== "" && dateEntered !== 'Invalid date' && dateEntered !== null && dateEntered !== undefined) {
      datavaild = moment(String(dateEntered)).format('MM/DD/YYYY')
      return datavaild
    } else {
      return datavaild
    }
  }

  const onbtn2Click = () => {
    try {
      thisObj.setFieldValue('ddbutton', 'btn2')
      goTo(thisObj, "ContractManagement#VendorLookup#DEFAULT#true#Click");
    } catch (err) {
    }
  };
  thisObj.onbtn2Click = onbtn2Click;

  // Reviewbutton Function

  const onbtnReviewClick = () => {
    try {
      let rowselectedvalue = getValue(thisObj, "gridSearchResults");
      let row = getSelectedRowNumber(thisObj, "gridSearchResults")
      // set frmreviewcheck data
      setData(thisObj, 'frmreviewcheckReadCheckInfo', rowselectedvalue[row])
      goTo(thisObj, "AccountPayment#ReviewCheck#DEFAULT#true#Click");
    } catch (err) {
    }
  };
  thisObj.onbtnReviewClick = onbtnReviewClick;

  // Correct button Function

  const onbtnCorrectClick = () => {
    btnCorrect
    try {
      let rowselectedvalue = getValue(thisObj, "gridSearchResults");
      let row = getSelectedRowNumber(thisObj, "gridSearchResults")
      setData(thisObj, 'frmCorrectCheckdata', rowselectedvalue[row])
    } catch (err) {
    }
  };
  thisObj.onbtnCorrectClick = onbtnCorrectClick;

  // Void button Function

  const onbtnVoidClick = () => {
    try {
      let rowselectedvalue = getValue(thisObj, "gridSearchResults");
      let row = getSelectedRowNumber(thisObj, "gridSearchResults")
      let selectedrow = rowselectedvalue[row].txtcolCheck
      if (confirm("Are You Sure You want To Void The Selected Checks?") == true) {
        AccountPayment.UpdateVoidCheckDetails(buy_pt_id, checkdt, cnum)
          .then(response => {
            if (response != undefined) {
              selectedrowtxtcolStatus = rowselectedvalue[row].txtcolStatus
              rowselectedvalue.forEach(v => {
                if (v.txtcolCheck === selectedrow) v.txtcolStatus = "Void";
              });
              /*** 
                selectedrow=rowselectedvalue[row].txtcolCheck
                selectedrowtxtcolStatus=rowselectedvalue[row].txtcolStatus
                if(selectedrowtxtcolStatus === "Issued" || selectedrowtxtcolStatus === "Sent to Recon")
                  {
                  rowselectedvalue.forEach(v => {
                    if (v.txtcolCheck === selectedrow) v.txtcolStatus ="Void";
                  });
                }
              */
            } else {
              showMessage(thisObj, 'An error occured while voiding selected checks!!!')
            }
          })
      }

    } catch (err) {
    }
  };
  thisObj.onbtnVoidClick = onbtnVoidClick;

  //creating excel file in collapse format
  const onbtnExportToExcelClick = () => {
    try {

      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = '.xlsx';
      let excelData = getValue(thisObj, "gridSearchResults")
      var fileName = "Check Search"
      const arrayData = []
      if (excelData.length > 1)
        for (var i = 0; i < excelData.length; i++) {
          var obj = {
            "Check #": ExcelNumericDataConvert(excelData[i].txtcolCheck),
            "Vendor": excelData[i].txtcolVendor1,
            "Location": excelData[i].txt2,
            "Issue Date": moment(excelData[i].txtcolIssueDate).format('MM/DD/YYYY h:mm A'),
            "Proceeds": ExcelNumericDataConvert(excelData[i].check_proceeds),
            "Status": excelData[i].txtcolStatus,
            "Wire Xfer Data": excelData[i].txtcolWireXferData,
            "Wire Acknowledgement": excelData[i].txtcolWireAcknowledgement,
            "Wire Confirmation": excelData[i].txtcolWireConfirmation,
          }

          arrayData.push(obj);

        } else {
        var obj = {
          "Check #": '',
          "Vendor": '',
          "Location": '',
          "Issue Date": '',
          "Proceeds": '',
          "Status": '',
          "Wire Xfer Data": '',
          "Wire Acknowledgement": '',
          "Wire Confirmation": '',
        }

        arrayData.push(obj);
      }

      const ws = XLSX.utils.json_to_sheet(arrayData)
      var wscols = [
        {width:10},
        {width:35},
        {width:30},
        {width:25},
        {width:15},
        {width:10},
        {width:15},
        {width:15},
        {width:15}
    ];
    ws['!cols'] = wscols;
      const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      var FileSaver = require('file-saver');
      FileSaver.saveAs(data, fileName + fileExtension);
    } catch (err) {

    }

  }
  thisObj.onbtnExportToExcelClick = onbtnExportToExcelClick;
  // code for print pdf...
  const onbtnPrintClick = () => {
    try {
      var data1 = getValue(thisObj, "gridSearchResults")
      let lblTotalCheckProceeds = 0.00;
      let lblTotalSystemVoids = 0.00, lblTotalWireTransfers = 0.00,
        lblGrandTotal = 0.00;

      if (data1.length != 0) {
        var res = []
        for (var i = 0; i < data1.length; i++) {
          res.push([
            data1[i].txtcolCheck,
            data1[i].txtcolVendor1,
            data1[i].txt2,
            moment(data1[i].txtcolIssueDate).format('MM/DD/YYYY h:mm A'),
            data1[i].txtcolProceeds,
            data1[i].txtcolStatus,
            data1[i].txtcolWireXferData,
            data1[i].txtcolWireAcknowledgement,
            data1[i].txtcolWireConfirmation,
          ])
          switch (data1[i].txtcolStatus) {
            case "Cleared":
              lblTotalCheckProceeds = lblTotalCheckProceeds + parseFloat(data1[i].check_proceeds);
              break;
            case "Issued":
              lblTotalCheckProceeds = lblTotalCheckProceeds + parseFloat(data1[i].check_proceeds);
              break;
            case "Sent to Recon":
              lblTotalCheckProceeds = lblTotalCheckProceeds + parseFloat(data1[i].check_proceeds);
              break;
            case "Void":
              break;
            case "System Void":
              if (data1[i].txtcolWireXferData == '') {
                lblTotalSystemVoids = lblTotalSystemVoids + parseFloat(data1[i].check_proceeds);
              } else {
                lblTotalWireTransfers = lblTotalWireTransfers + parseFloat(data1[i].check_proceeds);
              }

          }
          lblGrandTotal = lblTotalCheckProceeds + lblTotalSystemVoids + lblTotalWireTransfers;
        }
        res.push(['', '', '', "Check Proceeds:", `${numberFormat(lblTotalCheckProceeds)}`, '', '', '', ''])
        res.push(['', '', '', "System Voids:", `${numberFormat(lblTotalSystemVoids)}`, '', '', ''])
        res.push(['', '', '', "Wire Transfers:", `${numberFormat(lblTotalWireTransfers)}`, '', '', '', ''])
        res.push(['', '', '', "Grand Total:", `${numberFormat(lblGrandTotal)}`, '', '', '', ''])
        var obj = new jsPDF();
        const addFooters = obj => {
          const pageCount = obj.internal.getNumberOfPages()
          let sDocName = `${cropYearFromLS} Checks`,
            buy_pt_id = thisObj.values['ddBuyingPoint'],
            payee_vendor = thisObj.values['txtVendor']

          if (buy_pt_id !== '' && buy_pt_id !== null && buy_pt_id !== undefined) {
            sDocName = `${cropYearFromLS} Checks for Buying Point: ${buy_pt_id}`
            if (payee_vendor !== '' && payee_vendor !== null && payee_vendor !== undefined) {
              sDocName = `${cropYearFromLS} Checks for Buying Point: ${buy_pt_id} Vendor: ${payee_vendor}`
            }
          } else if (payee_vendor !== '' && payee_vendor !== null && payee_vendor !== undefined) {
            sDocName = `${cropYearFromLS} Checks for Vendor: ${payee_vendor}`
          }
          obj.setFont('helvetica', 'italic')
          obj.setFontSize(8)
          for (var i = 1; i <= pageCount; i++) {
            obj.setPage(i)
            obj.text(sDocName, 12, 285);
            obj.text(190, 285, String(i))

          }
        }
        obj.autoTable({
          startY: 5,
          startX: 20,
          styles: { fontSize: 7, lineWidth: 0.2, color: 10 },
          body: res,
          theme: 'plain',
          fontStyle: 'normal',
          head: [
            [
              "Check #", "Vendor", "Location", "Issue Date", "Proceeds", "Status", "Wire Xfer Data", "Wire Acknowledgemen", "Wire Confirmation"
            ]
          ]
        });
        addFooters(obj)
        // obj.setFontSize(7)
        let fileName = prompt("Please enter the file name :");
        if (fileName != null && fileName != undefined) {
          if (fileName != '') {
            obj.save(fileName + '.pdf')
          }
          else {
            alert("Routine:Accdist.cmdPrint_Click")
          }
        }
        else {
          alert("Routine:Accdist.cmdPrint_Click")
        }
      } else {
        var res = []
        res.push(['', '', '', "Check Proceeds:", lblTotalCheckProceeds.toFixed(2), '', ''])
        res.push(['', '', '', "System Voids:", lblTotalSystemVoids.toFixed(2), '', ''])
        res.push(['', '', '', "Wire Transfers:", lblTotalWireTransfers.toFixed(2), '', ''])
        res.push(['', '', '', "Grand Total:", lblGrandTotal.toFixed(2), '', ''])
        var obj = new jsPDF();
        let sDocName = `${cropYearFromLS} Checks`
        const addFooters = obj => {
          const pageCount = obj.internal.getNumberOfPages()

          obj.setFont('helvetica', 'italic')
          obj.setFontSize(8)
          for (var i = 1; i <= pageCount; i++) {
            obj.setPage(i)
            obj.text(sDocName, 12, 285);
            obj.text(190, 285, String(i))
          }
        }
        obj.autoTable({
          startY: 5,
          startX: 20,
          styles: { fontSize: 7, lineWidth: 0.2, color: 10 },
          body: res,
          theme: 'plain',
          fontStyle: 'normal',
          head: [
            [
              "Check #", "Vendor", "Location", "Issue Date", "Proceeds", "Status", "Wire Xfer Data", "Wire Acknowledgemen", "Wire Confirmation"
            ]
          ]
        });
        addFooters(obj)
        // obj.setFontSize(7)
        let fileName = prompt("Please enter the file name :");
        if (fileName != null && fileName != undefined) {
          if (fileName != '') {
            obj.save(fileName + '.pdf')
          }
          else {
            alert("Routine:Accdist.cmdPrint_Click")
          }
        }
        else {
          alert("Routine:Accdist.cmdPrint_Click")
        }
      }
    }
    catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnPrintscreen event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnPrintClick = onbtnPrintClick;

  const ontxt1007Blur = async () => {
    let txt1007_ = getValue(thisObj, 'txt1007')
    if (txt1007_ !== '' && txt1007_ !== undefined) {
      let value = String(txt1007_).padStart(7, '0')
      setValue(thisObj, 'txt1007', value)
    } else {
      setValue(thisObj, 'txt1007', '')
    }
  }
  thisObj.ontxt1007Blur = ontxt1007Blur;

  const ontxtCarrierAbbrBlur = async () => {
    let js = [];
    thisObj.setFieldValue('ddbutton', 'txtVendor')
    let txtCarrier_Abbrv = getValue(thisObj, 'txtCarrierAbbr')
    if (txtCarrier_Abbrv !== '') {
      let GetScac = await AccountPayment.RetrieveScacVendorLookupDetails('', txtCarrier_Abbrv);
      if (GetScac.length > 0) {
        setValue(thisObj, 'txtCarrierAbbr', GetScac[0].scac)
        setValue(thisObj, 'txtVendorName', GetScac[0].name)
        VendorDetails(GetScac[0].number);
      } else {
        setValue(thisObj, 'txtCarrierAbbr', '')
        setValue(thisObj, 'txtVendor', '')
        setValue(thisObj, 'txtVendorName', '')
        thisObj.setState(current => {
          return {
            ...current,
            ddRemitTo: {
              ...state["ddRemitTo"],
              valueList: js
            },
          }
        });
        showMessage(thisObj, "The carrier does not exist or does not have an active status!");
      }
   }
  }
  thisObj.ontxtCarrierAbbrBlur = ontxtCarrierAbbrBlur;

  const ontxtVendorBlur = async () => {
    thisObj.setFieldValue('ddbutton', 'txtVendor')
    let txtVendor_ = getValue(thisObj, 'txtVendor')
    if (txtVendor_ !== '') {
      VendorDetails(txtVendor_)
    } else {
      VendorDetails(null)
    }
  }
  thisObj.ontxtVendorBlur = ontxtVendorBlur;

  const ontxtPayAmountBlur = async () => {
    let txtPayAmount_ = getValue(thisObj, 'txtPayAmount')
    if (txtPayAmount_ !== null) {
      setValue(thisObj, 'txtPayAmount', txtPayAmount_)
    }
  }
  thisObj.ontxtPayAmountBlur = ontxtPayAmountBlur;

  const ongridSearchResultsDblclick = () => {
    try {
      if (thisObj.state.gridSearchResults.selected[0].txtcolCheck !== undefined) {
      let rowselectedvalue = getValue(thisObj, "gridSearchResults");
      let row = getSelectedRowNumber(thisObj, "gridSearchResults")
      // set frmreviewcheck data
      setData(thisObj, 'frmreviewcheckReadCheckInfo', rowselectedvalue[row])
      goTo(thisObj, "AccountPayment#ReviewCheck#DEFAULT#true#Click");
      }
      else {
        showMessage(thisObj, "error while selecting contract");
        return false
      }
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "error on double click , please try open button"
        );
      }
    }
  }
  thisObj.ongridSearchResultsDblclick = ongridSearchResultsDblclick;

  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />

            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              <Loading loading={loading}></Loading>
              {/* START_USER_CODE-USER_BEFORE_CheckInquiry*/}

              {/* END_USER_CODE-USER_BEFORE_CheckInquiry*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxCheckDetails1*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxCheckDetails1*/}

              <GroupBoxWidget
                conf={state.grpbxCheckDetails1}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_ddBuyingPoint*/}

                {/* END_USER_CODE-USER_BEFORE_ddBuyingPoint*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddBuyingPoint}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddBuyingPoint*/}

                {/* END_USER_CODE-USER_AFTER_ddBuyingPoint*/}
                {/* START_USER_CODE-USER_BEFORE_txtMemo*/}

                {/* END_USER_CODE-USER_BEFORE_txtMemo*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtMemo}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtMemo*/}

                {/* END_USER_CODE-USER_AFTER_txtMemo*/}
                {/* START_USER_CODE-USER_BEFORE_ddChargeLocation*/}

                {/* END_USER_CODE-USER_BEFORE_ddChargeLocation*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddChargeLocation}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddChargeLocation*/}

                {/* END_USER_CODE-USER_AFTER_ddChargeLocation*/}
                {/* START_USER_CODE-USER_BEFORE_txtVendor*/}

                {/* END_USER_CODE-USER_BEFORE_txtVendor*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtVendor}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtVendor*/}

                {/* END_USER_CODE-USER_AFTER_txtVendor*/}
                {/* START_USER_CODE-USER_BEFORE_btn2*/}

                {/* END_USER_CODE-USER_BEFORE_btn2*/}

                <ButtonWidget
                  conf={state.btn2}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btn2*/}

                {/* END_USER_CODE-USER_AFTER_btn2*/}
                {/* START_USER_CODE-USER_BEFORE_txtCarrierAbbr*/}

                {/* END_USER_CODE-USER_BEFORE_txtCarrierAbbr*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCarrierAbbr}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtCarrierAbbr*/}

                {/* END_USER_CODE-USER_AFTER_txtCarrierAbbr*/}
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtVendorName}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }></TextBoxWidget>

                {/* START_USER_CODE-USER_BEFORE_txtInvoice*/}

                {/* END_USER_CODE-USER_BEFORE_txtInvoice*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtInvoice}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtInvoice*/}

                {/* END_USER_CODE-USER_AFTER_txtInvoice*/}
                {/* START_USER_CODE-USER_BEFORE_txtStartDate*/}

                {/* END_USER_CODE-USER_BEFORE_txtStartDate*/}
                <DateWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtStartDate}
                  screenConf={state}
                ></DateWidget>
                {/* START_USER_CODE-USER_AFTER_txtStartDate*/}

                {/* END_USER_CODE-USER_AFTER_txtStartDate*/}
                {/* START_USER_CODE-USER_BEFORE_txtEndDate*/}

                {/* END_USER_CODE-USER_BEFORE_txtEndDate*/}
                <DateWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtEndDate}
                  screenConf={state}
                ></DateWidget>
                {/* START_USER_CODE-USER_AFTER_txtEndDate*/}

                {/* END_USER_CODE-USER_AFTER_txtEndDate*/}
                {/* START_USER_CODE-USER_BEFORE_ddRemitTo*/}

                {/* END_USER_CODE-USER_BEFORE_ddRemitTo*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddRemitTo}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddRemitTo*/}

                {/* END_USER_CODE-USER_AFTER_ddRemitTo*/}
                {/* START_USER_CODE-USER_BEFORE_ddItemCode*/}

                {/* END_USER_CODE-USER_BEFORE_ddItemCode*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddItemCode}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddItemCode*/}

                {/* END_USER_CODE-USER_AFTER_ddItemCode*/}
                {/* START_USER_CODE-USER_BEFORE_ddPeanutType*/}

                {/* END_USER_CODE-USER_BEFORE_ddPeanutType*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddPeanutType}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddPeanutType*/}

                {/* END_USER_CODE-USER_AFTER_ddPeanutType*/}
                {/* START_USER_CODE-USER_BEFORE_txtCheck*/}

                {/* END_USER_CODE-USER_BEFORE_txtCheck*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCheck}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtCheck*/}

                {/* END_USER_CODE-USER_AFTER_txtCheck*/}
                {/* START_USER_CODE-USER_BEFORE_txtPayAmount*/}

                {/* END_USER_CODE-USER_BEFORE_txtPayAmount*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtPayAmount}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtPayAmount*/}

                {/* END_USER_CODE-USER_AFTER_txtPayAmount*/}
                {/* START_USER_CODE-USER_BEFORE_ddSeedGeneration*/}

                {/* END_USER_CODE-USER_BEFORE_ddSeedGeneration*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddSeedGeneration}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddSeedGeneration*/}

                {/* END_USER_CODE-USER_AFTER_ddSeedGeneration*/}
                {/* START_USER_CODE-USER_BEFORE_txt1007*/}

                {/* END_USER_CODE-USER_BEFORE_txt1007*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txt1007}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txt1007*/}

                {/* END_USER_CODE-USER_AFTER_txt1007*/}
                {/* START_USER_CODE-USER_BEFORE_ddOleicInd*/}

                {/* END_USER_CODE-USER_BEFORE_ddOleicInd*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddOleicInd}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddOleicInd*/}

                {/* END_USER_CODE-USER_AFTER_ddOleicInd*/}
                {/* START_USER_CODE-USER_BEFORE_ddCheckStatus*/}

                {/* END_USER_CODE-USER_BEFORE_ddCheckStatus*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddCheckStatus}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddCheckStatus*/}

                {/* END_USER_CODE-USER_AFTER_ddCheckStatus*/}
                {/* START_USER_CODE-USER_BEFORE_chkboxWireTransferData*/}

                {/* END_USER_CODE-USER_BEFORE_chkboxWireTransferData*/}

                <CheckboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkboxWireTransferData}
                  screenConf={state}
                ></CheckboxWidget>
                {/* START_USER_CODE-USER_AFTER_chkboxWireTransferData*/}

                {/* END_USER_CODE-USER_AFTER_chkboxWireTransferData*/}
                {/* START_USER_CODE-USER_BEFORE_ddWireTransferData*/}

                {/* END_USER_CODE-USER_BEFORE_ddWireTransferData*/}
                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtWireXfer}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddWireTransferData}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddWireTransferData*/}

                {/* END_USER_CODE-USER_AFTER_ddWireTransferData*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxCheckDetails1*/}

              {/* END_USER_CODE-USER_AFTER_grpbxCheckDetails1*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxSearchResults*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxSearchResults*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget
                conf={state.grpbxSearchResults}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_lblSearchResults*/}

                {/* END_USER_CODE-USER_BEFORE_lblSearchResults*/}

                <LabelWidget
                  values={values}
                  conf={state.lblSearchResults}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblSearchResults*/}

                {/* END_USER_CODE-USER_AFTER_lblSearchResults*/}
                {/* START_USER_CODE-USER_BEFORE_btnSearch*/}

                {/* END_USER_CODE-USER_BEFORE_btnSearch*/}

                <ButtonWidget
                  conf={state.btnSearch}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnSearch*/}

                {/* END_USER_CODE-USER_AFTER_btnSearch*/}
                {/* START_USER_CODE-USER_BEFORE_btnCorrect*/}

                {/* END_USER_CODE-USER_BEFORE_btnCorrect*/}

                <ButtonWidget
                  conf={state.btnCorrect}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCorrect*/}

                {/* END_USER_CODE-USER_AFTER_btnCorrect*/}
                {/* START_USER_CODE-USER_BEFORE_btnVoid*/}

                {/* END_USER_CODE-USER_BEFORE_btnVoid*/}

                <ButtonWidget
                  conf={state.btnVoid}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnVoid*/}

                {/* END_USER_CODE-USER_AFTER_btnVoid
                {/* START_USER_CODE-USER_BEFORE_btnReview*/}

                {/* END_USER_CODE-USER_BEFORE_btnReview*/}

                <ButtonWidget
                  conf={state.btnReview}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnReview*/}

                {/* END_USER_CODE-USER_AFTER_btnReview*/}
                {/* START_USER_CODE-USER_BEFORE_gridSearchResults*/}

                {/* END_USER_CODE-USER_BEFORE_gridSearchResults*/}

                <GridWidget
                  conf={state.gridSearchResults}
                  screenConf={state}
                  onRowChange={selectedRows => onGridRowChange(selectedRows)}
                  setFieldValue={setFieldValue}
                  linkClick={event => {
                    if (state.gridSearchResults.selected.length > 0) {

                      thisObj.selectedRow = state.gridSearchResults.selected[0];
                    }
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  }
                  errors={errors}
                  touched={touched}
                  rows={values.gridSearchResults}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  refObject={thisObj}
                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_gridSearchResults*/}

                {/* END_USER_CODE-USER_AFTER_gridSearchResults*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxbtn1*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxbtn1*/}

                <GroupBoxWidget conf={state.grpbxbtn1} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_btn1*/}

                  {/* END_USER_CODE-USER_BEFORE_btn1*/}

                  <ButtonWidget
                    conf={state.btn1}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btn1*/}

                  {/* END_USER_CODE-USER_AFTER_btn1*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxbtn1*/}

                {/* END_USER_CODE-USER_AFTER_grpbxbtn1*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxCheckDetails2*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxCheckDetails2*/}

                <GroupBoxWidget
                  conf={state.grpbxCheckDetails2}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblCheckProceeds*/}

                  {/* END_USER_CODE-USER_BEFORE_lblCheckProceeds*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblCheckProceeds}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblCheckProceeds*/}

                  {/* END_USER_CODE-USER_AFTER_lblCheckProceeds*/}
                  {/* START_USER_CODE-USER_BEFORE_lblCheckProceedsValue*/}

                  {/* END_USER_CODE-USER_BEFORE_lblCheckProceedsValue*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblCheckProceedsValue}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblCheckProceedsValue*/}

                  {/* END_USER_CODE-USER_AFTER_lblCheckProceedsValue*/}
                  {/* START_USER_CODE-USER_BEFORE_lblSystemVoids*/}

                  {/* END_USER_CODE-USER_BEFORE_lblSystemVoids*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblSystemVoids}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblSystemVoids*/}

                  {/* END_USER_CODE-USER_AFTER_lblSystemVoids*/}
                  {/* START_USER_CODE-USER_BEFORE_lblSystemVoidsValue*/}

                  {/* END_USER_CODE-USER_BEFORE_lblSystemVoidsValue*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblSystemVoidsValue}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblSystemVoidsValue*/}

                  {/* END_USER_CODE-USER_AFTER_lblSystemVoidsValue*/}
                  {/* START_USER_CODE-USER_BEFORE_lblWireTransfers*/}

                  {/* END_USER_CODE-USER_BEFORE_lblWireTransfers*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblWireTransfers}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblWireTransfers*/}

                  {/* END_USER_CODE-USER_AFTER_lblWireTransfers*/}
                  {/* START_USER_CODE-USER_BEFORE_lblWireTransfersValue*/}

                  {/* END_USER_CODE-USER_BEFORE_lblWireTransfersValue*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblWireTransfersValue}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblWireTransfersValue*/}

                  {/* END_USER_CODE-USER_AFTER_lblWireTransfersValue*/}
                  {/* START_USER_CODE-USER_BEFORE_lblGrandTotal*/}

                  {/* END_USER_CODE-USER_BEFORE_lblGrandTotal*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblGrandTotal}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblGrandTotal*/}

                  {/* END_USER_CODE-USER_AFTER_lblGrandTotal*/}
                  {/* START_USER_CODE-USER_BEFORE_lblGrandTotalValue*/}

                  {/* END_USER_CODE-USER_BEFORE_lblGrandTotalValue*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblGrandTotalValue}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblGrandTotalValue*/}

                  {/* END_USER_CODE-USER_AFTER_lblGrandTotalValue*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxCheckDetails2*/}

                {/* END_USER_CODE-USER_AFTER_grpbxCheckDetails2*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxSearchResults*/}

              {/* END_USER_CODE-USER_AFTER_grpbxSearchResults*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrActions}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
                {/* START_USER_CODE-USER_BEFORE_btnExportToExcel*/}

                {/* END_USER_CODE-USER_BEFORE_btnExportToExcel*/}

                <ButtonWidget
                  conf={state.btnExportToExcel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnExportToExcel*/}

                {/* END_USER_CODE-USER_AFTER_btnExportToExcel*/}
                {/* START_USER_CODE-USER_BEFORE_btnPrint*/}

                {/* END_USER_CODE-USER_BEFORE_btnPrint*/}

                <ButtonWidget
                  conf={state.btnPrint}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnPrint*/}

                {/* END_USER_CODE-USER_AFTER_btnPrint*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* START_USER_CODE-USER_AFTER_CheckInquiry*/}

              {/* END_USER_CODE-USER_AFTER_CheckInquiry*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(AccountPayment_CheckInquiry);
