/* istanbul ignore file */
import React from "react";

const GetMessage = props => {
  let response = props.response;
  let msg;
  let subErrors;
  if (response.error) {
    msg = response.error.message;
    if (response.error.subErrors) {
      subErrors = (
        <ul>
          {response.error.subErrors.map(message => {
            return <li>{message}</li>;
          })}
        </ul>
      );
    }
  }

  if (response.data) {
    if (response.data.message) {
      msg = response.data.message;
    }
  }

  return (
    <div>
      {msg}
      <br />
      {subErrors}
    </div>
  );
};

export default GetMessage;
