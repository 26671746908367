/* eslint-disable*/
import React from "react";
import WarehouseReceipts_EWRSendUpdate from "./EWRSendUpdate";
import { screen, cleanup } from "@testing-library/react";
import "regenerator-runtime/runtime";
import userEvent from "@testing-library/user-event";
import { act } from "react-dom/test-utils";
import axios from "axios";
import renderTestScreen from "../../../../common/renderTestScreen";
import { t } from "../../../../__mocks__/react-i18next";

describe("EWRSendUpdate Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<WarehouseReceipts_EWRSendUpdate />);
    });
  });
  afterEach(cleanup);
  test("is EWRSendUpdate Loads Successfully", () => {
    expect(screen.getByText("EWRSendUpdate")).toBeInTheDocument;
  });
  test("Custom Test Cases for EWRSendUpdate", () => {
    // START_USER_CODE-USER_EWRSendUpdate_Custom_Test_Case
    // END_USER_CODE-USER_EWRSendUpdate_Custom_Test_Case
  });
});
describe("Widget Test Cases", () => {
  beforeEach(async () => {
    act(() => {
      renderTestScreen(<WarehouseReceipts_EWRSendUpdate />);
    });
  });
  afterEach(cleanup);
  test("btnClose(Button Widget) Test Cases", async () => {
    const btnClose = screen.getByTestId("btnClose");
    expect(btnClose).toBeInTheDocument;
    expect(btnClose.textContent).toEqual(
      t("WarehouseReceipts:EWRSendUpdate_btnClose")
    );
  });
  test("Custom Test Cases for btnClose", () => {
    // START_USER_CODE-USER_btnClose_TEST
    // END_USER_CODE-USER_btnClose_TEST
  });
  test("btnSend(Button Widget) Test Cases", async () => {
    const btnSend = screen.getByTestId("btnSend");
    expect(btnSend).toBeInTheDocument;
    expect(btnSend.textContent).toEqual(
      t("WarehouseReceipts:EWRSendUpdate_btnSend")
    );
  });
  test("Custom Test Cases for btnSend", () => {
    // START_USER_CODE-USER_btnSend_TEST
    // END_USER_CODE-USER_btnSend_TEST
  });
  test("grpbxEWRSendUpdate(GroupBox Widget) Test Cases", async () => {
    const grpbxEWRSendUpdate = screen.getByTestId("grpbxEWRSendUpdate");
    expect(grpbxEWRSendUpdate.tagName).toBe("BUTTON");
    expect(grpbxEWRSendUpdate.type).toBe("button");
    expect(grpbxEWRSendUpdate.classList).toContain("btn");
  });
  test("Custom Test Cases for grpbxEWRSendUpdate", () => {
    // START_USER_CODE-USER_grpbxEWRSendUpdate_TEST
    // END_USER_CODE-USER_grpbxEWRSendUpdate_TEST
  });
  test("lblFarmName(Label Widget) Test Cases", async () => {
    const lblFarmName = screen.getByTestId("lblFarmName");
    expect(lblFarmName.tagName).toBe("LABEL");
    expect(lblFarmName.classList).toContain("form-label");
    expect(lblFarmName.textContent).toEqual(
      t("WarehouseReceipts:EWRSendUpdate_lblFarmName")
    );
  });
  test("Custom Test Cases for lblFarmName", () => {
    // START_USER_CODE-USER_lblFarmName_TEST
    // END_USER_CODE-USER_lblFarmName_TEST
  });
  test("txtActionCode(Textbox Widget) Test Cases", async () => {
    const txtActionCode = screen.getByTestId("txtActionCode");
    expect(txtActionCode.tagName).toBe("INPUT");
    expect(txtActionCode.type).toBe("text");
    expect(txtActionCode.classList).toContain("textboxWidgetClass");
    expect(txtActionCode.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:EWRSendUpdate_txtActionCode")
    );
    await act(async () => {
      userEvent.type(txtActionCode, "1");
    });
  });
  test("Custom Test Cases for txtActionCode", () => {
    // START_USER_CODE-USER_txtActionCode_TEST
    // END_USER_CODE-USER_txtActionCode_TEST
  });
  test("txtCropYear(Textbox Widget) Test Cases", async () => {
    const txtCropYear = screen.getByTestId("txtCropYear");
    expect(txtCropYear.tagName).toBe("INPUT");
    expect(txtCropYear.type).toBe("text");
    expect(txtCropYear.classList).toContain("textboxWidgetClass");
    expect(txtCropYear.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:EWRSendUpdate_txtCropYear")
    );
    await act(async () => {
      userEvent.type(txtCropYear, "1");
    });
  });
  test("Custom Test Cases for txtCropYear", () => {
    // START_USER_CODE-USER_txtCropYear_TEST
    // END_USER_CODE-USER_txtCropYear_TEST
  });
  test("txtFromHolderID(Textbox Widget) Test Cases", async () => {
    const txtFromHolderID = screen.getByTestId("txtFromHolderID");
    expect(txtFromHolderID.tagName).toBe("INPUT");
    expect(txtFromHolderID.type).toBe("text");
    expect(txtFromHolderID.classList).toContain("textboxWidgetClass");
    expect(txtFromHolderID.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:EWRSendUpdate_txtFromHolderID")
    );
    await act(async () => {
      userEvent.type(txtFromHolderID, "1");
    });
  });
  test("Custom Test Cases for txtFromHolderID", () => {
    // START_USER_CODE-USER_txtFromHolderID_TEST
    // END_USER_CODE-USER_txtFromHolderID_TEST
  });
  test("txtReceiptNumber(Textbox Widget) Test Cases", async () => {
    const txtReceiptNumber = screen.getByTestId("txtReceiptNumber");
    expect(txtReceiptNumber.tagName).toBe("INPUT");
    expect(txtReceiptNumber.type).toBe("text");
    expect(txtReceiptNumber.classList).toContain("textboxWidgetClass");
    expect(txtReceiptNumber.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:EWRSendUpdate_txtReceiptNumber")
    );
    await act(async () => {
      userEvent.type(txtReceiptNumber, "1");
    });
  });
  test("Custom Test Cases for txtReceiptNumber", () => {
    // START_USER_CODE-USER_txtReceiptNumber_TEST
    // END_USER_CODE-USER_txtReceiptNumber_TEST
  });
  test("txtToHolderID(Textbox Widget) Test Cases", async () => {
    const txtToHolderID = screen.getByTestId("txtToHolderID");
    expect(txtToHolderID.tagName).toBe("INPUT");
    expect(txtToHolderID.type).toBe("text");
    expect(txtToHolderID.classList).toContain("textboxWidgetClass");
    expect(txtToHolderID.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:EWRSendUpdate_txtToHolderID")
    );
    await act(async () => {
      userEvent.type(txtToHolderID, "1");
    });
  });
  test("Custom Test Cases for txtToHolderID", () => {
    // START_USER_CODE-USER_txtToHolderID_TEST
    // END_USER_CODE-USER_txtToHolderID_TEST
  });
  test("txtToWarehouseCode(Textbox Widget) Test Cases", async () => {
    const txtToWarehouseCode = screen.getByTestId("txtToWarehouseCode");
    expect(txtToWarehouseCode.tagName).toBe("INPUT");
    expect(txtToWarehouseCode.type).toBe("text");
    expect(txtToWarehouseCode.classList).toContain("textboxWidgetClass");
    expect(txtToWarehouseCode.previousElementSibling.textContent).toEqual(
      t("WarehouseReceipts:EWRSendUpdate_txtToWarehouseCode")
    );
    await act(async () => {
      userEvent.type(txtToWarehouseCode, "1");
    });
  });
  test("Custom Test Cases for txtToWarehouseCode", () => {
    // START_USER_CODE-USER_txtToWarehouseCode_TEST
    // END_USER_CODE-USER_txtToWarehouseCode_TEST
  });
});
