import React from "react";
import Form from "react-bootstrap/Form";
import { getWidgetClasses } from "../ParentWidgetFunc";

function WhiteSpaceWidget (props) {
    let conf = props.conf
    let screenConf = props.screenConf
    return (
      <Form.Group
        className={getWidgetClasses(conf,screenConf).join(" ")}
        controlId={conf.name}
        data-testid={conf.name}
      ></Form.Group>
    );
  }


export default WhiteSpaceWidget;
