/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  DropDownWidget,
  CommandContainerWidget,
  ButtonWidget,
  LabelWidget,
  setValue,
  getValue,
  getData,
  setData,
} from "../../shared/WindowImports";

import "./BuyingPointTransfer.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import Loading from "../../../Loader/Loading";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { SystemMaintenanceMasterManagementService } from "../Service/SystemMaintenanceMasterManagementServices";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceMasterManagement_BuyingPointTransfer(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "BuyingPointTransfer",
    windowName: "BuyingPointTransfer",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceMasterManagement.BuyingPointTransfer",
    // START_USER_CODE-USER_BuyingPointTransfer_PROPERTIES
    // horizontalForm:true,
    headerData: {
      scrName: "Buying Point Transfer",
      scrCode: "PN0020E",
    },
    // END_USER_CODE-USER_BuyingPointTransfer_PROPERTIES
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    btnOk: {
      name: "btnOk",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Ok",
      CharWidth: "8",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnOk_PROPERTIES

      // END_USER_CODE-USER_btnOk_PROPERTIES
    },
    ddTransferBuyingPoint: {
      name: "ddTransferBuyingPoint",
      type: "DropDownFieldWidget",
      parent: "grpbxBuyingPointDetails",
      Label: "Transfer Buying Point:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddTransferBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_ddTransferBuyingPoint_PROPERTIES
    },
    lblBuyingPoint: {
      name: "lblBuyingPoint",
      type: "LabelWidget",
      parent: "grpbxBuyingPointDetails",
      Label: "Buying Point:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_lblBuyingPoint_PROPERTIES
    },
    lblBuyingPointValue: {
      name: "lblBuyingPointValue",
      type: "LabelWidget",
      parent: "grpbxBuyingPointDetails",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblBuyingPointValue_PROPERTIES

      // END_USER_CODE-USER_lblBuyingPointValue_PROPERTIES
    },
    grpbxBuyingPointDetails: {
      name: "grpbxBuyingPointDetails",
      type: "GroupBoxWidget",
      parent: "BuyingPointTransfer",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxBuyingPointDetails_PROPERTIES

      // END_USER_CODE-USER_grpbxBuyingPointDetails_PROPERTIES
    },
    cmmndCntnrActions: {
      name: "cmmndCntnrActions",
      type: "CommandContainerWidget",
      parent: "BuyingPointTransfer",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrActions_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const [loading, setLoading] = useState(true);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  const compIdFromLS = () => (sessionStorage.getItem('compId'));
  const cropYearFromLS = () => (JSON.parse(sessionStorage.getItem('year')));
  let cropyear = cropYearFromLS();
  let compId = compIdFromLS();
  
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad();
    ddTransferBuyingPoint();
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  // START_USER_CODE-USER_METHODS
//For form load functionality
  async function FormLoad() {    
    //For binding buying point level
    let byingpointdetails=getData(thisObj,"BPSetupTransferBPData");
    if(byingpointdetails!="" && byingpointdetails!=null && byingpointdetails!=undefined)
    {
        setValue(thisObj,"lblBuyingPointValue",byingpointdetails.lblBuyingPoint)
        document.getElementById("btnOk").innerText = byingpointdetails.btnOk;
    }  
    setLoading(false)  
  };
  // Method to bind Transfer Buying Point deopdown
  const ddTransferBuyingPoint = async () => {
    setLoading(true)
    let js = []
    let response = await ContractManagementService.RetrieveBuyingPointControlList(compId, cropyear)
    if (response !== undefined && response !== null) {
      for (var i = 0; i < response.length; i++) {
        js.push({
          key         : response[i].buy_pt_id,
          description : response[i].buy_pt_id + ' - ' + response[i].buy_pt_name
        })
      }
    }
    thisObj.setState(current => {
      return {
        ...current,
        ddTransferBuyingPoint: {
          ...state["ddTransferBuyingPoint"],
          valueList: js
        }
      }
    })
    setValue(thisObj, 'ddTransferBuyingPoint', js.at(0).key);
    setLoading(false)
  }
  
  // For Add Button functionality
  const onbtnOkClick = async () => {
    setLoading(true)
    let response = ''
    let dataObj={};
    let BuyingPointDetail = getData(thisObj,"BPSetupTransferBPData")   
    let buyingPoint = BuyingPointDetail.BuyingPointId
    let XferBuyiungPoint =  getValue(thisObj, "ddTransferBuyingPoint")
    dataObj = {};  
    let flag = BuyingPointDetail.btnOk;
      if (flag == 'Add') {
          dataObj["buy_pt_id"] = buyingPoint;
          dataObj["xfer_buy_pt_id"] = XferBuyiungPoint;              
      }  
      response = await SystemMaintenanceMasterManagementService.CreateXferBuyingPoint(buyingPoint, dataObj)
      if(response.status == 200){
        setData(thisObj,'closingOfBuyingPointTransferProfile',true);     
        document.getElementById("SystemMaintenanceMasterManagement_BuyingPointTransferPopUp").childNodes[0].click();  
        setLoading(false)
      }
      else{
        showMessage(thisObj, "An error occured while updating the database.  Please verify that your data is correct and retry. Contact your system administrator if this problem persists.         "+response.message, false);        
        setLoading(false)
      }
  };
  thisObj.onbtnOkClick = onbtnOkClick;
// For Cancel Button
  const onbtnCancelClick = () => {
    try {
      setLoading(true)
      document.getElementById("SystemMaintenanceMasterManagement_BuyingPointTransferPopUp").childNodes[0].click()
      
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
        setLoading(false)
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnCancel event:Click"
        );
      }
      return false;
    }
    finally{
      setLoading(false);
    }
    return true;
  };
  thisObj.onbtnCancelClick = onbtnCancelClick;
  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            <Loading loading={loading}></Loading>
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData}/>
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_BuyingPointTransfer*/}

              {/* END_USER_CODE-USER_BEFORE_BuyingPointTransfer*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxBuyingPointDetails*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxBuyingPointDetails*/}

              <GroupBoxWidget
                conf={state.grpbxBuyingPointDetails}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_lblBuyingPoint*/}

                {/* END_USER_CODE-USER_BEFORE_lblBuyingPoint*/}

                <LabelWidget
                  values={values}
                  conf={state.lblBuyingPoint}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblBuyingPoint*/}

                {/* END_USER_CODE-USER_AFTER_lblBuyingPoint*/}
                {/* START_USER_CODE-USER_BEFORE_lblBuyingPointValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblBuyingPointValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblBuyingPointValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblBuyingPointValue*/}

                {/* END_USER_CODE-USER_AFTER_lblBuyingPointValue*/}
                {/* START_USER_CODE-USER_BEFORE_ddTransferBuyingPoint*/}

                {/* END_USER_CODE-USER_BEFORE_ddTransferBuyingPoint*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddTransferBuyingPoint}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddTransferBuyingPoint*/}

                {/* END_USER_CODE-USER_AFTER_ddTransferBuyingPoint*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxBuyingPointDetails*/}

              {/* END_USER_CODE-USER_AFTER_grpbxBuyingPointDetails*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrActions}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnOk*/}

                {/* END_USER_CODE-USER_BEFORE_btnOk*/}

                <ButtonWidget
                  conf={state.btnOk}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnOk*/}

                {/* END_USER_CODE-USER_AFTER_btnOk*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* START_USER_CODE-USER_AFTER_BuyingPointTransfer*/}

              {/* END_USER_CODE-USER_AFTER_BuyingPointTransfer*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(
  SystemMaintenanceMasterManagement_BuyingPointTransfer
);
